import {
  Image,
  ImageSourcePropType,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import React, {useEffect} from 'react'
import {useFocusEffect, useNavigation} from '@react-navigation/native'
import {Link} from '../util/Link'
import {ClaimBtn} from './ClaimBtn'
import {Day} from './Day'
import {NavigationProp} from 'lib/routes/types'
import {ProgressBar} from './ProgressBar'
import {ShareButton} from './ShareButton'
import {Text} from '../util/text/Text'
import {colors} from 'lib/styles'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {s3ToCdn} from 'lib/media/util'
import {useAnalytics} from 'lib/analytics/analytics'
import {usePalette} from 'lib/hooks/usePalette'
import {useSplxWallet} from '../wallet/useSplxWallet'
import {useStores} from 'state/index'
import LinearGradient from 'react-native-linear-gradient'
import {TrophyIcon} from 'lib/icons'
import {Button} from '../util/forms/Button'
import {RewardClaimButtonNavigate} from './RewardClaimBtns'
import {useTheme} from 'lib/ThemeContext'
import {isMobileWeb} from 'platform/detection'
import {Mission} from 'state/rewards'
import * as Toast from '../util/Toast.web'

import {ProfileViewDetailed} from '@usedispatch/atproto-api/dist/client/types/app/bsky/actor/defs'
import {makeProfileLink} from 'lib/routes/links'
import {removeSuffixFromHandle} from 'lib/strings/handles'
import {sanitizeDisplayName} from 'lib/strings/display-names'
import {CollectibleViewBasic} from '@usedispatch/atproto-api/dist/client/types/app/bsky/splx/defs'

interface RewardClaimedProps {
  userId: string
  img: string
}

interface UserRewardProps {
  userId: string
  style?: StyleProp<ViewStyle>
  missionId: string
  collectibleInfo?: CollectibleViewBasic
}

interface UserClaimProps extends Omit<UserRewardProps, 'collectibleInfo'> {
  source: ImageSourcePropType
  title: string
  desc: string
}

function RewardClaimedImage({img}: RewardClaimedProps) {
  return (
    <View style={styles.DiceRollImageContainer}>
      <Image
        accessibilityIgnoresInvertColors
        source={{
          uri: s3ToCdn(img || '', 512),
        }}
        accessible={true}
        accessibilityLabel={'reward image'}
        accessibilityHint=""
        resizeMode="contain"
        style={styles.rewardImage}
      />
    </View>
  )
}

type PostRewardClaimedProps = {
  rewardsImg?: string
  userId: string
  shareText: string
  missionId?: string
}

function PostRewardClaimedButton({
  userId,
  shareText,
  rewardsImg,
}: PostRewardClaimedProps) {
  return (
    <View style={styles.buttonGroup}>
      <ShareButton
        shareText={shareText}
        userId={userId}
        rewardsImg={rewardsImg}
      />
    </View>
  )
}

// Collectibles
// if wallet not connect and not linked,
// if wallet linked but not connected,
// if wallet connected and linked
// if wallet connected and not linked

// Reactions
// if wallet not connect and not linked,
// if wallet linked but not connected,
// if wallet connected and linked
// if wallet connected and not linked

// View Reactions
// if wallet not connect and not linked,
// if wallet linked but not connected,
// if wallet connected and linked
// if wallet connected and not linked

// TODO:(Pratik) this is for sure a better way to write this up and remove this conditional shit
const RewardClaimButton = observer(function RewardClaimButton({
  userId,
  missionId,
}: {
  userId: string
  missionId: string
  creatorReward?: CollectibleViewBasic
}) {
  const store = useStores()
  const [
    _visible,
    setVisible,
    linkedWallet,
    _walletAddressFromModel,
    connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
    linkWalletIsBusy,
  ] = useSplxWallet(true)
  // const isDone = store.rewards.hasClaimedMission(userId, missionId)
  const mission = store.rewards.mission(missionId)
  const isDone =
    store.rewards.hasClaimedMission(userId, missionId) ||
    store.rewards.claimedCollectibles.includes(mission?.reward as string)
  const shouldClaim =
    mission?.mechanic === 'weekly'
      ? mission?.progress?.percent === 1 && !mission?.reward && !isDone
      : store.rewards.shouldClaimMission(userId, missionId)
  const isClaiming = store.rewards.isClaimingMission(userId, missionId)
  const missionReward = store.rewards.missionReward(userId, missionId)
  const defaultText =
    mission?.mechanic === 'weekly' ? 'Unlock Reward' : 'Roll for your reward!'
  const [atprotoClaimLoading, setAtprotoClaimLoading] = React.useState(false)
  const claimingState =
    mission?.mechanic === 'weekly' ? 'Unlocking...' : 'Claiming..'
  const isDoneText = mission?.mechanic === 'weekly' ? 'Unlocked' : 'Claimed'

  //TODO(pratik): fix and verify this works as expected + clean as needed

  const text = isDone
    ? isDoneText
    : isClaiming || atprotoClaimLoading
    ? claimingState
    : linkWalletIsBusy
    ? 'Connecting...'
    : !linkedWallet
    ? 'Connect Wallet'
    : defaultText

  const {track} = useAnalytics()

  const claimHandler = React.useCallback(async () => {
    try {
      if (!linkedWallet) {
        setVisible(true)
        await store.wallet.ensureWalletConnectedAndLinked()
      }

      setAtprotoClaimLoading(true)
      await store.rewards.claimReward(userId, missionId)
      if (mission?.mechanic === 'weekly') {
        track('InnerCircle:UnlockCreatorReward', {
          creator: mission.id,
          collector: userId,
        })
      } else {
        track('Claim:Reward', {
          type: missionId,
        })
      }
      setAtprotoClaimLoading(false)
    } catch (e: any) {
      setAtprotoClaimLoading(false)
      console.error('Error claiming reward', e)
      Toast.show(`Error: ${e.message}`, {
        containerStyle: {
          backgroundColor: colors.red3,
        },
        labelStyle: {
          color: colors.red1,
        },
      })
    }
  }, [
    missionId,
    store.rewards,
    store.wallet,
    track,
    userId,
    setVisible,
    linkedWallet,
    mission?.mechanic,
    mission?.id,
  ])

  return (
    <View style={[styles.RollBtn]}>
      <ClaimBtn
        btnstyle={{
          paddingVertical: 12,
          paddingHorizontal: 24,
        }}
        done={isDone}
        disabled={!shouldClaim && !missionReward}
        loading={connectWalletIsBusy || (isClaiming && !isDone)}
        text={text}
        onClick={claimHandler}
      />
    </View>
  )
})

function RewardClaimed({userId, missionId, collectibleInfo}: UserRewardProps) {
  const store = useStores()
  const reward = store.rewards.missionReward(userId, missionId)
  const navigation = useNavigation<NavigationProp>()
  const theme = useTheme()
  const isClaimWaitingOnBlockchain = store.rewards.isClaimWaitingOnBlockchain(
    userId,
    missionId,
  )
  const [rewardCreator, setRewardCreator] =
    React.useState<ProfileViewDetailed>()
  const rewardContainsImage =
    typeof reward === 'object' ? !!reward.image : !!collectibleInfo?.imageUri
  useEffect(() => {
    ;(async () => {
      if (collectibleInfo && collectibleInfo.creator) {
        const creatorProfile = await store.agent.getProfile({
          actor: collectibleInfo.creator,
        })
        setRewardCreator(creatorProfile.data)
      }
    })()
  }, [collectibleInfo, store.agent])

  const pal = usePalette('default')
  // shareText={`Freshly Minted!  ${
  //   typeof reward === 'object'
  //     ? `Check out my new ${reward?.name} reaction!`
  //     : `I just earned a reward by joining  Inner-Circle ⭕️ reward`
  // } by ${
  //   typeof reward === 'object'
  //     ? reward?.attributes?.artist
  //     : '@' + rewardCreator?.handle || rewardCreator?.displayName
  // }`}
  const reactionShareText =
    typeof reward === 'object'
      ? `Freshly Minted!  ${`Check out my new ${reward?.name} reaction!`} by ${
          reward?.attributes?.artist
        }`
      : ''

  const collectibleShareText = `I just earned a reward by joining @${
    rewardCreator?.handle || rewardCreator?.displayName
  } Inner-Circle ⭕️! Join us! https://solarplex.xyz/rewards/missions`

  return (
    <>
      {/* @ts-ignore */}
      {reward && rewardContainsImage && (
        <RewardClaimedImage
          userId={userId}
          img={
            typeof reward === 'object'
              ? reward?.image
              : (collectibleInfo?.imageUri as string)
          }
        />
      )}
      <View style={[s.flexCol, s.alignCenter, {padding: 32}]}>
        <Text type="post-text-lg" style={[pal.text, s.bold]}>
          Congratulations!
        </Text>
        <Text
          type="lg"
          style={[{textAlign: 'center', paddingVertical: 8}, pal.text]}>
          You received{' '}
          <Text
            type="lg-bold"
            style={{
              color:
                theme.colorScheme === 'dark'
                  ? colors.white
                  : colors.splx.primary[50],
            }}>
            {typeof reward === 'object' ? reward?.name : collectibleInfo?.title}
          </Text>
        </Text>
        <Text type="lg" style={[{textAlign: 'center'}, pal.text]}>
          Continue using Solarplex to complete missions and win more awesome
          rewards!
        </Text>
        <View style={[s.flexRow, s.alignCenter, {gap: 6}]}>
          {!collectibleInfo && (
            <Button
              onPress={() => navigation.navigate('Reactions')}
              style={{
                paddingVertical: 12,
                paddingHorizontal: 24,
              }}
              labelStyle={{
                fontWeight: '700',
                color:
                  theme.colorScheme === 'dark'
                    ? colors.white
                    : colors.splx.primary[50],
              }}
              type="primary-outline"
              label={
                isClaimWaitingOnBlockchain
                  ? 'Adding to wallet...'
                  : 'View collection'
              }
            />
          )}
          {rewardContainsImage && (
            <PostRewardClaimedButton
              missionId={missionId}
              shareText={
                typeof reward === 'object'
                  ? reactionShareText
                  : collectibleShareText
              }
              rewardsImg={typeof reward === 'object' ? reward?.image : ''}
              userId={userId}
            />
          )}
        </View>
        <View style={[s.flexRow, s.alignCenter]}>
          <Button
            onPress={() => navigation.navigate('MissionsList')}
            style={{
              paddingVertical: 12,
              paddingHorizontal: 24,
            }}
            type="default-light"
            label="Return to Mission page"
          />
        </View>
      </View>
    </>
  )
}

const ClaimScreen = ({
  source,
  title,
  desc,
  RewardBtnComponent,
}: {
  source: ImageSourcePropType
  isWeekly?: boolean
  title: string
  desc: string
  userId: string
  RewardBtnComponent: React.ReactNode
}) => {
  const pal = usePalette('default')
  return (
    <>
      <View style={styles.DiceRollImageContainer}>
        <Image
          accessibilityIgnoresInvertColors
          source={source}
          style={styles.DiceRollImage}
        />
      </View>
      <View style={[s.flexCol, s.alignCenter, {padding: 32}]}>
        <Text
          type="post-text-lg"
          style={[pal.text, s.bold, {paddingBottom: 8}]}>
          {title}
        </Text>
        <Text type="lg" style={[{textAlign: 'center'}, pal.text]}>
          {desc}
        </Text>
        {RewardBtnComponent}
      </View>
    </>
  )
}

export function RewardClaimScreen({
  userId,
  source,
  title,
  desc,
  missionId,
}: UserClaimProps) {
  const store = useStores()
  const [weeklyReward, setWeeklyReward] = React.useState<
    CollectibleViewBasic | undefined
  >()
  const missionReward = store.rewards.missionReward(userId, missionId)
  const mission = store.rewards.mission(missionId)
  const weeklyMissionCheck =
    !store.rewards.claimedCollectibles.includes(mission?.reward as string) &&
    weeklyReward
  useEffect(() => {
    ;(async () => {
      const res = await store.rewards.getRewardCollectible(
        mission?.reward as string,
      )

      setWeeklyReward(res)
    })()
  }, [mission?.reward, store.rewards])

  return (
    <View style={styles.DiceRowCol}>
      {weeklyMissionCheck || !missionReward ? (
        <ClaimScreen
          source={source}
          userId={userId}
          title={title}
          desc={desc}
          RewardBtnComponent={
            <RewardClaimButton userId={userId} missionId={missionId} />
          }
        />
      ) : (
        <>
          <RewardClaimed
            userId={userId}
            missionId={missionId}
            collectibleInfo={weeklyReward}
          />
        </>
      )}
    </View>
  )
}

export function PointsProgress({
  count,
  total,
  style,
}: {
  count: number
  total: number
  style?: StyleProp<ViewStyle>
}) {
  const pal = usePalette('default')
  return (
    <View style={[style, styles.streaksContainerBox]}>
      <Text type="xs-heavy" style={[pal.text, styles.textPadding]}>
        {count ?? 0}
        <Text type="xs-heavy" style={s.gray3}>
          /{total}
        </Text>
      </Text>
    </View>
  )
}

export function WeeklyStreakProgress({count}: {count: number}) {
  return (
    <View style={styles.streaksContainerBox}>
      <View style={styles.horizontalBox}>
        {[1, 2, 3, 4, 5, 6, 7].map((day, idx) => {
          return <Day key={idx} day={day} isCompleted={day <= (count || -1)} />
        })}
      </View>
    </View>
  )
}

type MissionHeaderProps = {
  title: string
  children?: React.ReactNode
}

const MissionHeader = ({title, children}: MissionHeaderProps) => {
  const pal = usePalette('default')
  return (
    <View style={styles.missionItemHeader}>
      <Text type="2xl-bold" style={pal.text}>
        {title}
      </Text>
      {children}
    </View>
  )
}

export const MissionImageContainer = ({image}: {image: string}) => {
  return (
    <View style={styles.imageContainer}>
      <Image
        accessibilityIgnoresInvertColors
        style={styles.missionImage}
        source={{
          uri: s3ToCdn(image, 128),
        }}
      />
    </View>
  )
}

export const MissionDivider = () => {
  const pal = usePalette('default')
  return (
    <View
      style={{
        height: StyleSheet.hairlineWidth,
        marginVertical: 7,
        backgroundColor: pal.border.borderColor,
      }}
    />
  )
}

type MissionMainItemProps = {
  image: string
  children?: React.ReactNode | null
  missionText: string
}

const MissionMainItem = ({
  children = null,
  image,
  missionText,
}: MissionMainItemProps) => {
  const pal = usePalette('default')
  return (
    <View style={styles.mainItemContainer}>
      <MissionImageContainer image={image} />
      <View style={styles.mainItemBody}>
        <Text type="sm" style={[pal.text]}>
          {missionText}
        </Text>
        {children}
      </View>
    </View>
  )
}

function getTimeUntilMidnightUTC() {
  const now = new Date()
  const tomorrowMidnightUTC = new Date(
    Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1),
  )
  const timeUntilMidnightUTC =
    (tomorrowMidnightUTC.getTime() - now.getTime()) / 1000 // convert to seconds
  const hours = Math.floor(timeUntilMidnightUTC / 3600)
  const minutes = Math.floor((timeUntilMidnightUTC % 3600) / 60)
  return {hours, minutes}
}

function DailyEndTime() {
  const pal = usePalette('default')
  const timeUntilMidnightUTC = getTimeUntilMidnightUTC()

  return (
    <Text type="xs" style={pal.text}>
      Ending in:{' '}
      <Text type="xs-bold" style={pal.text}>
        {timeUntilMidnightUTC.hours} hours {timeUntilMidnightUTC.minutes}{' '}
        minutes
      </Text>
    </Text>
  )
}

function EndTimeUI({unixTimeStamp}: {unixTimeStamp: string}) {
  const pal = usePalette('default')
  const endTime = new Date(parseInt(unixTimeStamp, 10))
  const now = new Date()
  const difference = endTime.getTime() - now.getTime()

  if (difference < 0) {
    return (
      <Text type="mono" style={pal.text}>
        Mission has expired
      </Text>
    )
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  )
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))

  return (
    <Text type="xs" style={pal.text}>
      Ending in:{' '}
      <Text type="xs-bold" style={pal.text}>
        {days} days {hours} hours {minutes} minutes
      </Text>
    </Text>
  )
}

type MissionSectionProps = {
  userId: string
}

const DailyMissionSection = observer(function DailyMissionSection({
  userId,
}: MissionSectionProps) {
  const pal = usePalette('default')
  const store = useStores()
  const dailyMissionId = store.rewards.dailyMissionId(userId)
  const dailyProgress = store.rewards.missionProgress(userId, dailyMissionId)
  const bannerImg = store.rewards.getRewardsBannerImage(userId, dailyMissionId)
  return (
    <View style={[styles.missionContainer, pal.border]}>
      <MissionHeader title="Daily Mission">
        <DailyEndTime />
      </MissionHeader>
      <MissionMainItem
        missionText="Create quality content and engage with others to earn 50 points in
              a day and unlock a collectible Reaction!"
        image={bannerImg || ''}>
        <View style={[s.flexRow, {alignItems: 'center', paddingTop: 7}]}>
          <View style={{paddingRight: 4}}>
            <Text type="xs" style={s.gray3}>
              POINTS
            </Text>
            <PointsProgress
              style={{paddingRight: 11}}
              count={!store.session ? 0 : dailyProgress.count}
              total={50}
            />
          </View>

          <ProgressBar progressValue={dailyProgress.count} totalProgress={50} />
        </View>
      </MissionMainItem>
      <MissionDivider />
      <View style={styles.mainItemContainer}>
        <InfoChip
          text="Common Reaction"
          GradientColors={['#E1E1E5', '#E1E1E5']}
        />
        {/* <DailyRewardClaimButton userId={userId} /> */}
        <RewardClaimButtonNavigate userId={userId} missionId={dailyMissionId} />
      </View>
    </View>
  )
})

// const PepeMissionSection = observer(({userId}: MissionSectionProps) => {
//   const pal = usePalette('default')
//   const store = useStores()
//   const pepeClownMissionId = store.rewards.pepeClownMissionId(userId)
//   const missionProgress = store.rewards.missionProgress(
//     userId,
//     pepeClownMissionId,
//   )
//   const bannerImg = store.rewards.getRewardsBannerImage(
//     userId,
//     pepeClownMissionId,
//   )

//   return (
//     <View style={[styles.missionContainer, pal.border]}>
//       <MissionHeader title="Halloween mission">
//         <DailyEndTime />
//       </MissionHeader>
//       <MissionMainItem
//         missionText="It's spooky szn! Craft haunting #memes and engage with the community to earn 100 points. Complete this bonus mission to unlock an exclusive and limited edition spooky Pepe Reaction!"
//         image={bannerImg || ''}>
//         <View style={[s.flexRow, {paddingTop: 7}]}>
//           <PointsProgress
//             style={{paddingRight: 11}}
//             count={!store.session ? 0 : missionProgress.count}
//             total={100}
//           />

//           <ProgressBar
//             progressValue={missionProgress.count}
//             totalProgress={100}
//           />
//         </View>
//       </MissionMainItem>
//       <MissionDivider />
//       <View style={styles.mainItemContainer}>
//         <InfoChip
//           text="Pepe Reaction"
//           GradientColors={['#FF33A0', '#FFDD00']}
//         />
//         <RewardClaimButtonNavigate
//           userId={userId}
//           missionId={pepeClownMissionId}
//         />
//       </View>
//     </View>
//   )
// })

type InfoChipProps = {
  text: string
  GradientColors: string[]
  textColor?: string
}

// This is a Badge which is part of the mission card UI
const InfoChip = ({text, GradientColors, textColor}: InfoChipProps) => {
  return (
    <LinearGradient
      start={{x: 0, y: 0}}
      end={{x: 0, y: 1}}
      colors={GradientColors}
      style={{
        paddingVertical: isMobileWeb ? 4 : 8,
        paddingHorizontal: isMobileWeb ? 8 : 12,
        borderRadius: 3,
      }}>
      <View style={[s.flexRow, {flex: 1}]}>
        <TrophyIcon />
        <Text
          type="xs-bold"
          style={{
            paddingHorizontal: 4,
            color: textColor || colors.splx.primary[50],
            borderRadius: 3,
          }}>
          {text}
        </Text>
      </View>
    </LinearGradient>
  )
}

const CreatorMissionSection = observer(function CreatorMissionSectionImpl({
  mission,
}: {
  mission: Mission
}) {
  const store = useStores()
  const pal = usePalette('default')
  const [missionCreatorProfile, setMissionCreatorProfile] =
    React.useState<ProfileViewDetailed>()

  const did = store.session?.currentSession?.did ?? ''
  const missionId = mission.id
  const missionData = store.rewards.missionData(mission.id)

  React.useEffect(() => {
    if (missionData?.did_constraint !== undefined) {
      async function fetchProfile() {
        const profile = await store.profiles.getProfile(
          missionData?.did_constraint ?? '',
        )
        if (profile.success) {
          setMissionCreatorProfile(profile.data)
        }
      }
      fetchProfile()
    }
  }, [store, missionData?.did_constraint])

  if (!missionData) {
    return null
  }

  const missionTitle =
    missionData.title ||
    (missionCreatorProfile &&
      `${sanitizeDisplayName(
        missionCreatorProfile.displayName ||
          removeSuffixFromHandle(missionCreatorProfile.handle),
      )}'s Mission`) ||
    'Loading Mission'
  const missionDesc = missionData?.description || 'Loading Mission Desc'
  const missionImg = missionData?.image_url || ''
  const missionProgress = Math.min(
    mission.progress.count,
    mission.progress.endValue,
  )

  const missionEndValue = mission.progress.endValue
  return (
    <View style={[styles.missionContainer, pal.border]}>
      <Link
        href={missionCreatorProfile && makeProfileLink(missionCreatorProfile)}>
        <MissionHeader title={missionTitle}>
          {missionData?.end_time !== undefined && (
            <EndTimeUI unixTimeStamp={missionData.end_time} />
          )}
        </MissionHeader>
      </Link>
      <MissionMainItem missionText={missionDesc} image={missionImg}>
        <View style={[s.flexRow, {paddingTop: 7}]}>
          <PointsProgress
            style={{paddingRight: 11}}
            count={!store.session ? 0 : missionProgress}
            total={missionEndValue}
          />
          <ProgressBar
            progressValue={missionProgress}
            totalProgress={missionEndValue}
          />
        </View>
      </MissionMainItem>
      <MissionDivider />
      <View style={styles.mainItemContainer}>
        <InfoChip
          text="Limited Edition"
          GradientColors={['#ffb549', '#ffb549']}
          textColor="black"
        />
        <RewardClaimButtonNavigate
          userId={did}
          missionId={missionId}
          creatorHandle={missionCreatorProfile?.handle}
        />
      </View>
    </View>
  )
})

const WeeklyMissionSection = observer(function WeeklyMissionSection({
  userId,
}: Pick<MissionSectionProps, 'userId'>) {
  const store = useStores()
  const pal = usePalette('default')

  const weeklyMissionId = store.rewards.weeklyMissionId(userId)
  const weeklyProgress = store.rewards.missionProgress(userId, weeklyMissionId)
  const bannerImg = store.rewards.getRewardsBannerImage(userId, weeklyMissionId)
  return (
    <View style={[styles.missionContainer, pal.border]}>
      <MissionHeader title="Weekly Mission" />
      <MissionMainItem
        missionText="Complete 7 Daily Missions in a row to earn a rare collectible reaction!"
        image={bannerImg || ''}>
        <View style={[s.flexRow, {paddingTop: 7}]}>
          <PointsProgress
            count={!store.session ? 0 : weeklyProgress.count}
            total={7}
            style={{paddingRight: 11}}
          />
          <WeeklyStreakProgress
            count={!store.session ? 0 : weeklyProgress.count}
          />
        </View>
      </MissionMainItem>
      <MissionDivider />
      <View style={styles.mainItemContainer}>
        <InfoChip
          text="Rare Reaction"
          GradientColors={['#E17032', '#FFB549']}
        />
        {/* <WeeklyRewardClaimButton userId={userId} />
         */}
        <RewardClaimButtonNavigate
          userId={userId}
          missionId={weeklyMissionId}
        />
      </View>
    </View>
  )
})

export const MissionContainer = observer(function MissionContainer({
  userId,
}: {
  userId: string
}) {
  const store = useStores()
  const pal = usePalette('default')
  const {screen} = useAnalytics()
  useFocusEffect(
    React.useCallback(() => {
      screen('Rewards')
      store.rewards.fetchMissionData()
      if (userId !== '') {
        store.rewards.fetchMissionData()

        store.rewards.fetchMissions(userId)
      }
    }, [store, userId, screen]),
  )

  // const creatorMissionId = store.rewards.creatorMissionId(userId)

  const creatorMissions = store.rewards.creatorMissionList

  return (
    <View style={[styles.outer, s.h100pct]}>
      <Text
        type="title-xl"
        style={[s.mb20, pal.text, s.bold, s.borderBottom1, pal.borderDark]}>
        Missions
      </Text>
      <View style={[s.flexCol, {gap: 16}]}>
        <DailyMissionSection userId={userId} />
        <WeeklyMissionSection userId={userId} />
        <Text
          type="title-xl"
          style={[s.mb5, pal.text, s.bold, s.borderBottom1, pal.borderDark]}>
          Inner-Circle Missions ⭕️
        </Text>
        <View
          style={[
            pal.viewLight,
            {padding: 8, flexDirection: 'column', alignItems: 'flex-start'},
          ]}>
          <Text style={[pal.text, {textAlign: 'center'}]}>
            🚀 Introducing the Solarplex Inner Circle ⭕️
          </Text>
          <Text style={[pal.text, s.pt5, {textAlign: 'center'}]}>
            🔥 A revolutionary collector experience.
          </Text>
          <Text style={[pal.text, s.pt5, {textAlign: 'center'}]}>
            🎁 Get rewarded for being a loyal collector!
          </Text>
        </View>
        {creatorMissions.map(mission => {
          return <CreatorMissionSection key={mission.id} mission={mission} />
        })}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  buttonGroup: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  leftgroup: {
    paddingVertical: 10,
  },
  claimBtnContainer: {
    flexShrink: 1,
  },
  dataContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 10,
    width: '100%',
  },
  imageContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 3,
    borderWidth: 11,
    borderColor: '#F4F4F7',
    backgroundColor: s.gray3.color,
  },
  missionImage: {
    width: 41,
    height: 41,
    resizeMode: 'contain',
  },
  missionContainer: {
    flexDirection: 'column',
    padding: 16,
    borderWidth: 1,
    borderRadius: 3,
  },
  outer: {
    paddingTop: 10,
    paddingHorizontal: 24,
    paddingBottom: isMobileWeb ? 40 : 80,
  },
  streaks: {
    flexDirection: 'column',
    paddingHorizontal: 14,
    flexShrink: 1,
    paddingVertical: 10,
  },
  paraTextPadding: {
    paddingBottom: 4,
    textAlign: 'left',
  },
  textPadding: {
    width: '100%',
  },
  para: {
    fontSize: 16,
    paddingTop: 4,
    textTransform: 'capitalize',
  },
  streaksContainerBox: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  streaksText: {
    fontWeight: '600',
    fontSize: 18,
  },
  horizontalBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  dailyPoints: {
    fontWeight: '800',
    fontSize: 24,
    color: colors.splx.primary[50],
    // textAlign: "center",
  },
  DiceRowCol: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  DiceRollImageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    width: '100%',
    backgroundColor: colors.splx.neutral[10],
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  DiceRollText: {
    paddingVertical: 6,
  },
  RollBtn: {
    width: isMobileWeb ? '85%' : '50%',
    paddingVertical: 8,
  },
  DiceRollImage: {
    width: 200,
    height: 150,
    borderRadius: 12,
  },
  rewardImage: {
    width: 250,
    height: 250,
  },
  rewardClaimedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 24,
    paddingHorizontal: 16,
    flex: 1,
    backgroundColor: colors.splx.neutral[10],
  },
  missionItemHeader: {
    flexDirection: 'row',
    flex: 1,
    alignItems: 'center',
    width: '100%',
    paddingBottom: 7,
    justifyContent: 'space-between',
  },
  mainItemContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  progressContainer: {
    height: 20,
    backgroundColor: colors.splx.neutral[10],
    borderRadius: 10,
    margin: 10,
  },
  bar: {
    height: 20,
    backgroundColor: colors.splx.primary[50],
    borderRadius: 10,
  },
  mainItemBody: {
    flexDirection: 'column',
    flex: 1,
    paddingHorizontal: 9,
  },
})
