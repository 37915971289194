import {Platform} from 'react-native'
import {getLocales} from 'expo-localization'
import {dedupArray} from 'lib/functions'

export const isIOS = Platform.OS === 'ios'
export const isAndroid = Platform.OS === 'android'
export const isNative = isIOS || isAndroid
export const devicePlatform = isIOS ? 'ios' : isAndroid ? 'android' : 'web'
export const isWeb = !isNative
export const isMobileWebMediaQuery = 'only screen and (max-width: 1300px)'
export const isMobileWeb =
  isWeb &&
  // @ts-ignore we know window exists -prf
  global.window.matchMedia(isMobileWebMediaQuery)?.matches

export const isDesktopWeb = isWeb && !isMobileWeb
export const isPWA =
  Platform.OS === 'web' &&
  window.matchMedia('(display-mode: standalone)').matches
export const deviceLocales = dedupArray(
  getLocales?.().map?.(locale => locale.languageCode),
)

type Browser =
  | 'Chrome'
  | 'Safari'
  | 'Firefox'
  | 'IE'
  | 'Edge'
  | 'Opera'
  | 'Unknown'

export function getBrowser(): Browser {
  if (typeof window === 'undefined') {
    return 'Unknown' // Browser detection is only applicable in a browser environment
  }

  if (
    typeof (window as any).chrome !== 'undefined' &&
    typeof (window as any).chrome.app !== 'undefined'
  ) {
    return 'Chrome'
  }

  if (
    typeof (window as any).safari !== 'undefined' &&
    typeof (window as any).safari.pushNotification !== 'undefined'
  ) {
    return 'Safari'
  }

  if (typeof (window as any).InstallTrigger !== 'undefined') {
    return 'Firefox'
  }

  if (
    typeof (window as any).opr !== 'undefined' &&
    typeof (window as any).opr.addons !== 'undefined'
  ) {
    return 'Opera'
  }

  if (
    typeof (window as any).msCrypto !== 'undefined' ||
    typeof window.crypto !== 'undefined'
  ) {
    return 'Edge'
  }

  if (typeof (window as any).document.documentMode !== 'undefined') {
    return 'IE'
  }

  return 'Unknown'
}
