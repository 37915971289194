/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-shadow */
import React, {useState} from 'react'
import {Text} from '../text/Text'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {usePalette} from 'lib/hooks/usePalette'
import {
  AppBskyActorDefs,
  AppBskyEmbedFrame,
  AppBskyFeedDefs,
  AppBskySplxPostFrameAction,
} from '@usedispatch/atproto-api'
import {toNiceDomain} from 'lib/strings/url-helpers'
import {Button} from '../forms/Button'
import {colors, s} from 'lib/styles'
import {useStores} from 'state/index'
import {AutoSizedImage} from '../images/AutoSizedImage'
import {isDesktopWeb, isMobileWeb} from 'platform/detection'
import {ImagesLightbox} from 'state/models/ui/shell'
import * as Toast from '../Toast.web'
import {track} from 'lib/analytics/analytics'
import {useRequireAuth} from 'view/com/auth/withAuthRequired'
import {Link} from '../Link'
import {useSplxWallet} from 'view/com/wallet/useSplxWallet'
import {Connection} from '@solana/web3.js'
import {MAINNET_RPC_API} from 'lib/constants'
import {TextInput} from 'view/com/auth/util/TextInput'
import {ScrollView} from 'react-native-gesture-handler'

interface FrameEmbedProps {
  initialFrame: AppBskyEmbedFrame.ViewFrame | undefined
  itemCid?: string
  itemUri?: string
  author?: AppBskyActorDefs.ProfileViewBasic | any
  imageChild?: React.ReactNode
  post?: AppBskyFeedDefs.PostView
  onFrameUpdate?: (newFrame: AppBskyEmbedFrame.ViewFrame) => void
}

export const FrameEmbed: React.FC<FrameEmbedProps> = ({
  initialFrame,
  itemCid,
  itemUri,
  author,
  post,
  onFrameUpdate,
}) => {
  const [frame, setFrame] = useState<AppBskyEmbedFrame.ViewFrame | undefined>(
    initialFrame,
  )
  const pal = usePalette('default')
  const store = useStores()
  const requireAuth = useRequireAuth(author, itemUri)
  const [
    _visible,
    setVisible,
    _linkedWallet,
    _connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
  ] = useSplxWallet(false)
  const [txnStatus, setTxnStatus] = useState<string>('')
  const [frameTextInput, setFrameTextInput] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const buttonPostHandler = async (
    button: AppBskyEmbedFrame.FrameButtonMetadata,
    frame: AppBskyEmbedFrame.ViewFrame | undefined,
    txnSignature?: string,
  ) => {
    try {
      setIsLoading(true)
      // TODO(zfaizal2) turn into generic frame action
      if (frame?.post_url?.includes('memecoin')) {
        setVisible(true)
        await store.wallet.ensureWalletConnectedAndLinked()
        if (!store.wallet.state.connectedWalletId) {
          throw new Error('No wallet connected')
        }
      }
      // END
      const res: AppBskySplxPostFrameAction.Response =
        await store.appviewAgent.postFrameAction({
          frameActionIndex: button.index,
          framePostUrl: frame?.post_url,
          itemCid: post?.cid,
          itemUri: post?.uri,
          author: author.did,
          did: store.me.did,
          // TODO(zfaizal2): get this from UI if destination frame is giving an input
          inputText: frameTextInput,
          followsAuthor: store.me.follows.getFollowState(author.did),
          linkedWallet: store.wallet.linkedWallet,
          connectedWallet: store.wallet.walletId,
          likedPost: post ? !!post.viewer?.like : false,
          repostedPost: post ? !!post.viewer?.repost : false,
          txnSignature,
        })
      const result = res.data?.result as any
      if (
        res.data &&
        (res.data?.result as any)?.success &&
        result?.isRedirect
      ) {
        const userAgreed = window.confirm(
          'This frame is redirecting you to a different URL. Solarplex does not take responsibility of that page. Click OK to continue.',
        )
        if (userAgreed) {
          window.open(result?.redirectUrl, '_blank')
        } else {
          console.log('Redirect cancelled by the user.')
        }
        setIsLoading(false)
      } else if (res.data && result.success && result?.frame) {
        const newFrame: AppBskyEmbedFrame.ViewFrame = {
          ...result?.frame,
          title: result?.ogTags.title,
        }
        setIsLoading(false)
        setFrame(newFrame)
        onFrameUpdate?.(newFrame)
      }
    } catch (e: any) {
      setIsLoading(false)
      Toast.show(e.message, {
        containerStyle: {
          backgroundColor: colors.red3,
        },
        labelStyle: {
          color: colors.red1,
        },
      })
    }
  }

  const txnButtonHandler = async (
    button: AppBskyEmbedFrame.FrameButtonMetadata,
    frame: AppBskyEmbedFrame.ViewFrame | undefined,
  ) => {
    try {
      const connection = new Connection(MAINNET_RPC_API)

      if (!store.wallet.state.connectedWalletId) {
        setVisible(true)
        await store.wallet.ensureWalletConnectedAndLinked()
      }
      if (!store.wallet.state.connectedWalletId) {
        throw new Error('No wallet connected')
      }
      const postUrl = (button.post_url as string) ?? frame?.post_url
      const transactionsReq = await fetch(postUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          untrustedData: {
            frameActionIndex: button.index,
            framePostUrl: frame?.post_url,
            itemCid: itemCid,
            itemUri: itemUri,
            author: author.did,
            did: store.me.did,
            // TODO(zfaizal2): get this from UI if destination frame is giving an input
            inputText: frameTextInput,
            followsAuthor: store.me.follows.getFollowState(author.did),
            linkedWallet: store.wallet.linkedWallet,
            connectedWallet: store.wallet.walletId,
            likedPost: post ? !!post.viewer?.like : false,
            repostedPost: post ? !!post.viewer?.repost : false,
          },
        }),
      })
      if (store.wallet.walletPopup?.signTransaction) {
        const res = await transactionsReq.json()
        if (!res.encodedTxn || !res.lastValidBlockHeight) {
          throw new Error(
            'Invalid transaction, please contact the frame developer.',
          )
        }
        const txn = store.me.nft.getDeserializedTxn(res.encodedTxn)
        const signedTx = await store.wallet.walletPopup?.signTransaction(txn)
        setTxnStatus('Processing...')
        if (!signedTx) {
          throw new Error('Could not sign transaction')
        }
        const serializedTxn = signedTx?.serialize()
        const txnSignature = await connection.sendRawTransaction(serializedTxn)
        // TODO(zfaizal2): use TransactionConfirmationStrategy
        await connection.confirmTransaction(
          {
            blockhash: txn.message.recentBlockhash,
            lastValidBlockHeight: res.lastValidBlockHeight,
            signature: txnSignature,
          },
          'confirmed',
        )
        await buttonPostHandler(button, frame, txnSignature)
        setTxnStatus('Success!')
      }
    } catch (e: any) {
      setTxnStatus('Failed, try again.')
      Toast.show(e.message, {
        containerStyle: {
          backgroundColor: colors.red3,
        },
        labelStyle: {
          color: colors.red1,
        },
      })
    }
  }

  const shareButtonHandler = async (
    button: AppBskyEmbedFrame.FrameButtonMetadata,
    frame: AppBskyEmbedFrame.ViewFrame | undefined,
  ) => {
    store.shell.openModal({
      name: 'frame-share',
      text: button.text ?? '',
      frameUri: frame?.uri ?? '',
    })
  }

  return (
    <View style={[pal.border, styles.extContainer]}>
      <Text
        type="sm"
        numberOfLines={isDesktopWeb ? 2 : 4}
        style={[
          pal.text,
          {color: colors.white},
          styles.extBold,
          styles.extNFTMeta,
        ]}>
        {frame?.title}
      </Text>
      <View style={[pal.borderDark, styles.extImageContainer]}>
        <AutoSizedImage
          style={[styles.extImage, isMobileWeb && styles.singleImageMobile]}
          uri={frame?.image || ''}
          onPress={() =>
            frame?.image &&
            store.shell.openLightbox(
              new ImagesLightbox([{uri: frame.image}], 0),
            )
          }
        />
      </View>
      {frame?.input?.text && (
        <TouchableOpacity
          accessibilityRole="button"
          onPress={e => e.stopPropagation()}
          activeOpacity={1}>
          <View
            style={[
              pal.borderDark,
              {borderWidth: 0.5, borderBottomWidth: 0},
              styles.extInner,
            ]}>
            <TextInput
              accessibilityLabel="Text input field"
              accessibilityHint='Enter your text here and click "Submit" to proceed.'
              style={[pal.text, {width: '100%', height: 25}]}
              placeholder={frame?.input.text}
              icon="pencil"
              onChange={text => setFrameTextInput(text)}
              onPressIn={e => e.stopPropagation()}
            />
          </View>
        </TouchableOpacity>
      )}

      <View
        style={[
          pal.borderDark,
          {borderWidth: 0.5, overflow: 'scroll'},
          styles.extInner,
        ]}>
        <Text style={[pal.text, styles.extDescription]}>{txnStatus}</Text>
        <ScrollView horizontal style={[pal.borderDark, s.flexRow]}>
          {frame?.buttons?.map(
            (button, index) =>
              index < 4 && (
                <Button
                  key={index}
                  labelStyle={{
                    fontWeight: '700',
                    color: colors.white,
                  }}
                  style={{marginRight: 8}}
                  withLoading={true}
                  type="primary"
                  label={
                    isLoading
                      ? 'Loading...'
                      : button.action === 'txn'
                      ? `⭐️ ${button.label}`
                      : button.action === 'share'
                      ? `📲 ${button.label}`
                      : button.action === 'post_redirect'
                      ? `🔗 ${button.label}`
                      : button.label
                  }
                  onPress={async () => {
                    requireAuth(async () => {
                      track('Frame:ButtonClick', {
                        frameUrl: frame?.post_url,
                        buttonLabel: button.label,
                        buttonIndex: index,
                        postUri: itemUri,
                      })
                      switch (button.action as any) {
                        case 'post':
                          await buttonPostHandler(button, frame)
                          break
                        case 'post_redirect':
                          await buttonPostHandler(button, frame)
                          break
                        case 'txn':
                          await txnButtonHandler(button, frame)
                          break
                        case 'share':
                          await shareButtonHandler(button, frame)
                          break
                        default:
                      }
                    })
                  }}
                />
              ),
          )}
        </ScrollView>
      </View>
      {initialFrame?.uri && (
        // @ts-expect-error
        <Link style={[pal.text, s.underline, s.f15]} href={initialFrame.uri}>
          {toNiceDomain(initialFrame.uri)}
        </Link>
      )}
    </View>
  )
}
const styles = StyleSheet.create({
  extImage: {
    width: '100%',
    maxHeight: 500,
  },
  extUri: {
    marginTop: 2,
  },
  extDescription: {
    marginTop: 4,
  },
  extContainer: {
    flexDirection: 'column',
  },
  extNFTMeta: {
    marginBottom: -25,
    marginLeft: 4,
    zIndex: 1,
    backgroundColor: 'rgba(38, 39, 45, 0.6)',
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    width: 'auto',
    borderEndStartRadius: 5,
    borderEndEndRadius: 5,
    alignSelf: 'flex-start',
  },
  extInner: {
    paddingHorizontal: isDesktopWeb ? 14 : 10,
    paddingTop: 8,
    paddingBottom: 10,
    flex: isDesktopWeb ? 1 : undefined,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  extImageContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0.5,
    width: '100%',
    maxHeight: 1000,
    overflow: 'hidden',
  },

  singleImageMobile: {
    width: '100%',
    maxHeight: 500,
  },

  extBold: {
    fontWeight: 'bold',
  },
  centerIcon: {
    width: 142,
    height: 142,
    opacity: 0.7,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
})
