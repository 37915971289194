export function choose<U, T extends Record<string, U>>(
  value: keyof T,
  choices: T,
): U {
  return choices[value]
}

export function dedupArray<T>(arr: T[]): T[] {
  const s = new Set(arr)
  return [...s]
}

export function walletFormatted(address: string) {
  return `${address.slice(0, 4)}...${address.slice(-4)}`
}
