import {usePalette} from 'lib/hooks/usePalette'
import {Creator} from 'state/models/ui/creator-tools'
import {Text} from 'view/com/util/text/Text'
import React from 'react'
import {
  Linking,
  StyleSheet,
  View,
  FlatList,
  TouchableOpacity,
} from 'react-native'
import {s} from 'lib/styles'
import {VerifiedIcon} from '../util/Verified'
import {creatorToolBadgeColor, resources, videoGuideLink} from 'lib/constants'
import {isMobileWeb} from 'platform/detection'

function GetStartedInfo() {
  const pal = usePalette('default')
  return (
    <View style={[pal.viewLight, styles.cardContainer]}>
      <Text type="title" style={[styles.heading, pal.text]}>
        How to get started as a Solarplex Creator ?
      </Text>
      <Text type="post-text" style={[{paddingBottom: 15}, pal.text]}>
        1. Draft a post on Solarplex
      </Text>
      <Text type="post-text" style={[{paddingBottom: 15}, pal.text]}>
        2. Click the Mint Icon
      </Text>
      <Text type="post-text" style={[{paddingBottom: 15}, pal.text]}>
        3. Add image
      </Text>
      <Text type="post-text" style={[{paddingBottom: 15}, pal.text]}>
        4. Add metadata
      </Text>
      <Text type="post-text" style={[{paddingBottom: 15}, pal.text]}>
        5. Set number of editions
      </Text>
      <Text type="post-text" style={[{paddingBottom: 22}, pal.text]}>
        6. Mint!
      </Text>
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => Linking.openURL(videoGuideLink)}>
        <Text type="md" style={[pal.link, {textDecorationLine: 'underline'}]}>
          Video Collectible Instructions
        </Text>
      </TouchableOpacity>
    </View>
  )
}

function MoreResourcesSection() {
  const pal = usePalette('default')

  return (
    <View style={[pal.viewLight, styles.cardContainer]}>
      <Text type="title" style={[styles.heading, pal.text]}>
        More Resources!
      </Text>
      <FlatList
        data={resources}
        renderItem={({item}) => (
          <TouchableOpacity
            accessibilityRole="button"
            onPress={() => Linking.openURL(item.link)}>
            <View style={{flexDirection: 'row'}}>
              <Text style={[pal.text, {paddingRight: 8}]}>•</Text>
              <Text
                type="post-text"
                style={[
                  pal.text,
                  {paddingBottom: 10, textDecorationLine: 'underline'},
                ]}>
                {item.icon} {item.title}
              </Text>
            </View>
          </TouchableOpacity>
        )}
        keyExtractor={item => item.title}
      />
    </View>
  )
}

export function CreatorToolsDashboard({creator}: {creator: Creator}) {
  const pal = usePalette('default')

  return (
    <View>
      <View
        style={[s.flexRow, {alignItems: 'center', justifyContent: 'center'}]}>
        <Text type="title-lg" style={[styles.heading, pal.text]}>
          Welcome!
        </Text>
        <View style={styles.heading}>
          <VerifiedIcon size="sm" color={creatorToolBadgeColor} />
        </View>
      </View>

      <View style={[pal.viewLight, styles.cardContainer]}>
        <Text type="lg-bold" style={pal.text}>
          ArtistName:{' '}
          <Text type="lg" style={pal.text}>
            {creator.artist_name}
          </Text>
        </Text>
        <Text type="lg-bold" style={[pal.text, s.pt2]}>
          Twitter Handle:{' '}
          <Text type="lg" style={pal.text}>
            {creator.twitter_handle}
          </Text>
        </Text>
      </View>
      <View style={[pal.viewLight, styles.cardContainer]}>
        <Text type="lg-bold" style={pal.text}>
          Payout address:{' '}
          <Text type="lg" style={pal.text}>
            {creator.royalty_address}
          </Text>
        </Text>
        <Text style={[pal.textLight, s.p5]} type="sm-bold">
          (All proceeds from primary and secondary sales will go into this
          wallet. Please contact robert@solarplex.xyz to change it.)
        </Text>
      </View>

      <GetStartedInfo />
      <MoreResourcesSection />
      <View style={{height: isMobileWeb ? 50 : 10}} />
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingBottom: 8,
    paddingTop: 16,
    paddingLeft: 10,
  },
  cardContainer: {
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
  },
})
