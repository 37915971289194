import {usePalette} from '../../../lib/hooks/usePalette'
import {observer} from 'mobx-react-lite'

import {GalleryViewModal} from '../../../state/models/ui/shell'
import {Text} from '../util/text/Text'
import {Image} from 'expo-image'
import {Linking, StyleSheet, View} from 'react-native'

import {colors, s} from '../../../lib/styles'
import React, {useEffect, useRef, useState} from 'react'

import {Button} from '../util/forms/Button'
import {useStores} from '../../../state'
import {useNavigation} from '@react-navigation/native'
import {NavigationProp} from 'lib/routes/types'
import {ShareButton} from '../rewards/ShareButton'
import {track} from 'lib/analytics/analytics'
import {sanitizeUrl} from '@braintree/sanitize-url'
import {SOLARPLEX_UI_URL} from 'lib/constants'
import {CreatorResponse} from 'state/models/splx-users'

export const Component = observer(function ComponentImpl({
  asset,
  profileHandle,
}: GalleryViewModal) {
  const pal = usePalette('default')
  const store = useStores()
  const navigation = useNavigation<NavigationProp>()
  const [creator, setCreator] = useState<CreatorResponse>()
  const creatorRef = useRef<CreatorResponse | null>(null)

  useEffect(() => {
    async function fetchCreator() {
      if (creatorRef.current) {
        setCreator(creatorRef.current)
        return
      }
      const creatorResponse = await store.users.fetchCreator(asset?.creator)
      if (creatorResponse) {
        creatorRef.current = creatorResponse
        setCreator(creatorResponse)
      }
    }
    fetchCreator()
  }, [asset, store.users])
  const twitterIntentLink = encodeURI(
    `https://twitter.com/intent/tweet?url=${SOLARPLEX_UI_URL}/profile/${profileHandle}?tab=gallery&text=${
      asset.title
    } by ${
      creator && creator?.twitter_handle !== undefined
        ? creator.twitter_handle
        : asset.handle
    } on Solarplex! 
  `,
  )

  return (
    <View style={[pal.view, styles.center]}>
      <View style={[pal.border, styles.imageContainer]}>
        <Image
          accessibilityIgnoresInvertColors
          contentFit="contain"
          source={{
            uri: asset?.image,
          }}
          style={styles.image}
        />
      </View>
      <View style={[pal.border, styles.textContainer]}>
        <View style={s.mb5}>
          <Text style={[pal.text, s.mb2, s.bold]}>{asset?.title}</Text>
          <Text
            style={[pal.text, {marginTop: 8}]}
            onPress={() =>
              navigation.navigate('Profile', {name: asset?.handle})
            }>
            By{' '}
            <Text
              style={[
                pal.text,
                {color: pal.text.color, textDecorationLine: 'underline'},
              ]}>
              {asset?.handle}
            </Text>
          </Text>
        </View>

        <View style={styles.textInnerContainer}>
          {asset.attributes &&
            Object.entries(asset.attributes).map(([key, value], index) => (
              <View
                key={index}
                style={[
                  {
                    paddingHorizontal: 14,
                    paddingVertical: 8,
                    borderWidth: 1,
                    borderRadius: 10,
                    marginRight: 4,
                    marginTop: 4,
                  },
                  pal.border,
                ]}>
                <Text style={[pal.text, s.mb2]}>{key} </Text>
                <Text style={[pal.text, s.bold]}>{value}</Text>
              </View>
            ))}
        </View>
      </View>
      <ShareButton
        style={{flex: 1, marginTop: 12}}
        rewardsImg={asset?.image}
        shareText={`${asset.title} by ${asset.handle} on Solarplex!`}
        userId={''}
        btnText="Share on Solarplex"
        canShare={true}
        gallery={{
          profileHandle: profileHandle,
          assetTitle: asset.title,
        }}
      />
      <Button
        style={styles.shareButtonTwitter}
        labelStyle={{
          fontWeight: '700',
          flex: 1,
          textAlign: 'center',
          color: colors.white,
        }}
        label="Share on Twitter"
        type="primary"
        onPress={() => {
          track('Gallery:TwitterShare', {
            galleryOwner: profileHandle,
            user: store.me.handle,
            collectibleTitle: asset.title,
          })
          Linking.openURL(sanitizeUrl(twitterIntentLink))
        }}
      />
      <Button
        labelStyle={{color: pal.text.color, fontSize: 12}}
        type="default"
        style={{
          marginTop: 10,
          width: '100%',
          paddingVertical: 12,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => store.shell.closeModal()}
        label="Close"
      />
    </View>
  )
})

const styles = StyleSheet.create({
  imageContainer: {
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 12,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
  },
  textInnerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',

    justifyContent: 'flex-start',
    width: '100%',
  },
  textContainer: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 13,
    paddingHorizontal: 20,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  container: {
    paddingHorizontal: 1,
  },
  center: {
    paddingHorizontal: 10,
    paddingTop: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  shareButtonTwitter: {
    fontSize: 12,
    marginTop: 4,
    width: '100%',
    paddingVertical: 12,
    justifyContent: 'center',
    backgroundColor: colors.blue2,
  },
})
