/* eslint-disable react-native-a11y/has-accessibility-hint */
import React, {useCallback, useState} from 'react'
import {
  Text,
  View,
  TextInput,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  SafeAreaView,
} from 'react-native'
import {observer} from 'mobx-react-lite'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
import {usePalette} from 'lib/hooks/usePalette'
import {CenteredView} from 'view/com/util/Views'
import {FrameEmbed} from 'view/com/util/post-embeds/FrameEmbed'
import {useStores} from 'state/index'
import {
  LinkMeta,
  fetchFrameOrOgMeta,
  getLinkMeta,
} from 'lib/link-meta/link-meta'
import {getFrameFromLinkAsEmbed} from 'lib/link-meta/bsky'
import * as apilib from 'lib/api/index'
import {AppBskyEmbedFrame} from '@usedispatch/atproto-api'
import {s, colors} from 'lib/styles'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'DeveloperFrames'>

const exampleURLs = [
  'https://solarplex-polls-demo.vercel.app/polls/e9467d19-2dcc-496a-acc2-6623c521254d',
]
export const DeveloperFramesScreen = observer(
  function DeveloperFramesScreenImpl({}: Props) {
    const pal = usePalette('default')
    const [frameUrl, setFrameUrl] = useState('')
    const [frameData, setFrameData] = useState<
      AppBskyEmbedFrame.ViewFrame | undefined
    >(undefined)
    const store = useStores()
    const handleFrameUpdate = (
      newFrame: React.SetStateAction<AppBskyEmbedFrame.ViewFrame | undefined>,
    ) => {
      setFrameData(newFrame)
    }

    const postFrameAction = useCallback(
      async (url: string) => {
        try {
          getLinkMeta(store, url).then((meta: LinkMeta) => {
            if (meta.frame) {
              getFrameFromLinkAsEmbed(store, url, meta).then(embed => {
                // directly setting frameData with the embed, which is of type AppBskyEmbedFrame.ViewFrame
                // console.log('data from get frame', embed)
                if (embed.frame) {
                  const frame = embed.frame
                  const viewFrame: AppBskyEmbedFrame.ViewFrame = {
                    uri: frame.uri,
                    title: frame.title,
                    description: frame.description || '',
                    image: frame.image,
                    buttons: frame.buttons,
                    input: frame.input,
                    post_url: frame.post_url,
                    refresh_period: frame.refresh_period,
                  }
                  setFrameData(viewFrame)
                  console.log('update frame', viewFrame)
                }
              })
            }
          })
        } catch (error) {
          console.error('API call failed:', error)
        }
      },
      [store],
    )

    const handleExampleClick = (url: string) => {
      setFrameUrl(url)
      postFrameAction(url)
    }

    const handleReload = () => {
      if (frameUrl) {
        postFrameAction(frameUrl)
      }
    }
    const renderFrameDataKeys = () => {
      if (frameData) {
        return Object.entries(frameData).map(([key, value], index) => (
          <View key={index} style={styles.keyValueRow}>
            <Text style={styles.keyText}>{key}:</Text>
            <Text
              style={styles.valueText}
              textBreakStrategy="simple"
              ellipsizeMode="tail">
              {JSON.stringify(value)}
            </Text>
          </View>
        ))
      }
      return null
    }

    return (
      <SafeAreaView style={[pal.view, s.flex1]}>
        <ScrollView style={pal.view}>
          <CenteredView style={styles.container}>
            <Text style={[pal.text, styles.header]}>Frame Validator</Text>
            <View style={styles.inputContainer}>
              <TextInput
                accessibilityLabel="Text input field"
                style={styles.input}
                onChangeText={setFrameUrl}
                value={frameUrl}
                placeholder="Your frame URL"
                onSubmitEditing={() => postFrameAction(frameUrl)}
              />
              <TouchableOpacity
                accessibilityRole="button"
                style={styles.reloadButton}
                onPress={handleReload}>
                <Text>⟳</Text>
              </TouchableOpacity>
            </View>

            <View style={styles.examplesContainer}>
              <Text style={styles.exampleText}>Examples</Text>
              {exampleURLs.map((url, index) => (
                <Text
                  key={index}
                  style={styles.urlExample}
                  onPress={() => handleExampleClick(url)}>
                  {url}
                </Text>
              ))}
            </View>
            {frameData && (
              <>
                <FrameEmbed
                  key={Date.now()}
                  initialFrame={frameData}
                  itemCid={'itemCid'}
                  itemUri={'itemUri'}
                  author={'author'}
                  onFrameUpdate={handleFrameUpdate}
                />
                <View style={styles.resultContainer}>
                  <Text style={styles.header}>Properties</Text>
                  {renderFrameDataKeys()}
                </View>
              </>
            )}

            {/* <Text style={styles.resultText}>
              {JSON.stringify(frameData, null, 2)}
            </Text> */}
          </CenteredView>
        </ScrollView>
      </SafeAreaView>
    )
  },
)
const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  header: {
    fontSize: 24,
    marginBottom: 20,
  },
  inputContainer: {
    flexDirection: 'row',
    borderColor: '#000',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 20,
    alignItems: 'center',
  },
  input: {
    flex: 1,
    padding: 10,
    fontSize: 16,
  },
  reloadButton: {
    padding: 10,
  },
  examplesContainer: {
    marginBottom: 20,
  },
  exampleText: {
    fontSize: 16,
    marginBottom: 10,
  },
  urlExample: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
  keyValueRow: {
    flexDirection: 'column',
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    alignItems: 'flex-start',
  },
  keyText: {
    fontWeight: 'bold',
    fontSize: 16,
    paddingBottom: 4,
    flexWrap: 'wrap',
  },
  valueText: {
    fontSize: 16,
    color: '#555',
    flexShrink: 1,
    textAlign: 'left',
    flex: 1,
    marginBottom: 8,
    // overflow: 'hidden',
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
  },
  resultContainer: {
    borderColor: '#ddd',
    borderWidth: 1,
    padding: 4,
    margin: 4,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.1,
    shadowRadius: 1.41,
  },
  resultText: {
    flexWrap: 'wrap',
    flex: 1,
    flexShrink: 1,
    wordBreak: 'break-all',
    overflow: 'hidden',
  },
})

export default DeveloperFramesScreen
