import {SOLARPLEX_FEED_API} from 'lib/constants'
import {makeAutoObservable, runInAction} from 'mobx'

import {ChannelFeedModel} from '../feeds/channel-feed'
import {RootStoreModel} from '../root-store'
import {SolarplexChannel} from 'lib/splx-types'
import {actions} from '../actions'

export class FollowedChannelsModel {
  // simply stores ids of communities joined by current user
  channels: string[] = []

  // data
  _channelModelCache: Record<string, ChannelFeedModel> = {}

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      {autoBind: true},
    )
    this.rootStore.onSessionLoaded(() => {
      this.updateCache(true)
    })
  }
  /**
   * syncs the cached models against the current state
   * should only be called by the preferences model after syncing state
   */
  _updateCache = actions.wrapAction(
    async (did: string, clearCache?: boolean) => {
      let newChannelModels: Record<string, ChannelFeedModel> = {}
      if (!clearCache) {
        newChannelModels = {...this._channelModelCache}
      }

      // fetch the joined communities
      const response = await fetch(
        `${SOLARPLEX_FEED_API}/splx/get_channels_for_user/${did}`,
        {
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Access-Control-Allow-Origin': 'no-cors',
            Authorization: `Bearer ${this.rootStore.session?.currentSession?.accessJwt}`,
          },
        },
      )
      const joinedCommunities = await response.json()

      this.channels = joinedCommunities.data.map((c: SolarplexChannel) => c.id)

      // collect the community IDs that haven't been synced yet
      const neededChannelIds = this.channels?.filter(
        id => !(id in newChannelModels),
      )

      // fetch the missing models
      for (const id of neededChannelIds) {
        const channel = await this.fetchChannel(id)
        newChannelModels[id] = channel
      }

      // merge into the cache
      runInAction(() => {
        this._channelModelCache = newChannelModels
      })
    },
    this,
    '_updateCache',
  )

  async updateCache(clearCache?: boolean) {
    if (!this.rootStore.me.did) {
      return
    }
    return await this._updateCache(this.rootStore.me.did, !!clearCache)
  }

  get isUpdatingCache() {
    return (
      this.rootStore.me.did &&
      (actions.isBusy('_updateCache', this, [this.rootStore.me.did, false]) ||
        actions.isBusy('_updateCache', this, [this.rootStore.me.did, true]))
    )
  }

  // gets the community model data for a give community id here is sample req https://feed.solarplex.xyz/splx/get_community/splx-memes
  async fetchChannel(id: string): Promise<ChannelFeedModel> {
    const response = await fetch(
      `${SOLARPLEX_FEED_API}/splx/get_channels/${id}`,
      {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Access-Control-Allow-Origin': 'no-cors',
        },
      },
    )

    if (!response.ok) {
      throw new Error(`Failed to fetch community with ID ${id}`)
    }

    const channelData = await response.json()
    return new ChannelFeedModel(this.rootStore, channelData)
  }
  // public api
  // =

  // TODO(viksit)[F1]: joined communities may not be happening
  // lets make sure these are getting synced with the preferenecs
  _toggle = actions.wrapAction(
    async (did: string, cid: string, join: boolean) => {
      const channel = this.rootStore.channels.byId(cid)
      if (!channel) return
      // join ? await community.join() : await community.leave()
      const url = `${SOLARPLEX_FEED_API}/${
        join ? 'join' : 'leave'
      }_channel_by_id`
      await this.rootStore.api.post(url, {
        body: {did, cid},
      })

      await this._updateCache(did, true)
    },
    this,
    '_toggle',
  )

  isBusy(community: ChannelFeedModel) {
    return (
      actions.isBusy('_toggle', this, [
        this.rootStore.me.did,
        community.id,
        true,
      ]) ||
      actions.isBusy('_toggle', this, [
        this.rootStore.me.did,
        community.id,
        false,
      ])
    )
  }

  async follow(channel: ChannelFeedModel) {
    const did = this.rootStore.me.did
    const cid = channel.id
    if (!did || !cid) {
      return
    }

    return await this._toggle(did, cid, true)
  }

  async unfollow(channel: ChannelFeedModel) {
    const did = this.rootStore.me.did
    const cid = channel.id
    if (!did || !cid) {
      return
    }

    return await this._toggle(did, cid, false)
  }

  /**
   * Nuke all data
   */
  clear() {
    this.channels = []
    this._channelModelCache = {}
  }

  // state transitions
  // =
}
