import {makeAutoObservable, runInAction} from 'mobx'
import {RootStoreModel} from '../root-store'
import {actions} from '../actions'
import {SOLARPLEX_FEED_API} from 'lib/constants'
import {getCollectibles, getTopMinters} from 'lib/api'
import {
  CollectibleViewDetailed,
  TopMintersView,
} from '@usedispatch/atproto-api/dist/client/types/app/bsky/splx/defs'
import {ProfileViewBasic} from '@usedispatch/atproto-api/dist/client/types/app/bsky/actor/defs'

export type Collector = {
  handle: string
  did: string
  wallet: string
  minted: string
}

export type Creator = {
  did: string
  artist_name: string
  twitter_handle: string
  royalty_address: string
  verified: boolean
  project_id: number
}

export interface TopMintersProfile extends TopMintersView {
  profile: ProfileViewBasic
}

export class CreatorToolsModel {
  creator: Creator | undefined = undefined
  isVerified: boolean = false
  collectibles: CollectibleViewDetailed[] = []
  topMinters: TopMintersProfile[] = []
  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {}, {autoBind: true})
  }
  creatorLatestMissionDetails: any = {}

  get innerCircleCollectibles() {
    const innerCircleCollectibles = this.collectibles.filter(collectible => {
      const attributes = collectible.attributes
      if (!attributes) {
        return false
      }
      const parsedAttributes = JSON.parse(attributes)

      return parsedAttributes.mission === 'inner_circle'
    })

    const sortedInnerCircleCollectibles = innerCircleCollectibles.sort(
      (a, b) =>
        new Date(b.createdAt || '1970-01-01T00:00:00Z').getTime() -
        new Date(a.createdAt || '1970-01-01T00:00:00Z').getTime(),
    )

    return sortedInnerCircleCollectibles
  }

  async fetchTopMinters() {
    try {
      const topMintersData = await getTopMinters(this.rootStore)
      const profile =
        topMintersData.length !== 0 &&
        (await this.rootStore.agent.getProfiles({
          actors: topMintersData
            .map(minter => minter.did)
            .filter(Boolean) as string[],
        }))
      if (!profile || !profile.data || !profile.data.profiles) {
        throw new Error('Profile not found')
      }

      const topMintersWithProfile = topMintersData.map(minter => {
        if (minter?.did) {
          this.rootStore.users.fetchUser(minter.did)
        }
        const profileData = profile.data.profiles.find(
          prof => prof.did === minter.did,
        ) as ProfileViewBasic
        return {
          ...minter,
          profile: profileData,
        }
      })

      const sortedTopMinters = topMintersWithProfile.sort(
        (a, b) => (b.mints || 0) - (a.mints || 0),
      )

      this.topMinters = sortedTopMinters
    } catch (error) {
      console.log('fetchTopMinters failed ', error)
    }
    return []
  }

  async fetchCollectibles() {
    try {
      const collectiblesData = await getCollectibles(this.rootStore)

      this.collectibles = collectiblesData
    } catch (error) {
      console.log('fetchCollectibles failed ', error)
    }
    return []
  }

  getAllCollectorsForCollectibleIsBusy(cid: string) {
    return actions.isBusy('getAllCollectorsForCollectible', this, [cid])
  }

  cancelMission = actions.wrapAction(
    async (missionId: string) => {
      try {
        const url = `${SOLARPLEX_FEED_API}/splx/disableMission/${missionId}`
        const response = await this.rootStore.api.post(url)
        if (!response || this.rootStore.api.getError(url)) {
          throw new Error('API call failed')
        }

        this.getMissionsByDid(this.rootStore.me.did)
      } catch (error) {
        console.log('Error in cancelMission call', error)
      }
    },
    this,
    'cancelMission',
  )

  getMissionsByDid = actions.wrapAction(
    async (did: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/missions/${did}`
      try {
        const response: any = await this.rootStore.api.get(url)

        if (!response || this.rootStore.api.getError(url)) {
          throw new Error('API call failed')
        }
        runInAction(() => {
          this.creatorLatestMissionDetails = response.data.missions[0]
        })
      } catch (error) {
        console.log('Error in getMissionsByDid call', error)
      }
    },
    this,
    'getMissionsByDid',
  )

  getAllCollectorsForCreatorInnerCircle = actions.wrapAction(
    async (did: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/creatorTools/innerCircleCollectors/${did}`
      try {
        const response = await this.rootStore.api.get<{
          data: Array<Collector>
        }>(url)

        if (
          !response ||
          this.rootStore.api.getError(url) ||
          response.data.length === 0
        ) {
          throw new Error('API call failed')
        }

        const profile =
          response.data.length !== 0 &&
          (await this.rootStore.agent.getProfiles({
            actors: response.data
              .map(minter => minter.did)
              .filter(Boolean) as string[],
          }))

        if (!profile || !profile.data || !profile.data.profiles) {
          throw new Error('Profile not found')
        }

        const collectorsWithProfile = response.data.map(minter => {
          const profileData = profile.data.profiles.find(
            prof => prof.did === minter.did,
          ) as ProfileViewBasic
          return {
            ...minter,
            profile: profileData,
          }
        })

        return collectorsWithProfile
      } catch (error) {
        console.log('Error in getAllCollectorsForCreatorInnerCircle', error)
      }
    },
    this,
    'getAllCollectorsForCreatorInnerCircle',
  )

  getAllCollectorsForCollectible = actions.wrapAction(
    async (cid: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/collectors/${cid}`

      try {
        const response = await this.rootStore.api.get<{
          data: Array<Collector>
        }>(url)

        if (
          !response ||
          this.rootStore.api.getError(url) ||
          response.data.length === 0
        ) {
          throw new Error('API call failed')
        }

        const profile =
          response.data.length !== 0 &&
          (await this.rootStore.agent.getProfiles({
            actors: response.data
              .map(minter => minter.did)
              .filter(Boolean) as string[],
          }))

        if (!profile || !profile.data || !profile.data.profiles) {
          throw new Error('Profile not found')
        }

        const collectorsWithProfile = response.data.map(minter => {
          const profileData = profile.data.profiles.find(
            prof => prof.did === minter.did,
          ) as ProfileViewBasic
          return {
            ...minter,
            profile: profileData,
          }
        })

        return collectorsWithProfile
      } catch (error) {
        console.log('Error in API call', error)
      }
    },
    this,
    'getAllCollectorsForCollectible',
  )

  getCreatorByHandle = actions.wrapAction(
    async (handle: string) => {
      try {
        const profile = await this.rootStore.agent.getProfile({
          actor: handle,
        })

        const did = profile.data.did

        if (!did) {
          throw new Error('Did not found')
        }
        return this._getCreatorByDid(did)
      } catch (error) {
        console.log('Error in getCreatorByHandle', error)
        throw new Error(`Error in getCreatorByHandle: ${error}`)
      }
    },
    this,
    'getCreatorByHandle',
  )

  _getCreatorByDid = actions.wrapAction(
    async (did: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/creator_tools/${did}`
      // const url = `http://localhost:3000/splx/creator_tools/${did}`
      try {
        const response = await this.rootStore.api.get<{
          status: string
          data: Array<Creator>
        }>(url)

        if (
          !response ||
          this.rootStore.api.getError(url) ||
          response.data.length === 0
        ) {
          throw new Error('API call failed')
        }

        return response.data[0]
      } catch (error) {
        console.log('Error in API call', error)
      }
    },
    this,
    '_getACreatorBydid',
  )

  fetch = actions.wrapAction(
    async () => {
      try {
        if (!this.rootStore.me.did) {
          return
        }

        const creator = await this._getCreatorByDid(this.rootStore.me.did)

        if (creator === undefined) {
          throw new Error('Creator is undefined')
        }

        runInAction(() => {
          this.creator = creator
          this.isVerified = creator.verified
        })
      } catch (error) {
        console.error(error)
      }
    },
    this,
    'fetch',
  )

  downloadCSV = async () => {
    try {
      const url = `${SOLARPLEX_FEED_API}/splx/creatorTools/allcollectors/${this.rootStore.me.did}`

      const response = await fetch(url)

      if (!response.ok) throw new Error('Network response was not ok.')

      const blob = await response.blob()

      const downloadUrl = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = downloadUrl
      link.setAttribute('download', `collectors.csv`)
      document.body.appendChild(link)
      link.click()
      if (link.parentNode) {
        link.parentNode.removeChild(link)
      }
    } catch (error) {
      console.error('Error downloading the file', error)
    }
  }

  // async createCreator() {
  //   await this.rootStore.wallet.feedApiCall('/splx/creator/tools', 'POST', {
  //     did: this.rootStore.me.did,
  //     artist_name: this.creator?.artist_name,
  //     twitter_handle: this.creator?.twitter_handle,
  //     royalty_address: this.creator?.royalty_address,
  //     verified: this.creator?.verified,
  //   })
  // }
}
