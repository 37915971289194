import React, {useEffect} from 'react'
import {Image} from 'expo-image'
import {Text} from '../text/Text'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import {usePalette} from 'lib/hooks/usePalette'
import {AppBskyEmbedExternal, AppBskyActorDefs} from '@usedispatch/atproto-api'
import {isDesktopWeb} from 'platform/detection'
import {Button} from '../forms/Button'
import {s} from 'lib/styles'
import {UserAvatar} from '../UserAvatar'
import {Link} from '../Link'
import {colors} from 'lib/styles'
import {useStores} from 'state/index'
import {useSplxWallet} from 'view/com/wallet/useSplxWallet'
import {Connection} from '@solana/web3.js'
import {
  RPC_API,
  SOLARPLEX_FEED_API,
  SOLARPLEX_TRANSACTION_OPTIONS,
} from 'lib/constants'
import * as Toast from '../../util/Toast'
import {observer} from 'mobx-react-lite'
import {LoadingIndicator} from 'lib/icons'
import {track} from 'lib/analytics/analytics'
import {useRequireAuth} from 'view/com/auth/withAuthRequired'

export const MallowEmbed = observer(function Component({
  link,
  mintAddress,
  imageChild,
  author,
  itemUri,
  itemCid,
  text,
  indexedAt,
}: {
  author?: AppBskyActorDefs.ProfileViewBasic | undefined
  itemUri?: string
  itemCid?: string
  text?: string
  indexedAt?: string
  link: AppBskyEmbedExternal.ViewExternal
  mintAddress: string
  imageChild?: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  const pal = usePalette('default')
  let metaDescription = {
    creator: '',
    price: '',
  }
  try {
    metaDescription = JSON.parse(link.description)
  } catch (error) {
    console.error('Error parsing JSON:', error)
    // Handle the error appropriately here
  }
  const store = useStores()
  const [buyingNft, setBuyingNft] = React.useState(false)
  const [isBought, setIsBought] = React.useState(false)
  const [linkMeta, setLinkMeta] = React.useState<any>()
  const [
    _visible,
    setVisible,
    _linkedWallet,
    _connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
  ] = useSplxWallet(false)
  const requireAuth = useRequireAuth(author?.handle ?? '')

  useEffect(() => {
    fetch(`${SOLARPLEX_FEED_API}/splx/mallow_meta/${mintAddress}`).then(body =>
      body.json().then(meta => setLinkMeta(meta)),
    )
  }, [mintAddress])

  function shareNft() {
    const sharingText = `I just bought ${link.title} from ${metaDescription.creator} on Mallow!`
    if (itemUri && itemCid && indexedAt && author) {
      store.shell.openComposer({
        isSharing: true,
        sharingText: sharingText,
        quote: {
          uri: itemUri,
          author: {
            did: author?.did,
            handle: author?.handle,
            avatar: author?.avatar,
          },
          cid: itemCid,
          text: text || sharingText,
          indexedAt: indexedAt,
        },
      })
    }
  }

  async function handleBuyNft() {
    requireAuth(async () => {
      setVisible(true)
      await store.wallet.waitForWalletConnect()

      try {
        const txn = await store.me.nft.getBuyNftTxn(
          store.wallet.state.connectedWalletId,
          mintAddress,
        )
        const connection = new Connection(RPC_API)

        // NOTE(zfaizal2) TS is complaining about walletPopup not existing, but is checked above
        // @ts-expect-error
        const signedTx = await store.wallet.walletPopup?.signTransaction(txn)
        if (!signedTx) {
          throw new Error('Could not sign transaction')
        }
        const txnSig = await connection.sendRawTransaction(
          signedTx.serialize(),
          SOLARPLEX_TRANSACTION_OPTIONS,
        )
        track('NFT:MallowBuySuccess', {
          mallowLink: link.uri,
          mintAddress,
          buyer: store.session.currentSession?.handle,
          txnId: txnSig,
          amountSOL: metaDescription.price,
        })
        setBuyingNft(false)
        setIsBought(true)
        Toast.show(`Purchase successful!`)
      } catch (e) {
        track('NFT:MallowBuyError', {
          mallowLink: link.uri,
          mintAddress,
          buyer: store.session.currentSession?.handle,
          error: e,
        })
        setBuyingNft(false)
        console.error(e)
        Toast.show('Error buying NFT')
      }
    })
  }

  return (
    <View style={[pal.border, styles.extContainer]}>
      <Text
        type="sm"
        numberOfLines={isDesktopWeb ? 2 : 4}
        style={[
          pal.text,
          {color: colors.white},
          styles.extBold,
          styles.extNFTMeta,
        ]}>
        {link.title || link.uri}{' '}
        {metaDescription?.creator ? `by @${metaDescription.creator}` : ''}
      </Text>
      {link.thumb ? (
        <Link href={link.uri}>
          <View style={[pal.borderDark, styles.extImageContainer]}>
            <Image
              style={styles.extImage}
              source={{uri: link.thumb}}
              accessibilityIgnoresInvertColors
            />
            {imageChild}
          </View>
        </Link>
      ) : undefined}
      <View style={[pal.borderDark, {borderWidth: 0.5}, styles.extInner]}>
        <Link href="/profile/mallow.live.solarplex.xyz">
          <UserAvatar
            avatar={
              'https://appview.solarplex.xyz/image/avatar/plain/did:plc:yobmrizb7gdv5vyqvbcvifr5/bafkreia4kxmkzonyfmfksmzkfpls5a5pj3tcpn2chfyqwvjilr7phc65fy@jpeg'
            }
            size={32}
          />
        </Link>
        {metaDescription ? (
          <View style={[pal.borderDark, s.flexRow]}>
            {linkMeta && linkMeta.maxSupply > 1 ? (
              <Text
                type="md"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, styles.extDescription]}>
                {linkMeta.supply} / {linkMeta.maxSupply} NFT
                {linkMeta.supply > 1 ? 's' : ''}
              </Text>
            ) : undefined}
            <Text
              type="md"
              numberOfLines={isDesktopWeb ? 2 : 4}
              style={[pal.text, styles.extDescription]}>
              {metaDescription.price} SOL
            </Text>

            {!linkMeta ? (
              <LoadingIndicator />
            ) : !linkMeta.error ? (
              <Button
                labelStyle={{
                  fontWeight: '700',
                  color: colors.white,
                }}
                withLoading={true}
                type="primary"
                label={buyingNft ? '' : 'Buy Collectible'}
                onPress={async () => {
                  track('NFT:MallowBuyClick', {
                    mallowLink: link.uri,
                    mintAddress,
                    buyer: store.session.currentSession?.handle,
                    amountSOL: metaDescription.price,
                  })
                  setBuyingNft(true)
                  await handleBuyNft()
                }}>
                {buyingNft ? <LoadingIndicator /> : ''}
              </Button>
            ) : linkMeta.supply === linkMeta.maxSupply ? (
              <>
                <Button
                  type="default"
                  isdisabled={true}
                  labelStyle={{fontWeight: '600'}}
                  label="Sold out!"
                />
              </>
            ) : isBought ? (
              <Button
                labelStyle={{
                  fontWeight: '700',
                  color: colors.white,
                }}
                withLoading={true}
                type="primary"
                label={buyingNft ? '' : 'Share Collectible'}
                onPress={shareNft}
              />
            ) : (
              <Text
                type="md"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, styles.extDescription, styles.extBold]}>
                Collectible not available
              </Text>
            )}
          </View>
        ) : undefined}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  extContainer: {
    flexDirection: 'column',
  },
  extNFTMeta: {
    marginBottom: -25,
    marginLeft: 4,
    zIndex: 1,
    backgroundColor: 'rgba(38, 39, 45, 0.6)',
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    width: 'auto',
    borderEndStartRadius: 5,
    borderEndEndRadius: 5,
    alignSelf: 'flex-start',
  },
  extInner: {
    paddingHorizontal: isDesktopWeb ? 14 : 10,
    paddingTop: 8,
    paddingBottom: 10,
    flex: isDesktopWeb ? 1 : undefined,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  extImageContainer: {
    borderWidth: 0.5,
    width: '100%',
    height: 500,
    overflow: 'hidden',
  },
  extImage: {
    width: '100%',
    height: 500,
  },
  extUri: {
    marginTop: 2,
  },
  extDescription: {
    margin: 'auto',
    paddingRight: 8,
  },
  extBold: {
    fontWeight: 'bold',
  },
})
