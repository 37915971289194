import React from 'react'
import {View} from 'react-native'

import {
  NativeDropdown,
  DropdownItem as NativeDropdownItem,
} from './NativeDropdown'
import {EventStopper} from '../EventStopper'
import {usePalette} from 'lib/hooks/usePalette'

import {useStores} from 'state/index'
import {usePhotoLibraryPermission} from 'lib/hooks/usePermissions'
import {useAnalytics} from 'lib/analytics/analytics'
import {FontAwesomeIconStyle} from '@fortawesome/react-native-fontawesome'
import {isDesktopWeb} from 'platform/detection'
import {NFTGalleryModel} from 'state/models/media/nft-gallery'
import {NFTDropDownIcon, NFTDropDownIconDark} from 'lib/icons'
import {useTheme} from 'lib/ThemeContext'

type ImgVidDropdownProps = {
  gallery: NFTGalleryModel
  disable: boolean
  addChannel: (channelTag: string) => void
}

export function NftDropDown({
  disable,
  gallery,
  addChannel,
}: ImgVidDropdownProps) {
  const store = useStores()
  // const theme = useTheme()
  const pal = usePalette('default')
  const {track} = useAnalytics()
  const {colorScheme} = useTheme()
  const {requestPhotoAccessIfNeeded} = usePhotoLibraryPermission()
  // const defaultCtrlColor = theme.palette.default.postCtrl

  const dropdownItems: NativeDropdownItem[] = [
    {
      label: 'NFT Image',
      async onPress() {
        if (disable) {
          return
        }

        track('Composer:GalleryOpened')

        if (!isDesktopWeb && !(await requestPhotoAccessIfNeeded())) {
          return
        }

        await gallery.pick()
        store.shell.openModal({
          name: 'nft-meta',
          image: gallery.images[0],
          gallery: gallery,
          addChannel: addChannel,
        })
      },
    },
    {
      label: 'NFT Video',
      async onPress() {
        if (disable) {
          return
        }
        track('Composer:GalleryOpened')

        if (!isDesktopWeb && !(await requestPhotoAccessIfNeeded())) {
          return
        }

        await gallery.pickVideo()

        if (gallery.video !== undefined) {
          store.shell.openModal({
            name: 'nft-video',
            video: gallery.video,
            gallery: gallery,
            addChannel: addChannel,
          })
        }
      },
    },
  ]

  return (
    <EventStopper>
      <NativeDropdown
        testID={'ChannelDropdownBtn'}
        items={dropdownItems}
        accessibilityLabel="More post options"
        accessibilityHint="">
        <View style={{}}>
          {colorScheme === 'light' ? (
            <NFTDropDownIcon
              style={pal.link as FontAwesomeIconStyle}
              size={26}
            />
          ) : (
            <NFTDropDownIconDark
              style={pal.link as FontAwesomeIconStyle}
              size={26}
            />
          )}
        </View>
      </NativeDropdown>
    </EventStopper>
  )
}

// const styles = StyleSheet.create({
//   channelDropdown: {
//     borderRadius: 100,
//     paddingHorizontal: 8,
//     paddingVertical: 10,
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     borderColor: '#B0A6D4',
//     borderWidth: 1,
//     backgroundColor: colors.splx.primary[20],
//   },
// })
