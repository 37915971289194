import * as Toast from '../util/Toast'
import * as buffer from 'buffer'

import {Connection, PublicKey, Transaction} from '@solana/web3.js'
import React, {useState} from 'react'
import {SOL, searchTokenByName} from '../../../lib/tipTokens'
import {StyleSheet, TextInput, View} from 'react-native'
import {solTransferIx, splTransferIx} from '../../../lib/solana/spl-transfer'

import {Button} from '../util/forms/Button'
import {CenteredView} from '../util/Views'
import {Image} from 'expo-image'
import {
  RPC_API,
  SOLARPLEX_FEED_API,
  // isProdTeamHandle,
} from '../../../lib/constants'
import {Text} from '../util/text/Text'
import {TippingModal} from '../../../state/models/ui/shell'
import {observer} from 'mobx-react-lite'
import {s} from '../../../lib/styles'
import {useAnalytics} from '../../../lib/analytics/analytics'
import {usePalette} from '../../../lib/hooks/usePalette'
import {useSplxWallet} from '../wallet/useSplxWallet'
import {useStores} from '../../../state/index'
import {useTheme} from '../../../lib/ThemeContext'
import {useWallet} from '@solana/wallet-adapter-react'

window.Buffer = buffer.Buffer

type TipBtnProps = {
  label: string
  disabled: boolean
  onPress: () => void
}

function TipBtn({label, onPress}: TipBtnProps) {
  return (
    <Button
      withLoading={true}
      style={{width: 255, alignItems: 'center'}}
      type="primary-outline"
      onPress={() => {
        onPress()
      }}
      label={label}
    />
  )
}

type ConnectWalletModalProps = {
  recipientName: string
}

function ConnectWalletModal({recipientName}: ConnectWalletModalProps) {
  const pal = usePalette('default')
  const [
    _visible,
    setVisible,
    _linkedWallet,
    _connectedWallet,
    connectWalletIsBusy,
    _disconnectWalletIsBusy,
  ] = useSplxWallet(false)

  async function handleConnectWallet() {
    setVisible(true)
  }
  return (
    <CenteredView style={[pal.view, styles.center]}>
      <View style={{paddingVertical: 10}}>
        <Text type="2xl-bold" style={[pal.text]}>
          {`Connect Your Wallet to Tip ${recipientName}`}
        </Text>
      </View>
      <TipBtn
        disabled={connectWalletIsBusy}
        onPress={() => {
          handleConnectWallet()
        }}
        label="Connect Wallet"
      />
    </CenteredView>
  )
}

// Depricated
export const Component = observer(function Component({
  recipientName,
  tokenName,
  recipientAddress,
  itemUri,
  recipientdid,
  senderdid,
}: TippingModal) {
  const store = useStores()
  const pal = usePalette('default')
  const token = searchTokenByName(tokenName)
  const theme = useTheme()
  const [tipUIAmount, setTipUIAmount] = useState<string>('')
  const wallet = useWallet()

  const [
    _visible,
    _setVisible,
    _linkedWallet,
    connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
  ] = useSplxWallet(false)
  const {track} = useAnalytics()

  async function handleTipTranfer(
    senderAddress: string,
    recipientAddress: string,
    amount: string,
  ) {
    const senderPubkey = new PublicKey(senderAddress)
    if (!connectedWallet || !wallet.signTransaction || !wallet.connected) {
      Toast.show('Please make sure to connect your wallet')
      return
    }
    if (!token || !token.mintAddress) {
      Toast.show('Token mint not found')
      return
    }

    const tx = new Transaction()
    const connection = new Connection(RPC_API)
    try {
      const {blockhash} = await connection.getLatestBlockhash('finalized')
      tx.recentBlockhash = blockhash
      tx.feePayer = senderPubkey

      if (tokenName !== SOL.tokenName) {
        const ixs = await splTransferIx({
          amount,
          tokenName,
          receiver: recipientAddress,
          sender: senderAddress,
          connection,
        })

        tx.add(...ixs)
      } else {
        const ix = solTransferIx({
          amount,
          receiver: recipientAddress,
          sender: senderAddress,
        })

        tx.add(ix)
      }

      const signedTx = await wallet.signTransaction(tx)
      const sig = await connection.sendRawTransaction(signedTx.serialize())

      track('Tip:TipConfirmation', {
        tokenName: tokenName,
        sender: senderAddress,
        reciever: recipientAddress,
        amount: amount,
        txSig: sig,
        postUri: itemUri,
        senderDid: senderdid,
        recipientDid: recipientdid,
      })

      // const currentUserHandle = store.me.handle
      const body = {
        sender_did: senderdid,
        receiver_did: recipientdid,
        amount: amount,
        token_name: tokenName,
        token_mint: token.mintAddress,
        post: itemUri,
        tx_sig: sig,
      }
      // TODO: (Pratik) this should be in a store but since we dont have a tips store will do once the scope of tip increase and has a store for it.
      // if (isProdTeamHandle(currentUserHandle)) {
      await store.api.post(`${SOLARPLEX_FEED_API}/splx/sent_tip`, {body})
      const isInFlight = store.api.postBusy(
        `${SOLARPLEX_FEED_API}/splx/sent_tip`,
        {body},
      )
      const error = store.api.postError(`${SOLARPLEX_FEED_API}/splx/sent_tip`, {
        body,
      })
      if (!error && !isInFlight) {
        Toast.show('Tip Sent')
      }
      // }
      store.shell.closeModal()
    } catch (error) {
      Toast.show('Something Went Wrong')
      console.error('Something Went Wrong', error)
    }
  }

  return (
    <View style={[s.flex1, pal.view, styles.container]}>
      {connectedWallet !== '' ? (
        <CenteredView style={[pal.view, styles.center]}>
          <View style={{paddingVertical: 10}}>
            <Text type="2xl-bold" style={[pal.text]}>
              {`Tip ${recipientName} with ${tokenName} and support their work`}
            </Text>
          </View>
          <View style={[pal.borderDark, styles.inputContainer]}>
            <View style={{paddingRight: 10}}>
              <Image
                accessibilityIgnoresInvertColors
                source={{
                  uri: token?.tokenImage,
                }}
                style={[styles.image]}
              />
            </View>
            <TextInput
              accessibilityHint="Text input field"
              accessibilityLabel="Text input field"
              style={[pal.text, styles.textInput]}
              placeholderTextColor={pal.colors.textLight}
              placeholder="0.00"
              keyboardAppearance={theme.colorScheme}
              keyboardType="decimal-pad"
              value={tipUIAmount}
              onChangeText={text => {
                const formattedText = text.replace(/[^0-9.]/g, '')
                setTipUIAmount(formattedText)
              }}
            />
          </View>
          <View style={{paddingVertical: 10}}>
            <TipBtn
              label={`Tip ${recipientName}`}
              disabled={parseFloat(tipUIAmount) === 0}
              onPress={() => {
                handleTipTranfer(connectedWallet, recipientAddress, tipUIAmount)
              }}
            />
          </View>
        </CenteredView>
      ) : (
        <ConnectWalletModal recipientName={recipientName} />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    width: '100%',
  },
  image: {
    width: 30,
    height: 30,
    paddingRight: 2,
  },
  tipAmountContainer: {
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  container: {
    paddingHorizontal: 1,
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    marginTop: 2,
    flex: 1,
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 12,
    fontSize: 17,
    letterSpacing: 0.25,
    fontWeight: '400',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: s.gray3.color,
  },
})
