import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {observer} from 'mobx-react-lite'
import {TabBar} from 'view/com/pager/TabBar'
import {RenderTabBarFnProps} from 'view/com/pager/Pager'
import {useStores} from 'state/index'
import {useHomeTabs} from 'lib/hooks/useHomeTabs'
import {usePalette} from 'lib/hooks/usePalette'

import {HITSLOP_10} from 'lib/constants'
import {useMinimalShellMode} from 'lib/hooks/useMinimalShellMode'
import Animated from 'react-native-reanimated'

import {UserAvatar} from 'view/com/util/UserAvatar'
import {BrandLogo, BrandLogoSmall} from 'view/shell/BrandLogo'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {Button} from '../util/forms/Button'
import {s} from 'lib/styles'
import {useNavigation} from '@react-navigation/native'
import {NavigationProp} from 'lib/routes/types'
import {isMobileWeb} from 'platform/detection'
import {useAnalytics} from 'lib/analytics/analytics.web'

export const FeedsTabBar = observer(function FeedsTabBarImpl(
  props: RenderTabBarFnProps & {
    testID?: string
    onPressSelected: () => void
    items: string[]
  },
) {
  const pal = usePalette('default')
  const {track} = useAnalytics()
  const store = useStores()
  const items = useHomeTabs(store.preferences.pinnedFeeds)

  const {headerMinimalShellTransform} = useMinimalShellMode()
  const insets = useSafeAreaInsets()
  const navigation = useNavigation<NavigationProp>()

  const onPressAvi = React.useCallback(() => {
    store.shell.openDrawer()
  }, [store])

  return (
    <Animated.View
      style={[
        pal.view,
        pal.border,
        styles.tabBar,
        headerMinimalShellTransform,
        store.shell.minimalShellMode && styles.disabled,
        {paddingTop: insets.top},
      ]}>
      <View style={[pal.view, styles.topBar, s.borderBottom1, pal.borderDark]}>
        <View style={[pal.view]}>
          {store.session.hasSession ? (
            <TouchableOpacity
              testID="viewHeaderDrawerBtn"
              onPress={onPressAvi}
              accessibilityRole="button"
              accessibilityLabel="Open navigation"
              accessibilityHint="Access profile and other navigation links"
              hitSlop={HITSLOP_10}>
              <UserAvatar avatar={store.me.avatar} size={27} />
            </TouchableOpacity>
          ) : (
            <BrandLogoSmall size={25} />
          )}
        </View>

        {store.session.hasSession ? (
          <View
            style={{
              width: 150,
              height: 25,
              flexDirection: 'row',
              alignItems: 'center',
            }}>
            <BrandLogo />
          </View>
        ) : (
          <>
            {/* <Text style={[brandBlue, s.bold, styles.title]}>
              {store.session.isSandbox ? 'SANDBOX' : 'Solarplex'}
            </Text> */}
            {/* <View style={[pal.view]}>
              <Link
                testID="viewHeaderHomeFeedPrefsBtn"
                href="/settings/home-feed"
                hitSlop={HITSLOP_10}
                accessibilityRole="button"
                accessibilityLabel="Edit Saved Feeds"
                accessibilityHint="Opens screen to edit Saved Feeds">
                <FontAwesomeIcon
                  icon="sliders"
                  style={pal.textLight as FontAwesomeIconStyle}
                />
              </Link>
            </View> */}
          </>
        )}
        <View />
        {!store.session.hasSession && (
          <View style={{flexDirection: 'row', gap: 8}}>
            <Button
              type="default"
              label="Sign In"
              onPress={() => {
                track('Banner:SignIn')
                navigation.navigate('SignIn')
              }}
              labelStyle={{fontWeight: '400'}}
            />
            <Button
              type="primary"
              label="Create Account"
              onPress={() => {
                track('Banner:SignUp')
                navigation.navigate('SignUp', {
                  name: 'creator',
                })
              }}
              labelStyle={{fontWeight: '700'}}
            />
          </View>
        )}
      </View>
      <TabBar
        key={(props.items ?? items).join(',')}
        onPressSelected={props.onPressSelected}
        selectedPage={props.selectedPage}
        onSelect={props.onSelect}
        testID={props.testID}
        items={props.items ?? items}
        indicatorColor={pal.colors.link}
      />
    </Animated.View>
  )
})

const styles = StyleSheet.create({
  tabBar: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0,
    flexDirection: 'column',
    alignItems: 'center',
    borderBottomWidth: 1,
  },
  noSessionTopBar: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 18,
    paddingTop: 8,
    paddingBottom: 4,
    width: '100%',
  },
  topBar: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 18,
    paddingTop: isMobileWeb ? 4 : 8,
    paddingBottom: isMobileWeb ? 4 : 2,
    width: '100%',
  },
  title: {
    fontSize: 21,
  },
  disabled: {
    pointerEvents: 'none',
  },
})
