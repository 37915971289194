import {makeAutoObservable, runInAction} from 'mobx'

import {Json} from 'lib/splx-utils/json'
import {RootStoreModel} from './models/root-store'
import {SOLARPLEX_FEED_API} from 'lib/constants'
import {debouncer} from '../lib/splx-utils/functions'
import {md5} from '../lib/splx-utils/string'
import merge from 'lodash.merge'
import {tryEachAnimationFrame} from '../lib/splx-utils/timers'
import {WalletContextState} from '@solana/wallet-adapter-react'

export interface ModelWalletState {
  connectedWalletId: string
  waitingToConnectWallet: boolean
  canceledWaitingToConnectWallet: boolean
  linkedWalletDidMap: {[did: string]: string}
  walletPopup: WalletContextState | undefined
}

enum ActionStatus {
  Loading = 'loading',
  Busy = 'busy',
  Error = 'error',
  Idle = 'idle',
  New = 'new',
}

interface ActionState {
  key: string
  status: ActionStatus
}

interface Actions {
  [id: string]: ActionState
}

interface ActionStatePayload {
  status: ActionStatus
  error?: Error
  key?: string
}

function getInitialState(): ModelWalletState {
  return {
    canceledWaitingToConnectWallet: false,
    waitingToConnectWallet: false,
    connectedWalletId: '',
    linkedWalletDidMap: {},
    walletPopup: undefined,
  }
}

function getActionKeyFromPayload(...args: any[]): string | undefined {
  for (let i = 0; i < args.length; i++) {
    if (args[i]?.storeActionKey) {
      return args[i]?.storeActionKey
    }
  }
}

function getKeyPrefix(fnName: string) {
  return `Wallet/${fnName}`
}

function getPayloadString(...args: any[]): string {
  const str = getActionKeyFromPayload(...args) ?? JSON.stringify(args ?? '')
  return str
}

function payloadToKey(fnName: string, ...args: any[]) {
  const prefix = getKeyPrefix(fnName)
  const payloadStr = getPayloadString(...args)
  const hash = payloadStr.length > 512 ? md5(payloadStr) : payloadStr
  if (!hash) {
    return prefix
  }
  return `${prefix}#${hash}`
}

export class SplxWallet {
  state: ModelWalletState = getInitialState()

  actions: Actions = {}

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      {autoBind: true},
    )
  }

  get linkedWallet(): string {
    if (!this.rootStore.me.did) {
      return ''
    }
    return this.state.linkedWalletDidMap[this.rootStore.me.did]
  }

  // TODO(zfaizal2) move to it's own store
  async feedApiCall(subDir: string, method: string = 'GET', body?: Json) {
    return this._execute(
      async () => {
        if (!subDir.startsWith('/')) {
          subDir = `/${subDir}`
        }
        try {
          const bodyStr = body ? JSON.stringify(body) : ''
          const requestInit: RequestInit = {
            method,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.rootStore.session?.currentSession?.accessJwt}`,
            },
          }
          if (bodyStr) {
            requestInit.body = bodyStr
          }
          const response = await fetch(
            `${SOLARPLEX_FEED_API}${subDir}`,
            requestInit,
          )

          if (!response.ok) {
            throw new Error(`feedApiCallFailed_${subDir}_${bodyStr}`)
          }
          return await response.json()
        } catch (err) {
          console.error(`apiFeedError`, err)
          throw err
        }
      },
      '_feedApiCall',
      subDir,
      body,
    )
  }

  _maybeCreateActionStateAndReturnKey(fnName: string, ...args: any[]) {
    const key = payloadToKey(fnName, ...args)
    if (!this.actions[key]) {
      runInAction(() => {
        this.actions[key] = {
          key,
          status: ActionStatus.New,
        }
      })
    }
    return key
  }

  _setActionState(
    {status, key}: ActionStatePayload,
    fnName: string,
    ...args: any[]
  ) {
    const stateKey = (key ??
      this._maybeCreateActionStateAndReturnKey(fnName, ...args)) as string
    runInAction(() => {
      const currentState = {...this.actions[stateKey]}
      currentState.status = status
      this.actions[stateKey] = merge(this.actions[stateKey], currentState)
    })
  }

  _setBusy(fnName: string, ...args: any[]) {
    const key = this._maybeCreateActionStateAndReturnKey(fnName, ...args)
    const currentStatus = this.actions[key].status
    this._setActionState(
      {
        key,
        status:
          currentStatus === ActionStatus.New
            ? ActionStatus.Loading
            : ActionStatus.Busy,
      },
      fnName,
      ...args,
    )
  }

  _setIdle(fnName: string, ...args: any[]) {
    const key = this._maybeCreateActionStateAndReturnKey(fnName, ...args)
    const currentStatus = this.actions[key].status
    if (currentStatus === ActionStatus.New) {
      return
    }
    this._setActionState(
      {
        key,
        status: ActionStatus.Idle,
      },
      fnName,
      ...args,
    )
  }

  _setError(error: Error, fnName: string, ...args: any[]) {
    const key = this._maybeCreateActionStateAndReturnKey(fnName, ...args)
    this._setActionState(
      {
        error,
        key,
        status: ActionStatus.Error,
      },
      fnName,
      ...args,
    )
  }

  _isBusy(fnName: string, ...args: any[]) {
    const key = this._maybeCreateActionStateAndReturnKey(fnName, ...args)
    const currentStatus = this.actions[key].status
    return (
      currentStatus === ActionStatus.Busy ||
      currentStatus === ActionStatus.Loading
    )
  }

  async _execute<T>(
    fn: (...args: any[]) => Promise<T>,
    fnName: string,
    ...args: any[]
  ) {
    return await debouncer.execute(async () => {
      this._setBusy(fnName, ...args)
      try {
        const r = await fn(...args)
        this._setIdle(fnName, ...args)
        return r
      } catch (err) {
        console.error('error in', getKeyPrefix(fnName), err)
        this._setError(err as Error, fnName, ...args)
        throw err
      }
    }, payloadToKey(fnName, ...args))
  }

  async waitForWalletConnect() {
    return this._execute(async () => {
      await tryEachAnimationFrame(() => {
        return (
          (!!this.state.connectedWalletId &&
            this.state.walletPopup?.connected) ||
          this.state.canceledWaitingToConnectWallet
        )
      }, 86400 * 1000)
      runInAction(() => {
        this.state.waitingToConnectWallet = false
        this.state.canceledWaitingToConnectWallet = false
      })
      return this.state.connectedWalletId
    }, 'waitForWalletConnect')
  }

  async ensureWalletConnectedAndLinked(): Promise<string | undefined> {
    /**
     * - First, wait to connect wallet in the browser and get a wallet ID
     * - Second, check if user has a linked wallet in the database or not
     * - If they have a linked wallet, continue
     * - If not, link this connected wallet and continue
     */
    // console.log('1/ waiting for wallet connect')
    const connectedWalletId = await this.waitForWalletConnect()
    if (!connectedWalletId) {
      throw new Error('No connected wallet')
    }
    // console.log('2/ wallet connected is ', connectedWalletId)
    const linkedWalletFromServer = await this.getLinkedWalletFromServer()
    // console.log(
    //   '3/ is there a linked wallet on the server?',
    //   linkedWalletFromServer,
    // )
    if (!linkedWalletFromServer) {
      // console.log('4a/ no linked wallet was found on the server, link now')
      await this.linkWallet(this.state.connectedWalletId)
      const linkedWallet = await this.getLinkedWalletFromServer()
      return linkedWallet
      // console.log('5a/ check this works', linkedWallet)
    } else {
      return linkedWalletFromServer
    }
  }

  waitForWalletConnectIsBusy() {
    // We are currently waiting or the wallet to be connected via the popup.
    return this._isBusy('waitForWalletConnect')
  }

  startWaitForWalletConnect() {
    console.log('[Walletconnect] User started wallet connect')
    // Begin the process of wallet connect via the wallet popup.
    runInAction(() => {
      this.state.waitingToConnectWallet = true
      this.state.canceledWaitingToConnectWallet = false
    })
  }

  cancelWaitForWalletConnect() {
    // User canceled the process of wallet connect via the wallet popup.
    console.log('[Walletconnect] User canclced wallet connect')
    runInAction(() => {
      this.state.waitingToConnectWallet = false
      this.state.canceledWaitingToConnectWallet = true
    })
  }

  get walletPopup() {
    return this.state.walletPopup
  }

  get walletId() {
    return this.state.connectedWalletId
  }

  setWalletPopup(walletPopup: WalletContextState | undefined) {
    runInAction(() => {
      this.state.walletPopup = walletPopup
    })
  }

  setConnectedWalletId(address: string) {
    runInAction(() => {
      this.state.connectedWalletId = address ?? ''
    })
  }

  unsetConnectedWalletId() {
    runInAction(() => {
      this.state.connectedWalletId = ''
    })
  }

  async getLinkedWalletFromServer() {
    return this._execute(
      async () => {
        const did = this.rootStore.me.did
        const {user} = await this.feedApiCall(`splx/get_user/${did}`)
        runInAction(() => {
          this.state.linkedWalletDidMap[did] = user?.wallet ?? ''
        })
        return user?.wallet ?? ''
      },
      'getLinkedWallet',
      this.rootStore.me.did,
    )
  }

  linkWalletIsBusy(wallet: string) {
    return this._isBusy('linkWallet', this.rootStore.me.did, wallet)
  }

  async linkWallet(wallet: string) {
    /**
     * this function should -
     * - check details before continuing
     * - add the wallet to the
     */
    return this._execute(
      async () => {
        if (!this.rootStore.session || wallet === this.linkedWallet) {
          return
        }
        try {
          await this.feedApiCall('splx/add_wallet_to_user', 'POST', {
            did: this.rootStore.me.did,
            wallet,
          })
          // sanity check to make sure above call was done fine
          await this.getLinkedWalletFromServer()
          // runInAction(() => {
          //   this.state.connectedWall = address
          // })
          //TODO(pratik): come back to this - where else can this be put?
          this.rootStore.me.nft.fetchNfts(this.linkedWallet)
        } catch (err) {}
      },
      'linkWallet',
      this.rootStore.me.did,
      wallet,
    )
  }

  unlinkWalletIsBusy() {
    return this._isBusy('unlinkWallet', this.rootStore.me.did)
  }

  async unlinkWallet() {
    return await this._execute(
      async () => {
        if (!this.linkedWallet) {
          return
        }
        try {
          const did = this.rootStore.me.did
          await this.feedApiCall('splx/remove_wallet_from_user', 'POST', {did})
          const address = await this.getLinkedWalletFromServer()
          runInAction(() => {
            this.state.linkedWalletDidMap[did] = address ?? ''
            this.state.connectedWalletId = ''
          })
          //TODO(pratik): come back to this to see where we move this to
          this.rootStore.me.nft.setAssets([])
        } catch (err) {}
      },
      'unlinkWallet',
      this.rootStore.me.did,
    )
  }
}
