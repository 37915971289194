import {
  AppBskyEmbedCollectible,
  AppBskyEmbedExternal,
  AppBskyEmbedImages,
  AppBskyEmbedVideos,
  AppBskyEmbedRecord,
  AppBskyEmbedRecordWithMedia,
  AppBskyEmbedFrame,
  AppBskyRichtextFacet,
  BlobRef,
  ComAtprotoLabelDefs,
  ComAtprotoRepoUploadBlob,
  RichText,
} from '@usedispatch/atproto-api'

import {AtUri} from '@usedispatch/atproto-api'
import {ImageModel} from 'state/models/media/image'
import {LinkMeta} from '../link-meta/link-meta'
import {RootStoreModel} from 'state/models/root-store'
import {isNetworkError} from 'lib/strings/errors'
import {isWeb} from 'platform/detection'
import {shortenLinks} from 'lib/strings/rich-text-manip'
import {TopMintersView} from '@usedispatch/atproto-api/dist/client/types/app/bsky/splx/defs'
import {VideoModel} from 'state/models/media/video'
import uploadVideo from 'lib/splx-utils/gaslessArUpload'

export interface ExternalEmbedDraft {
  uri: string
  isLoading: boolean
  meta?: LinkMeta
  embed?: AppBskyEmbedRecord.Main
  localThumb?: ImageModel
  frame?: AppBskyEmbedFrame.Main
}

interface NFTEmbedDraft {
  collUri: string
  collCid: string
}

interface CollectionOpts {
  collectionId: string
  attributes: any
  description: string
  image: BlobRef
  imageCid: string
  supply: number
  title: string
  wallet: string
  isPaid: boolean
  animationUrl: string
}

interface CreateClaimOpts {
  collectionCid: string
  did: string
  wallet: string
  collectionUri: string
  postSubject?: {
    uri: string
    cid: string
  }
}

interface CreatePaymentCollectionOpts {
  subjectCid: string
  wallet: string
  price: number
}

interface SubmitPaidNftTransactionOpts {
  collectibleUri: string
  collectibleCid: string
  createdAt: string
  paymentId: string
  serializedTxn: string
  subjectCid: string
  linkedWallet: string
  postSubject?: {
    uri: string
    cid: string
  }
}

export async function getTopMinters(
  store: RootStoreModel,
): Promise<TopMintersView[]> {
  try {
    const res = await store.appviewAgent.getTopMinters({
      did: store.me.did,
    })

    if (res.success && res.data.topMinters !== undefined) {
      return res.data.topMinters
    }
  } catch (e: any) {
    console.error(`Failed to get collectibles: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'get collectibles failed, Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
  return []
}

export async function getCollectibles(store: RootStoreModel) {
  try {
    const res = await store.appviewAgent.getCollectibles({
      did: store.me.did,
    })

    if (res.success && res.data.collectibles !== undefined) {
      return res.data.collectibles
    }
  } catch (e: any) {
    console.error(`Failed to get collectibles: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'get collectibles failed, Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
  return []
}

export async function submitPaidNftTransaction(
  store: RootStoreModel,
  opts: SubmitPaidNftTransactionOpts,
) {
  try {
    const res = await store.agent.submitPaidNftTransaction({
      ...opts,
      wallet: opts.linkedWallet,
    })

    return res.data
  } catch (e: any) {
    console.error(`Failed to submit paid nft transaction: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'submit paid nft transaction failed, Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
}

interface CreatePaidNftTransactionOpts {
  connectedWallet: string
  collectionCid: string
  did?: string
  postSubject?: {
    uri: string
    cid: string
  }
}

export async function createPaidNftTransaction(
  store: RootStoreModel,
  opts: CreatePaidNftTransactionOpts,
) {
  try {
    return await store.agent.createPaidNftTransaction({
      collectibleCid: opts.collectionCid,
      wallet: opts.connectedWallet,
      did: opts.did,
      postSubject: opts.postSubject,
    })
  } catch (e: any) {
    console.error(`Failed to create paid nft transaction: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'create paid nft transaction failed, Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
}

export async function createPaymentCollection(
  store: RootStoreModel,
  opts: CreatePaymentCollectionOpts,
) {
  try {
    const data = {
      ...opts,
      paymentType: 'sphere',
      currency: 'SOL',
    }
    return await store.agent.createPaymentCollection(data)
  } catch (e: any) {
    console.error(`Failed to create payment collection: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'create payment collection failed, Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
}

export async function createClaim(
  store: RootStoreModel,
  opts: CreateClaimOpts,
) {
  try {
    return await store.agent.createClaim(
      opts.collectionCid,
      opts.did,
      opts.wallet,
      opts.collectionUri,
      opts.postSubject,
    )
  } catch (e: any) {
    console.error(`Failed to create claim: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'create claim failed, Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
}

export async function createCollection(
  store: RootStoreModel,
  opts: CollectionOpts,
) {
  try {
    return await store.agent.createCollection({
      collectionId: opts.collectionId,
      attributes: opts.attributes,
      description: opts.description,
      imageCid: opts.imageCid,
      supply: opts.supply,
      title: opts.title,
      wallet: opts.wallet,
      image: {
        image: opts.image,
        alt: '',
      },
      isPaid: opts.isPaid,
      animationUrl: opts.animationUrl,
    })
  } catch (e: any) {
    console.error(`Failed to create collection: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'Post failed to upload. Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
}

export async function resolveName(store: RootStoreModel, didOrHandle: string) {
  if (!didOrHandle) {
    throw new Error('Invalid handle: ""')
  }
  if (didOrHandle.startsWith('did:')) {
    return didOrHandle
  }

  // we run the resolution always to ensure freshness
  const promise = store.agent
    .resolveHandle({
      handle: didOrHandle,
    })
    .then(res => {
      store.handleResolutions.cache.set(didOrHandle, res.data.did)
      return res.data.did
    })

  // but we can return immediately if it's cached
  const cached = store.handleResolutions.cache.get(didOrHandle)
  if (cached) {
    return cached
  }

  return promise
}

export async function uploadBlob(
  store: RootStoreModel,
  blob: string,
  encoding: string,
): Promise<ComAtprotoRepoUploadBlob.Response> {
  if (isWeb) {
    // `blob` should be a data uri
    return store.agent.uploadBlob(convertDataURIToUint8Array(blob), {
      encoding,
    })
  } else {
    // `blob` should be a path to a file in the local FS
    return store.agent.uploadBlob(
      blob, // this will be special-cased by the fetch monkeypatch in /src/state/lib/api.ts
      {encoding},
    )
  }
}

interface PostOpts {
  rawText: string
  replyTo?: string
  quote?: {
    uri: string
    cid: string
  }
  extLink?: ExternalEmbedDraft
  images?: ImageModel[]
  videos?: VideoModel[]
  labels?: string[]
  knownHandles?: Set<string>
  onStateChange?: (state: string) => void
  langs?: string[]
  nft?: NFTEmbedDraft
}

export async function post(store: RootStoreModel, opts: PostOpts) {
  let embed:
    | AppBskyEmbedImages.Main
    | AppBskyEmbedVideos.Main
    | AppBskyEmbedExternal.Main
    | AppBskyEmbedRecord.Main
    | AppBskyEmbedRecordWithMedia.Main
    | AppBskyEmbedCollectible.Main
    | AppBskyEmbedFrame.Main
    | undefined

  let reply
  let rt = new RichText(
    {text: opts.rawText.trimEnd()},
    {
      cleanNewlines: true,
    },
  )

  opts.onStateChange?.('Processing...')
  await rt.detectFacets(store.agent)
  rt = shortenLinks(rt)

  // filter out any mention facets that didn't map to a user
  rt.facets = rt.facets?.filter(facet => {
    const mention = facet.features.find(feature =>
      AppBskyRichtextFacet.isMention(feature),
    )
    if (mention && !mention.did) {
      return false
    }
    return true
  })

  // add quote embed if present
  if (opts.quote) {
    embed = {
      $type: 'app.bsky.embed.record',
      record: {
        uri: opts.quote.uri,
        cid: opts.quote.cid,
      },
    } as AppBskyEmbedRecord.Main
  }

  // add image embed if present
  // only if this is not an nft since we would have done it before otherwise
  // maybe we shift that actual embed here..
  // instead of passing it through?

  if (opts.images?.length && !opts.nft) {
    const images: AppBskyEmbedImages.Image[] = []
    for (const image of opts.images) {
      opts.onStateChange?.(`Uploading image #${images.length + 1}...`)
      await image.compress()
      const path = image.compressed?.path ?? image.path
      const {width, height} = image.compressed || image
      const res = await uploadBlob(store, path, 'image/jpeg')
      images.push({
        image: res.data.blob,
        alt: image.altText ?? '',
        aspectRatio: {width, height},
      })
    }

    if (opts.quote) {
      embed = {
        $type: 'app.bsky.embed.recordWithMedia',
        record: embed,
        media: {
          $type: 'app.bsky.embed.images',
          images,
        },
      } as AppBskyEmbedRecordWithMedia.Main
    } else {
      embed = {
        $type: 'app.bsky.embed.images',
        images,
      } as AppBskyEmbedImages.Main
    }
  }

  if (opts.videos?.length && !opts.nft) {
    const videos: AppBskyEmbedVideos.Video[] = []

    for (const video of opts.videos) {
      opts.onStateChange?.(`uploading video to arweave...`)
      const path = video.path
      // hard code for now to see how to get this later
      // expo-av doesnt seem to give us height / width or duration
      const {width, height} = {width: 1000, height: 1000}
      const duration = video.duration
      let uri = ''
      // Upload video to NFT.storage and receive URI
      try {
        uri = await uploadVideo(path, [])
      } catch (error) {
        console.log('Error uploading video to arweave', error)
        opts.onStateChange?.(`failed to upload to ipfs`)
        return
      }

      videos.push({
        uri,
        alt: video.alt ?? '',
        duration,
        aspectRatio: {width, height},
      })

      if (opts.quote) {
        embed = {
          $type: 'app.bsky.embed.recordWithMedia',
          record: embed,
          media: {
            $type: 'app.bsky.embed.videos',
            videos: videos,
          },
        } as AppBskyEmbedRecordWithMedia.Main
      } else {
        embed = {
          $type: 'app.bsky.embed.videos',
          videos: videos,
        } as AppBskyEmbedVideos.Main
      }
    }
  }

  // add external embed if present
  if (opts.extLink && !opts.images?.length) {
    if (opts.extLink.embed) {
      embed = opts.extLink.embed
    } else {
      let thumb
      if (opts.extLink.localThumb) {
        opts.onStateChange?.('Uploading link thumbnail...')
        let encoding
        if (opts.extLink.localThumb.mime) {
          encoding = opts.extLink.localThumb.mime
        } else if (opts.extLink.localThumb.path.endsWith('.png')) {
          encoding = 'image/png'
        } else if (
          opts.extLink.localThumb.path.endsWith('.jpeg') ||
          opts.extLink.localThumb.path.endsWith('.jpg')
        ) {
          encoding = 'image/jpeg'
        } else {
          store.log.warn(
            'Unexpected image format for thumbnail, skipping',
            opts.extLink.localThumb.path,
          )
        }
        if (encoding) {
          const thumbUploadRes = await uploadBlob(
            store,
            opts.extLink.localThumb.path,
            encoding,
          )
          thumb = thumbUploadRes.data.blob
        }
      }

      if (opts.quote) {
        embed = {
          $type: 'app.bsky.embed.recordWithMedia',
          record: embed,
          media: {
            $type: 'app.bsky.embed.external',
            external: {
              uri: opts.extLink.uri,
              title: opts.extLink.meta?.title || '',
              description: opts.extLink.meta?.description || '',
              thumb,
            },
          } as AppBskyEmbedExternal.Main,
        } as AppBskyEmbedRecordWithMedia.Main
      } else {
        if (opts.extLink.frame) {
          const {frame} = opts.extLink.frame
          embed = {
            $type: 'app.bsky.embed.frame',
            frame: frame,
          } as AppBskyEmbedFrame.Main
        } else {
          embed = {
            $type: 'app.bsky.embed.external',
            external: {
              uri: opts.extLink.uri,
              title: opts.extLink.meta?.title || '',
              description: opts.extLink.meta?.description || '',
              thumb,
            },
          } as AppBskyEmbedExternal.Main
        }
      }
    }
  }

  // add replyTo if post is a reply to another post
  if (opts.replyTo) {
    const replyToUrip = new AtUri(opts.replyTo)
    const parentPost = await store.agent.getPost({
      repo: replyToUrip.host,
      rkey: replyToUrip.rkey,
    })
    if (parentPost) {
      const parentRef = {
        uri: parentPost.uri,
        cid: parentPost.cid,
      }
      reply = {
        root: parentPost.value.reply?.root || parentRef,
        parent: parentRef,
      }
    }
  }

  // set labels
  let labels: ComAtprotoLabelDefs.SelfLabels | undefined
  if (opts.labels?.length) {
    labels = {
      $type: 'com.atproto.label.defs#selfLabels',
      values: opts.labels.map(val => ({val})),
    }
  }

  // add top 3 languages from user preferences if langs is provided
  let langs = opts.langs
  if (opts.langs) {
    langs = opts.langs.slice(0, 3)
  }

  if (opts.nft) {
    opts.onStateChange?.('Doing NFT stuff ...')
    embed = {
      $type: 'app.bsky.embed.collectible',
      collectible: {
        collCid: opts.nft.collCid,
        collUri: opts.nft.collUri,
      },
    } as AppBskyEmbedCollectible.Main
  }

  try {
    opts.onStateChange?.('Posting...')
    return await store.agent.post({
      text: rt.text,
      facets: rt.facets,
      reply,
      embed,
      langs,
      labels,
    })
  } catch (e: any) {
    console.error(`Failed to create post: ${e.toString()}`)
    if (isNetworkError(e)) {
      throw new Error(
        'Post failed to upload. Please check your Internet connection and try again.',
      )
    } else {
      throw e
    }
  }
}

// helpers
// =

function convertDataURIToUint8Array(uri: string): Uint8Array {
  var raw = window.atob(uri.substring(uri.indexOf(';base64,') + 8))
  var binary = new Uint8Array(new ArrayBuffer(raw.length))
  for (let i = 0; i < raw.length; i++) {
    binary[i] = raw.charCodeAt(i)
  }
  return binary
}
