import {makeAutoObservable} from 'mobx'
import {RootStoreModel} from '../root-store'
import {bundleAsync} from 'lib/async/bundle'
import {cleanError} from 'lib/strings/errors'
import {SOLARPLEX_FEED_API} from 'lib/constants'

export const apiUrls = {
  gallery: {
    getUserCollectibles: (did: string) => `/splx/gallery/${did}`,
    getCreatorByDid: (did: string) => `/splx/gallery/creator/${did}`,
  },
}

export interface UserCollectible {
  animationUrl: string
  attributes: {
    [key: string]: string // This allows for ad
  }
  collectible_post_uri: string
  creator: string
  description: string
  did: string
  handle: string
  image: string
  imageCid: string
  title: string
  wallet: string
}

export interface AssetPair {
  items: {asset1: UserCollectible; asset2: UserCollectible}
}

export class GalleryFeedsModel {
  // state
  isLoading = false
  isRefreshing = false
  hasLoaded = false
  error = ''
  hasMore = true
  loadMoreCursor?: string
  profileDid: string

  // data
  assets: AssetPair[] = []

  constructor(public rootStore: RootStoreModel, public did: string) {
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      {autoBind: true},
    )
    this.profileDid = did
  }

  get hasContent() {
    return this.assets.length > 0
  }

  get hasError() {
    return this.error !== ''
  }

  get isEmpty() {
    return this.hasLoaded && !this.hasContent
  }

  // public api
  // =

  async refresh() {
    return this.loadMore(true)
  }

  clear() {
    this.isLoading = false
    this.isRefreshing = false
    this.hasLoaded = false
    this.error = ''
    this.hasMore = true
    this.loadMoreCursor = undefined
    this.assets = []
  }

  loadMore = bundleAsync(async (replace: boolean = false) => {
    if (!replace && !this.hasMore) {
      return
    }
    this._xLoading(replace)
    try {
      const url = `${SOLARPLEX_FEED_API}${apiUrls.gallery.getUserCollectibles(
        this.profileDid,
      )}`
      const res = await this.rootStore.api.get<{data: UserCollectible[]}>(url)

      const data = res?.data
      if (data === undefined) return
      const parsedRes: AssetPair[] = []
      if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i += 2) {
          if (data[i] !== undefined && data[i + 1] !== undefined) {
            const assetPair: AssetPair = {
              items: {
                asset1: data[i],
                asset2: data[i + 1],
              },
            }
            parsedRes.push(assetPair)
          }
        }
      }

      if (replace) {
        this._replaceAll(parsedRes)
      } else {
        this._appendAll(parsedRes)
      }
      this._xIdle()
    } catch (e: any) {
      this._xIdle(e)
    }
  })

  // state transitions
  // =

  _xLoading(isRefreshing = false) {
    this.isLoading = true
    this.isRefreshing = isRefreshing
    this.error = ''
  }

  _xIdle(err?: any) {
    this.isLoading = false
    this.isRefreshing = false
    this.hasLoaded = true
    this.error = cleanError(err)
    if (err) {
      this.rootStore.log.error('Failed to fetch user followers', err)
    }
  }

  // helper functions
  // =

  _replaceAll(res: AssetPair[]) {
    this.assets = []
    this._appendAll(res)
  }

  _appendAll(res: AssetPair[]) {
    this.hasMore = !!this.loadMoreCursor
    this.assets = res
    // for (const f of res.data.feeds) {
    //   const model = new FeedSourceModel(this.rootStore, f.uri)
    //   model.hydrateFeedGenerator(f)
    //   this.feeds.push(model)
    // }
  }
}
