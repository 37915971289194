import {makeAutoObservable} from 'mobx'
import {isObj, hasProp} from 'lib/type-guards'
import {RootStoreModel} from '../root-store'
// import {isMobileWeb, isPWA} from 'platform/detection'

export class PWAReminder {
  lastPWAClosed: Date | null = null

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(
      this,
      {serialize: false, hydrate: false},
      {autoBind: true},
    )
  }

  serialize() {
    return {
      lastPWAClosed: this.lastPWAClosed
        ? this.lastPWAClosed.toISOString()
        : undefined,
    }
  }

  hydrate(v: unknown) {
    if (
      isObj(v) &&
      hasProp(v, 'lastPWAClosed') &&
      typeof v.lastPWAClosed === 'string'
    ) {
      this.lastPWAClosed = new Date(v.lastPWAClosed)
    }
  }

  get shouldDisplayPWAInfo() {
    const sess = this.rootStore.session.currentSession

    if (!sess) {
      return false
    }
    if (this.lastPWAClosed) {
      const twentyFourHoursAgo = new Date()
      twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24)
      if (this.lastPWAClosed > twentyFourHoursAgo) {
        return false
      }
    }

    // const today = new Date()
    // shard the users into 2 day of the week buckets
    // (this is to avoid a sudden influx of email updates when
    // this feature rolls out)
    // const code = toHashCode(sess.did) % 7
    // if (code !== today.getDay() && code !== (today.getDay() + 1) % 7) {
    //   return false
    // }
    return true
  }

  setPWAInfoRequested() {
    this.lastPWAClosed = new Date()
  }
}
