import {StyleSheet, View} from 'react-native'

import {Followers} from './Followers'
import React from 'react'
import {Streaks} from './Streaks'
import {Tipping} from './Tipping'
import {observer} from 'mobx-react-lite'
import {track} from 'lib/analytics/analytics'
import {usePalette} from 'lib/hooks/usePalette'
import {useSplxWallet} from '../../wallet/useSplxWallet'
import {useStores} from '../../../../state/index'

enum OnboardingStep {
  FOLLOWERS = 'FOLLOWERS',
  STREAKS = 'STREAKS',
  TIPPING = 'TIPPING',
}
type OnboardingState = {
  currentStep: OnboardingStep
}
type Action = {type: 'NEXT_STEP' | 'PREV_STEP'}
const initialState: OnboardingState = {
  currentStep: OnboardingStep.FOLLOWERS,
}
const reducer = (state: OnboardingState, action: Action): OnboardingState => {
  switch (action.type) {
    case 'NEXT_STEP':
      switch (state.currentStep) {
        case OnboardingStep.FOLLOWERS:
          track('Onboarding:Begin')
          return {...state, currentStep: OnboardingStep.STREAKS}
        case OnboardingStep.STREAKS:
          return {...state, currentStep: OnboardingStep.TIPPING}
        case OnboardingStep.TIPPING:
          track('Onboarding:Complete')
          return {...state, currentStep: OnboardingStep.TIPPING}
        default:
          return state
      }
    default:
      return state
    case 'PREV_STEP':
      switch (state.currentStep) {
        case OnboardingStep.STREAKS:
          return {...state, currentStep: OnboardingStep.FOLLOWERS}
        case OnboardingStep.TIPPING:
          return {...state, currentStep: OnboardingStep.STREAKS}
        default:
          return state
      }
  }
}

//TODO(pratik): fix linting error
export const Onboarding = observer(() => {
  const pal = usePalette('default')
  const rootStore = useStores()
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const [
    _visible,
    _setVisible,
    linkedWallet,
    _connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
  ] = useSplxWallet(true)
  const next = React.useCallback(
    () => dispatch({type: 'NEXT_STEP'}),
    [dispatch],
  )

  const prev = React.useCallback(
    () => dispatch({type: 'PREV_STEP'}),
    [dispatch],
  )
  // console.log("STATE",state.currentStep);
  // console.log("LINKED",linkedWallet);
  // console.log("state.currentStep === OnboardingStep.TIPPING",state.currentStep === OnboardingStep.TIPPING);
  // console.log("linkedWallet !== ''",linkedWallet !== '');
  React.useEffect(() => {
    if (linkedWallet !== '' && state.currentStep === OnboardingStep.TIPPING) {
      rootStore.me.mainFeed.refresh()

      rootStore.shell.closeModal()
    }
    if (!rootStore.me.mainFeed.hasContent) {
      rootStore.me.mainFeed.refresh()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentStep, rootStore.shell, rootStore.wallet, linkedWallet])

  return (
    <View style={[pal.view, styles.container]}>
      {state.currentStep === OnboardingStep.FOLLOWERS && (
        <Followers next={next} />
      )}
      {state.currentStep === OnboardingStep.STREAKS && (
        <Streaks next={next} prev={prev} />
      )}
      {state.currentStep === OnboardingStep.TIPPING && (
        <Tipping next={next} prev={prev} />
      )}
    </View>
  )
})

Onboarding.displayName = 'Onboarding'

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
