import 'lib/sentry' // must be near top

import React, {useState, useEffect} from 'react'
import {observer} from 'mobx-react-lite'
import {QueryClientProvider} from '@tanstack/react-query'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {RootSiblingParent} from 'react-native-root-siblings'
import 'view/icons'
import * as analytics from 'lib/analytics/analytics'
import {RootStoreModel, setupState, RootStoreProvider} from './state'
import {ToastContainer} from 'view/com/util/Toast.web'
import {ThemeProvider} from 'lib/ThemeContext'
import {queryClient} from 'lib/react-query'
import * as notifications from 'lib/notifications/notifications'
import {ConnectionProvider, WalletProvider} from '@solana/wallet-adapter-react'
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets'

import {WalletAdapter} from '@solana/wallet-adapter-base'

import {Shell} from './view/shell/index'

import {WalletModalProvider} from '@solana/wallet-adapter-react-ui'
import {useFonts} from 'expo-font'
import {RPC_API} from 'lib/constants'

import {LogBox} from 'react-native'

LogBox.ignoreLogs([
  'TouchableWithoutFeedback is deprecated',
  '"transform" style array value is deprecated',
  '"fontVariant" style array value is deprecated',
  '"textAlignVertical" style is deprecated',
  'accessibilityLabel is deprecated.',
  'TouchableOpacity is deprecated',
])

require('@solana/wallet-adapter-react-ui/styles.css')

const App = observer(function AppImpl() {
  const [rootStore, setRootStore] = useState<RootStoreModel | undefined>(
    undefined,
  )
  const [fontsLoaded] = useFonts({
    Manrope: require('../assets/fonts/Manrope.ttf'),
    Chirp: require('../assets/fonts/Chirp-Regular.ttf'),
  })

  // The network can be set to 'devnet', 'testnet', or 'mainnet-beta'.
  // const network = WalletAdapterNetwork.Mainnet

  // You can also provide a custom RPC endpoint.
  const endpoint = RPC_API

  // init
  useEffect(() => {
    setupState().then(store => {
      setRootStore(store)
      notifications.init(store)
      analytics.init(store)
    })
  }, [])

  // show nothing prior to init
  if (!rootStore || !fontsLoaded) {
    return null
  }

  const wallets: WalletAdapter[] = [
    new PhantomWalletAdapter(),
    new SolflareWalletAdapter(),
    // new BackpackWalletAdapter(),
    // new GlowWalletAdapter(),
    // new BraveWalletAdapter(),
  ]

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={rootStore.shell.colorMode}>
        <RootSiblingParent>
          <analytics.Provider>
            <RootStoreProvider value={rootStore}>
              <ConnectionProvider endpoint={endpoint}>
                <WalletProvider wallets={wallets} autoConnect>
                  <WalletModalProvider>
                    <SafeAreaProvider>
                      <Shell />
                    </SafeAreaProvider>
                    <ToastContainer />
                  </WalletModalProvider>
                </WalletProvider>
              </ConnectionProvider>
            </RootStoreProvider>
          </analytics.Provider>
        </RootSiblingParent>
      </ThemeProvider>
    </QueryClientProvider>
  )
})

export default App
