import React from 'react'
import {Linking, StyleSheet, TouchableOpacity, View} from 'react-native'
import {observer} from 'mobx-react-lite'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import Clipboard from '@react-native-clipboard/clipboard'
import {Text} from '../util/text/Text'
import {Button} from '../util/forms/Button'
import * as Toast from '../util/Toast'
import {useStores} from 'state/index'
import {ScrollView} from './util'
import {usePalette} from 'lib/hooks/usePalette'
import {isWeb} from 'platform/detection'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'

import {formatCount} from '../util/numeric/format'
import {pluralize} from 'lib/strings/helpers'
import {s} from 'lib/styles'
import {TwitterIcon} from 'lib/icons'
import {HANDLE_SUFFIX, SOLARPLEX_REALM} from 'lib/constants'
import {sanitizeUrl} from '@braintree/sanitize-url'

export const snapPoints = ['70%']

export function Component({}: {}) {
  const pal = usePalette('default')
  const store = useStores()
  const {invitesUsed} = store.me
  const {isTabletOrDesktop} = useWebMediaQueries()

  const onClose = React.useCallback(() => {
    store.shell.closeModal()
  }, [store])

  if (store.me.invites.length === 0) {
    return (
      <View style={[styles.container, pal.view]} testID="inviteCodesModal">
        <View style={[styles.empty, pal.viewLight]}>
          <Text type="lg" style={[pal.text, styles.emptyText]}>
            You don't have any invite codes yet! We'll send you some when you've
            been on Solarplex for a little longer.
          </Text>
        </View>
        <View style={styles.flex1} />
        <View style={styles.smallSpacer} />
        <View
          style={[
            styles.btnContainer,
            isTabletOrDesktop && styles.btnContainerDesktop,
          ]}>
          <Button
            type="primary"
            label="Done"
            style={styles.btn}
            labelStyle={styles.btnLabel}
            onPress={onClose}
          />
        </View>
      </View>
    )
  }

  return (
    <View style={[styles.container, pal.view]} testID="inviteCodesModal">
      <Text type="title-xl" style={[styles.title, pal.text]}>
        Invite a Friend
      </Text>
      <Text type="lg" style={[styles.description, pal.text]}>
        Send these invites to your friends so they can create an account. Each
        code works once!
      </Text>
      <Text type="sm" style={[styles.description, pal.text]}>
        (Ping @viksit on Solarplex to receive more.)
      </Text>
      <View
        style={[
          styles.description,
          s.alignCenter,
          s.flexRow,
          {justifyContent: 'center'},
        ]}>
        <FontAwesomeIcon
          icon="ticket"
          style={[styles.inviteCodesIcon, pal.text]}
          size={16}
        />
        <Text type="md-medium" style={pal.text}>
          {formatCount(invitesUsed)} invite{' '}
          {pluralize(store.me.invitesAvailable, 'code')} used
        </Text>
      </View>
      <ScrollView style={[styles.scrollContainer, pal.border]}>
        {store.me.invites.map((invite, i) =>
          invite.uses.length ? (
            <></>
          ) : (
            <View
              key={invite.code}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <InviteCode
                testID={`inviteCode-${i}`}
                code={invite.code}
                used={
                  invite.available - invite.uses.length <= 0 || invite.disabled
                }
              />
              <TwitterShare code={invite.code} />
            </View>
          ),
        )}
      </ScrollView>
      <View style={styles.btnContainer}>
        <Button
          testID="closeBtn"
          type="primary"
          label="Done"
          style={styles.btn}
          labelStyle={styles.btnLabel}
          onPress={onClose}
        />
      </View>
    </View>
  )
}

const TwitterShare = function TwitterShareImpl({code}: {code: string}) {
  const store = useStores()
  const creator_handle = store.me.handle
  const link =
    SOLARPLEX_REALM === 'dev'
      ? `https://staging.v2.solarplex.xyz/signup/${
          creator_handle.split(HANDLE_SUFFIX)[0]
        }`
      : `https://www.solarplex.xyz/signup/${
          creator_handle.split(HANDLE_SUFFIX)[0]
        }`

  const twitterIntentLink = `https://twitter.com/intent/tweet?text=Come join me on @solarplex_xyz to connect with creators and earn rare collectibles! Use my invite code: ${code}&url=${link}`

  const onPress = React.useCallback(() => {
    Linking.openURL(sanitizeUrl(twitterIntentLink))
  }, [twitterIntentLink])

  return (
    <TouchableOpacity onPress={onPress} accessibilityRole="button">
      <TwitterIcon size={22} strokeWidth={1.5} />
    </TouchableOpacity>
  )
}

const InviteCode = observer(function InviteCodeImpl({
  testID,
  code,
  used,
}: {
  testID: string
  code: string
  used?: boolean
}) {
  const pal = usePalette('default')
  const store = useStores()
  const {invitesAvailable} = store.me

  const onPress = React.useCallback(() => {
    Clipboard.setString(code)
    Toast.show('Copied to clipboard')
    store.invitedUsers.setInviteCopied(code)
  }, [store, code])

  return (
    <TouchableOpacity
      testID={testID}
      style={[styles.inviteCode, pal.border, {flex: 1}]}
      onPress={onPress}
      accessibilityRole="button"
      accessibilityLabel={
        invitesAvailable === 1
          ? 'Invite codes: 1 available'
          : `Invite codes: ${invitesAvailable} available`
      }
      accessibilityHint="Opens list of invite codes">
      <Text
        testID={`${testID}-code`}
        type={used ? 'md' : 'md-bold'}
        style={used ? [pal.textLight, styles.strikeThrough] : pal.text}>
        {code}
      </Text>
      <View style={styles.flex1} />
      {!used && store.invitedUsers.isInviteCopied(code) && (
        <Text style={[pal.textLight, styles.codeCopied]}>Copied</Text>
      )}
      {!used && (
        <FontAwesomeIcon
          icon={['far', 'clone']}
          style={pal.text as FontAwesomeIconStyle}
        />
      )}
    </TouchableOpacity>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: isWeb ? 0 : 50,
  },
  title: {
    textAlign: 'center',
    marginTop: 12,
    marginBottom: 12,
  },
  description: {
    textAlign: 'center',
    paddingHorizontal: 42,
    marginBottom: 14,
  },

  scrollContainer: {
    flex: 1,
    borderTopWidth: 1,
    marginTop: 4,
    marginBottom: 16,
  },

  flex1: {
    flex: 1,
  },
  empty: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRadius: 16,
    marginHorizontal: 24,
    marginTop: 10,
  },
  emptyText: {
    textAlign: 'center',
  },

  inviteCode: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    paddingHorizontal: 20,
    paddingVertical: 14,
  },
  codeCopied: {
    marginRight: 8,
  },
  strikeThrough: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
  },

  btnContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  btnContainerDesktop: {
    marginTop: 14,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 32,
    paddingHorizontal: 60,
    paddingVertical: 14,
  },
  btnLabel: {
    fontSize: 18,
  },
  smallSpacer: {
    height: 20,
  },
  inviteCodesIcon: {
    marginRight: 6,
  },
})
