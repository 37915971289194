import React from 'react'
import {observer} from 'mobx-react-lite'
import {CreateAccount} from 'view/com/auth/create/CreateAccount'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
import {InviteCodes, SOLARPLEX_REALM, StagingInviteCodes} from 'lib/constants'
import {track} from 'lib/analytics/analytics'

type Props = NativeStackScreenProps<
  CommonNavigatorParams,
  'Creator' | 'Creators'
>
export const CreatorInviteScreen = observer(function CreatorInviteScreenImpl({
  route,
}: Props) {
  const inviteCode =
    SOLARPLEX_REALM === 'dev' ? StagingInviteCodes.creator : InviteCodes.creator
  track('SignUp:Creator', {
    route: route,
  })
  return <CreateAccount inviteCode={inviteCode} onPressBack={() => {}} />
})
