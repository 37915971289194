import {useTheme} from 'lib/ThemeContext'
import React from 'react'
import {
  SolarplexCircleLogoDark,
  SolarplexCircleLogoLight,
  SolarplexLogo,
  SolarplexLogoDark,
} from 'lib/icons'

export const BrandLogo = () => {
  const theme = useTheme()

  return (
    <>
      {theme.colorScheme === 'dark' ? <SolarplexLogoDark /> : <SolarplexLogo />}
    </>
  )
}

export const BrandLogoSmall = ({size}: {size?: number}) => {
  const theme = useTheme()
  return (
    <>
      {theme.colorScheme === 'dark' ? (
        <SolarplexCircleLogoLight size={size} />
      ) : (
        <SolarplexCircleLogoDark size={size} />
      )}
    </>
  )
}
