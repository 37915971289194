/**
 * This is a temporary client-side system for storing muted threads
 * When the system lands on prod we should switch to that
 */

import {SOLARPLEX_DID, SOLARPLEX_FEED_API, SOLARPLEX_REALM} from 'lib/constants'
import {hasProp, isObj} from 'lib/type-guards'
import {makeAutoObservable, runInAction} from 'mobx'

import {ChannelFeedModel} from './feeds/channel-feed'

import {PostsFeedModel} from './feeds/posts'
import {RootStoreModel} from './root-store'
import {SolarplexChannel} from 'lib/splx-types'
import {actions} from './actions'
import {makeRecordUri} from 'lib/strings/url-helpers'
import merge from 'lodash.merge'
import {AppBskyActorDefs} from '@usedispatch/atproto-api'
import {Sections} from './ui/profile'

interface ChannelsMap {
  [id: string]: {
    idx: number
    channel: SolarplexChannel
  }
}

interface ChannelFeedMap {
  [id: string]: {
    idx: number
    channelFeed: ChannelFeedModel
  }
}

interface ChannelPostsFeedMap {
  [id: string]: {
    idx: number
    channelPostsFeed: PostsFeedModel
  }
}

type member = {
  uid: string
}

export class ChannelsModel {
  private _channels: ChannelsMap = {}
  private _channelFeeds: ChannelFeedMap = {}
  private _channelPostsFeeds: ChannelPostsFeedMap = {}
  public usersByChannel: Record<string, member[]> = {}
  public channelUsersProfiles: Record<
    string,
    AppBskyActorDefs.ProfileViewDetailed[]
  > = {}
  private _channelUsersProfilesCache: Record<
    string,
    AppBskyActorDefs.ProfileViewDetailed[]
  > = {}
  public allUsersProfiles: Record<
    string,
    AppBskyActorDefs.ProfileViewDetailed[]
  > = {}
  private _allUsersProfilesCache: Record<
    string,
    AppBskyActorDefs.ProfileViewDetailed[]
  > = {}
  priorityNames: string[] = ['Showcase', 'Comics']

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(
      this,
      {rootStore: false, serialize: false, hydrate: false},
      {autoBind: true},
    )
  }

  get channels(): SolarplexChannel[] {
    return Object.values(this._channels)
      .sort((a, b) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(i => i.channel)
  }

  get channelFeeds(): ChannelFeedModel[] {
    return Object.values(this._channelFeeds)
      .sort((a, b) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(i => i.channelFeed)
  }

  get channelPostsFeeds(): PostsFeedModel[] {
    return Object.values(this._channelPostsFeeds)
      .sort((a, b) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(i => i.channelPostsFeed)
  }

  get channelPostsFeedsWithKeys(): {
    key: string
    channelPostsFeed: PostsFeedModel
  }[] {
    return Object.entries(this._channelPostsFeeds)
      .sort(([, a], [, b]) => {
        if (!a || !b) return -1
        return a.idx - b.idx
      })
      .map(([key, i]) => ({key, channelPostsFeed: i.channelPostsFeed}))
  }

  get filteredChannelPostsFeedWithKeys(): {
    key: string
    channelPostsFeed: PostsFeedModel
  }[] {
    const joinedChannels = this.rootStore.me.followedChannels.channels
    return Object.entries(this._channelPostsFeeds)
      .filter(
        ([key]) =>
          this.publicChannels().includes(key) || joinedChannels.includes(key),
      )
      .map(([key, value]) => ({
        key,
        channelPostsFeed: value.channelPostsFeed,
      }))
  }

  byId(cid: string) {
    return this.channelFeeds.find(i => i.id === cid)
  }

  serialize() {
    return {channels: this.channels}
  }

  hydrate(v: unknown) {
    if (
      isObj(v) &&
      hasProp(v, 'channels') &&
      Array.isArray(v.channels) // check if v.communities is an array
    ) {
      // ensure that every item in the array is a SolarplexCommunity
      const isValidSolarplexChannelArray = v.channels.every(
        (item: any) =>
          typeof item === 'object' &&
          item !== null &&
          'id' in item &&
          'name' in item &&
          'description' in item &&
          'createdAt' in item &&
          'published' in item &&
          'banner' in item &&
          'uri' in item &&
          'image' in item,
      )

      if (isValidSolarplexChannelArray) {
        this._setChannels(v.channels as SolarplexChannel[])
      }
    }
  }

  publicChannels() {
    const shouldJoinChannelIds =
      SOLARPLEX_REALM === 'dev'
        ? ['splx-art', 'splx-solana']
        : ['splx-art', 'splx-solana']

    return shouldJoinChannelIds
  }

  _setChannels(channels: SolarplexChannel[], reset: boolean = false) {
    // Gotta do this so we don't clobber a pointer and make components flash.
    const map = channels.reduce<ChannelsMap>((acc, channel, idx) => {
      acc[channel.id] = {
        idx,
        channel,
      }
      return acc
    }, {})
    //CommunityFeedModel map being created here
    const feedMap = channels.reduce<ChannelFeedMap>((acc, channel, idx) => {
      if (!this._channelFeeds[channel.id] || reset) {
        acc[channel.id] = {
          idx,
          channelFeed: new ChannelFeedModel(
            this.rootStore,
            channel.id,
            channel,
          ),
        }
      }
      return acc
    }, {})

    // i want to extract the list of communityFeedModel from feedMap
    // const communityFeedModels = Object.values(feedMap).map(i => i.communityFeed)
    // console.log('communityFeedModels', communityFeedModels)
    // const filteredFeedMaps = Object.keys(feedMap)
    //   .filter(key => this.publicCommunities().includes(key))
    //   .reduce<CommunityFeedMap>((acc, key) => {
    //     acc[key] = feedMap[key]
    //     return acc
    //   }, {})
    // console.log('filteredFeedMaps', filteredFeedMaps)
    // this.shouldJoinCommunities(communityFeedModels)
    // feed post model being created over here
    const postsFeedMap = channels.reduce<ChannelPostsFeedMap>(
      (acc, channel, idx) => {
        if (channel.uri && (!this._channelPostsFeeds[channel.id] || reset)) {
          let filter = Sections.PostsNoReplies
          if (channel.channel_type) {
            filter = channel.channel_type as Sections
          }
          acc[channel.id] = {
            idx,
            channelPostsFeed: new PostsFeedModel(
              this.rootStore,
              'custom',
              {
                feed: channel.uri,
              },
              {
                isSimpleFeed: ['posts_with_media'].includes(filter),
              },
            ),
          }
        }
        return acc
      },
      {},
    )

    runInAction(() => {
      this._channels = reset ? map : merge(this._channels, map)
      this._channelFeeds = reset ? feedMap : merge(this._channelFeeds, feedMap)
      this._channelPostsFeeds = reset
        ? postsFeedMap
        : merge(this._channelPostsFeeds, postsFeedMap)
    })
  }

  _getAllUsersInAChannel = actions.wrapAction(
    async (did: string, cid: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/get_users_by_channel`
      const response = await this.rootStore.api.post<{
        data: member[]
      }>(url, {
        body: {
          did,
          cid,
        },
      })
      if (!response || this.rootStore.api.postError(url, {body: {did, cid}})) {
        return
      }
      return response.data.filter((u: member) => u.uid !== null)
    },
    this,
    '_getAllUsersInAChannel',
  )

  _getAllUsersInChannels = actions.wrapAction(
    async () => {
      const communities = this.channels
      const usersByChannel: Record<string, member[]> = {}
      const promises = communities.map(async channel => {
        const did = this.rootStore.me.did
        if (!did) {
          return
        }
        const users = await this._getAllUsersInAChannel(
          this.rootStore.me.did,
          channel.id,
        )

        usersByChannel[channel.id] = users || []
      })
      await Promise.all(promises)
      runInAction(() => {
        this.usersByChannel = usersByChannel
      })
    },
    this,
    '_getAllUsersInChannels',
  )

  _getAllChannels = actions.wrapAction(
    async () => {
      const url = `${SOLARPLEX_FEED_API}/splx/get_all_channels`
      const response = await this.rootStore.api.get<{
        data: SolarplexChannel[]
      }>(url)

      if (!response || this.rootStore.api.getError(url)) {
        return
      }
      return response.data
    },
    this,
    '_getAllChannels',
  )

  fetchAllUserProfilesInChannel = actions.wrapAction(
    async (cid: string) => {
      if (this._allUsersProfilesCache[cid]) {
        this.allUsersProfiles[cid] = this._allUsersProfilesCache[cid]
        return
      }

      const users = this.usersByChannel[cid] ?? []
      if (users.length === 0) {
        return
      }

      const chunkSize = 25 // Set the chunk size to 25

      const promises = users
        .reduce((acc: string[][], user, index) => {
          const chunkIndex = Math.floor(index / chunkSize)
          if (!acc[chunkIndex]) {
            acc[chunkIndex] = []
          }
          if (user.uid !== null) {
            acc[chunkIndex].push(user.uid)
          }
          return acc
        }, [])
        .map(chunk =>
          this.rootStore.agent.app.bsky.actor.getProfiles({actors: chunk}),
        )

      const results = await Promise.all(promises)
      runInAction(() => {
        const userProfiles = results.flatMap(res =>
          res && res.data.profiles.length !== 0 ? res.data.profiles : [],
        )
        this.allUsersProfiles[cid] = userProfiles
        // this.rootStore.me.follows.hydrateProfiles(userProfiles)

        this._allUsersProfilesCache[cid] = userProfiles
      })
    },
    this,
    'fetchAllUserProfilesInChannel',
  )

  fetchAllUserProfilesInChannelError(cid: string) {
    return actions.error('fetchAllUserProfilesInChannel', this, [cid])
  }

  fetchAllUserProfilesInChannelBusy(cid: string) {
    return actions.isBusy('fetchAllUserProfilesInChannel', this, [cid])
  }

  _fetchTopUserProfilesInChannel = actions.wrapAction(
    async (cid: string) => {
      if (this._channelUsersProfilesCache[cid]) {
        this.channelUsersProfiles[cid] = this._channelUsersProfilesCache[cid]
        return
      }

      const users = this.usersByChannel[cid] ?? []
      if (users.length === 0) {
        return
      }

      const topUsers = users.slice(-5)

      try {
        const res = await this.rootStore.agent.app.bsky.actor.getProfiles({
          actors: topUsers.filter(u => u.uid !== null).map(u => u.uid),
        })
        if (res && res.data.profiles.length !== 0) {
          const profileData = res.data.profiles

          const topProfiles = profileData.slice(0, 3)
          runInAction(() => {
            this.channelUsersProfiles[cid] = topProfiles
            this._channelUsersProfilesCache[cid] = topProfiles
          })
        }
      } catch (error) {
        console.log('Community Fetch error', error)
        this.rootStore.log.error('Failed to fetch profile for user', error)
      }
    },
    this,
    '_fetchTopUserProfilesInChannel',
  )

  _fetchAllUserProfilesInChannels = actions.wrapAction(
    async () => {
      const channels = this.channels
      const promises = channels.map(async channel => {
        this.allUsersProfiles[channel.id] = []
        await this.fetchAllUserProfilesInChannel(channel.id)
      })
      await Promise.all(promises)
    },
    this,
    '_fetchAllUserProfilesInChannels',
  )

  _fetchTopUserProfilesInChannels = actions.wrapAction(
    async () => {
      const channels = this.channels
      const promises = channels.map(async channel => {
        this.channelUsersProfiles[channel.id] = []

        await this._fetchTopUserProfilesInChannel(channel.id)
      })
      await Promise.all(promises)
    },
    this,
    '_fetchTopUserProfilesInChannels',
  )

  get _fetchTopUserProfilesInChannelsBusy() {
    return actions.isBusy('_fetchTopUserProfilesInChannels', this, [])
  }

  // this is where i have to automatically allow the user to join a community and the header should be fetched from the community joined list
  // TODO(zfaizal2): fix db for communities to add handle
  _fetch = actions.wrapAction(
    async (reset: boolean = false) => {
      const [channels] = await Promise.all([this._getAllChannels()])

      try {
        this.rootStore.me.followedChannels.updateCache(reset)
      } catch (err) {}
      channels?.map(c => {
        c.uri = makeRecordUri(SOLARPLEX_DID, 'app.bsky.feed.generator', c.id)
      })
      const sortedChannel = this.sortChannels(this.priorityNames, channels)

      this._setChannels(sortedChannel ?? [], reset)
      await this._getAllUsersInChannels()
      await Promise.all([
        this._fetchTopUserProfilesInChannels(),
        this._fetchAllUserProfilesInChannels(),
      ])
    },
    this,
    '_fetch',
  )

  fetch = actions.wrapAction(
    async () => {
      return await this._fetch(true)
    },
    this,
    'fetch',
  )

  sortChannels(
    priorityNames: string[],
    channels?: SolarplexChannel[],
  ): SolarplexChannel[] | undefined {
    return channels?.sort((a, b) => {
      return this.sortByPriority(a, b, priorityNames) || this.sortByName(a, b)
    })
  }

  sortByPriority(
    a: SolarplexChannel,
    b: SolarplexChannel,
    priorityNames: string[],
  ): number {
    return priorityNames.indexOf(a.name) - priorityNames.indexOf(b.name)
  }

  sortByName(a: SolarplexChannel, b: SolarplexChannel): number {
    return a.name.localeCompare(b.name)
  }
}
