import {makeAutoObservable, runInAction} from 'mobx'
import {RootStoreModel} from './root-store'
import {SOLARPLEX_FEED_API} from 'lib/constants'
import {actions} from './actions'
import {bundleAsync} from 'lib/async/bundle'

export const apiUrls = {
  gallery: {
    getCreatorByDid: (did: string) => `/splx/gallery/creator/${did}`,
  },
}

export type UserInfo = {
  [did: string]: {
    handle: string
    wallet: string
    user_verified: boolean
    creator_verified: boolean
  }
}

export interface CreatorResponse {
  did: string
  project_id: number
  artist_name: string
  twitter_handle: string
  royalty_address: string
  verified: boolean
}

export class SplxUsersModel {
  userInfo: UserInfo = {}

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {rootStore: false}, {autoBind: true})
  }

  fetchUser = actions.wrapAction(
    async (did: string) => {
      const userInfo = (
        (await this.rootStore.api.get(
          `${SOLARPLEX_FEED_API}/splx/get_user/${did}`,
        )) as any
      ).user

      runInAction(() => {
        this.userInfo[did] = userInfo as any
      })
    },
    this,
    'fetchUser',
  )

  fetchCreator = bundleAsync(async (did: string) => {
    try {
      const url = `${SOLARPLEX_FEED_API}${apiUrls.gallery.getCreatorByDid(did)}`
      const res = await this.rootStore.api.get<{data: [CreatorResponse]}>(url)

      if (res?.data) {
        return res.data[0]
      }
    } catch (error) {
      console.log('error in fetchCreator', error)
    }
  })

  getUserInfo(did: string) {
    return this.userInfo[did]
  }

  fetchUserIsBusy(did: string) {
    return (
      actions.isBusy('fetchUser', this, [this.rootStore.me.did, did, true]) ||
      actions.isBusy('fetchUser', this, [this.rootStore.me.did, did, false])
    )
  }
}
