import * as Toast from 'view/com/util/Toast'

import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {Link} from '../util/Link'
import {Button} from '../util/forms/Button'
import {ChannelFeedModel} from 'state/models/feeds/channel-feed'
import {NavigationProp} from 'lib/routes/types'
import React from 'react'
import {Text} from '../util/text/Text'
import {UserAvatar} from '../util/UserAvatar'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {useNavigation} from '@react-navigation/native'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from '../../../state/index'
import {AppBskyActorDefs, moderateProfile} from '@usedispatch/atproto-api'
import {LoadingPlaceholder} from '../util/LoadingPlaceholder'
import {isMobileWeb} from 'platform/detection'
import {useAnalytics} from 'lib/analytics/analytics'

function FollowersListLoadingPlaceHolder() {
  return (
    <View style={styles.followedBy}>
      <View style={{flexDirection: 'column', maxWidth: '60%'}}>
        <LoadingPlaceholder
          width={isMobileWeb ? 200 : 300}
          height={8}
          style={{marginBottom: 4}}
        />
        <LoadingPlaceholder width={isMobileWeb ? 150 : 200} height={8} />
      </View>
      <View
        style={{flexDirection: 'row', alignItems: 'center', maxWidth: 'auto'}}>
        <View style={styles.followedByAviContainer}>
          <LoadingPlaceholder
            width={32}
            height={32}
            style={styles.followedByAvi}
          />
        </View>
        <View style={styles.followedByAviContainer}>
          <LoadingPlaceholder
            width={32}
            height={32}
            style={styles.followedByAvi}
          />
        </View>
        <View style={styles.followedByAviContainer}>
          <LoadingPlaceholder
            width={32}
            height={32}
            style={styles.followedByAvi}
          />
        </View>
      </View>
    </View>
  )
}

const FollowersList = observer(function FollowerListComponent({
  followers,
  total,
  cid,
}: {
  followers?: AppBskyActorDefs.ProfileView[] | undefined
  total: number | undefined
  cid: string
}) {
  const store = useStores()
  const pal = usePalette('default')
  if (!followers || followers.length === 0) {
    return null
  }

  const followersWithMods = followers
    .map(f => ({
      f,
      mod: moderateProfile(f, store.preferences.moderationOpts),
    }))
    .filter(({mod}) => !mod.account.filter)

  return (
    <View style={styles.followedBy}>
      <Link style={{maxWidth: '60%'}} href={`/channels/${cid}/members`}>
        <Text
          type="sm"
          style={[styles.followsByDesc, pal.textLight]}
          numberOfLines={2}
          lineHeight={1.2}>
          {cid === 'splx-art' ? 'Members Include:' : 'Joined by'}{' '}
          {followersWithMods.map(({f}) => f.displayName || f.handle).join(', ')}{' '}
          and {total} others
        </Text>
      </Link>

      <View
        style={{flexDirection: 'row', alignItems: 'center', maxWidth: 'auto'}}>
        {followersWithMods.map(({f, mod}) => (
          <View key={f.did} style={styles.followedByAviContainer}>
            <View style={[styles.followedByAvi, pal.view]}>
              <UserAvatar avatar={f.avatar} size={32} moderation={mod.avatar} />
            </View>
          </View>
        ))}
      </View>
    </View>
  )
})

// eslint-disable-next-line react/display-name
export const ChannelFeed = observer(
  ({
    item,
    style,
    showJoinBtn = false,
    showDescription = true,
  }: {
    item: ChannelFeedModel
    style?: StyleProp<ViewStyle>
    showJoinBtn?: boolean
    showDescription?: boolean
  }) => {
    const store = useStores()
    const pal = usePalette('default')
    const navigation = useNavigation<NavigationProp>()
    const {track} = useAnalytics()

    const onToggleJoined = React.useCallback(async () => {
      // TODO(viksit)[F1]: add a store.me.joinedCommunities
      // then check for this
      if (item.isJoined) {
        store.shell.openModal({
          name: 'confirm',
          title: 'Remove from my channels',
          message: `Remove ${item.displayName} from my channels?`,
          onPressConfirm: async () => {
            try {
              track('ChannelFeed:Leave', {
                channel: item.id,
              })
              await store.me.followedChannels.unfollow(item)
              Toast.show('Removed from my channels')
            } catch (e) {
              Toast.show('There was an issue contacting your server')
              store.log.error('Failed to unsave channels', {e})
            }
          },
        })
      } else {
        try {
          track('ChannelFeed:Join', {
            channel: item.id,
          })
          await store.me.followedChannels.follow(item)
          Toast.show('Added to my channels')
          await item.reload()
        } catch (e) {
          Toast.show('There was an issue contacting your server')
          store.log.error('Failed to save channel', {e})
        }
      }
    }, [store, item, track])

    // store.log.debug('item.displayName', item)
    // console.log("item.displayName", item.displayName);
    // console.log("item.displayName", item.data);

    // console.log(store.communities.usersByCommunity[item.id ?? 'splx-solana']?.length);

    // TODO(viksit)[F1]: get avatar from server not hardcoded here

    return (
      <TouchableOpacity
        accessibilityRole="button"
        style={[styles.container, pal.border, style]}
        onPress={() => {
          track('ChannelItem:Clicked', {
            channel: item.id,
          })
          // TODO(viksit)[f1]: create community feed
          navigation.push('ChannelFeed', {
            name: item.data?.name ?? '',
            rkey: item.id ?? '',
          })
        }}
        key={item.id}>
        <View style={[styles.headerContainer]}>
          <View style={[s.mr10]}>
            <UserAvatar type="algo" size={36} avatar={item.data?.image} />
          </View>
          <View style={[styles.headerTextContainer]}>
            <Text style={[pal.text, s.bold]} numberOfLines={3}>
              {item.data?.name}
            </Text>
            <Text style={[pal.textLight]} numberOfLines={3}>
              by @solarplex
            </Text>
          </View>
          {showJoinBtn && (
            <View>
              <Button
                // accessibilityRole="button"
                type={item?.isJoined ? 'default' : 'primary'}
                onPress={onToggleJoined}
                // hitSlop={15}
                style={styles.btn}
                label={item.isJoined ? 'Unfollow' : 'Follow'}
              />
            </View>
          )}
        </View>

        {showDescription && item.data?.description ? (
          <Text style={[pal.text, styles.description]} numberOfLines={3}>
            {item.data.description}{' '}
            {item.id === 'splx-art' && (
              <Link
                style={[pal.link]}
                href="https://usedispatch.retool.com/form/879cd1b0-003d-4a24-a1a2-9fcc5e39e1d0"
                asAnchor={true}>
                <Text style={[{textDecorationLine: 'underline'}, pal.link]}>
                  Apply to be added as an artist here!
                </Text>
              </Link>
            )}
          </Text>
        ) : null}
        {store.channels.channelUsersProfiles[item.id ?? 'splx-art'].length ===
        0 ? (
          <FollowersListLoadingPlaceHolder />
        ) : (
          <FollowersList
            cid={item.id ?? 'splx-art'}
            followers={
              store.channels.channelUsersProfiles[item.id ?? 'splx-art']
            }
            total={
              store.channels.usersByChannel[item.id ?? 'splx-art']?.length ?? 0
            }
          />
        )}
      </TouchableOpacity>
    )
  },
)

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 18,
    paddingTop: 20,
    paddingBottom: 10,
    flexDirection: 'column',
    flex: 1,
    borderTopWidth: 1,
    gap: 14,
  },
  headerContainer: {
    flexDirection: 'row',
  },
  headerTextContainer: {
    flexDirection: 'column',
    columnGap: 4,
    flex: 1,
  },
  description: {
    flex: 1,
    flexWrap: 'wrap',
  },
  btn: {
    paddingVertical: 6,
  },
  followedBy: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 20,
    marginTop: -6,
    flexWrap: 'wrap',
  },
  followedByAviContainer: {
    width: 24,
    height: 36,
  },
  followedByAvi: {
    width: 36,
    height: 36,
    borderRadius: 18,
    padding: 2,
  },
  followsByDesc: {
    flex: 1,
    paddingRight: 10,
  },
})
