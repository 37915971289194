export function makeValidHandle(str: string): string {
  if (str.length > 20) {
    str = str.slice(0, 20)
  }
  str = str.toLowerCase()
  return str.replace(/^[^a-z0-9]+/g, '').replace(/[^a-z0-9-]/g, '')
}

export function createFullHandle(name: string, domain: string): string {
  name = (name || '').replace(/[.]+$/, '')
  domain = (domain || '').replace(/^[.]+/, '')
  return `${name}.${domain}`
}

export function isInvalidHandle(handle: string): boolean {
  return handle === 'handle.invalid'
}

export function sanitizeHandle(handle: string, prefix = ''): string {
  return isInvalidHandle(handle)
    ? '⚠Invalid Handle'
    : `${prefix}` + removeSuffixFromHandle(handle)
}

export function removeSuffixFromHandle(handle: string): string {
  let suffix = ''
  if (process.env.SOLARPLEX_REALM === 'prod') {
    suffix = '.live.solarplex.xyz'
  } else if (process.env.SOLARPLEX_REALM === 'dev') {
    suffix = '.staging.live.solarplex.xyz'
  }

  if (handle.endsWith(suffix)) {
    return handle.slice(0, -suffix.length)
  }
  return handle
}
