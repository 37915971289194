import {
  AppBskyEmbedExternal,
  AppBskyEmbedImages,
  AppBskyEmbedVideos,
  AppBskyEmbedNft,
  AppBskyEmbedFrame,
  AppBskyEmbedRecord,
  AppBskyEmbedRecordWithMedia,
  AppBskyFeedDefs,
  AppBskyGraphDefs,
  ModerationUI,
  AppBskyActorDefs,
  AppBskyEmbedCollectible,
} from '@usedispatch/atproto-api'
import {
  InteractionManager,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native'

import {AutoSizedImage} from '../images/AutoSizedImage'
import {CustomFeedEmbed} from './CustomFeedEmbed'
import {ExternalLinkEmbed} from './ExternalLinkEmbed'
import {Image} from 'expo-image'
import {ImageLayoutGrid} from '../images/ImageLayoutGrid'
import {ImagesLightbox} from 'state/models/ui/shell'
import {Link} from '../Link'
import {ListEmbed} from './ListEmbed'
import {MaybeQuoteEmbed} from './QuoteEmbed'
import React from 'react'
import {YoutubeEmbed} from './YoutubeEmbed'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {
  getMallowMintAddress,
  getYoutubeVideoId,
} from '../../../../lib/strings/url-helpers'

import {isCauseALabelOnUri} from '../../../../lib/moderation'
import {usePalette} from '../../../../lib/hooks/usePalette'
import {useStores} from '../../../../state/index'
import {NFTEmbed} from './NFTEmbed'
import {Button} from '../forms/Button'
import {MallowEmbed} from './MallowEmbed'
import {CollectibleEmbed} from './CollectibleEmbed'
import {FrameEmbed} from './FrameEmbed'
import {ResizeMode, Video} from 'expo-av'

export type Embed =
  | AppBskyEmbedRecord.View
  | AppBskyEmbedImages.View
  | AppBskyEmbedExternal.View
  | AppBskyEmbedRecordWithMedia.View
  | AppBskyEmbedNft.View
  | {$type: string; [k: string]: unknown}

export function PostEmbeds({
  author,
  embed,
  moderation,
  style,
  itemUri,
  itemCid,
  text,
  indexedAt,
  post,
}: {
  author?: AppBskyActorDefs.ProfileViewBasic
  embed?: Embed
  moderation: ModerationUI
  style?: StyleProp<ViewStyle>
  itemUri?: string
  itemCid?: string
  text?: string
  indexedAt?: string
  post?: AppBskyFeedDefs.PostView
}) {
  const pal = usePalette('default')
  const store = useStores()
  const {isMobile} = useWebMediaQueries()
  // quote post with media
  // =
  if (AppBskyEmbedRecordWithMedia.isView(embed)) {
    const isModOnQuote =
      AppBskyEmbedRecord.isViewRecord(embed.record.record) &&
      isCauseALabelOnUri(moderation.cause, embed.record.record.uri)
    const mediaModeration = isModOnQuote ? {} : moderation
    const quoteModeration = isModOnQuote ? moderation : {}

    return (
      <View style={[styles.stackContainer, style]}>
        <PostEmbeds
          itemCid={itemCid}
          itemUri={itemUri}
          indexedAt={indexedAt}
          text={text}
          author={author}
          embed={embed.media as Embed}
          moderation={mediaModeration}
          post={post}
        />
        <MaybeQuoteEmbed
          embed={embed.record}
          moderation={quoteModeration}
          post={post}
        />
      </View>
    )
  }

  if (AppBskyEmbedRecord.isView(embed)) {
    // custom feed embed (i.e. generator view)
    // =
    if (AppBskyFeedDefs.isGeneratorView(embed.record)) {
      return <CustomFeedEmbed record={embed.record} />
    }

    // list embed
    if (AppBskyGraphDefs.isListView(embed.record)) {
      return <ListEmbed item={embed.record} />
    }

    // quote post
    // =
    return (
      <MaybeQuoteEmbed
        embed={embed}
        style={style}
        moderation={moderation}
        post={post}
      />
    )
  }

  // nft embed (old)
  // =
  if (AppBskyEmbedNft.isView(embed)) {
    return (
      <NFTEmbed
        itemCid={itemCid}
        indexedAt={indexedAt}
        itemUri={itemUri}
        author={author}
        embed={embed}
        text={text}
      />
    )
  }

  // frame embed
  // =
  if (AppBskyEmbedFrame.isView(embed)) {
    return (
      <FrameEmbed
        initialFrame={embed.frame}
        itemCid={itemCid}
        itemUri={itemUri}
        author={author}
        post={post}
      />
    )
  }

  // collectible embed
  // =
  if (AppBskyEmbedCollectible.isView(embed)) {
    return (
      <CollectibleEmbed
        itemCid={itemCid}
        indexedAt={indexedAt}
        itemUri={itemUri}
        author={author}
        embed={embed}
        text={text}
      />
    )
  }

  // video embed
  // =

  if (AppBskyEmbedVideos.isView(embed)) {
    const {videos} = embed
    const videoStyles = StyleSheet.create({
      container: {
        overflow: 'hidden',
      },
      video: {
        width: '100%',
        height: 300,
      },
      videoPlayer: {
        width: '100%',
        height: '100%',
        marginTop: 0,
        marginBottom: 0,
      },
    })
    if (videos.length === 1) {
      return (
        <Pressable accessibilityRole="button" style={[style]}>
          <Video
            source={{uri: videos[0].uri}}
            useNativeControls
            style={[videoStyles.video]}
            videoStyle={[videoStyles.video]}
            resizeMode={ResizeMode.CONTAIN}
          />
        </Pressable>
      )
    }
  }

  // image embed
  // =
  if (AppBskyEmbedImages.isView(embed)) {
    const {images} = embed

    if (images.length > 0) {
      const items = embed.images.map(img => ({
        uri: img.fullsize,
        alt: img.alt,
        aspectRatio: img.aspectRatio,
      }))
      const openLightbox = (index: number) => {
        store.shell.openLightbox(new ImagesLightbox(items, index))
      }
      const onPressIn = (_: number) => {
        InteractionManager.runAfterInteractions(() => {
          Image.prefetch(items.map(i => i.uri))
        })
      }

      if (images.length === 1) {
        const {alt, thumb, aspectRatio} = images[0]
        return (
          <View style={[styles.imagesContainer, style]}>
            <AutoSizedImage
              alt={alt}
              uri={thumb}
              dimensionsHint={aspectRatio}
              onPress={() => openLightbox(0)}
              onPressIn={() => onPressIn(0)}
              style={[
                styles.singleImage,
                isMobile && styles.singleImageMobile,
              ]}>
              {alt === '' ? null : (
                <View style={styles.altContainer}>
                  <Text style={styles.alt} accessible={false}>
                    ALT
                  </Text>
                </View>
              )}
            </AutoSizedImage>
          </View>
        )
      }

      return (
        <View style={[styles.imagesContainer, style]}>
          <ImageLayoutGrid
            images={embed.images}
            onPress={openLightbox}
            onPressIn={onPressIn}
            style={
              embed.images.length === 1
                ? [styles.singleImage, isMobile && styles.singleImageMobile]
                : undefined
            }
          />
        </View>
      )
    }
  }

  // external link embed
  // =

  // we need to add one for checking if there's a frame embed
  // and if its a frame embed, we return <FrameEmbed>
  // and that will do the right UI magic for dynamic frames

  if (AppBskyEmbedExternal.isView(embed)) {
    const link = embed.external
    const youtubeVideoId = getYoutubeVideoId(link.uri)

    if (youtubeVideoId) {
      return <YoutubeEmbed link={link} style={style} />
    }
    const mallowMintAddress = getMallowMintAddress(link.uri)

    if (mallowMintAddress) {
      return (
        <MallowEmbed
          itemCid={itemCid}
          indexedAt={indexedAt}
          itemUri={itemUri}
          author={author}
          text={text}
          link={link}
          mintAddress={mallowMintAddress}
          style={style}
        />
      )
    }

    return (
      <Link
        asAnchor
        style={[styles.extOuter, pal.view, pal.border, style]}
        href={link.uri}>
        <ExternalLinkEmbed link={link} />
        {mallowMintAddress && <Button> Buy </Button>}
      </Link>
    )
  }

  return <View />
}

const styles = StyleSheet.create({
  stackContainer: {
    gap: 6,
  },
  imagesContainer: {
    marginTop: 8,
  },
  videoContainer: {
    marginTop: 4,
  },
  singleImage: {
    borderRadius: 8,
    maxHeight: 1000,
  },
  singleImageMobile: {
    maxHeight: 500,
  },
  extOuter: {
    borderWidth: 1,
    borderRadius: 8,
    marginTop: 4,
  },
  altContainer: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    borderRadius: 6,
    paddingHorizontal: 6,
    paddingVertical: 3,
    position: 'absolute',
    left: 6,
    bottom: 6,
  },
  alt: {
    color: 'white',
    fontSize: 10,
    fontWeight: 'bold',
  },
})
