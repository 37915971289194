import React from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {Text} from 'view/com/util/text/Text'
import {ErrorBoundary} from 'view/com/util/ErrorBoundary'
import {s, colors} from 'lib/styles'
import {usePalette} from 'lib/hooks/usePalette'
import {isWeb} from 'platform/detection'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {CenteredView} from 'view/com/util/Views'
import {TextLink} from 'view/com/util/Link'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import {CommonNavigatorParams} from 'lib/routes/types'
import {observer} from 'mobx-react-lite'
import {useStores} from 'state/index'
import {useNavigation} from '@react-navigation/native'
import {NavigationProp} from 'lib/routes/types'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Creators'>
export const CreatorsSplashScreen = observer(
  function CreatorsSplashScreenImpl({}: Props) {
    const allowCreation = true
    const pal = usePalette('default')
    const {isTabletOrMobile} = useWebMediaQueries()
    const styles = useStyles()
    const isMobileWeb = isWeb && isTabletOrMobile
    const store = useStores()
    const navigation = useNavigation<NavigationProp>()

    const handle = store.session.inviteHandle

    return (
      <CenteredView style={[styles.container, pal.view]}>
        <View
          testID="noSessionView"
          style={[
            styles.containerInner,
            isMobileWeb && styles.containerInnerMobile,
            pal.border,
          ]}>
          <ErrorBoundary>
            {/* <Text style={isMobileWeb ? styles.titleMobile : styles.title}>
            Bluesky
          </Text> */}
            <View style={styles.logo}>{/*<SolarplexLogo />*/}</View>
            <Text
              style={[
                isMobileWeb ? styles.subtitleMobile : styles.subtitle,
                pal.text,
              ]}>
              Please create an account or sign-in to start using Solarplex
            </Text>
            <View testID="signinOrCreateAccount" style={styles.btns}>
              {allowCreation && (
                <TouchableOpacity
                  testID="createAccountButton"
                  style={[styles.btn]}
                  onPress={() =>
                    navigation.navigate('Creator', {
                      name: handle,
                    })
                  }
                  // TODO: web accessibility
                  accessibilityRole="button">
                  <Text style={[s.white, styles.btnLabel]}>Create Account</Text>
                </TouchableOpacity>
              )}
              <TouchableOpacity
                testID="signInButton"
                style={[styles.btn]}
                onPress={() => navigation.navigate('SignIn')}
                // TODO: web accessibility
                accessibilityRole="button">
                <Text style={[pal.text, styles.btnLabel]}>Sign In</Text>
              </TouchableOpacity>
            </View>
            <Text
              type={isMobileWeb ? 'md' : 'lg'}
              style={[styles.notice, pal.text]}
              lineHeight={1.3}>
              Solarplex is a Social Marketplace that helps creators build
              authentic relationships with their audience.
            </Text>
          </ErrorBoundary>
        </View>
        <Footer styles={styles} />
      </CenteredView>
    )
  },
)

function Footer({styles}: {styles: ReturnType<typeof useStyles>}) {
  const pal = usePalette('default')

  return (
    <View style={[styles.footer, pal.view, pal.border]}>
      <TextLink
        href="https://www.solarplex.xyz/"
        text="Business"
        style={[styles.footerLink, pal.link]}
      />
      {/* <TextLink
        href="https://blueskyweb.xyz/blog"
        text="Blog"
        style={[styles.footerLink, pal.link]}
      /> */}
      <TextLink
        href="https://twitter.com/solarplex_xyz"
        text="Contact"
        style={[styles.footerLink, pal.link]}
      />
    </View>
  )
}
const useStyles = () => {
  const {isTabletOrMobile} = useWebMediaQueries()
  const isMobileWeb = isWeb && isTabletOrMobile
  return StyleSheet.create({
    logo: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      height: '100%',
    },
    containerInner: {
      height: '100%',
      justifyContent: 'center',
      // @ts-ignore web only
      paddingBottom: '20vh',
      paddingHorizontal: 20,
    },
    containerInnerMobile: {
      paddingBottom: 50,
    },
    title: {
      textAlign: 'center',
      color: colors.blue3,
      fontSize: 68,
      fontWeight: 'bold',
      paddingBottom: 10,
    },
    titleMobile: {
      textAlign: 'center',
      color: colors.blue3,
      fontSize: 58,
      fontWeight: 'bold',
    },
    subtitle: {
      textAlign: 'center',
      color: colors.gray5,
      fontSize: 28,
      fontWeight: '600',
      paddingBottom: 30,
    },
    subtitleMobile: {
      textAlign: 'center',
      color: colors.gray5,
      fontSize: 28,
      fontWeight: 'bold',
      paddingBottom: 30,
    },
    btns: {
      flexDirection: isMobileWeb ? 'column' : 'row',
      gap: 20,
      justifyContent: 'center',
      paddingBottom: 40,
    },
    btn: {
      backgroundColor: colors.splx.primary[50],

      borderRadius: 30,
      paddingHorizontal: 24,
      paddingVertical: 12,
      minWidth: 220,
    },
    btnLabel: {
      color: colors.splx.neutral[10],
      textAlign: 'center',
      fontSize: 18,
    },
    notice: {
      fontFamily: 'Manrope',
      paddingHorizontal: 40,
      textAlign: 'center',
      color: colors.splx.primary[70],
    },
    footer: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 0,
      padding: 20,
      borderTopWidth: 1,
      flexDirection: 'row',
    },
    footerLink: {
      marginRight: 20,
      fontSize: 18,
    },
  })
}
