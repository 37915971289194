import React from 'react'
import {View} from 'react-native'
import {FABInner, FABProps} from './FABInner'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {useStores} from 'state/index'

export const FAB = (_opts: FABProps) => {
  const {isDesktop} = useWebMediaQueries()
  const store = useStores()

  if (!isDesktop && store.session.hasSession) {
    return <FABInner {..._opts} />
  }

  return <View />
}
