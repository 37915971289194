import {
  ImagePickerOptions,
  launchImageLibraryAsync,
  MediaTypeOptions,
} from 'expo-image-picker'
import {getDataUriSize} from './util'

export async function openPicker(opts?: ImagePickerOptions) {
  const response = await launchImageLibraryAsync({
    exif: false,
    mediaTypes: opts?.mediaTypes || MediaTypeOptions.Images, // Allow the caller to specify media type
    quality: 1,
    ...opts,
  })

  return (response.assets ?? []).map(asset => {
    const res = {
      mime: asset.mimeType ? asset.mimeType : 'video/mp4',
      height: asset.height,
      width: asset.width,
      path: asset.uri,
      size: asset.mimeType ? getDataUriSize(asset.uri) : undefined, // Size calculation for images only
      duration: asset.mimeType ? undefined : asset.duration, // Include duration for videos
    }
    return res
  })
}
