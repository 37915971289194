import {usePalette} from 'lib/hooks/usePalette'
import {observer} from 'mobx-react-lite'
import {useStores} from 'state/index'
import React, {useEffect, useState} from 'react'
import {s} from 'lib/styles'
import Clipboard from '@react-native-clipboard/clipboard'
import * as Toast from 'view/com/util/Toast'

import {
  Linking,
  StyleSheet,
  View,
  FlatList,
  Image,
  TouchableOpacity,
} from 'react-native'
import {Text} from 'view/com/util/text/Text'
import {isMobileWeb} from 'platform/detection'
import {innerCircleLearnMore} from 'lib/constants'
import {Button} from 'view/com/util/forms/Button'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {ProfileView} from '@usedispatch/atproto-api/dist/client/types/app/bsky/actor/defs'
import {ProfileCard} from '../profile/ProfileCard'
import {formatWalletAddress} from 'lib/strings/helpers'
import {NFTGalleryModel} from 'state/models/media/nft-gallery'

export function EndTimeUI({unixTimeStamp}: {unixTimeStamp: string}) {
  const pal = usePalette('default')
  const endTime = new Date(parseInt(unixTimeStamp, 10))
  const now = new Date()
  const difference = endTime.getTime() - now.getTime()

  if (difference < 0) {
    return (
      <Text type="mono" style={pal.text}>
        Mission has expired
      </Text>
    )
  }

  const days = Math.floor(difference / (1000 * 60 * 60 * 24))
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  )
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))

  return (
    <Text type="xs" style={pal.text}>
      Ending in:{' '}
      <Text type="xs-bold" style={pal.text}>
        {isNaN(days) ? '0' : days} days {isNaN(hours) ? '0' : hours} hours{' '}
        {isNaN(minutes) ? '0' : minutes} minutes
      </Text>
    </Text>
  )
}

const InnerCircleNotFound = observer(function InnerCircleNotFoundImpl() {
  const pal = usePalette('default')
  const store = useStores()
  const gallery = new NFTGalleryModel(store)
  const innerCircleImg =
    'https://res.cloudinary.com/diyjjw5ke/image/upload/v1709228188/innerCircle_bluy4b.png'
  return (
    <View style={[{flexDirection: 'column', alignItems: 'center'}]}>
      <Text
        type="title"
        style={[pal.text, {paddingVertical: 20, fontWeight: '700'}]}>
        Create Inner Circle Missions Now
      </Text>
      <View style={{width: '100%', paddingHorizontal: 40}}>
        <Image
          accessibilityIgnoresInvertColors
          style={styles.image}
          resizeMode="contain"
          source={{uri: innerCircleImg}}
        />
      </View>
      <View
        style={[
          {
            paddingHorizontal: 40,
            flexDirection: 'column',
            alignItems: 'center',
          },
        ]}>
        <Button
          type="primary"
          label="Create Mission"
          onPress={() => {
            store.shell.openModal({
              name: 'inner-circle-create',
              gallery: gallery,
            })
          }}
          style={{
            marginTop: 20,
            width: isMobileWeb ? '100%' : '60%',
            alignItems: 'center',
            paddingVertical: 10,
          }}
        />
        <Button
          type="inverted"
          label="Learn more"
          onPress={() => {
            Linking.openURL(innerCircleLearnMore)
          }}
          style={{
            marginTop: 10,
            width: isMobileWeb ? '100%' : '60%',
            alignItems: 'center',
            paddingVertical: 10,
          }}
        />
        <Text
          type="post-text"
          style={[pal.textLight, {paddingTop: 8, textAlign: 'center'}]}>
          By requesting access, you agree to our Terms and Privacy Policy.
        </Text>
      </View>
    </View>
  )
})

type InnerCircleCollectors = {
  handle: string
  did: string
  wallet: string
  minted: string
  profile: ProfileView
}

const InnerCircleFound = observer(function InnerCircleFoundImpl() {
  const pal = usePalette('default')
  const store = useStores()
  const innerCircleCollectibles = store.creatorTools.innerCircleCollectibles
  const [collectors, setCollectors] = useState<InnerCircleCollectors[]>()
  const cid = innerCircleCollectibles[0].cid
  const imgUrl = innerCircleCollectibles[0].imageUri

  const isCollectorsListBusy =
    store.creatorTools.getAllCollectorsForCollectibleIsBusy(cid)
  useEffect(() => {
    async function fetchCollectors() {
      let collectorslist =
        await store.creatorTools.getAllCollectorsForCollectible(cid)
      await store.creatorTools.getMissionsByDid(store.me.did)

      if (collectorslist === undefined) {
        collectorslist =
          await store.creatorTools.getAllCollectorsForCreatorInnerCircle(
            store.me.did,
          )
      }
      setCollectors(collectorslist)
    }
    fetchCollectors()
  }, [store.creatorTools, cid, store.me.did])

  const gallery = new NFTGalleryModel(store)

  const renderItem = ({item}: {item: InnerCircleCollectors}) => {
    return (
      <View
        style={[
          pal.view,
          pal.border,
          {
            borderWidth: 1,
            width: '100%',
            padding: 5,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingHorizontal: 12,
          },
        ]}>
        <ProfileCard profile={item.profile} noBg={true} noBorder={true} />
        <View style={{flexDirection: 'column', alignItems: 'center'}}>
          <Text type="lg" style={pal.text}>
            <Text type="lg-bold" style={[pal.text]}>
              {item.minted}
            </Text>{' '}
            {item.minted === '1' ? 'reward' : 'rewards'}
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Text type="md" style={[pal.view, pal.text]}>
              {formatWalletAddress(item.wallet)}
            </Text>
            <TouchableOpacity
              style={[pal.border, {paddingLeft: 8}]}
              accessibilityRole="button"
              onPress={() => {
                Clipboard.setString(item.wallet)
                Toast.show('Copied to clipboard')
              }}>
              <FontAwesomeIcon
                icon={['far', 'clone']}
                style={pal.text as FontAwesomeIconStyle}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    )
  }

  const isMissionExpired =
    new Date().getTime() <
    store.creatorTools.creatorLatestMissionDetails.end_time

  const isUIActive = store.creatorTools.creatorLatestMissionDetails.ui_active

  return (
    <View>
      <View
        style={[s.flexRow, s.alignBaseline, {justifyContent: 'space-between'}]}>
        <Text type="title" style={[pal.text, styles.heading]}>
          Your Reward
        </Text>
      </View>
      <View style={[s.pt5, s.pb20, s.flexCol, {alignItems: 'center'}]}>
        <View style={[{paddingHorizontal: 10, width: '100%'}]}>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.icImage}
            resizeMode="cover"
            source={{uri: imgUrl}}
          />
        </View>
        <View
          style={[
            pal.viewLight,
            {
              marginVertical: 10,
              padding: 12,
              borderRadius: 20,
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            },
          ]}>
          <Text type="md-bold" style={[pal.text, {marginRight: 20}]}>
            Create your Exclusive Inner Circle Mission Now!
          </Text>
          {store.creatorTools.creatorLatestMissionDetails.end_time !==
            undefined && (
            <View>
              {isMissionExpired && isUIActive ? (
                <Button
                  onPress={async () => {
                    try {
                      await store.creatorTools.cancelMission(
                        store.creatorTools.creatorLatestMissionDetails.id,
                      )
                      Toast.show('Mission Cancelled')
                    } catch (error) {
                      console.log('Error in cancelMission call', error)
                      Toast.show('Error Happened, Please Try Again')
                    }
                  }}
                  label="Cancel Mission"
                />
              ) : (
                <Button
                  onPress={() => {
                    store.shell.openModal({
                      name: 'inner-circle-create',
                      gallery: gallery,
                    })
                  }}
                  label="Create Mission"
                />
              )}
            </View>
          )}
        </View>
        <View
          style={[
            {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            },
          ]}>
          <Text type="2xl" style={[pal.text, {paddingLeft: 10}]}>
            Title: {innerCircleCollectibles[0].title}
          </Text>
          {isUIActive && (
            <EndTimeUI
              unixTimeStamp={
                store.creatorTools.creatorLatestMissionDetails.end_time
              }
            />
          )}
        </View>
      </View>
      <View
        style={[
          pal.view,
          pal.borderDark,
          {
            paddingVertical: 16,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            borderRadius: 10,
            borderWidth: 1,
          },
        ]}>
        <Text type="lg-bold" style={[pal.text]}>
          {isCollectorsListBusy
            ? '...'
            : collectors?.length === 0 || collectors === undefined
            ? 'No Collectors Found'
            : `${collectors?.length}`}
        </Text>
        {collectors?.length !== 0 && collectors !== undefined && (
          <Text type="md" style={[pal.text]}>
            Holders
          </Text>
        )}
      </View>
      <Text
        type="title"
        style={[
          pal.text,
          {paddingTop: 20, paddingBottom: 10, paddingLeft: 10},
        ]}>
        Inner Circle Members
      </Text>

      {isCollectorsListBusy ? (
        <View style={[pal.viewLight, {padding: 10}]}>
          <Text type="md-bold" style={[pal.text]}>
            Loading Collectors
          </Text>
        </View>
      ) : (
        <FlatList data={collectors} renderItem={renderItem} />
      )}
    </View>
  )
})

export const InnerCircleTab = observer(function InnerCircleTabImpl() {
  const store = useStores()
  const innerCircleCollectibles = store.creatorTools.innerCircleCollectibles

  return (
    <View>
      {innerCircleCollectibles.length === 0 ||
      innerCircleCollectibles === undefined ? (
        <InnerCircleNotFound />
      ) : (
        <InnerCircleFound />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingBottom: 8,
    paddingTop: 16,
    paddingLeft: 10,
  },
  image: {
    width: '100%',
    height: 300,
    borderRadius: 8,
  },
  icImage: {
    width: '100%',
    height: 250,
    borderRadius: 4,
  },
})
