import {View} from 'react-native'
import {useStores} from 'state/index'
import {FeedPage} from 'view/com/feeds/FeedPage'
import React, {useEffect} from 'react'
import {CustomFeedEmptyState} from 'view/com/posts/CustomFeedEmptyState'
import {useFocusEffect} from '@react-navigation/native'
import {observer} from 'mobx-react-lite'
import {Pager, RenderTabBarFnProps} from 'view/com/pager/Pager.web'
import {PagerRef} from 'view/com/pager/Pager'
import {FeedsTabBar} from 'view/com/pager/FeedsTabBar.web'

export const NonLoggedInHome = observer(function NonLoggedInHomeImpl() {
  const store = useStores()
  const [selectedPage, setSelectedPage] = React.useState(0)
  const pagerRef = React.useRef<PagerRef>(null)
  const channelFeeds = store.channels.channelPostsFeedsWithKeys
  const feedstoRemove = ['splx-trade', 'splx-comics', 'showcase']
  const channelFeedsFiltered = channelFeeds.filter(
    feed => !feedstoRemove.includes(feed.key),
  )

  const channelsToRemove = ['Trade', 'Comics', 'Earn']
  const joinedChannelsNames = store.channels.channels
    .map(channel => channel.name)
    .filter(name => !channelsToRemove.includes(name))

  const renameSolanaChannelName = joinedChannelsNames.map(name =>
    name === 'Solana' ? 'Explore' : name,
  )

  const channels = React.useMemo(
    () => [...renameSolanaChannelName],
    [renameSolanaChannelName],
  )

  const onPageSelected = React.useCallback(
    (index: number) => {
      store.shell.setMinimalShellMode(false)
      setSelectedPage(index)
      store.shell.setIsDrawerSwipeDisabled(index > 0)
    },
    [store, setSelectedPage],
  )

  const renderCustomFeedEmptyState = React.useCallback(() => {
    return <CustomFeedEmptyState />
  }, [])

  useFocusEffect(
    React.useCallback(() => {
      store.channels.fetch()
    }, [store]),
  )

  useEffect(() => {
    async function fetch() {
      for (const feed of channelFeeds) {
        await feed.channelPostsFeed.setup()
      }
    }
    fetch()
  }, [channelFeeds])

  const onPressSelected = React.useCallback(() => {
    store.emitScreenSoftReset()
  }, [store])

  const renderTabBar = React.useCallback(
    (props: RenderTabBarFnProps) => {
      return (
        <FeedsTabBar
          items={channels}
          key="FEEDS_TAB_BAR"
          selectedPage={props.selectedPage}
          onSelect={props.onSelect}
          testID="homeScreenFeedTabs"
          onPressSelected={onPressSelected}
        />
      )
    },
    [onPressSelected, channels],
  )

  return (
    <View>
      <Pager
        ref={pagerRef}
        onPageSelected={onPageSelected}
        renderTabBar={renderTabBar}
        tabBarPosition="top">
        {channelFeedsFiltered.map(
          ({channelPostsFeed: channelPostsFeed, _}, index) => {
            return (
              <FeedPage
                key={channelPostsFeed.reactKey}
                testID="customFeedPage"
                isPageFocused={selectedPage === 1 + index}
                feed={channelPostsFeed}
                renderEmptyState={renderCustomFeedEmptyState}
              />
            )
          },
        )}
      </Pager>
    </View>
  )
})
