import React, {useMemo} from 'react'
import {StyleSheet, Animated} from 'react-native'
import {observer} from 'mobx-react-lite'
import {TabBar} from 'view/com/pager/TabBar'
import {RenderTabBarFnProps} from 'view/com/pager/Pager'
import {useStores} from 'state/index'
import {useHomeTabs} from 'lib/hooks/useHomeTabs'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {FeedsTabBar as FeedsTabBarMobile} from './FeedsTabBarMobile'
import {useMinimalShellMode} from 'lib/hooks/useMinimalShellMode'
import {useAnimatedValue} from 'lib/hooks/useAnimatedValue'

export const FeedsTabBar = observer(function FeedsTabBarImpl(
  props: RenderTabBarFnProps & {
    testID?: string
    onPressSelected: () => void
    items: string[]
  },
) {
  const splxIsDesktop = true
  const {isMobile, isTablet, isDesktop} = useWebMediaQueries()
  if (isMobile) {
    return <FeedsTabBarMobile {...props} />
  } else if (isTablet) {
    return <FeedsTabBarTablet {...props} />
  } else if (splxIsDesktop && isDesktop) {
    return <FeedsTabBarDesktop {...props} />
  } else {
    return null
  }
})

const FeedsTabBarTablet = observer(function FeedsTabBarTabletImpl(
  props: RenderTabBarFnProps & {
    testID?: string
    onPressSelected: () => void
    items: string[]
  },
) {
  const store = useStores()
  const items = useHomeTabs(store.preferences.pinnedFeeds)
  const pal = usePalette('default')
  const {headerMinimalShellTransform} = useMinimalShellMode()

  return (
    // @ts-ignore the type signature for transform wrong here, translateX and translateY need to be in separate objects -prf
    <Animated.View
      style={[pal.view, styles.tabBar, headerMinimalShellTransform]}>
      <TabBar
        key={items.join(',')}
        {...props}
        items={props.items}
        indicatorColor={pal.colors.link}
      />
    </Animated.View>
  )
})

// This is copy paste from our prev code 18/11/2023
const FeedsTabBarDesktop = observer(function FeedsTabBarTabletImpl(
  props: RenderTabBarFnProps & {
    testID?: string
    onPressSelected: () => void
    items: string[]
  },
) {
  const store = useStores()
  const items = useMemo(
    () => ['Following', ...store.me.savedFeeds.pinnedFeedNames],
    [store.me.savedFeeds.pinnedFeedNames],
  )

  // Get the user's joined communities from joinedCommunities.communities
  // Get the names of that community from this list for display here
  // For each, we can construct the URL of that feed.
  const pal = usePalette('default')
  const interp = useAnimatedValue(0)

  React.useEffect(() => {
    Animated.timing(interp, {
      toValue: store.shell.minimalShellMode ? 1 : 0,
      duration: 100,
      useNativeDriver: true,
      isInteraction: false,
    }).start()
  }, [interp, store.shell.minimalShellMode])

  return (
    // @ts-ignore the type signature for transform wrong here, translateX and translateY need to be in separate objects -prf
    <Animated.View style={[pal.view, styles.tabBar]}>
      <TabBar
        key={(props.items ?? items).join(',')}
        {...props}
        items={props.items ?? items}
        indicatorColor={pal.colors.link}
      />
    </Animated.View>
  )
})

const styles = StyleSheet.create({
  tabBar: {
    position: 'absolute',
    zIndex: 1,
    // @ts-ignore Web only -prf
    left: 'calc(50% - 299px)',
    width: 598,
    top: 0,
    flexDirection: 'row',
    alignItems: 'center',
  },
})
