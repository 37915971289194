import React, {useCallback, useEffect, useState} from 'react'
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import {Text} from '../text/Text'
import {PostDropdownBtn} from '../forms/PostDropdownBtn'
import {
  HeartIcon,
  HeartIconSolid,
  CommentBottomArrow,
  RegularFaceSmileIcon,
  SolidFaceSmileIcon,
  MoneyBill,
} from 'lib/icons'
import {s, colors} from 'lib/styles'
import {pluralize} from 'lib/strings/helpers'
import {HITSLOP_10, HITSLOP_20} from 'lib/constants'

import {Haptics} from 'lib/haptics'
import {ReactionDropdownButton} from '../forms/ReactionDropdownButton'
import {ReactionList} from 'view/com/reactions/ReactionList'
import {RepostButton} from './RepostButton'
import {SolarplexReaction} from 'state/models/media/reactions'
// import {TipDropdownBtn} from '../forms/TipdropdownBtn'
import {usePalette} from 'lib/hooks/usePalette'
import {useRequireAuth} from 'view/com/auth/withAuthRequired'
import {useStores} from 'state/index'
import {useTheme} from 'lib/ThemeContext'
import {useAnalytics} from 'lib/analytics/analytics.web'

interface PostCtrlsOpts {
  itemUri: string
  itemCid: string
  itemHref: string
  itemTitle: string
  isAuthor: boolean
  author: {
    did: string
    handle: string
    displayName?: string | undefined
    avatar?: string | undefined
  }
  text: string
  indexedAt: string
  big?: boolean
  style?: StyleProp<ViewStyle>
  replyCount?: number
  repostCount?: number
  likeCount?: number
  reactions?: string[]
  viewerReaction?: string
  isReposted: boolean
  isLiked: boolean
  isThreadMuted: boolean
  onPressReply: () => void
  onPressReaction: (reactionId: string, remove?: boolean) => Promise<void>
  onPressToggleRepost: () => Promise<void>
  onPressToggleLike: () => Promise<void>
  onCopyPostText: () => void
  onOpenTranslate: () => void
  onToggleThreadMute: () => void
  onDeletePost: () => void
}

export function PostCtrls(opts: PostCtrlsOpts) {
  const store = useStores()
  const theme = useTheme()
  const pal = usePalette('default')
  const {track} = useAnalytics()
  const requireAuth = useRequireAuth(opts.author, opts.itemUri)
  store.users.getUserInfo(opts.author.did)
  const [wallet, setWallet] = useState('')

  useEffect(() => {
    ;(async () => {
      await store.users.fetchUser(opts.author.did)
      await store.reactions.fetchPacks()
      setWallet(store.users.getUserInfo(opts.author.did)?.wallet)
    })()
  }, [store.users, opts.author.did, wallet, store.reactions])

  const earnedReactions = Object.keys(store.reactions.reactionSets).filter(
    set => store.reactions.earnedReactions[set],
  )

  const reactionSet = earnedReactions.flatMap(
    reaction => store.reactions.earnedReactions[reaction],
  )
  const defaultCtrlColor = React.useMemo(
    () => ({
      color: theme.palette.default.postCtrl,
    }),
    [theme],
  ) as StyleProp<ViewStyle>

  const onRepost = useCallback(() => {
    store.shell.closeModal()
    if (!opts.isReposted) {
      Haptics.default()
      opts.onPressToggleRepost().catch(_e => undefined)
    } else {
      opts.onPressToggleRepost().catch(_e => undefined)
    }
  }, [opts, store.shell])

  const onQuote = useCallback(() => {
    store.shell.closeModal()
    store.shell.openComposer({
      quote: {
        uri: opts.itemUri,
        cid: opts.itemCid,
        text: opts.text,
        author: opts.author,
        indexedAt: opts.indexedAt,
      },
    })
    Haptics.default()
  }, [
    opts.author,
    opts.indexedAt,
    opts.itemCid,
    opts.itemUri,
    opts.text,
    store.shell,
  ])
  const handleRepost = () => {
    if (!store.session.hasSession) {
      track('Banner:Repost')
    }
    requireAuth(onRepost)
  }

  const handleQuote = () => {
    if (!store.session.hasSession) {
      track('Banner:Quote')
    }

    requireAuth(onQuote)
  }

  function onTip() {
    if (!store.session.hasSession) {
      track('Banner:Tip')
    }
    requireAuth(() => {
      store.shell.openModal({
        name: 'new-tip-modal',
        recipientName: opts.author.handle ? opts.author.handle : 'this user',
        recipientAddress: wallet,
        recipientdid: opts.author.did,
        senderdid: store.me.did,
        itemUri: opts.itemUri,
        itemCid: opts.itemCid,
        indexedAt: opts.indexedAt,
        avatar: opts.author.avatar,
        authorDid: opts.author.did,
        authorHandle: opts.author.handle,
        text: opts.text,
      })
    })
  }

  const onPressToggleLikeWrapper = async () => {
    if (!opts.isLiked) {
      Haptics.default()
      await opts.onPressToggleLike().catch(_e => undefined)
    } else {
      await opts.onPressToggleLike().catch(_e => undefined)
    }
  }
  const [selectedEmoji, setSelectedEmoji] = useState<
    SolarplexReaction | undefined
  >(store.reactions.reactionTypes[opts.viewerReaction ?? ' '])

  // TODO(pratik): make sure we don't show reactions to logged out users
  const onPressReaction = async (emoji: SolarplexReaction | undefined) => {
    if (!store.session.hasSession) {
      track('Banner:Reaction')
    }
    requireAuth(async () => {
      if (!emoji) {
        onRemoveReaction()
        return
      }
      if (selectedEmoji) {
        onRemoveReaction()
      }
      setSelectedEmoji(emoji)
      await opts.onPressReaction(emoji.id).catch(_e => undefined)
    })
  }

  const onRemoveReaction = async () => {
    await opts
      .onPressReaction(selectedEmoji?.id ?? '', true)
      .catch(_e => undefined)
    setSelectedEmoji(undefined)
  }

  return (
    <>
      {!opts.big && opts.reactions?.length ? (
        <View
          style={[
            pal.borderDark,
            {borderTopWidth: 1, marginTop: 8, paddingVertical: 8},
          ]}>
          <ReactionList reactions={opts.reactions} />
        </View>
      ) : (
        <></>
      )}
      <View style={[styles.ctrls, opts.style]}>
        <TouchableOpacity
          testID="replyBtn"
          style={[styles.ctrl, !opts.big && styles.ctrlPad, {paddingLeft: 0}]}
          onPress={() => {
            if (!store.session.hasSession) {
              track('Banner:Reply')
            }
            requireAuth(() => opts.onPressReply())
          }}
          accessibilityRole="button"
          accessibilityLabel={`Reply (${opts.replyCount} ${
            opts.replyCount === 1 ? 'reply' : 'replies'
          })`}
          accessibilityHint=""
          hitSlop={opts.big ? HITSLOP_20 : HITSLOP_10}>
          <CommentBottomArrow
            style={[defaultCtrlColor, opts.big ? s.mt2 : styles.mt1]}
            strokeWidth={3}
            size={opts.big ? 20 : 15}
          />
          {typeof opts.replyCount !== 'undefined' ? (
            <Text style={[defaultCtrlColor, s.ml5, s.f15]}>
              {opts.replyCount}
            </Text>
          ) : undefined}
        </TouchableOpacity>
        <RepostButton {...opts} onRepost={handleRepost} onQuote={handleQuote} />
        <TouchableOpacity
          testID="likeBtn"
          style={[styles.ctrl, !opts.big && styles.ctrlPad]}
          onPress={() => {
            if (!store.session.hasSession) {
              track('Banner:Like')
            }
            requireAuth(() => onPressToggleLikeWrapper())
          }}
          accessibilityRole="button"
          accessibilityLabel={`${opts.isLiked ? 'Unlike' : 'Like'} (${
            opts.likeCount
          } ${pluralize(opts.likeCount || 0, 'like')})`}
          accessibilityHint=""
          hitSlop={opts.big ? HITSLOP_20 : HITSLOP_10}>
          {opts.isLiked ? (
            <HeartIconSolid
              style={styles.ctrlIconLiked}
              size={opts.big ? 22 : 16}
            />
          ) : (
            <HeartIcon
              style={[defaultCtrlColor, opts.big ? styles.mt1 : undefined]}
              strokeWidth={3}
              size={opts.big ? 20 : 16}
            />
          )}
          {typeof opts.likeCount !== 'undefined' ? (
            <Text
              testID="likeCount"
              style={
                opts.isLiked
                  ? [s.bold, s.red3, s.f15, s.ml5]
                  : [defaultCtrlColor, s.f15, s.ml5]
              }>
              {opts.likeCount}
            </Text>
          ) : undefined}
        </TouchableOpacity>
        <TouchableOpacity
          testID="reactBtn"
          style={styles.emojiCtrl}
          hitSlop={{
            left: 10,
            right: 10,
            top: HITSLOP_10.top,
            bottom: HITSLOP_10.bottom,
          }}
          accessibilityLabel={opts.viewerReaction ? 'Reacted' : 'React'}
          accessibilityHint=""
          onPress={onRemoveReaction}>
          {reactionSet?.length ? (
            <ReactionDropdownButton
              testID="communityHeaderDropdownBtn"
              type="bare"
              items={reactionSet}
              style={[
                styles.btn,
                styles.secondaryBtn,
                {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
              ]}
              onPressReaction={onPressReaction}>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
                {selectedEmoji ? (
                  <TouchableOpacity
                    accessibilityRole="button"
                    onPress={onRemoveReaction}>
                    <SolidFaceSmileIcon />
                  </TouchableOpacity>
                ) : (
                  <RegularFaceSmileIcon />
                )}
                <Text
                  testID="likeCount"
                  style={[
                    defaultCtrlColor,
                    s.f15,
                    s.ml5,
                    {
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 4,
                    },
                  ]}>
                  {opts.reactions?.length ? opts.reactions.length : <></>}
                </Text>
              </View>
            </ReactionDropdownButton>
          ) : undefined}
        </TouchableOpacity>
        {wallet ? (
          <TouchableOpacity
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingTop: 2,
            }}
            accessibilityRole="button"
            onPress={onTip}>
            <MoneyBill fill="#3e9c35" size={20} />
          </TouchableOpacity>
        ) : undefined}
        <View>
          {opts.big ? undefined : store.session.hasSession ? (
            <PostDropdownBtn
              testID="postDropdownBtn"
              itemUri={opts.itemUri}
              itemCid={opts.itemCid}
              itemHref={opts.itemHref}
              itemTitle={opts.itemTitle}
              isAuthor={opts.isAuthor}
              isThreadMuted={opts.isThreadMuted}
              onCopyPostText={opts.onCopyPostText}
              onOpenTranslate={opts.onOpenTranslate}
              onToggleThreadMute={opts.onToggleThreadMute}
              onDeletePost={opts.onDeletePost}
              style={styles.ctrlPad}
            />
          ) : undefined}
        </View>
        {/* used for adding pad to the right side */}
        <View />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  ctrls: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  ctrl: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ctrlPad: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
  },
  ctrlIconLiked: {
    color: colors.like,
  },
  mt1: {
    marginTop: 1,
  },
  emojiCtrl: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
    margin: -5,
  },
  emojiSet: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginRight: 10,
    marginLeft: 10,
  },
  emojiContainerStyle: {
    backgroundColor: 'gray',
    width: 100,
    height: 100,
  },
  secondaryBtn: {
    // paddingHorizontal: 14,
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    // paddingVertical: 7,
    borderRadius: 50,
  },
})
