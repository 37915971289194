import {useNavigationState} from '@react-navigation/native'
import {getCurrentRoute} from 'lib/routes/helpers'

export function useNavigationTabState() {
  return useNavigationState(state => {
    let currentRoute = state ? getCurrentRoute(state).name : 'Home'
    return {
      isAtHome: currentRoute === 'Home',
      isAtSearch: currentRoute === 'Search',
      isAtNotifications: currentRoute === 'Notifications',
      isAtMyProfile: currentRoute === 'MyProfile',
      isAtAllChannels: currentRoute === 'Channels',
      isAtRewards: currentRoute === 'Rewards',
      isAtWallets: currentRoute === 'Wallets',
      isAtSignIn: currentRoute === 'SignIn',
      isAtReactions: currentRoute === 'Reactions',
      isAtMissionsList: currentRoute === 'MissionsList',
      isAtWaitlist: currentRoute === 'WaitList',
      isAtMission: currentRoute === 'Mission',
      isAtSignUp: currentRoute === 'SignUp',
      isAtBreakpoint: currentRoute === 'Breakpoint',
      isAtDeanList: currentRoute === 'Deans',
      isAtCreator: currentRoute === 'Creator',
      isAtCreators: currentRoute === 'Creators',
      isAtChannel: currentRoute === 'ChannelFeed',
    }
  })
}
