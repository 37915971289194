import {
  AppBskyActorDefs,
  AppBskyEmbedRecord,
  ModerationUI,
} from '@usedispatch/atproto-api'
import {StyleProp, ViewStyle} from 'react-native'
import {hasProp, isObj} from 'lib/type-guards'
import {isMobileWeb, isPWA, isWeb} from 'platform/detection'
import {makeAutoObservable, runInAction} from 'mobx'

import {GalleryModel} from '../media/gallery'
import {ImageModel} from '../media/image'
import {ListModel} from '../content/list'
import {NFTGalleryModel} from '../media/nft-gallery'
import {ProfileModel} from '../content/profile'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {RootStoreModel} from '../root-store'
import {SolarplexReaction} from '../media/reactions'
import {VideoModel} from '../media/video'
import {UserCollectible} from '../lists/gallery-feed'

export type ColorMode = 'system' | 'light' | 'dark'

export function isColorMode(v: unknown): v is ColorMode {
  return v === 'system' || v === 'light' || v === 'dark'
}

export interface GalleryViewModal {
  name: 'gallery-view'
  asset: UserCollectible
  profileHandle: string
}
export interface InnerCircleCreateModal {
  name: 'inner-circle-create'
  gallery: NFTGalleryModel
}

export interface CollectibleCreatedSuccessModal {
  name: 'collectible-created-success'
  imageSrc: string
  twitterIntentLink: string
}

export interface NFTBuySuccessModal {
  name: 'nft-buy-success'
  imageSrc: string
  onShare: () => void
  twitterIntentLink: string
  creatorDid: string
}

export interface NFTMetaModal {
  name: 'nft-meta'
  image: ImageModel
  gallery: NFTGalleryModel
  addChannel?: (channelTag: string) => void
}

export interface NFtVideoModal {
  name: 'nft-video'
  video: VideoModel
  gallery: NFTGalleryModel
  addChannel?: (channelTag: string) => void
}

export interface PWAInfoModal {
  name: 'pwa-info'
}

export interface ReactionModal {
  name: 'reaction-modal'
  item: SolarplexReaction
  reactionPack: string
}

export interface NewTippingModal {
  name: 'new-tip-modal'
  recipientName?: string
  recipientAddress: string
  recipientdid: string
  senderdid: string
  itemUri?: string
  itemCid?: string
  indexedAt?: string
  authorDid?: string
  authorHandle?: string
  avatar?: string | undefined
  text?: string
}

export interface TippingModal {
  name: 'tip-modal'
  recipientName: string
  recipientAddress: string
  tokenName: string
  recipientdid: string
  senderdid: string
  itemUri: string
}

export interface Onboarding {
  name: 'onboarding'
}

export interface ConfirmModal {
  name: 'confirm'
  title: string
  message: string | (() => JSX.Element)
  onPressConfirm: () => void | Promise<void>
  onPressCancel?: () => void | Promise<void>
  confirmBtnText?: string
  confirmBtnStyle?: StyleProp<ViewStyle>
  cancelBtnText?: string
}

export interface EditProfileModal {
  name: 'edit-profile'
  profileView: ProfileModel
  onUpdate?: () => void
}

export interface ProfilePreviewModal {
  name: 'profile-preview'
  did: string
}

export interface ServerInputModal {
  name: 'server-input'
  initialService: string
  onSelect: (url: string) => void
}

export interface ModerationDetailsModal {
  name: 'moderation-details'
  context: 'account' | 'content'
  moderation: ModerationUI
}

export type ReportModal = {
  name: 'report'
} & (
  | {
      uri: string
      cid: string
    }
  | {did: string}
)

export interface CreateOrEditListModal {
  name: 'create-or-edit-list'
  purpose?: string
  list?: ListModel
  onSave?: (uri: string) => void
}

export interface UserAddRemoveListsModal {
  name: 'user-add-remove-lists'
  subject: string
  displayName: string
  onAdd?: (listUri: string) => void
  onRemove?: (listUri: string) => void
}

export interface ListAddUserModal {
  name: 'list-add-user'
  list: ListModel
  onAdd?: (profile: AppBskyActorDefs.ProfileViewBasic) => void
}

export interface EditImageModal {
  name: 'edit-image'
  image: ImageModel
  gallery: GalleryModel
}

export interface CropImageModal {
  name: 'crop-image'
  uri: string
  onSelect: (img?: RNImage) => void
}

export interface AltTextImageModal {
  name: 'alt-text-image'
  image: ImageModel
}

export interface DeleteAccountModal {
  name: 'delete-account'
}

export interface RepostModal {
  name: 'repost'
  onRepost: () => void
  onQuote: () => void
  isReposted: boolean
}

export interface SelfLabelModal {
  name: 'self-label'
  labels: string[]
  hasMedia: boolean
  onChange: (labels: string[]) => void
}

export interface ChangeHandleModal {
  name: 'change-handle'
  onChanged: () => void
}

export interface WaitlistModal {
  name: 'waitlist'
}

export interface InviteCodesModal {
  name: 'invite-codes'
}

export interface AddAppPasswordModal {
  name: 'add-app-password'
}

export interface ContentFilteringSettingsModal {
  name: 'content-filtering-settings'
}

export interface ContentLanguagesSettingsModal {
  name: 'content-languages-settings'
}

export interface PostLanguagesSettingsModal {
  name: 'post-languages-settings'
}

export interface BirthDateSettingsModal {
  name: 'birth-date-settings'
}

export interface VerifyEmailModal {
  name: 'verify-email'
  showReminder?: boolean
}

export interface ChangeEmailModal {
  name: 'change-email'
}

export interface SwitchAccountModal {
  name: 'switch-account'
}

export interface LinkWarningModal {
  name: 'link-warning'
  text: string
  href: string
}

export interface FrameShareModal {
  name: 'frame-share'
  text: string
  frameUri: string
}

export type Modal =
  // Account
  | AddAppPasswordModal
  | ChangeHandleModal
  | DeleteAccountModal
  | EditProfileModal
  | ProfilePreviewModal
  | BirthDateSettingsModal
  | VerifyEmailModal
  | ChangeEmailModal
  | SwitchAccountModal

  // Curation
  | ContentFilteringSettingsModal
  | ContentLanguagesSettingsModal
  | PostLanguagesSettingsModal

  // Moderation
  | ModerationDetailsModal
  | ReportModal

  // Lists
  | CreateOrEditListModal
  | UserAddRemoveListsModal
  | ListAddUserModal

  // Posts
  | AltTextImageModal
  | NFTMetaModal
  | CropImageModal
  | EditImageModal
  | ServerInputModal
  | RepostModal
  | SelfLabelModal

  // Bluesky access
  | WaitlistModal
  | InviteCodesModal

  // Generic
  | ConfirmModal
  | LinkWarningModal
  | FrameShareModal

  // Tipping
  | TippingModal
  | Onboarding
  | ReactionModal
  | PWAInfoModal
  | NewTippingModal
  | NFTBuySuccessModal
  | CollectibleCreatedSuccessModal
  | NFtVideoModal
  | GalleryViewModal
  | InnerCircleCreateModal

interface LightboxModel {}

export class ProfileImageLightbox implements LightboxModel {
  name = 'profile-image'
  constructor(public profileView: ProfileModel) {
    makeAutoObservable(this)
  }
}

interface ImagesLightboxItem {
  uri: string
  alt?: string
}

export class ImagesLightbox implements LightboxModel {
  name = 'images'
  constructor(public images: ImagesLightboxItem[], public index: number) {
    makeAutoObservable(this)
  }
  setIndex(index: number) {
    this.index = index
  }
}

export interface ComposerOptsPostRef {
  uri: string
  cid: string
  text: string
  author: {
    handle: string
    displayName?: string
    avatar?: string
  }
}
export interface ComposerOptsQuote {
  uri: string
  cid: string
  text: string
  indexedAt: string
  author: {
    did: string
    handle: string
    displayName?: string
    avatar?: string
  }
  embeds?: AppBskyEmbedRecord.ViewRecord['embeds']
}
export interface ComposerOpts {
  replyTo?: ComposerOptsPostRef
  onPost?: () => void
  quote?: ComposerOptsQuote
  mention?: string // handle of user to mention
  isSharing?: boolean
  uri?: string
  sharingText?: string
}

export class ShellUiModel {
  colorMode: ColorMode = 'system'
  minimalShellMode = false
  isDrawerOpen = false
  isDrawerSwipeDisabled = false
  isModalActive = false
  activeModals: Modal[] = []
  isLightboxActive = false
  activeLightbox: ProfileImageLightbox | ImagesLightbox | null = null
  isComposerActive = false
  composerOpts: ComposerOpts | undefined
  tickEveryMinute = Date.now()
  isSharing = false
  sharedUri = ''
  sharingText = ''
  splxUseLoginModals = true

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {
      serialize: false,
      rootStore: false,
      hydrate: false,
    })

    this.setupClock()
    !this.splxUseLoginModals && this.setupLoginModals()
    this.setupPWAModal()
  }

  serialize(): unknown {
    return {
      colorMode: this.colorMode,
    }
  }

  hydrate(v: unknown) {
    if (isObj(v)) {
      if (hasProp(v, 'colorMode') && isColorMode(v.colorMode)) {
        this.setColorMode(v.colorMode)
      }
    }
  }

  setColorMode(mode: ColorMode) {
    this.colorMode = mode

    if (isWeb && typeof window !== 'undefined') {
      const html = window.document.documentElement
      // remove any other color mode classes
      html.className = html.className.replace(/colorMode--\w+/g, '')
      html.classList.add(`colorMode--${mode}`)
    }
  }

  setMinimalShellMode(v: boolean) {
    this.minimalShellMode = v
  }

  /**
   * returns true if something was closed
   * (used by the android hardware back btn)
   */
  closeAnyActiveElement(): boolean {
    if (this.isLightboxActive) {
      this.closeLightbox()
      return true
    }
    if (this.isModalActive) {
      this.closeModal()
      return true
    }
    if (this.isComposerActive) {
      this.closeComposer()
      return true
    }
    if (this.isDrawerOpen) {
      this.closeDrawer()
      return true
    }
    return false
  }

  /**
   * used to clear out any modals, eg for a navigation
   */
  closeAllActiveElements() {
    if (this.isLightboxActive) {
      this.closeLightbox()
    }
    while (this.isModalActive) {
      this.closeModal()
    }
    if (this.isComposerActive) {
      this.closeComposer()
    }
    if (this.isDrawerOpen) {
      this.closeDrawer()
    }
  }

  openDrawer() {
    this.isDrawerOpen = true
  }

  closeDrawer() {
    this.isDrawerOpen = false
  }

  setIsDrawerSwipeDisabled(v: boolean) {
    this.isDrawerSwipeDisabled = v
  }

  openModal(modal: Modal) {
    this.rootStore.emitNavigation()
    this.isModalActive = true
    this.activeModals.push(modal)
  }

  closeModal() {
    this.activeModals.pop()
    this.isModalActive = this.activeModals.length > 0
  }

  openLightbox(lightbox: ProfileImageLightbox | ImagesLightbox) {
    this.rootStore.emitNavigation()
    this.isLightboxActive = true
    this.activeLightbox = lightbox
  }

  closeLightbox() {
    this.isLightboxActive = false
    this.activeLightbox = null
  }

  openComposer(opts: ComposerOpts) {
    this.rootStore.emitNavigation()
    this.isComposerActive = true
    this.composerOpts = opts
    this.isSharing = opts.isSharing ?? false
    this.sharedUri = opts.uri ?? ''
    this.sharingText = opts.sharingText ?? ''
  }

  closeComposer() {
    this.isComposerActive = false
    this.composerOpts = undefined
    this.isSharing = false
    this.sharedUri = ''
    this.sharingText = ''
  }

  setupClock() {
    setInterval(() => {
      runInAction(() => {
        this.tickEveryMinute = Date.now()
      })
    }, 60_000)
  }

  setupLoginModals() {
    this.rootStore.onSessionReady(() => {
      if (this.rootStore.reminders.shouldRequestEmailConfirmation) {
        this.openModal({name: 'verify-email', showReminder: true})
        this.rootStore.reminders.setEmailConfirmationRequested()
      }
    })
  }

  setupPWAModal() {
    this.rootStore.onSessionReady(() => {
      if (
        this.rootStore.pwaReminder.shouldDisplayPWAInfo &&
        (isPWA || isMobileWeb)
      ) {
        //TODO: REMOVE THIS LOG
        console.log('OPENING THE MODAL')
        this.openModal({name: 'pwa-info'})
        this.rootStore.pwaReminder.setPWAInfoRequested()
      }
    })
  }
}
