import {Image, StyleSheet, View} from 'react-native'
import {colors, s} from '../../../../lib/styles'

import {Button} from '../../../../view/com/util/forms/Button'
import {OnboardingHeader} from './Header'
import React from 'react'

export const Streaks = ({next, prev}: {next: () => void; prev: () => void}) => {
  return (
    <View style={[styles.container]}>
      <View testID="welcomeScreen" style={[s.flexCol]}>
        <OnboardingHeader
          title="Daily streaks"
          desc="Make posts and get engagement on them everyday to unlock unique cNFT reactions!"
          step="2"
        />
        <View style={styles.spacer} />
        <View style={[{maxHeight: 300, minHeight: 300}]}>
          <Image
            style={styles.ob3}
            source={require('../../../../../assets/ob3.png')}
            accessibilityIgnoresInvertColors
          />
        </View>
      </View>
      <Button
        onPress={next}
        style={{
          width: '100%',
          margin: 20,
          marginBottom: 0,
        }}
        label="Next"
        testID="continueBtn"
        labelStyle={styles.buttonText}
      />
      <Button
        type="default-light"
        onPress={prev}
        style={{
          width: '100%',
          margin: 20,
          marginBottom: 0,
        }}
        label="Prev"
        testID="PrevBtn"
        labelStyle={styles.prevButtonText}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  // ob stands for onboarding
  ob3: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
  container: {
    flex: 1,
    marginVertical: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  title: {
    fontSize: 48,
    fontWeight: '800',
  },
  row: {
    flexDirection: 'row',
    columnGap: 20,
    alignItems: 'center',
    marginVertical: 20,
  },
  rowText: {
    flex: 1,
  },
  spacer: {
    height: 20,
  },
  buttonText: {
    textAlign: 'center',
    fontSize: 18,
    marginVertical: 4,
  },
  prevButtonText: {
    textAlign: 'center',
    fontSize: 16,
    marginVertical: 2,
    color: colors.gray8,
  },
})
