import {StyleSheet, View} from 'react-native'
import React from 'react'
import {BrandLogo, BrandLogoSmall} from './BrandLogo'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'

export const Banner = () => {
  const {isTablet} = useWebMediaQueries()

  return (
    <View
      style={[
        isTablet ? bannerStyles.tabletContainer : bannerStyles.container,
      ]}>
      {isTablet ? <BrandLogoSmall /> : <BrandLogo />}
    </View>
  )
}

const bannerStyles = StyleSheet.create({
  tabletContainer: {
    marginBottom: 10,
    flex: 1,
    paddingTop: 12,
    position: 'relative',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  container: {
    marginBottom: 10,
    flex: 1,
    position: 'relative',
    width: '100%',
    paddingLeft: 12,
    // padding: 20,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  backgroundImage: {
    position: 'absolute',
    zIndex: -1,
    width: '100%',
    height: 80,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  logo: {
    width: 200,
    height: 200,
    marginLeft: 20,
  },
})
