import React from 'react'
import {Text} from '../text/Text'
import {
  Linking,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native'
import {usePalette} from 'lib/hooks/usePalette'
import {
  AppBskyActorDefs,
  AppBskyEmbedCollectible,
  AtUri,
} from '@usedispatch/atproto-api'
import {isDesktopWeb, isMobileWeb, isPWA} from 'platform/detection'
import {Button} from '../forms/Button'
import {s} from 'lib/styles'
import {colors} from 'lib/styles'
import {useStores} from 'state/index'
import {useSplxWallet} from 'view/com/wallet/useSplxWallet'
import * as Toast from '../Toast.web'
import {observer} from 'mobx-react-lite'
import {LoadingIndicator, SolLogo} from 'lib/icons'
import {track} from 'lib/analytics/analytics'
import {Link} from '../Link'
import {UserAvatar} from '../UserAvatar'
import {useRequireAuth} from 'view/com/auth/withAuthRequired'
import * as apilib from 'lib/api/index'
import {LAMPORTS_PER_SOL} from '@solana/web3.js'
import {AutoSizedImage} from '../images/AutoSizedImage'
import {HANDLE_SUFFIX, SOLARPLEX_UI_URL} from 'lib/constants'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {ResizeMode, Video} from 'expo-av'
import {ImagesLightbox} from 'state/models/ui/shell'
import {makeProfileLink} from 'lib/routes/links'

export const CollectibleEmbed = observer(function Component({
  author,
  embed,
  imageChild,
  itemUri,
  itemCid,
  text,
  indexedAt,
}: {
  author?: AppBskyActorDefs.ProfileViewBasic | any
  embed: AppBskyEmbedCollectible.View
  imageChild?: React.ReactNode
  style?: StyleProp<ViewStyle>
  itemUri?: string
  itemCid?: string
  text?: string
  indexedAt?: string
}) {
  const pal = usePalette('default')
  const store = useStores()
  const [buyingNft, setBuyingNft] = React.useState(false)
  const [shouldShare, setShouldShare] = React.useState(false)
  const requireAuth = useRequireAuth(author, itemUri)
  const [
    _visible,
    setVisible,
    _linkedWallet,
    _connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
  ] = useSplxWallet(false)

  const [mintStatusText, setMintStatusText] = React.useState('')
  const price =
    embed.collectible.collectiblePrice !== 0
      ? (embed.collectible.collectiblePrice as number) / LAMPORTS_PER_SOL ?? 100
      : 0
  const claimedCollectibles =
    (embed.collectible.aggMinted ?? 0) + (embed.collectible.aggPending ?? 0)

  function shareNft() {
    const sharingTextTemplates = [
      `Collected! Thank you @${author?.handle}.`,
      `I just got one! Thank you @${author?.handle}.`,
      `Added to my collection! Amazing work @${author?.handle}.`,
    ]
    const sharingText =
      sharingTextTemplates[
        Math.floor(Math.random() * sharingTextTemplates.length)
      ]

    if (itemUri && itemCid && indexedAt && author) {
      store.shell.openComposer({
        isSharing: true,
        sharingText: sharingText,
        replyTo: {
          uri: itemUri,
          cid: itemCid,
          text: text || sharingText,
          author: {
            displayName: author?.displayName,
            handle: author?.handle,
            avatar: author?.avatar,
          },
        },
      })
    }

    // setShouldShare(false)
    track('NFT:NFTShare', {
      collectionId: embed.collectible.collectibleCid,
      buyer: store.session.currentSession?.handle,
      isPaid: embed.collectible.collectiblePrice !== 0,
    })
  }

  const authorHandle = author?.handle.split(HANDLE_SUFFIX)[0]
  const itemTid = itemUri?.split('/').pop()
  const animationUri = embed.collectible.collectibleAnimationUrl?.includes(
    'ipfs://',
  )
    ? `https://cloudflare-ipfs.com/ipfs/${embed.collectible.collectibleAnimationUrl
        .split('ipfs://')
        .pop()}`
    : embed.collectible.collectibleAnimationUrl
  const twitterIntentLink = encodeURI(
    `https://twitter.com/intent/tweet?url=${SOLARPLEX_UI_URL}/profile/${author?.handle}/post/${itemTid}&text=${embed.collectible.collectibleTitle} by ${authorHandle}
    
Collected On @solarplex_xyz
  `,
  )

  async function handleBuyNft() {
    requireAuth(async () => {
      try {
        setVisible(true)
        await store.wallet.ensureWalletConnectedAndLinked()
        let claimResponse
        if (!store.wallet.state.connectedWalletId) {
          throw new Error('No wallet connected')
        }
        setBuyingNft(true)
        if (price) {
          const {data} = await apilib.createPaidNftTransaction(store, {
            collectionCid: embed.collectible.collectibleCid,
            did: store.me.did,
            connectedWallet: store.wallet.state.connectedWalletId,
          })

          if (data && store.wallet.walletPopup?.signTransaction) {
            const paidTxn = data.serializedTxn
            const deserializedTxn = store.me.nft.getDeserializedTxn(paidTxn)
            const signedTx = await store.wallet.walletPopup?.signTransaction(
              deserializedTxn,
            )

            if (!signedTx) {
              throw new Error('Could not sign transaction')
            }
            const serializedTxn = signedTx?.serialize()
            const buffer = Buffer.from(
              serializedTxn.buffer,
              serializedTxn.byteOffset,
              serializedTxn.byteLength,
            )
            Toast.show(`Confirming payment...`)
            setMintStatusText('Confirming payment...')
            const encodedTxn = buffer.toString('base64')
            claimResponse = await apilib.submitPaidNftTransaction(store, {
              collectibleUri: embed.collectible.collectibleUri,
              collectibleCid: embed.collectible.collectibleCid,
              serializedTxn: encodedTxn,
              subjectCid: embed.collectible.collectibleCollectionId ?? '',
              linkedWallet: store.wallet.state.connectedWalletId,
              paymentId: data.paymentId,
              createdAt: data.createdAt,
              postSubject: {
                uri: itemUri!,
                cid: itemCid!,
              },
            })
          }
        } else {
          claimResponse = await store.me.nft.claimNft(
            store.wallet.state.connectedWalletId,
            embed.collectible.collectibleCid,
            store.me.did,
            embed.collectible.collectibleUri,
            {uri: itemUri!, cid: itemCid!},
          )
        }
        if (!claimResponse?.actionId && !claimResponse?.transactionId) {
          Toast.show(`${claimResponse?.message?.split('_').join(' ')}`)
          setBuyingNft(false)
          track('NFT:NFTBuyError', {
            collectionId: embed.collectible.collectionCid,
            buyer: store.session.currentSession?.handle,
            error: claimResponse?.message,
            isPaid: embed.collectible.collectiblePrice !== 0,
          })
          return
        }

        track('NFT:NFTBuySuccess', {
          collectionId: embed.collectible.collectibleCid,
          buyer: store.session.currentSession?.handle,
          title: embed.collectible.collectionTitle,
          description: embed.collectible.collectionDescription,
          supply: embed.collectible.collectionSupply,
          minted: claimedCollectibles,
          price: price,
          isPaid: embed.collectible.collectiblePrice !== 0,
        })

        setMintStatusText('Done!')
        setBuyingNft(false)
        setShouldShare(true)
        store.shell.openModal({
          name: 'nft-buy-success',
          imageSrc: embed.collectible.collectibleImageUri,
          onShare: shareNft,
          twitterIntentLink,
          creatorDid: author?.did,
        })
        Toast.show(`Purchase successful!`, {
          containerStyle: {
            backgroundColor: colors.green3,
          },
          labelStyle: {
            color: colors.white,
          },
        })
      } catch (e: any) {
        setBuyingNft(false)
        console.error(e)
        Toast.show(e.message, {
          containerStyle: {
            backgroundColor: colors.red3,
          },
          labelStyle: {
            color: colors.red1,
          },
        })
      }
    })
  }

  return (
    <View style={[pal.border, styles.extContainer]}>
      <Text
        type="sm"
        numberOfLines={isDesktopWeb ? 2 : 4}
        style={[
          pal.text,
          {color: colors.white},
          styles.extBold,
          styles.extNFTMeta,
        ]}>
        {embed.collectible.collectibleTitle}{' '}
        {embed.collectible.collectibleTitle ? `` : ''}
      </Text>
      {/* {link.thumb ? ( */}
      {/* <Link href={link.uri}> */}
      <View style={[pal.borderDark, styles.extImageContainer]}>
        {embed.collectible.collectibleAnimationUrl !== '' &&
        embed.collectible.collectibleAnimationUrl !== undefined ? (
          <Pressable
            accessibilityRole="button"
            style={[styles.extImageContainer]}>
            <Video
              source={{uri: animationUri ?? ''}}
              useNativeControls
              style={[{width: '100%', height: 300}]}
              videoStyle={[
                {
                  width: '100%',
                  height: 300,
                },
              ]}
              resizeMode={ResizeMode.CONTAIN}
            />
          </Pressable>
        ) : (
          <AutoSizedImage
            style={[styles.extImage, isMobileWeb && styles.singleImageMobile]}
            uri={embed.collectible.collectibleImageUri}
            onPress={() =>
              store.shell.openLightbox(
                new ImagesLightbox(
                  [{uri: embed.collectible.collectibleImageUri}],
                  0,
                ),
              )
            }
          />
        )}
        {/* {embed.collectible.collectibleAnimationUrl !== '' && (
          <TouchableOpacity
            style={styles.centerIcon}
            accessibilityRole="button"
            onPress={() => Linking.openURL(animationUri)}>
            <View
              style={[
                styles.centerIcon,
                {borderRadius: 100, backgroundColor: colors.gray6},
              ]}>
              <FontAwesomeIcon
                icon={['fas', 'play']}
                size={60}
                color={colors.white}
                style={{marginLeft: 6}}
              />
            </View>
          </TouchableOpacity>
        )} */}

        {imageChild}
      </View>
      <View
        style={[
          pal.borderDark,
          {borderWidth: 0.5, overflow: 'scroll'},
          styles.extInner,
        ]}>
        {store.session.hasSession && !isMobileWeb && (
          <Link href="/profile/solarplex.live.solarplex.xyz">
            <UserAvatar
              avatar={
                'https://appview.solarplex.xyz/image/avatar/plain/did:plc:4srpaai54v3d35bigtfbtbd5/bafkreidfbgdswcssmcjtzo5dmezyyurljn5trlcbfbvnpwl7q3am6yxbdy@jpeg'
              }
              size={32}
            />
          </Link>
        )}
        {mintStatusText === 'Done!' ? (
          <FontAwesomeIcon
            icon="check"
            style={[pal.text as FontAwesomeIconStyle, s.ml10]}
          />
        ) : (
          <Text style={[pal.textLight, styles.extUri, s.ml10, s.mr10]}>
            {mintStatusText}
          </Text>
        )}
        {/* {metaDescription ? ( */}
        <View style={[pal.borderDark, s.flexRow, {marginLeft: 'auto'}]}>
          <View
            style={{
              flexDirection: 'column',
              alignItems: 'flex-start',
              marginRight: 8,
            }}>
            <Text style={[pal.text]} type="sm-medium">
              Minted
            </Text>

            <Text
              type="md-heavy"
              numberOfLines={isDesktopWeb ? 2 : 4}
              style={[pal.text]}>
              {claimedCollectibles}/{embed.collectible.collectibleSupply}{' '}
            </Text>
          </View>
          <View style={{marginRight: 8}}>
            <Text style={[pal.text]} type="sm-medium">
              Price
            </Text>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <SolLogo size={14} fill={pal.colors.text} />
              <Text
                type="md-heavy"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, styles.extDescription]}>
                {price}
              </Text>
            </View>
          </View>
          {embed.collectible.collectibleSupply === claimedCollectibles ? (
            <Button
              type="default"
              isdisabled={true}
              labelStyle={{fontWeight: '600'}}
              label="Sold out!"
            />
          ) : !shouldShare ? (
            <Button
              labelStyle={{
                fontWeight: '700',
                color: colors.white,
              }}
              withLoading={true}
              type="primary"
              label={buyingNft ? '' : `Mint Collectible `}
              onPress={async () => {
                const itemUrip = new AtUri(itemUri!)
                const itemHref = makeProfileLink(author, 'post', itemUrip.rkey)
                const url = generatePhantomDeepLinkUrl(itemHref)

                if (isPWA) {
                  // window.open(
                  //   'https://solarplex.xyz/signin?next=https://www.solarplex.xyz/profile/liegemuller.live.solarplex.xyz/post/3knrqmuc3tg2e',
                  // )
                  if (!window.navigator.userAgent.includes('Phantom')) {
                    Linking.openURL(url)
                  }
                }
                track('NFT:NFTBuyClick', {
                  collectionId: embed.collectible.collectibleCid,
                  buyer: store.session.currentSession?.handle,
                  price: price,
                  isPaid: embed.collectible.collectiblePrice !== 0,
                })

                await handleBuyNft()
              }}>
              {buyingNft ? <LoadingIndicator /> : ''}
            </Button>
          ) : (
            <Button
              labelStyle={{
                fontWeight: '700',
                color: colors.white,
              }}
              withLoading={true}
              type="primary"
              label={'Share Collectible'}
              onPress={() =>
                store.shell.openModal({
                  name: 'nft-buy-success',
                  imageSrc: embed.collectible.collectibleImageUri,
                  onShare: shareNft,
                  twitterIntentLink,
                  creatorDid: author?.did,
                })
              }
            />
          )}
          {/* ) : (
              <Text
                type="md"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, styles.extDescription, styles.extBold]}>
                SOLD OUT!
              </Text>
            )} */}
        </View>
        {/* // ) : undefined} */}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  extContainer: {
    flexDirection: 'column',
  },
  extNFTMeta: {
    marginBottom: -25,
    marginLeft: 4,
    zIndex: 1,
    backgroundColor: 'rgba(38, 39, 45, 0.6)',
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    width: 'auto',
    borderEndStartRadius: 5,
    borderEndEndRadius: 5,
    alignSelf: 'flex-start',
  },
  extInner: {
    paddingHorizontal: isDesktopWeb ? 14 : 10,
    paddingTop: 8,
    paddingBottom: 10,
    flex: isDesktopWeb ? 1 : undefined,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  extImageContainer: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 0.5,
    width: '100%',
    maxHeight: 1000,
    overflow: 'hidden',
  },
  extImage: {
    width: '100%',
    maxHeight: 1000,
  },
  singleImageMobile: {
    width: '100%',
    maxHeight: 500,
  },
  extUri: {
    marginTop: 2,
  },
  extDescription: {
    margin: 'auto',
    paddingRight: 8,
    paddingLeft: 4,
  },
  extBold: {
    fontWeight: 'bold',
  },
  centerIcon: {
    width: 142,
    height: 142,
    opacity: 0.7,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
})

function generatePhantomDeepLinkUrl(itemHref: string): string {
  const mainUrl = window.location.origin
  const encodedRef = encodeURIComponent(mainUrl)
  const urlToEncode = mainUrl + itemHref
  const encodedRedirectUrl = encodeURIComponent(urlToEncode)
  return `https://phantom.app/ul/browse/${encodedRedirectUrl}?ref=${encodedRef}`
}
