import React from 'react'
import {observer} from 'mobx-react-lite'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {PressableWithHover} from 'view/com/util/PressableWithHover'
import {
  useLinkProps,
  useNavigation,
  useNavigationState,
} from '@react-navigation/native'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {Text} from 'view/com/util/text/Text'
import {UserAvatar} from 'view/com/util/UserAvatar'
import {Link} from 'view/com/util/Link'
import {LoadingPlaceholder} from 'view/com/util/LoadingPlaceholder'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from 'state/index'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {s, colors} from 'lib/styles'
import {
  HomeIcon,
  HomeIconSolid,
  MagnifyingGlassIcon2,
  MagnifyingGlassIcon2Solid,
  BellIcon,
  BellIconSolid,
  UserIcon,
  UserIconSolid,
  CogIcon,
  CogIconSolid,
  ComposeIcon2,
  ListIcon,
  HashtagIcon,
  HandIcon,
  CommunitiesIcon,
  RegularRankingStarIcon,
  RegularReactionIcon,
  SolidRankingStarIcon,
  SolidReactionIcon,
  CreatorToolRegularIcon,
  CreatorToolSolidIcon,
} from 'lib/icons'
import {getCurrentRoute, isTab, isStateAtTabRoot} from 'lib/routes/helpers'
import {NavigationProp, CommonNavigatorParams} from 'lib/routes/types'
import {router} from '../../../routes'
import {makeProfileLink} from 'lib/routes/links'

import * as fa from '@fortawesome/free-solid-svg-icons'
import {Banner} from '../Banner'
import {useAnalytics} from 'lib/analytics/analytics'
import {BrandLogo} from '../BrandLogo'
import {Button} from 'view/com/util/forms/Button'
const ProfileCard = observer(function ProfileCardImpl() {
  const store = useStores()
  const {isDesktop} = useWebMediaQueries()
  const size = 48
  return store.me.handle ? (
    <Link
      href={makeProfileLink(store.me)}
      style={[styles.profileCard, !isDesktop && styles.profileCardTablet]}
      title="My Profile"
      asAnchor>
      <UserAvatar avatar={store.me.avatar} size={size} />
    </Link>
  ) : (
    <View style={[styles.profileCard, !isDesktop && styles.profileCardTablet]}>
      <LoadingPlaceholder
        width={size}
        height={size}
        style={{borderRadius: size}}
      />
    </View>
  )
})

function BackBtn() {
  const {isTablet} = useWebMediaQueries()
  const pal = usePalette('default')
  const navigation = useNavigation<NavigationProp>()
  const shouldShow = useNavigationState(state => !isStateAtTabRoot(state))

  const onPressBack = React.useCallback(() => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('Home')
    }
  }, [navigation])

  if (!shouldShow || isTablet) {
    return <></>
  }
  return (
    <TouchableOpacity
      testID="viewHeaderBackOrMenuBtn"
      onPress={onPressBack}
      style={styles.backBtn}
      accessibilityRole="button"
      accessibilityLabel="Go back"
      accessibilityHint="">
      <FontAwesomeIcon
        size={24}
        icon="angle-left"
        style={pal.text as FontAwesomeIconStyle}
      />
    </TouchableOpacity>
  )
}

interface NavItemProps {
  count?: string
  href: string
  icon: JSX.Element
  iconFilled: JSX.Element
  label: string
}
const NavItem = observer(function NavItemImpl({
  count,
  href,
  icon,
  iconFilled,
  label,
}: NavItemProps) {
  const pal = usePalette('default')
  const store = useStores()
  const {track} = useAnalytics()
  const {isDesktop, isTablet} = useWebMediaQueries()
  const [pathName] = React.useMemo(() => router.matchPath(href), [href])
  const currentRouteInfo = useNavigationState(state => {
    if (!state) {
      return {name: 'Home'}
    }
    return getCurrentRoute(state)
  })
  let isCurrent =
    currentRouteInfo.name === 'Profile'
      ? isTab(currentRouteInfo.name, pathName) &&
        (currentRouteInfo.params as CommonNavigatorParams['Profile']).name ===
          store.me.handle
      : isTab(currentRouteInfo.name, pathName)
  const {onPress} = useLinkProps({to: href})
  const onPressWrapped = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (e.ctrlKey || e.metaKey || e.altKey) {
        return
      }
      e.preventDefault()
      if (isCurrent) {
        store.emitScreenSoftReset()
      } else {
        switch (href) {
          case '/channels':
            track('Communities:CommunitiesLeftDrawer')
            break
          case '/search':
            track('Search:SearchLeftDrawer')
            break
          case '/rewards/missions':
            track('Rewards:MissionsLeftDrawer')
            break
          case '/rewards/reactions':
            track('Rewards:ReactionsLeftDrawer')
            break
          case '/notifications':
            track('Notifications:NotificationsLeftDrawer')
            break
          case '/wallets':
            track('Wallet:WalletLeftDrawer')
            break
        }
        onPress()
      }
    },
    [onPress, isCurrent, store, track, href],
  )

  return (
    <PressableWithHover
      style={styles.navItemWrapper}
      hoverStyle={pal.viewLight}
      // @ts-ignore the function signature differs on web -prf
      onPress={onPressWrapped}
      // @ts-ignore web only -prf
      href={href}
      dataSet={{noUnderline: 1}}
      accessibilityRole="tab"
      accessibilityLabel={label}
      accessibilityHint="">
      <View
        style={[
          styles.navItemIconWrapper,
          isTablet && styles.navItemIconWrapperTablet,
        ]}>
        {isCurrent ? iconFilled : icon}
        {typeof count === 'string' && count ? (
          <Text
            type="button"
            style={[
              styles.navItemCount,
              isTablet && styles.navItemCountTablet,
            ]}>
            {count}
          </Text>
        ) : null}
      </View>
      {isDesktop && (
        <Text type="title" style={[isCurrent ? s.bold : s.normal, pal.text]}>
          {label}
        </Text>
      )}
    </PressableWithHover>
  )
})

type SignOutProps = {
  onPressHandler: () => void
}

export function SignOutBtn({onPressHandler}: SignOutProps) {
  const pal = usePalette('default')
  const {isTablet} = useWebMediaQueries()
  return (
    <PressableWithHover
      style={styles.navItemWrapper}
      // @ts-ignore the function signature differs on web -prf
      onPress={onPressHandler}
      // @ts-ignore web only -prf
      dataSet={{noUnderline: 1}}
      accessibilityRole="tab"
      accessibilityLabel={'Sign Out'}
      accessibilityHint="">
      <View
        style={[
          styles.navItemIconWrapper,
          isTablet && styles.navItemIconWrapperTablet,
        ]}>
        <FontAwesomeIcon
          size={20}
          icon="sign-out"
          style={{...pal.text, marginLeft: 4} as FontAwesomeIconStyle}
        />
      </View>
      {!isTablet && (
        <Text type="title" style={[s.normal, pal.text]}>
          Sign Out
        </Text>
      )}
    </PressableWithHover>
  )
}

function ComposeBtn() {
  const store = useStores()
  const {getState} = useNavigation()
  const {isTablet} = useWebMediaQueries()

  const getProfileHandle = async () => {
    const {routes} = getState()
    const currentRoute = routes[routes.length - 1]

    if (currentRoute.name === 'Profile') {
      let handle: string | undefined = (
        currentRoute.params as CommonNavigatorParams['Profile']
      ).name

      if (handle.startsWith('did:')) {
        const cached = await store.profiles.cache.get(handle)
        const profile = cached ? cached.data : undefined
        // if we can't resolve handle, set to undefined
        handle = profile?.handle || undefined
      }

      if (!handle || handle === store.me.handle || handle === 'handle.invalid')
        return undefined

      return handle
    }

    return undefined
  }

  const onPressCompose = async () =>
    store.shell.openComposer({mention: await getProfileHandle()})

  if (isTablet) {
    return null
  }
  return (
    <TouchableOpacity
      style={[styles.newPostBtn]}
      onPress={onPressCompose}
      accessibilityRole="button"
      accessibilityLabel="New post"
      accessibilityHint="">
      <View style={styles.newPostBtnIconWrapper}>
        <ComposeIcon2
          size={19}
          strokeWidth={2}
          style={styles.newPostBtnLabel}
        />
      </View>
      <Text type="button" style={styles.newPostBtnLabel}>
        New Post
      </Text>
    </TouchableOpacity>
  )
}

const SplxNavItems = observer(function SplxNavItems() {
  const store = useStores()
  const pal = usePalette('default')
  const {track} = useAnalytics()
  const navigation = useNavigation<NavigationProp>()
  const onPressSignout = React.useCallback(() => {
    track('Settings:SignOutButtonClicked')
    store.session.logout()
    navigation.navigate('SignIn')
  }, [track, store, navigation])

  return (
    <>
      <NavItem
        href="/notifications"
        count={store.me.notifications.unreadCountLabel}
        icon={<BellIcon strokeWidth={2} size={24} style={pal.text} />}
        iconFilled={
          <BellIconSolid strokeWidth={1.5} size={24} style={pal.text} />
        }
        label="Notifications"
      />
      <NavItem
        href={`/channels`}
        icon={
          <View style={{width: 21, height: 21}}>
            <CommunitiesIcon fill={pal.text.color?.toString()} />
          </View>
        }
        iconFilled={
          <FontAwesomeIcon
            size={22}
            icon={fa.faPeopleGroup}
            style={{...pal.text} as FontAwesomeIconStyle}
          />
        }
        label="Channels"
      />
      <NavItem
        href="/search"
        icon={
          <MagnifyingGlassIcon2 strokeWidth={2} size={24} style={pal.text} />
        }
        iconFilled={
          <MagnifyingGlassIcon2Solid
            strokeWidth={2}
            size={24}
            style={pal.text}
          />
        }
        label="Search"
      />
      <NavItem
        href={`/rewards/missions`}
        count={
          store.rewards.noOfPendingCreatorClaims.toString() === '0'
            ? ''
            : store.rewards.noOfPendingCreatorClaims.toString()
        }
        icon={<RegularRankingStarIcon fill={pal.text.color?.toString()} />}
        iconFilled={<SolidRankingStarIcon fill={pal.text.color?.toString()} />}
        label="Missions"
      />
      <NavItem
        href={`/rewards/reactions`}
        icon={<RegularReactionIcon fill={pal.text.color?.toString()} />}
        iconFilled={<SolidReactionIcon fill={pal.text.color?.toString()} />}
        label="Reactions"
      />
      {store.session.hasSession && (
        <NavItem
          href={makeProfileLink(store.me)}
          icon={<UserIcon strokeWidth={1.75} size={28} style={pal.text} />}
          iconFilled={
            <UserIconSolid strokeWidth={1.75} size={28} style={pal.text} />
          }
          label="Profile"
        />
      )}
      <NavItem
        href="/creator-tools"
        icon={<CreatorToolRegularIcon size={20} fill={pal.colors.text} />}
        iconFilled={<CreatorToolSolidIcon size={20} fill={pal.colors.text} />}
        label="Creator Tools"
      />
      <NavItem
        href="/moderation"
        icon={
          <HandIcon
            strokeWidth={5.5}
            style={pal.text as FontAwesomeIconStyle}
            size={24}
          />
        }
        iconFilled={
          <FontAwesomeIcon
            icon="hand"
            style={pal.text as FontAwesomeIconStyle}
            size={20}
          />
        }
        label="Moderation"
      />
      <NavItem
        href="/settings"
        icon={<CogIcon strokeWidth={1.75} size={28} style={pal.text} />}
        iconFilled={
          <CogIconSolid strokeWidth={1.5} size={28} style={pal.text} />
        }
        label="Settings"
      />
      <SignOutBtn onPressHandler={() => onPressSignout()} />
    </>
  )
})

export const DesktopLeftNav = observer(function DesktopLeftNav() {
  const store = useStores()
  const pal = usePalette('default')
  const {isDesktop, isTablet} = useWebMediaQueries()
  const {track} = useAnalytics()
  const navigation = useNavigation<NavigationProp>()
  const splx = true

  if (!store.session.hasSession && !store.session.isResumingSession) {
    return (
      <View
        style={[
          styles.leftNav,
          isTablet && styles.leftNavTablet,
          pal.view,
          {paddingBottom: 20},
        ]}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            paddingRight: 20,
          }}>
          <BrandLogo />
        </View>
        <View style={{paddingTop: 20, paddingRight: 40}}>
          <Text type="title-2xl" style={[pal.textLight, {textAlign: 'left'}]}>
            Welcome!
          </Text>
          <Text
            type="lg-heavy"
            style={[pal.text, {textAlign: 'left', paddingTop: 12}]}>
            Engage with your favorite creators to earn digital collectibles
          </Text>
        </View>
        <View
          style={{
            flexDirection: 'column',
            gap: 12,
            paddingTop: 12,
            paddingRight: 40,
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}>
          <Button
            type="primary"
            label="Create Account"
            style={{
              alignItems: 'center',
              width: '100%',
            }}
            onPress={() => {
              track('Banner:SignUp')
              navigation.navigate('SignUp', {
                name: 'creator',
              })
            }}
            labelStyle={{fontWeight: 'bold'}}
          />
          <Button
            type="primary-outline"
            label="Sign In"
            onPress={() => {
              track('Banner:SignIn')
              navigation.navigate('SignIn')
            }}
            style={{
              alignItems: 'center',
              width: '100%',
            }}
            labelStyle={{
              fontWeight: 'bold',
              textAlign: 'center',
            }}
          />
        </View>
      </View>
    )
  }

  return (
    <View
      style={[
        styles.leftNav,
        isTablet && styles.leftNavTablet,
        pal.view,
        pal.border,
      ]}>
      {!isTablet && <Banner />}
      {store.session.hasSession && <ProfileCard />}
      <BackBtn />
      <NavItem
        href="/"
        icon={<HomeIcon size={isDesktop ? 24 : 28} style={pal.text} />}
        iconFilled={
          <HomeIconSolid
            strokeWidth={4}
            size={isDesktop ? 24 : 28}
            style={pal.text}
          />
        }
        label="Home"
      />
      {!splx ? (
        <>
          <NavItem
            href="/search"
            icon={
              <MagnifyingGlassIcon2
                strokeWidth={2}
                size={isDesktop ? 24 : 26}
                style={pal.text}
              />
            }
            iconFilled={
              <MagnifyingGlassIcon2Solid
                strokeWidth={2}
                size={isDesktop ? 24 : 26}
                style={pal.text}
              />
            }
            label="Search"
          />
          <NavItem
            href="/feeds"
            icon={
              <HashtagIcon
                strokeWidth={2.25}
                style={pal.text as FontAwesomeIconStyle}
                size={isDesktop ? 24 : 28}
              />
            }
            iconFilled={
              <HashtagIcon
                strokeWidth={2.5}
                style={pal.text as FontAwesomeIconStyle}
                size={isDesktop ? 24 : 28}
              />
            }
            label="Feeds"
          />
          <NavItem
            href="/notifications"
            count={store.me.notifications.unreadCountLabel}
            icon={
              <BellIcon
                strokeWidth={2}
                size={isDesktop ? 24 : 26}
                style={pal.text}
              />
            }
            iconFilled={
              <BellIconSolid
                strokeWidth={1.5}
                size={isDesktop ? 24 : 26}
                style={pal.text}
              />
            }
            label="Notifications"
          />
          <NavItem
            href="/lists"
            icon={
              <ListIcon
                style={pal.text}
                size={isDesktop ? 26 : 30}
                strokeWidth={2}
              />
            }
            iconFilled={
              <ListIcon
                style={pal.text}
                size={isDesktop ? 26 : 30}
                strokeWidth={3}
              />
            }
            label="Lists"
          />
          <NavItem
            href="/moderation"
            icon={
              <HandIcon
                style={pal.text}
                size={isDesktop ? 24 : 27}
                strokeWidth={5.5}
              />
            }
            iconFilled={
              <FontAwesomeIcon
                icon="hand"
                style={pal.text as FontAwesomeIconStyle}
                size={isDesktop ? 20 : 26}
              />
            }
            label="Moderation"
          />
          {store.session.hasSession && (
            <NavItem
              href={makeProfileLink(store.me)}
              icon={
                <UserIcon
                  strokeWidth={1.75}
                  size={isDesktop ? 28 : 30}
                  style={pal.text}
                />
              }
              iconFilled={
                <UserIconSolid
                  strokeWidth={1.75}
                  size={isDesktop ? 28 : 30}
                  style={pal.text}
                />
              }
              label="Profile"
            />
          )}
          <NavItem
            href="/settings"
            icon={
              <CogIcon
                strokeWidth={1.75}
                size={isDesktop ? 28 : 32}
                style={pal.text}
              />
            }
            iconFilled={
              <CogIconSolid
                strokeWidth={1.5}
                size={isDesktop ? 28 : 32}
                style={pal.text}
              />
            }
            label="Settings"
          />
        </>
      ) : (
        <SplxNavItems />
      )}
      {store.session.hasSession && <ComposeBtn />}
      {!store.session.hasSession && (
        <NavItem
          href="/signin"
          count={store.me.notifications.unreadCountLabel}
          label="Sign in"
          icon={<UserIcon />}
          iconFilled={<UserIconSolid />}
        />
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  leftNav: {
    position: 'absolute',
    top: 10,
    // @ts-ignore web only
    right: 'calc(50vw + 312px)',
    width: 220,
    // @ts-ignore web only
    maxHeight: 'calc(100vh - 10px)',
    overflowY: 'auto',
  },
  leftNavTablet: {
    top: 0,
    left: 0,
    right: 'auto',
    borderRightWidth: 1,
    height: '100%',
    width: 76,
    alignItems: 'center',
  },

  profileCard: {
    marginVertical: 10,
    width: 90,
    paddingLeft: 12,
  },
  profileCardTablet: {
    width: 70,
  },

  backBtn: {
    position: 'absolute',
    top: 12,
    right: 12,
    width: 30,
    height: 30,
  },

  navItemWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 12,
    padding: 12,
    borderRadius: 8,
    gap: 10,
  },
  navItemIconWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    marginTop: 2,
    zIndex: 1,
  },
  navItemIconWrapperTablet: {
    width: 40,
    height: 40,
  },
  navItemCount: {
    position: 'absolute',
    top: 0,
    left: 15,
    backgroundColor: colors.blue3,
    color: colors.white,
    fontSize: 12,
    fontWeight: 'bold',
    paddingHorizontal: 4,
    borderRadius: 6,
  },
  navItemCountTablet: {
    left: 18,
    fontSize: 14,
  },

  newPostBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: 140,
    borderRadius: 24,
    paddingTop: 10,
    paddingBottom: 12, // visually aligns the text vertically inside the button
    paddingLeft: 16,
    paddingRight: 18, // looks nicer like this
    backgroundColor: colors.splx.primary[50],
    marginLeft: 12,
    marginTop: 20,
    marginBottom: 10,
    gap: 8,
  },
  newPostBtnIconWrapper: {
    marginTop: 2, // aligns the icon visually with the text
  },
  newPostBtnLabel: {
    color: colors.white,
    fontSize: 16,
    fontWeight: '600',
  },
})
