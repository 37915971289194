import {makeAutoObservable, runInAction} from 'mobx'

import {AppBskyActorDefs} from '@usedispatch/atproto-api'
import {RootStoreModel} from '../root-store'
import {bundleAsync} from 'lib/async/bundle'
import {cleanError} from 'lib/strings/errors'

const PAGE_SIZE = 30

export type SuggestedActor =
  | AppBskyActorDefs.ProfileViewBasic
  | AppBskyActorDefs.ProfileView

export class OnboardingActorsModel {
  // state
  pageSize = PAGE_SIZE
  isLoading = false
  isRefreshing = false
  hasLoaded = false
  loadMoreCursor: string | undefined = undefined
  error = ''
  hasMore = false


  actorsList = [
    'did:plc:4kstsbxleikg4umjuhoy2atf',
    'did:plc:eb3mrvezlcp2k5jtw3jttkkl',
    'did:plc:7phpc2dwzsavgxmagr3zx2dd',
    'did:plc:4srpaai54v3d35bigtfbtbd5'
  ]
  // data
//   suggestions: SuggestedActor[] = [{
//     did: "did:plc:4kstsbxleikg4umjuhoy2atf",
//     handle: "viksit.live.solarplex.xyz"
//   },
//   {
//     did: 'did:plc:eb3mrvezlcp2k5jtw3jttkkl',
//     handle : 'drip.live.solarplex.xyz'
//   },
//   {
//     did:'did:plc:7phpc2dwzsavgxmagr3zx2dd',
//     handle:'toly.live.solarplex.xyz'
//   }
// ]
suggestions: SuggestedActor[] = []


  constructor(public rootStore: RootStoreModel, opts?: {pageSize?: number}) {
    if (opts?.pageSize) {
      this.pageSize = opts.pageSize
    }
    makeAutoObservable(
      this,
      {
        rootStore: false,
      },
      {autoBind: true},
    )
  }

  get hasContent() {
   
    return this.suggestions.length > 0
  }

  get hasError() {
    return this.error !== ''
  }

  get isEmpty() {
    return this.hasLoaded && !this.hasContent
  }

  // public api
  // =

  async refresh() {
    return this.loadMore(true)
  }

  loadMore = bundleAsync(async (replace: boolean = false) => {
    if (replace) {
      this.hasMore = true
      this.loadMoreCursor = undefined
    }
    if (!this.hasMore) {
      return
    }
    this._xLoading(replace)
    try {
      // const res = await this.rootStore.agent.app.bsky.actor.getSuggestions({
      //   limit: 25,
      //   cursor: this.loadMoreCursor,
      // })
      const res = await this.rootStore.agent.app.bsky.actor.getProfiles({
        actors: this.actorsList
      })
      runInAction(() => {
        const profileData = res.data.profiles
        this.suggestions = profileData;
        this.rootStore.me.follows.hydrateProfiles(profileData)
      })
      this._xIdle()
    } catch (e: any) {
      this._xIdle(e)
    }
  })

  // state transitions
  // =

  _xLoading(isRefreshing = false) {
    this.isLoading = true
    this.isRefreshing = isRefreshing
    this.error = ''
  }

  _xIdle(err?: any) {
    this.isLoading = false
    this.isRefreshing = false
    this.hasLoaded = true
    this.error = cleanError(err)
    if (err) {
      this.rootStore.log.error('Failed to fetch suggested actors', err)
    }
  }
}