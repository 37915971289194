import {usePalette} from 'lib/hooks/usePalette'
import {observer} from 'mobx-react-lite'
import React from 'react'
import {StyleSheet, TouchableOpacity, View, Linking} from 'react-native'
import {PWAInfoModal} from 'state/models/ui/shell'
import {colors, s} from '../../../lib/styles'
import {Text} from '../util/text/Text'
import {Button} from '../util/forms/Button'
import {useStores} from 'state/index'
import {
  AndroidShareIcon,
  AppleShareIcon,
  BellIcon,
  ExchangeArrowIcon,
  GlobeIcon,
  IphoneIcon,
} from 'lib/icons'
import {isMobileWeb, isPWA} from 'platform/detection'

function InfoCard({device}: {device: string; shareIcon?: any}) {
  const pal = usePalette('default')
  return (
    <View style={[styles.instructionContainer, pal.viewLight]}>
      <Text type="lg-bold" style={[pal.text]}>
        For {device}
      </Text>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        <Text type="md-medium" style={[pal.text, {paddingBottom: 5}]}>
          {device === 'Apple'
            ? 'Open in Safari and use the'
            : 'Use the Android'}
          <Text style={pal.text} type="md-bold">
            {' '}
            Share button
          </Text>
        </Text>
        {device === 'Apple' ? (
          <AppleShareIcon color={pal.colors.text} />
        ) : (
          <AndroidShareIcon color={pal.colors.text} />
        )}
      </View>
      <Text style={pal.text}>
        Scroll down the options and find
        <Text style={pal.text} type="md-bold">
          {' '}
          Add to Home
        </Text>
      </Text>
    </View>
  )
}

function PWAWebModal() {
  const pal = usePalette('default')
  const store = useStores()
  return (
    <>
      <View style={[s.flexRow, {alignItems: 'center'}]}>
        <View style={[pal.viewLight, styles.circle]}>
          <GlobeIcon size={50} color={pal.colors.text} />
        </View>
        <View style={{paddingHorizontal: 10}}>
          <ExchangeArrowIcon size={30} color={pal.colors.text} />
        </View>
        <View style={[pal.viewLight, styles.circle]}>
          <IphoneIcon size={50} color={pal.colors.text} />
        </View>
      </View>
      <Text type="2xl-heavy" style={[pal.text, {paddingVertical: 20}]}>
        Turn Solarplex into an app
      </Text>
      <InfoCard device="Apple" />
      <InfoCard device="Android" />
      <Button
        style={{width: '100%', alignItems: 'center', paddingVertical: 10}}
        label="Got it"
        type="primary"
        onPress={() => {
          store.shell.closeModal()
        }}
      />
    </>
  )
}

// Modal in PWA
function InPWAModal() {
  const pal = usePalette('default')
  const store = useStores()
  return (
    <>
      <View style={[pal.viewLight, styles.circle]}>
        <BellIcon size={50} style={pal.text} />
      </View>

      <Text
        type="2xl"
        style={[pal.text, {textAlign: 'center', paddingVertical: 20}]}>
        🚨 Important Update! 🚨 To enable notifications and access new features,
        please re-add Solarplex to your home screen as a PWA.{' '}
        <TouchableOpacity
          onPress={() => {
            Linking.openURL('https://solarplex.xyz')
          }}
          accessibilityRole="button">
          <Text type="2xl" style={[pal.link, {color: colors.splx.primary[50]}]}>
            Simply click here
          </Text>
        </TouchableOpacity>{' '}
        for a quick setup. Thanks for staying updated with us!
      </Text>
      <Button
        style={{width: '100%', alignItems: 'center', paddingVertical: 10}}
        label="Got it"
        type="primary"
        onPress={() => {
          store.shell.closeModal()
        }}
      />
    </>
  )
}

export const Component = observer(function ComponentImpl({}: PWAInfoModal) {
  const pal = usePalette('default')

  return (
    <View style={[pal.view, styles.container]}>
      {isPWA ? <InPWAModal /> : isMobileWeb && <PWAWebModal />}
    </View>
  )
})

const styles = StyleSheet.create({
  circle: {
    borderRadius: 100,
    padding: 10,
  },
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  instructionContainer: {
    width: '100%',
    padding: 15,
    marginBottom: 10,
    backgroundColor: '#f2f2f2',
    borderRadius: 10,
  },
  btnConainer: {
    paddingBottom: 22,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'auto',
    paddingTop: 9,
  },
  imageContainer: {
    paddingTop: 44,
    paddingBottom: 22,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '88%',
    backgroundColor: colors.gray2,
  },
  image: {
    width: 75,
    height: 75,
  },
  infoContainer: {
    paddingHorizontal: 32,
    paddingTop: 32,
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
})
