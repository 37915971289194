import {usePalette} from '../../../lib/hooks/usePalette'
import {observer} from 'mobx-react-lite'

import {ReactionModal} from '../../../state/models/ui/shell'
import {Text} from '../util/text/Text'
import {Image} from 'expo-image'
import {StyleSheet, View} from 'react-native'
import {s3ToCdn} from '../../../lib/media/util'
import {ShareButton} from '../rewards/ShareButton'

import {colors, s} from '../../../lib/styles'
import React from 'react'
import {Button} from '../util/forms/Button'
import {useStores} from '../../../state'

export const Component = observer(function ComponentImpl({
  item,
  reactionPack,
}: ReactionModal) {
  const pal = usePalette('default')
  const store = useStores()

  const canShare = store.reactions.earnedReactions[reactionPack]?.some(
    reaction => reaction.reaction_id === item.reaction_id,
  )

  return (
    <View style={[pal.view, styles.center]}>
      <View style={[pal.border, styles.imageContainer]}>
        <Image
          accessibilityIgnoresInvertColors
          contentFit="contain"
          source={{
            uri: s3ToCdn(item.nft_metadata.image, 512),
          }}
          style={styles.image}
        />
      </View>
      <View style={[pal.border, styles.textContainer]}>
        {item.nft_metadata.attributes?.artist && (
          <View style={s.mb5}>
            <Text style={[pal.text, s.mb2, s.bold]}>
              {item.nft_metadata.name}
            </Text>
            <Text style={[pal.text]}>
              By {item.nft_metadata.attributes?.artist}
            </Text>
          </View>
        )}
        <View style={styles.textInnerContainer}>
          {item.nft_metadata.attributes?.trait && (
            <View style={{paddingRight: 24}}>
              <Text style={[pal.text, s.mb2, s.bold]}>Trait</Text>
              <Text style={[pal.text]}>{item.reaction_id}</Text>
            </View>
          )}
          <View style={{paddingRight: 24}}>
            <Text style={[pal.text, s.bold]}>Rarity</Text>
            <Text style={pal.text}>{item.rare ? 'Rare' : 'Common'}</Text>
          </View>
        </View>
      </View>
      <ShareButton
        style={{flex: 1, marginTop: 12}}
        rewardsImg={s3ToCdn(item.nft_metadata.image, 512)}
        shareText=""
        userId={''}
        canShare={canShare}
      />
      <Button
        labelStyle={{color: colors.splx.primary[60], fontSize: 12}}
        type="default-light"
        onPress={() => store.shell.closeModal()}
        label="Close"
      />
    </View>
  )
})

const styles = StyleSheet.create({
  imageContainer: {
    borderWidth: 1,
    borderRadius: 10,
    marginBottom: 12,
    padding: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
  },
  textInnerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
  },
  textContainer: {
    width: '100%',
    borderWidth: 1,
    borderRadius: 10,
    paddingVertical: 13,
    paddingHorizontal: 20,
  },
  image: {
    width: '100%',
    height: '100%',
  },
  container: {
    paddingHorizontal: 1,
  },
  center: {
    paddingHorizontal: 10,
    paddingTop: 10,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
