import {makeAutoObservable, runInAction} from 'mobx'

import {RootStoreModel} from '../root-store'
import {SOLARPLEX_FEED_API, SOLARPLEX_V1_API} from 'lib/constants'
import {SolarplexReaction} from '../media/reactions'
import {actions} from '../actions'
import {VersionedTransaction} from '@solana/web3.js'
import * as apilib from 'lib/api/index'

export const apiUrls = {
  rewards: {
    getReactions: (wallet: string) => `/rewards/reactions/${wallet}`,
  },
}

export type ClaimResponseBody = {
  status: string
  message: string
  actionId: string
}

export class NftModel {
  assets: any[] = []

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {rootStore: false}, {autoBind: true})
  }

  setAssets(assets: any) {
    const genesisName = 'Solarplex Genesis Reaction'

    const reactionsMap = Object.values(
      this.rootStore.reactions.reactionTypes,
    ).reduce((acc: {[title: string]: SolarplexReaction}, item: any) => {
      if (item.collection_id !== 'default') {
        // Fix genesis nft data.
        let name = item.nft_metadata.name
        if (name.startsWith(genesisName)) {
          name = `${name}-${item.reaction_id}`
        }

        acc[name] = item
      }
      return acc
    }, {})

    const reactions: {[reactionPack: string]: SolarplexReaction[]} = {}
    const seenAttributes = new Set()

    assets.forEach((item: any) => {
      const metadata = item?.content?.metadata
      if (!metadata.attributes) return
      const name = metadata.name
      const attributes = metadata.attributes
      for (const attribute of attributes) {
        if (
          attribute.trait_type === 'trait' ||
          attribute.trait_type === 'Trait'
        ) {
          let key = name
          if (name.startsWith(genesisName)) {
            key = `${genesisName}-${attribute.value}`
          }
          if (!seenAttributes.has(key)) {
            seenAttributes.add(key)
            const reaction = reactionsMap[key]
            if (!reactions[reaction?.collection_id]) {
              reactions[reaction?.collection_id] = []
            }
            reactionsMap[key] &&
              reactions[reaction?.collection_id].push(reactionsMap[key])
          }
        }
      }
    })

    // console.log("reactions", reactions);

    runInAction(() => {
      this.assets = assets
    })

    this.rootStore.reactions.update(reactions)
  }

  _fetchNfts = actions.wrapAction(
    async (wallet: string) => {
      const url = `${SOLARPLEX_V1_API}${apiUrls.rewards.getReactions(wallet)}`
      const response = (await this.rootStore.api.get(url)) as {
        [collectionId: string]: {
          result: {items: any[]}
        }
      }
      if (this.rootStore.api.getError(url)) {
        return []
      }
      return Object.values(response).reduce<any[]>((acc, r) => {
        acc.push(...r.result.items)
        return acc
      }, [])
    },
    this,
    '_fetchNfts',
  )

  _fetchBuyNftTx = actions.wrapAction(
    async (wallet: string, mintAddress: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/mallow_mint`
      const res = await this.rootStore.api.post(url, {
        body: {
          buyer: wallet,
          mint: mintAddress,
        },
      })
      return res
    },
    this,
    '_fetchBuyNftTx',
  )

  _claimNft = actions.wrapAction(
    async (wallet: string, collectionId: string) => {
      const url = `${SOLARPLEX_FEED_API}/splx/collectibles/claim`
      const res = await this.rootStore.api.post(url, {
        body: {
          wallet,
          collectionId,
          did: this.rootStore.session.currentSession?.did,
        },
      })
      return res
    },
    this,
    '_claimNft',
  )

  fetchNfts(wallet: string) {
    this._fetchNfts(wallet).then(items => this.setAssets(items))
  }

  getDeserializedTxn(txn: string) {
    return VersionedTransaction.deserialize(Buffer.from(txn, 'base64'))
  }

  async getBuyNftTxn(wallet: string, mintAddress: string) {
    try {
      const res: any = await this._fetchBuyNftTx(wallet, mintAddress)
      const txn = this.getDeserializedTxn(res.txn)
      // const txn = VersionedTransaction.deserialize(
      //   Buffer.from(res.txn, 'base64'),
      // )
      return txn
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async claimNftOld(
    wallet: string,
    collectionId: string,
  ): Promise<ClaimResponseBody> {
    try {
      const res: any = await this._claimNft(wallet, collectionId)
      return res
    } catch (e) {
      console.log(e)
      throw e
    }
  }

  async claimNft(
    wallet: string,
    collectionId: string,
    did: string,
    collectionUri: string,
    postSubject?: {uri: string; cid: string},
  ): Promise<ClaimResponseBody> {
    try {
      const res = await apilib.createClaim(this.rootStore, {
        collectionCid: collectionId,
        did,
        wallet,
        collectionUri,
        postSubject,
      })
      return res.data as ClaimResponseBody
    } catch (e) {
      console.log(e)
      throw e
    }
  }
}
