import {Router} from 'lib/routes/router'

export const router = new Router(
  {
    Home: '/',
    Search: '/search',
    Feeds: '/feeds',
    DiscoverFeeds: '/search/feeds',
    Notifications: '/notifications',
    Channels: '/channels',
    ChannelMembers: '/channels/:cid/members',
    SignIn: '/signin',
    Settings: '/settings',
    LanguageSettings: '/settings/language',
    Lists: '/lists',
    Moderation: '/moderation',
    ModerationModlists: '/moderation/modlists',
    ModerationMutedAccounts: '/moderation/muted-accounts',
    ModerationBlockedAccounts: '/moderation/blocked-accounts',
    Profile: '/profile/:name',
    ProfileFollowers: '/profile/:name/followers',
    ProfileFollows: '/profile/:name/follows',
    ProfileList: '/profile/:name/lists/:rkey',
    PostThread: '/profile/:name/post/:rkey',
    PostLikedBy: '/profile/:name/post/:rkey/liked-by',
    PostRepostedBy: '/profile/:name/post/:rkey/reposted-by',
    ProfileFeed: '/profile/:name/feed/:rkey',
    ProfileFeedLikedBy: '/profile/:name/feed/:rkey/liked-by',
    Debug: '/sys/debug',
    Log: '/sys/log',
    AppPasswords: '/settings/app-passwords',
    PreferencesHomeFeed: '/settings/home-feed',
    PreferencesThreads: '/settings/threads',
    SavedFeeds: '/settings/saved-feeds',
    Support: '/support',
    PrivacyPolicy: '/support/privacy',
    TermsOfService: '/support/tos',
    CommunityGuidelines: '/support/community-guidelines',
    CopyrightPolicy: '/support/copyright',
    ChannelFeed: '/channel/:rkey',
    Rewards: '/rewards',
    MissionsList: '/rewards/missions',
    Reactions: '/rewards/reactions',
    Mission: '/rewards/missions/:id',
    CreatorTools: '/creator-tools',
    WaitList: '/waitlist',
    SignUp: '/signup/:name',
    Breakpoint: '/breakpoint',
    Deans: '/deans',
    Creator: '/creator/:name',
    Creators: '/creators',
    DeveloperFrames: '/developer/frames',
  },
  // Public routes
  ['Profile', 'PostThread'],
)
