import React from 'react'
import {observer} from 'mobx-react-lite'
import {ActivityIndicator} from 'react-native'
import {CenteredView, FlatList} from '../util/Views'

import {ErrorMessage} from '../util/error/ErrorMessage'
import {ProfileCardWithFollowBtn} from '../profile/ProfileCard'
import {useStores} from '../../../state/index'
import {ProfileViewDetailed} from '@usedispatch/atproto-api/dist/client/types/app/bsky/actor/defs'

export const ChannelMembersList = observer(function ({cid}: {cid: string}) {
  const store = useStores()
  const profiles = store.channels.allUsersProfiles[cid]

  if (
    store.channels.fetchAllUserProfilesInChannelBusy(cid) ||
    profiles.length === 0
  ) {
    return (
      <CenteredView>
        <ActivityIndicator />
      </CenteredView>
    )
  }

  if (store.channels.fetchAllUserProfilesInChannelError(cid)) {
    return (
      <CenteredView>
        <ErrorMessage message={'Something Went Wrong'} />
      </CenteredView>
    )
  }

  const renderItem = ({item}: {item: ProfileViewDetailed}) => (
    <ProfileCardWithFollowBtn key={item.did} profile={item} />
  )

  return (
    <FlatList
      data={profiles}
      keyExtractor={item => item.did}
      renderItem={renderItem}
      initialNumToRender={15}
      // ListFooterComponent={() => (
      //   <View style={styles.footer}>
      //     {view.isLoading && <ActivityIndicator />}
      //   </View>
      // )}
      // extraData={view.isLoading}
      // @ts-ignore our .web version only -prf
      desktopFixedHeight
    />
  )
})

ChannelMembersList.displayName = 'ChannelMembersList'

// const styles = StyleSheet.create({
//   footer: {
//     height: 200,
//     paddingTop: 20,
//   },
// })
