type TokenType = {
  tokenName: string
  mintAddress: string
  decimals: number
  tokenImage: string
}

export const PIKL: TokenType = {
  tokenName: 'PIKL',
  mintAddress: 'AVm6WLmMuzdedAMjpXLYmSGjLLPPjjVWNuR6JJhJLWn3',
  decimals: 9,
  tokenImage:
    'https://res.cloudinary.com/diyjjw5ke/image/upload/v1695912899/PIKL_lqrhug.png',
}

export const DEAN: TokenType = {
  tokenName: 'DEAN',
  mintAddress: 'Ds52CDgqdWbTWsua1hgT3AuSSy4FNx2Ezge1br3jQ14a',
  decimals: 6,
  tokenImage:
    'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Farweave.net%2FTWUBda7cjVjugUjlstko7peZ_VOOO9vr1buVZ7eZNtg&fit=cover&width=256&height=256',
}

export const BONK: TokenType = {
  tokenName: 'BONK',
  mintAddress: 'DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263',
  decimals: 5,
  tokenImage:
    'https://pbs.twimg.com/profile_images/1600956334635098141/ZSzYTrHf_400x400.jpg',
}

export const GUAC: TokenType = {
  tokenName: 'GUAC',
  mintAddress: 'AZsHEMXd36Bj1EMNXhowJajpUXzrKcK57wW4ZGXVa7yR',
  decimals: 5,
  tokenImage:
    'https://shdw-drive.genesysgo.net/36JhGq9Aa1hBK6aDYM4NyFjR5Waiu9oHrb44j1j8edUt/image.png',
}

export const BSOL: TokenType = {
  tokenName: 'BSOL',
  mintAddress: 'bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1',
  decimals: 9,
  tokenImage:
    'https://images.xnfts.dev/cdn-cgi/image/fit=crop,width=400,height=400,quality=85/https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/bSo13r4TkiE4KumL71LsHTPpL2euBYLFx6h9HP3piy1/logo.png',
}

export const BLAZE: TokenType = {
  tokenName: 'BLZE',
  mintAddress: 'BLZEEuZUBVqFhj8adcCFPJvPVCiCyVmh3hkJMrU8KuJA',
  decimals: 9,
  tokenImage:
    'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fsolblaze.org%2Fassets%2Fblze.png&fit=cover&width=256&height=256g',
}

export const SOL: TokenType = {
  tokenName: 'SOL',
  mintAddress: '11111111111111111111111111111111',
  decimals: 9,
  tokenImage:
    'https://seeklogo.com/images/S/solana-sol-logo-12828AD23D-seeklogo.com.png',
}

export const DEVNET_TOKEN: TokenType = {
  tokenName: 'TEST',
  mintAddress: '9qjrwyMQkU3WRVfeEG55BJw9P3hRC54D57GjUy7hKk97',
  decimals: 9,
  tokenImage:
    'https://api.phantom.app/image-proxy/?image=https%3A%2F%2Fsolblaze.org%2Fassets%2Fblze.png&fit=cover&width=256&height=256g',
}

export const MainnetTipTokens = {
  SOL,
  BONK,
  GUAC,
  DEAN,
  PIKL,
  BLAZE,
  BSOL,
}

export const DevnetTipTokens = {
  SOL,
  DEVNET_TOKEN,
}

export const TipTokens =
  process.env.SOLARPLEX_REALM === 'dev' ? DevnetTipTokens : MainnetTipTokens

export const preDefinedTokenValues = {
  SOL: [0.01, 0.1, 1],
  BONK: [69000, 420000, 1000000],
  GUAC: [100, 1000, 10000],
}

export function searchTokenByName(tokenName: string): TokenType | undefined {
  return Object.values(TipTokens).find(token => token.tokenName === tokenName)
}
