import {
  ChannelsTabNavigatorParams,
  NativeStackScreenProps,
} from '../../lib/routes/types'
import {Text} from '../com/util/text/Text'
import {ViewSelector, ViewSelectorHandle} from '../com/util/ViewSelector'
import {isDesktopWeb} from '../../platform/detection'
import {CenteredView} from '../com/util/Views'
import {ChannelFeed} from '../com/channels/ChannelFeed'
import {ChannelFeedModel} from 'state/models/feeds/channel-feed'
import React from 'react'
import {View, StyleSheet, Linking} from 'react-native'
import {ViewHeader} from '../com/util/ViewHeader'
import {isWeb} from 'platform/detection'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {useAnalytics} from 'lib/analytics/analytics'
import {useFocusEffect} from '@react-navigation/native'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from '../../state/index'
import {useTabFocusEffect} from 'lib/hooks/useTabFocusEffect'
import {withAuthRequired} from 'view/com/auth/withAuthRequired'
import {Button} from 'view/com/util/forms/Button'
import {sanitizeUrl} from '@braintree/sanitize-url'
import {createChannelLink} from 'lib/constants'

type Props = NativeStackScreenProps<ChannelsTabNavigatorParams, 'Channels'>

function Header() {
  const pal = usePalette('default')
  const {track} = useAnalytics()

  if (isDesktopWeb) {
    return (
      <View
        style={{
          paddingHorizontal: 10,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <Text type="title" style={[styles.heading, pal.text]}>
          Channels
        </Text>
        <Button
          type="primary"
          onPress={() => {
            track('CreateChannel:Clicked', {})
            Linking.openURL(sanitizeUrl(createChannelLink))
          }}
          label="Create Channel"
        />
      </View>
    )
  }
  return <View />
}

export const ChannelsScreen = withAuthRequired(
  observer(function CommunitiesScreen(_props: Props) {
    const store = useStores()
    const pal = usePalette('default')
    const {screen} = useAnalytics()
    const viewSelectorRef = React.useRef<ViewSelectorHandle>(null)

    // event handlers
    // =

    // on-visible setup
    // =
    useFocusEffect(
      React.useCallback(() => {
        store.shell.setMinimalShellMode(false)
        store.log.debug('ChannelsScreen: Updating communities')
        store.me.notifications.update()
        screen('Communities')
        store.channels.fetch()
        // return () => {
        //   softResetSub.remove();
        //   store.me.notifications.markAllRead();
        // };
      }, [store, screen]),
    )
    useTabFocusEffect(
      'Communities',
      React.useCallback(isInside => {
        // on mobile:
        // fires with `isInside=true` when the user navigates to the root tab
        // but not when the user goes back to the screen by pressing back
        // on web:
        // essentially equivalent to useFocusEffect because we dont used tabbed
        // navigation
        if (isInside) {
          if (isWeb) {
            //console.log("(web) notifications call back 2");
          } else {
            //console.log("(notweb) notifications call back 3");
            // store.communities.fetch();
          }
        }
      }, []),
    )
    const renderItem = React.useCallback(
      (item: any) => {
        if (item instanceof ChannelFeedModel) {
          const shouldDisplayChannel = store.channels
            .publicChannels()
            .includes(item.id!!)

          if (!shouldDisplayChannel) {
            return <ChannelFeed item={item} showJoinBtn={true} />
          } else {
            return <View />
          }
        }
        return <View />
      },
      [store.channels],
    )

    // const hasNew =
    //   store.me.notifications.hasNewLatest &&
    //   !store.me.notifications.isRefreshing;
    return (
      <View style={pal.view}>
        <View testID="channelsScreen" style={s.hContentRegion}>
          <CenteredView>
            <ViewHeader title="Channels" canGoBack={false} />

            {store.channels.channelFeeds && (
              <ViewSelector
                ref={viewSelectorRef}
                swipeEnabled={false}
                sections={[]}
                items={['', ...store.channels.channelFeeds]}
                renderItem={renderItem}
                renderHeader={() => <Header />}
              />
            )}
            {/* <Feed
            view={store.me.notifications}
            onPressTryAgain={onPressTryAgain}
            onScroll={onMainScroll}
            scrollElRef={scrollElRef}
          /> */}
            {/* {(isScrolledDown || hasNew) && (
            <LoadLatestBtn
              onPress={onPressLoadLatest}
              label="Load new notifications"
              showIndicator={hasNew}
              minimalShellMode={true}
            />
          )} */}
          </CenteredView>
        </View>
      </View>
    )
  }),
)

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingHorizontal: 12,
    paddingBottom: 8,
    paddingTop: 16,
  },
})
