import {usePalette} from 'lib/hooks/usePalette'
import React from 'react'
import {Linking, StyleSheet, View} from 'react-native'
import {FrameShareModal} from 'state/models/ui/shell'
import {Button} from '../util/forms/Button'
import {useSplxWallet} from '../wallet/useSplxWallet'

import {isDesktopWeb, isMobileWeb} from 'platform/detection'
import {useStores} from 'state/index'
import {colors} from 'lib/styles'

import {sanitizeUrl} from '@braintree/sanitize-url'
import {track} from 'lib/analytics/analytics'

//regex to match urls
const urlRegex = /(https?:\/\/[^\s]+)/g

export function Component({text, frameUri}: FrameShareModal) {
  const pal = usePalette('default')
  const store = useStores()
  const [
    _visible,
    _setVisible,
    _linkedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
  ] = useSplxWallet(true)
  const urlText = text.match(urlRegex)
  const encodedUrl = encodeURIComponent(urlText ? urlText[0] : '')
  const encodedText = text.split('http')[0] + encodedUrl
  const twitterIntentLink = `https://twitter.com/intent/tweet?text=${encodedText}`

  const onShare = () => {
    track('Frame:ModalShare', {text, frameUri})
    store.shell.openComposer({
      sharingText: text,
    })
  }

  return (
    <View style={[pal.view, styles.container]}>
      <View style={styles.shareContainer}>
        <Button
          style={styles.shareButtonTwitter}
          labelStyle={{
            fontWeight: '700',
            flex: 1,
            textAlign: 'center',
            color: colors.white,
          }}
          label="Share on Twitter"
          type="primary"
          onPress={() => {
            track('Frame:TwitterShare', {text, frameUri})
            Linking.openURL(sanitizeUrl(twitterIntentLink))
          }}
        />
        <Button
          style={styles.shareButtonSolarplex}
          labelStyle={{
            fontWeight: '700',
            fontSize: 12,
            flex: 1,
            textAlign: 'center',

            color: colors.white,
          }}
          label="Share on Solarplex"
          type="primary"
          onPress={() => {
            onShare()
            store.shell.closeModal()
          }}
        />
        <Button
          style={styles.shareButtonSolarplex}
          labelStyle={{
            fontWeight: '700',
            fontSize: 10,
            flex: 1,
            textAlign: 'center',
          }}
          label="Close"
          type="default"
          onPress={() => {
            store.shell.closeModal()
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: isDesktopWeb ? 0 : 18,
    paddingHorizontal: isDesktopWeb ? 0 : 18,
  },
  shareContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: isMobileWeb ? 10 : 25,
    gap: isMobileWeb ? 10 : 20,
  },
  shareButtonSolarplex: {
    height: 30,
    width: isMobileWeb ? '80%' : '40%',
    justifyContent: 'center',
  },
  shareButtonTwitter: {
    marginTop: isMobileWeb ? 10 : 20,
    fontSize: 12,
    width: isMobileWeb ? '80%' : '40%',
    justifyContent: 'center',
    backgroundColor: colors.blue2,
  },
})
