import {
  FlatList,
  Image,
  StyleSheet,
  View,
  TouchableOpacity,
  StyleProp,
  ImageStyle,
} from 'react-native'
import {colors, s} from '../../lib/styles'
import {isMobileWeb} from 'platform/detection'

import {CenteredView} from 'view/com/util/Views.web'
import {Link} from '../../view/com/util/Link'
import {MissionsTabNavigatorParams} from '../../lib/routes/types'
import {NativeStackScreenProps} from '@react-navigation/native-stack'
import React from 'react'
import {ScrollView} from '../com/util/Views'
import {Text} from '../../view/com/util/text/Text'
import {ToggleButton} from '../../view/com/util/forms/ToggleButton'
import {UserAvatar} from '../../view/com/util/UserAvatar'
import {ViewHeader} from '../../view/com/util/ViewHeader'
import {observer} from 'mobx-react-lite'
import {usePalette} from '../../lib/hooks/usePalette'
import {useStores} from '../../state/index'
import {withAuthRequired} from '../../view/com/auth/withAuthRequired'
import {useAnalytics} from '../../lib/analytics/analytics'
import {s3ToCdn} from '../../lib/media/util'
import {SolarplexReaction} from '../../state/models/media/reactions'
import {ImageContentFit} from 'expo-image'

function getReactionPackTitle(reactionPack: string): string {
  if (reactionPack === 'gaming') {
    return `Rubian Reactions by Zen Republic`
  }
  if (reactionPack === 'burger') {
    return 'Solarplex Blessed Burger Reactions'
  } else {
    return `Solarplex ${
      reactionPack.charAt(0).toUpperCase() + reactionPack.slice(1)
    } Reactions`
  }
}

function getReactionPackStyle(reactionPack: string) {
  if (reactionPack === 'default') {
    return {
      width: isMobileWeb ? 40 : 50,
      height: isMobileWeb ? 40 : 50,
    }
  } else if (reactionPack === 'gaming') {
    return {
      width: isMobileWeb ? 60 : 60,
      height: isMobileWeb ? 60 : 60,
    }
  } else if (reactionPack === 'squid') {
    return {
      width: isMobileWeb ? 100 : 100,
      height: isMobileWeb ? 100 : 100,
    }
  } else {
    return {
      width: isMobileWeb ? 75 : 70,
      height: isMobileWeb ? 75 : 70,
    }
  }
}

export function getReactionPackCreator(reactionPack: string): string {
  if (reactionPack === 'gaming') {
    return `zenrepublic.live.solarplex.xyz`
  } else if (reactionPack === 'squidz') {
    return `squidz.live.solarplex.xyz`
  } else if (reactionPack === 'default') {
    return 'solarplex.live.solarplex.xyz'
  } else if (reactionPack === 'tornado') {
    return 'tornado.live.solarplex.xyz'
  } else if (reactionPack === 'genesis') {
    return 'robert.live.solarplex.xyz'
  } else if (reactionPack === 'burger') {
    return 'glowburger.live.solarplex.xyz'
  } else if (reactionPack === 'chads') {
    return 'chadswtf.live.solarplex.xyz'
  } else if (reactionPack === 'dreader') {
    return 'dreader.live.solarplex.xyz'
  } else if (reactionPack === 'pepeclown') {
    return 'pepeclown.live.solarplex.xyz'
  } else if (reactionPack === 'graphe') {
    return 'graphe.live.solarplex.xyz'
  } else {
    return `solarplex.live.solarplex.xyz`
  }
}

function DisplayNft({
  onPress,
  reactionPack,
  children,
}: {
  onPress?: () => void
  reactionPack: string
  children: React.ReactNode
}) {
  const pal = usePalette('default')

  if (reactionPack !== 'default') {
    return (
      <TouchableOpacity
        accessibilityRole="button"
        style={[pal.border, styles.reactionSelect]}
        onPress={onPress}>
        {children}
      </TouchableOpacity>
    )
  } else {
    return children
  }
}

export const GrayedImage = ({
  image,
  reactionpack,
}: {
  image: any
  reactionpack: string
  styles?: StyleProp<ImageStyle>
  contentFit?: ImageContentFit | undefined
}) => {
  return (
    <View style={{flex: 1}}>
      <Image
        accessibilityIgnoresInvertColors
        source={{
          uri: image,
        }}
        style={[
          {
            tintColor: 'gray',
            ...getReactionPackStyle(reactionpack),
          },
        ]}
      />
      <Image
        accessibilityIgnoresInvertColors
        source={{
          uri: image,
        }}
        style={[
          {
            position: 'absolute',
            opacity: 0.17,
            ...getReactionPackStyle(reactionpack),
          },
        ]}
      />
    </View>
  )
}

const ReactionPackDisplay = ({reactionPack}: {reactionPack: string}) => {
  const store = useStores()
  const pal = usePalette('default') // replace with actual import path

  const creator = getReactionPackCreator(reactionPack)

  return (
    <View style={styles.horizontalContainer}>
      <View style={isMobileWeb && {alignSelf: 'center', paddingRight: 5}}>
        <UserAvatar
          size={25}
          avatar={
            reactionPack === 'gaming'
              ? 'https://live.solarplex.xyz/image/agIRkUaOAo7UKR83WhTqnSYMBUGILjWmXEKhlw0lhrc/rs:fill:1000:1000:1:0/plain/bafkreihko7w6unxciovmrzsou2naohht5zb7jst2op4hqaoi573m6bzh3m@jpeg'
              : 'https://i.ibb.co/NLkvySY/blob.png'
          }
        />
      </View>
      <View style={styles.verticalView}>
        <View style={isMobileWeb ? styles.verticalView : styles.horizontalView}>
          <Text type="lg-heavy" style={[pal.text, styles.textPadding]}>
            {getReactionPackTitle(reactionPack)}
          </Text>
          <Text
            type="sm-heavy"
            style={[
              pal.text,
              styles.textPadding,
              styles.reaction,
              pal.viewLight,
            ]}>
            {Math.min(
              store.reactions.earnedReactions[reactionPack]?.length ?? 0,
              11,
            )}
            /{store.reactions.reactionSets[reactionPack]?.length} Reactions
          </Text>
        </View>
        <Text type="lg-medium" style={[pal.link, styles.textPadding]}>
          <Link href={`/profile/${creator}`}>by {creator}</Link>
        </Text>
      </View>
    </View>
  )
}

const DisplayReactions = observer(function DisplayReactions() {
  const pal = usePalette('default')
  const store = useStores()
  const {screen, track} = useAnalytics()

  const onPressReactionPack = (reactionPack: string) => {
    if (store.reactions.curReactionsSet.includes(reactionPack)) {
      store.reactions.curReactionsSet = store.reactions.curReactionsSet.filter(
        set => set !== reactionPack,
      )
    } else {
      store.reactions.curReactionsSet.push(reactionPack)
    }
  }

  React.useEffect(() => {
    screen('Missions')
  }, [screen])

  function expandReactionPackItem(
    item: SolarplexReaction,
    reactionPack: string,
  ) {
    store.shell.openModal({
      name: 'reaction-modal',
      item,
      reactionPack,
    })
    track('Rewards:ReactionExpand')
  }

  return (
    <View>
      <View style={s.p10}>
        <Text style={[styles.details, pal.text, pal.viewLight]}>
          Select a reaction to learn more about it!
        </Text>
      </View>
      {Object.keys(store.reactions.reactionSets).map(reactionPack => (
        <View key={reactionPack} style={[pal.view]}>
          <View style={styles.HeaderRow}>
            <ReactionPackDisplay reactionPack={reactionPack} />

            <ToggleButton
              type="default-light"
              isSelected={store.reactions.curReactionsSet.includes(
                reactionPack,
              )}
              onPress={() => onPressReactionPack(reactionPack)}
              label=""
            />
          </View>
          <View
            style={[
              styles.reactionList,
              !store.reactions.earnedReactions[reactionPack]?.length && {
                opacity: 0.2,
              },
            ]}>
            <FlatList
              data={store.reactions.reactionSets[reactionPack]}
              numColumns={isMobileWeb ? 4 : 4}
              key={4}
              renderItem={({item}) => {
                if (
                  store.reactions.earnedReactions[reactionPack]?.find(
                    reaction => reaction.reaction_id === item.reaction_id,
                  )
                ) {
                  return (
                    <DisplayNft
                      reactionPack={reactionPack}
                      onPress={() =>
                        expandReactionPackItem(item, reactionPack)
                      }>
                      <View
                        style={[
                          {
                            width: 86,
                            height: 86,
                          },
                          styles.displayImageContainer,
                        ]}>
                        <Image
                          accessibilityIgnoresInvertColors
                          source={{
                            uri: s3ToCdn(item.nft_metadata.image, 512),
                          }}
                          style={getReactionPackStyle(reactionPack)}
                        />
                      </View>
                    </DisplayNft>
                  )
                } else {
                  return (
                    <DisplayNft
                      reactionPack={reactionPack}
                      onPress={() =>
                        expandReactionPackItem(item, reactionPack)
                      }>
                      <View
                        style={[
                          {
                            width: 86,
                            height: 86,
                          },
                          styles.displayImageContainer,
                        ]}>
                        <GrayedImage
                          reactionpack={reactionPack}
                          image={s3ToCdn(item.nft_metadata.image, 512)}
                        />
                      </View>
                    </DisplayNft>
                  )
                }
              }}
            />
          </View>
        </View>
      ))}
      <View style={styles.spacer20} />
    </View>
  )
})

type Props = NativeStackScreenProps<MissionsTabNavigatorParams, 'MissionsList'>
export const MissionsTab = withAuthRequired(
  observer(function Missions(_props: Partial<Props>) {
    return (
      <View testID="communitiesScreen" style={s.hContentRegion}>
        <ScrollView
          style={[s.hContentRegion]}
          scrollIndicatorInsets={{right: 1}}>
          <ViewHeader title="Reactions" canGoBack={false} />
          <CenteredView style={styles.container}>
            <DisplayReactions />
          </CenteredView>
        </ScrollView>
      </View>
    )
  }),
)

const styles = StyleSheet.create({
  outer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#FEDC9B',
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  reactionsBox: {
    backgroundColor: colors.gray1,
  },
  horizontalView: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  verticalView: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  container: {
    padding: 2,
  },
  RollBtn: {
    width: 200,
    paddingVertical: 4,
  },
  DiceRollImage: {
    width: 200,
    height: 150,
    borderRadius: 12,
  },
  DiceRowCol: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.gray1,
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 14,
    paddingVertical: 8,
  },
  DiceRollText: {
    paddingVertical: 6,
  },
  ImgView: {
    width: 150,
    height: 150,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  HeaderRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    paddingVertical: 8,
    flexWrap: 'wrap',
  },
  horizontalContainer: {
    flexDirection: 'row',
    // alignItems: "center",
    justifyContent: 'space-between',
  },
  HeaderItemVStack: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  textPadding: {
    paddingHorizontal: 4,
    paddingVertical: 2,
    flexWrap: 'wrap',
  },
  reactionImage: {
    width: 100,
    height: 100,
  },
  solarplexReactionContainer: {
    paddingHorizontal: 15,
  },
  solarplexReactionImage: {
    width: 100,
    height: 100,
  },
  reactionList: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingHorizontal: 12,
  },
  reaction: {
    borderRadius: 32,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  spacer20: {
    height: 20,
  },
  reactionSelect: {
    borderWidth: 1,
    borderRadius: 1,
    margin: 5,
  },
  displayImageContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: isMobileWeb ? 8 : 12,
  },
  details: {
    textAlign: 'center',
    paddingVertical: 10,
    paddingHorizontal: 14,
    overflow: 'hidden',
    fontWeight: 'bold',
  },
})
