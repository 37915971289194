import React from 'react'
import {View} from 'react-native'

import {
  NativeDropdown,
  DropdownItem as NativeDropdownItem,
} from './NativeDropdown'
import {EventStopper} from '../EventStopper'
import {usePalette} from 'lib/hooks/usePalette'

import {usePhotoLibraryPermission} from 'lib/hooks/usePermissions'
import {useAnalytics} from 'lib/analytics/analytics'
import {GalleryModel} from 'state/models/media/gallery'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {isNative} from 'platform/detection'

type ImgVidDropdownProps = {
  gallery: GalleryModel
  disable: boolean
}

export function ImgVidDropdown({disable, gallery}: ImgVidDropdownProps) {
  // const store = useStores()
  // const theme = useTheme()
  const pal = usePalette('default')
  const {track} = useAnalytics()
  const {requestPhotoAccessIfNeeded} = usePhotoLibraryPermission()
  // const defaultCtrlColor = theme.palette.default.postCtrl

  const dropdownItems: NativeDropdownItem[] = [
    {
      label: 'Image',
      async onPress() {
        if (disable) {
          return
        }

        track('Composer:GalleryOpened')

        if (isNative && !(await requestPhotoAccessIfNeeded())) {
          return
        }

        gallery.pick()
      },
    },
    {
      label: 'Video',
      async onPress() {
        if (disable) {
          return
        }

        if (isNative && !(await requestPhotoAccessIfNeeded())) {
          return
        }

        // Assuming that GalleryModel has a 'pickVideo' method similar to 'pick'
        gallery.pickVideo()
      },
    },
  ]

  return (
    <EventStopper>
      <NativeDropdown
        testID={'ChannelDropdownBtn'}
        items={dropdownItems}
        accessibilityLabel="More post options"
        accessibilityHint="">
        <View style={{}}>
          <FontAwesomeIcon
            icon={['far', 'image']}
            style={pal.link as FontAwesomeIconStyle}
            size={24}
          />
        </View>
      </NativeDropdown>
    </EventStopper>
  )
}

// const styles = StyleSheet.create({
//   channelDropdown: {
//     borderRadius: 100,
//     paddingHorizontal: 8,
//     paddingVertical: 10,
//     flexDirection: 'row',
//     alignItems: 'center',
//     justifyContent: 'space-between',
//     borderColor: '#B0A6D4',
//     borderWidth: 1,
//     backgroundColor: colors.splx.primary[20],
//   },
// })
