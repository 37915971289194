import {makeAutoObservable, runInAction} from 'mobx'
import {RootStoreModel} from 'state/index'
import {ImageModel} from './image'
import {
  Image as RNImage,
  Video as RNVideo,
} from 'react-native-image-crop-picker'
import {openPicker} from 'lib/media/picker'
import {getImageDim} from 'lib/media/manip'
import {isNative} from 'platform/detection'
import {VideoModel} from './video'
import {MediaTypeOptions} from 'expo-image-picker'
import {BASE64_BYTES_FACTOR, MAX_VIDEO_BYTES} from 'lib/constants'
import * as Toast from 'view/com/util/Toast'
export class GalleryModel {
  images: ImageModel[] = []
  videos: VideoModel[] = []

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {
      rootStore: false,
    })
  }

  get isEmpty() {
    return this.size === 0 && this.videoSize === 0
  }

  get size() {
    return this.images.length
  }

  get videoSize() {
    return this.videos.length
  }

  get needsAltText() {
    return this.images.some(image => image.altText.trim() === '')
  }

  async add(image_: Omit<RNImage, 'size'>) {
    if (this.size >= 4) {
      return
    }

    // Temporarily enforce uniqueness but can eventually also use index
    if (!this.images.some(i => i.path === image_.path)) {
      const image = new ImageModel(this.rootStore, image_)

      // Initial resize
      image.manipulate({})
      this.images.push(image)
    }
  }

  async addVideo(video_: RNVideo) {
    if (this.size >= 1) {
      this.videos = []
    }

    // Temporarily enforce uniqueness but can eventually also use index
    if (!this.videos.some(i => i.path === video_.path)) {
      const videoData = {
        path: video_.path,
        width: video_.width,
        height: video_.height,
        duration: video_.duration ?? undefined,
        mime: video_.mime,
      }
      const video = new VideoModel(this.rootStore, videoData)
      this.videos.push(video)
    }
  }

  removeVideo(video: VideoModel) {
    const index = this.videos.findIndex(video_ => video_.path === video.path)
    this.videos.splice(index, 1)
  }

  async edit(image: ImageModel) {
    if (isNative) {
      this.crop(image)
    } else {
      this.rootStore.shell.openModal({
        name: 'edit-image',
        image,
        gallery: this,
      })
    }
  }

  async paste(uri: string) {
    if (this.size >= 4) {
      return
    }

    const {width, height} = await getImageDim(uri)

    const image = {
      path: uri,
      height,
      width,
      mime: 'image/jpeg',
    }

    runInAction(() => {
      this.add(image)
    })
  }

  setAltText(image: ImageModel, altText: string) {
    image.setAltText(altText)
  }

  crop(image: ImageModel) {
    image.crop()
  }

  remove(image: ImageModel) {
    const index = this.images.findIndex(image_ => image_.path === image.path)
    this.images.splice(index, 1)
  }

  async previous(image: ImageModel) {
    image.previous()
  }

  async pick() {
    const images = await openPicker({
      selectionLimit: 4 - this.size,
      allowsMultipleSelection: true,
    })

    return await Promise.all(
      images.map(image => {
        this.add(image)
      }),
    )
  }

  async pickVideo() {
    const videos = await openPicker({
      mediaTypes: MediaTypeOptions.Videos,
      selectionLimit: 1, // Assuming we are only picking one video at a time
    })
    return await Promise.all(
      videos.map(video => {
        if (BASE64_BYTES_FACTOR * video.path.length > MAX_VIDEO_BYTES) {
          Toast.show('Video too large, 10 MB limit.')
          return
        }
        const adjustedVideo = {
          ...video,
          duration: video.duration ?? null,
          size: video.size ?? 0,
        }
        this.addVideo(adjustedVideo)
      }),
    )
  }
}
