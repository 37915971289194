/*
 * Note: the dataSet properties are used to leverage custom CSS in public/index.html
 */

import React, {useState, useEffect} from 'react'
import {
  StyleProp,
  StyleSheet,
  Text,
  View,
  ViewStyle,
  TextStyle,
} from 'react-native'
import {usePalette} from 'lib/hooks/usePalette'
import {useTheme} from 'lib/ThemeContext'

const DURATION = 3500

interface ActiveToast {
  text: string
  styles?: {
    containerStyle?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<TextStyle>
  }
}
type GlobalSetActiveToast = (_activeToast: ActiveToast | undefined) => void

// globals
// =
let globalSetActiveToast: GlobalSetActiveToast | undefined
let toastTimeout: NodeJS.Timeout | undefined

// components
// =
type ToastContainerProps = {
  containerStyle?: StyleProp<ViewStyle>
  labelStyle?: StyleProp<TextStyle>
}
export const ToastContainer: React.FC<ToastContainerProps> = ({}) => {
  const pal = usePalette('default')
  const theme = useTheme()
  const [activeToast, setActiveToast] = useState<ActiveToast | undefined>()
  useEffect(() => {
    globalSetActiveToast = (t: ActiveToast | undefined) => {
      setActiveToast(t)
    }
  })
  return (
    <>
      {activeToast && (
        <View
          style={[
            styles.container,
            {backgroundColor: theme.colorScheme === 'dark' ? 'white' : 'black'},
            activeToast.styles?.containerStyle,
          ]}>
          <Text
            style={[
              pal.text,
              styles.text,
              {color: theme.colorScheme === 'dark' ? 'black' : 'white'},
              activeToast.styles?.labelStyle,
            ]}>
            {activeToast.text}
          </Text>
        </View>
      )}
    </>
  )
}

// methods
// =
export function show(
  text: string,
  styles?: {
    containerStyle?: StyleProp<ViewStyle>
    labelStyle?: StyleProp<TextStyle>
  },
  duration: number = DURATION,
) {
  if (toastTimeout) {
    clearTimeout(toastTimeout)
  }
  globalSetActiveToast?.({text, styles})
  toastTimeout = setTimeout(() => {
    globalSetActiveToast?.(undefined)
  }, duration)
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 20,
    bottom: 20,
    // @ts-ignore web only
    width: 'calc(100% - 40px)',
    maxWidth: 350,
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
  },
  icon: {
    color: '#fff',
  },
  text: {
    color: '#fff',
    fontSize: 18,
    marginLeft: 10,
  },
})
