import {makeAutoObservable, runInAction} from 'mobx'

import {ImageModel} from './image'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {RootStoreModel} from 'state/index'
import {getImageDim} from 'lib/media/manip'
import {isNative} from 'platform/detection'
import {openPicker} from 'lib/media/picker'
import {BlobRef} from '@usedispatch/atproto-api'
import * as apilib from 'lib/api/index'
import {sleep} from 'lib/splx-utils/timers'
import {actions} from '../actions'
import {VideoModel} from './video'
import {MediaTypeOptions} from 'expo-image-picker'
import {
  BASE64_BYTES_FACTOR,
  MAX_VIDEO_BYTES,
  SOLARPLEX_FEED_API,
} from 'lib/constants'
import uploadVideo from 'lib/splx-utils/gaslessArUpload'
import * as Toast from 'view/com/util/Toast'

type createRewardAndMissionParams = {
  missionTitle: string
  missionDesc: string
  missionImageUrl: string
  missionCreatorDid: string
  rewardTitle: string
  rewardDesc: string
  rewardCollectibleCid: string
  rewardCollectibleUri: string
}

export interface CreateOrUpdateMissionInput {
  title: string
  description: string
  image_url: string
  creator_did: string
  goal_points: number
  reward_collectibles: any[]
  mission_mechanic: string
  duration?: number
  missions?: string[]
  ui_active: boolean
}

export interface CreateRewardAndMissionInput {
  mission: Omit<CreateOrUpdateMissionInput, 'reward_collectibles'>
  reward: CreateRewardInput
}
export interface CreateRewardInput {
  title: string
  description: string
  // random_array or exactly
  reward_mechanic: string
  collectibles: RewardCollectibleInput[]
}

export interface RewardCollectibleInput {
  // atproto cid of collectible
  collectible_cid: string
  // atproto uri of collectible
  collectible_uri: string
  // probability of collectible being rewarded
  probability: number
}

export class NFTGalleryModel {
  name = ''
  description = ''
  mintId = ''
  supply = 10
  uiPrice = 0.01
  uri = ''
  attributes: Record<string, string> = {}
  // can only one have one image.
  // restricted below.
  images: ImageModel[] = []
  imageBlobs: BlobRef[] = []
  animationUri: string = ''
  imageCid: string = ''
  isTypePaid: boolean = true
  isAddAnimationUri: boolean = false
  isSupplyOne: boolean = false
  video?: VideoModel
  innerCircleReward?: VideoModel | ImageModel

  constructor(public rootStore: RootStoreModel) {
    makeAutoObservable(this, {
      rootStore: false,
    })
  }
  get isEmpty() {
    return this.size === 0
  }

  get size() {
    return this.images.length
  }

  get needsAltText() {
    return this.images.some(image => image.altText.trim() === '')
  }

  get needsMetadata() {
    return !this.name || !this.description || !this.mintId || !this.supply
  }

  setTrait(key: string, value: string) {
    this.attributes[key] = value
  }

  removeTrait(key: string) {
    delete this.attributes[key]
  }

  toggleSupplyOne() {
    this.isSupplyOne = !this.isSupplyOne
    if (this.isSupplyOne) {
      this.supply = 1
    }
  }

  toggleAddAnimationUri() {
    this.isAddAnimationUri = !this.isAddAnimationUri
    if (!this.isAddAnimationUri) {
      this.animationUri = ''
    }
  }

  toggleTypeIsPaid() {
    this.isTypePaid = !this.isTypePaid
    if (!this.isTypePaid) {
      this.uiPrice = 0.01
    }
  }

  setAnimationUri(uri: string) {
    this.animationUri = uri
  }

  setUri(uri: string) {
    this.uri = uri
  }

  setUiPrice(price: number) {
    this.uiPrice = price
  }

  setName(name: string) {
    this.name = name
  }

  setDescription(description: string) {
    this.description = description
  }

  setMintId(mintId: string) {
    this.mintId = mintId
  }

  setSupply(supply: number) {
    this.supply = supply
  }

  setNftBlob(blob: BlobRef) {
    this.imageBlobs.push(blob)
  }

  async add(image_: Omit<RNImage, 'size'>) {
    if (this.size > 1) {
      return
    }

    // Temporarily enforce uniqueness but can eventually also use index
    if (!this.images.some(i => i.path === image_.path)) {
      const image = new ImageModel(this.rootStore, image_)

      // Initial resize
      image.manipulate({})
      this.images.push(image)
    }
  }

  async edit(image: ImageModel) {
    if (isNative) {
      this.crop(image)
    } else {
      this.rootStore.shell.openModal({
        name: 'edit-image',
        image,
        gallery: this,
      })
    }
  }

  async paste(uri: string) {
    if (this.size > 1) {
      return
    }

    const {width, height} = await getImageDim(uri)

    const image = {
      path: uri,
      height,
      width,
      mime: 'image/jpeg',
    }

    runInAction(() => {
      this.add(image)
    })
  }

  setAltText(image: ImageModel, altText: string) {
    image.setAltText(altText)
  }

  crop(image: ImageModel) {
    image.crop()
  }

  remove(image: ImageModel) {
    const index = this.images.findIndex(image_ => image_.path === image.path)
    this.images.splice(index, 1)
  }

  async previous(image: ImageModel) {
    image.previous()
  }

  async pick() {
    const images = await openPicker({
      selectionLimit: 1 - this.size,
      allowsMultipleSelection: true,
    })

    return await Promise.all(
      images.map(image => {
        this.add(image)
      }),
    )
  }

  isRewardUploaded() {
    if (this.innerCircleReward === undefined) {
      return false
    }
    return true
  }

  isInnerCircleRewardImage() {
    return this.innerCircleReward instanceof ImageModel
  }

  isInnerCircleRewardVideo() {
    return this.innerCircleReward instanceof VideoModel
  }

  async pickAny() {
    const imageOrVideo = await openPicker({
      mediaTypes: MediaTypeOptions.All,
      selectionLimit: 1,
    })

    if (imageOrVideo[0].mime?.startsWith('video')) {
      const duration =
        imageOrVideo[0].duration != null ? imageOrVideo[0].duration : undefined
      const video = new VideoModel(this.rootStore, {
        path: imageOrVideo[0].path,
        width: imageOrVideo[0].width,
        height: imageOrVideo[0].height,
        duration: duration,
      })
      runInAction(() => {
        this.innerCircleReward = video
      })
    } else if (imageOrVideo[0].mime?.startsWith('image')) {
      const image = {
        path: imageOrVideo[0].path,
        height: imageOrVideo[0].height,
        width: imageOrVideo[0].width,
        mime: imageOrVideo[0].mime,
      }
      const imageModel = new ImageModel(this.rootStore, image)
      this.innerCircleReward = imageModel
      runInAction(() => {
        this.innerCircleReward = imageModel
      })
    }
  }

  async pickVideo() {
    const videos = await openPicker({
      mediaTypes: MediaTypeOptions.Videos, // Specify that we want to pick videos
      selectionLimit: 1, // Only one video
    })

    // Assuming the first (and only) video in the array is the one we want
    if (videos.length > 0) {
      const video = videos[0]
      runInAction(() => {
        // Ensure duration is a number or undefined, but never null
        const duration = video.duration != null ? video.duration : undefined
        if (BASE64_BYTES_FACTOR * video.path.length > MAX_VIDEO_BYTES) {
          Toast.show('Video too large, 10 MB limit.')
          return
        }
        // Create a new VideoModel instance with the video data
        this.video = new VideoModel(this.rootStore, {
          path: video.path,
          width: video.width,
          height: video.height,
          duration: duration,
        })
      })
    }
  }

  async createVideoNft(): Promise<{
    status: 'success' | 'error'
    res: {
      uri: string
      cid: string
    }
    createError?: string
  }> {
    try {
      if (!this.video?.path) {
        throw new Error('No Video Found')
      }

      const img = this.images[0]
      await img.compress()
      const path = img.compressed?.path ?? img.path
      const blobRes = await apilib.uploadBlob(
        this.rootStore,
        path,
        'image/jpeg',
      )
      this.setNftBlob(blobRes.data.blob)
      const imageCid = blobRes.data.blob.ref.toString()
      const uri = await uploadVideo(this.video.path, [])

      this.setAnimationUri(uri)

      this.imageCid = imageCid
      const collectionId = `${
        this.rootStore.session.currentSession?.did
      }-${imageCid}-${Date.now()}`

      const walletAddress = this.rootStore.creatorTools.creator?.royalty_address
      if (walletAddress === undefined) {
        throw new Error('Wallet address is undefined')
      }

      if (this.uiPrice === 0 && this.isTypePaid) {
        throw new Error('Price cannot be 0 SOL')
      }

      if (this.uiPrice > 1000) {
        throw new Error('Price cannot be greater than 1000 SOL')
      }

      if (this.supply > 10000) {
        throw new Error('Supply cannot be greater than 10000')
      }
      this.setTrait('collection', 'solarplex')

      const collectionRes = await apilib.createCollection(this.rootStore, {
        title: this.name,
        description: this.description,
        supply: this.supply,
        image: blobRes.data.blob,
        collectionId: collectionId,
        attributes: JSON.stringify(this.attributes),
        wallet: walletAddress,
        imageCid: imageCid,
        isPaid: this.isTypePaid,
        animationUrl: this.animationUri,
      })
      if (this.uiPrice && this.isTypePaid) {
        const price = this.uiPrice * 10 ** 9
        await this._fetchCollectible(collectionRes.data.cid)
        await apilib.createPaymentCollection(this.rootStore, {
          price: price,
          subjectCid: collectionRes.data.cid,
          wallet: walletAddress,
        })
      }

      return {
        // other things from our V1 API
        status: 'success',
        res: {
          uri: collectionRes.data.uri,
          cid: collectionRes.data.cid,
        },
      }
    } catch (err) {
      console.log('NFT Gallery Video error', err)
      return {
        status: 'error',
        res: {
          uri: '',
          cid: '',
        },
        createError:
          err instanceof Error ? err.toString() : 'An error occurred',
      }
    }
  }

  createRewardAndMission = actions.wrapAction(
    async (params: createRewardAndMissionParams) => {
      try {
        const url = `${SOLARPLEX_FEED_API}/splx/createRewardAndMission`
        const body: CreateRewardAndMissionInput = {
          mission: {
            title: params.missionTitle,
            description: params.missionDesc,
            image_url: params.missionImageUrl,
            creator_did: params.missionCreatorDid,
            goal_points: 100,
            mission_mechanic: 'weekly',
            duration: 7,
            ui_active: true,
          },
          reward: {
            title: params.rewardTitle,
            description: params.rewardDesc,
            reward_mechanic: 'random_array',
            collectibles: [
              {
                collectible_cid: params.rewardCollectibleCid,
                collectible_uri: params.rewardCollectibleUri,
                probability: 1,
              },
            ],
          },
        }
        const response = await this.rootStore.api.post(url, {body})

        if (!response || this.rootStore.api.postError(url, {body})) {
          return
        }
        return response
      } catch (error) {
        console.log('Error in createRewardAndMission', error)
        throw new Error(`Error in createRewardAndMission: ${error}`)
      }
    },
    this,
    'createRewardAndMission',
  )

  clearInnerCircleReward() {
    this.innerCircleReward = undefined
    this.rootStore.shell.closeModal()
  }

  async createInnerCircleReward(): Promise<{
    status: 'success' | 'error'
    res: {
      uri: string
      cid: string
    }
    createError?: string
  }> {
    let res = {
      uri: '',
      cid: '',
    }

    try {
      const walletAddress = this.rootStore.creatorTools.creator?.royalty_address
      if (walletAddress === undefined) {
        throw new Error('Wallet address is undefined')
      }
      if (this.innerCircleReward instanceof ImageModel) {
        const img = this.innerCircleReward
        await img.compress()
        const path = img.compressed?.path ?? img.path
        const blobRes = await apilib.uploadBlob(
          this.rootStore,
          path,
          'image/jpeg',
        )
        this.setNftBlob(blobRes.data.blob)
        const imageCid = blobRes.data.blob.ref.toString()
        this.imageCid = imageCid
        const collectionId = `${
          this.rootStore.session.currentSession?.did
        }-${imageCid}-${Date.now()}`

        this.validateNftCreationParameters()
        this.setTrait('collection', 'solarplex')
        this.setTrait('mission', 'inner_circle')
        const collectionRes = await apilib.createCollection(this.rootStore, {
          title: this.name,
          description: this.description,
          supply: this.supply,
          image: blobRes.data.blob,
          collectionId: collectionId,
          attributes: JSON.stringify(this.attributes),
          wallet: walletAddress,
          imageCid: imageCid,
          isPaid: false,
          animationUrl: this.animationUri,
        })
        res = {
          uri: collectionRes.data.uri,
          cid: collectionRes.data.cid,
        }
      } else if (this.innerCircleReward instanceof VideoModel) {
        // need to replace this with the actual cover image
        const img = this.images[0]

        if (img === undefined) {
          throw new Error('No Cover Image Found')
        }

        await img.compress()
        const path = img.compressed?.path ?? img.path
        const blobRes = await apilib.uploadBlob(
          this.rootStore,
          path,
          'image/jpeg',
        )

        this.setNftBlob(blobRes.data.blob)
        const imageCid = blobRes.data.blob.ref.toString()
        const uri = await uploadVideo(this.innerCircleReward.path, [])
        this.setAnimationUri(uri)
        this.imageCid = imageCid
        const collectionId = `${
          this.rootStore.session.currentSession?.did
        }-${imageCid}-${Date.now()}`

        this.validateNftCreationParameters()
        this.setTrait('mission', 'inner_circle')
        const collectionRes = await apilib.createCollection(this.rootStore, {
          title: this.name,
          description: this.description,
          supply: this.supply,
          image: blobRes.data.blob,
          collectionId: collectionId,
          attributes: JSON.stringify(this.attributes),
          wallet: walletAddress,
          imageCid: imageCid,
          isPaid: false,
          animationUrl: this.animationUri,
        })
        res = {
          uri: collectionRes.data.uri,
          cid: collectionRes.data.cid,
        }
      }

      await this.createRewardAndMission({
        missionTitle: `${this.rootStore.me.displayName} Mission - ${this.name}`,
        missionCreatorDid: this.rootStore.me.did,
        missionDesc: `Collect art by ${this.rootStore.me.displayName} to unlock their legendary edition!`,
        missionImageUrl: this.rootStore.me.avatar,
        rewardCollectibleCid: res.cid,
        rewardCollectibleUri: res.uri,
        rewardTitle: this.name,
        rewardDesc: this.description,
      })
    } catch (err) {
      return {
        status: 'error',
        res: {
          uri: '',
          cid: '',
        },
        createError:
          err instanceof Error ? err.toString() : 'An error occurred',
      }
    }

    this.rootStore.creatorTools.fetchCollectibles()

    return {
      status: 'success',
      res: {
        uri: res.uri,
        cid: res.cid,
      },
    }
  }

  private validateNftCreationParameters() {
    if (this.uiPrice === 0 && this.isTypePaid) {
      throw new Error('Price cannot be 0 SOL')
    }

    if (this.uiPrice > 1000) {
      throw new Error('Price cannot be greater than 1000 SOL')
    }

    if (this.supply > 10000) {
      throw new Error('Supply cannot be greater than 10000')
    }
  }

  async createNft(): Promise<{
    status: 'success' | 'error'
    res: {
      uri: string
      cid: string
    }
    createError?: string
  }> {
    try {
      const img = this.images[0]
      await img.compress()
      const path = img.compressed?.path ?? img.path
      const blobRes = await apilib.uploadBlob(
        this.rootStore,
        path,
        'image/jpeg',
      )
      this.setNftBlob(blobRes.data.blob)
      const imageCid = blobRes.data.blob.ref.toString()
      this.imageCid = imageCid
      const collectionId = `${
        this.rootStore.session.currentSession?.did
      }-${imageCid}-${Date.now()}`

      const walletAddress = this.rootStore.creatorTools.creator?.royalty_address

      if (walletAddress === undefined) {
        throw new Error('Wallet address is undefined')
      }

      if (this.uiPrice === 0 && this.isTypePaid) {
        throw new Error('Price cannot be 0 SOL')
      }

      if (this.uiPrice > 1000) {
        throw new Error('Price cannot be greater than 1000 SOL')
      }

      if (this.supply > 10000) {
        throw new Error('Supply cannot be greater than 10000')
      }

      this.setTrait('collection', 'solarplex')

      const collectionRes = await apilib.createCollection(this.rootStore, {
        title: this.name,
        description: this.description,
        supply: this.supply,
        image: blobRes.data.blob,
        collectionId: collectionId,
        attributes: JSON.stringify(this.attributes),
        wallet: walletAddress,
        imageCid: imageCid,
        isPaid: this.isTypePaid,
        animationUrl: this.animationUri,
      })
      if (this.uiPrice && this.isTypePaid) {
        const price = this.uiPrice * 10 ** 9
        await this._fetchCollectible(collectionRes.data.cid)
        await apilib.createPaymentCollection(this.rootStore, {
          price: price,
          subjectCid: collectionRes.data.cid,
          wallet: walletAddress,
        })
      }
      return {
        // other things from our V1 API
        status: 'success',
        res: {
          uri: collectionRes.data.uri,
          cid: collectionRes.data.cid,
        },
      }
    } catch (err) {
      return {
        status: 'error',
        res: {
          uri: '',
          cid: '',
        },
        createError:
          err instanceof Error ? err.toString() : 'An error occurred',
      }
    }
  }

  _fetchCollectible: any = actions.wrapAction(
    async (collectibleCid: string) => {
      try {
        await sleep(250)
        const response = await this.rootStore.appviewAgent.getCollectible({
          collectibleCid,
        })
        return response.data
      } catch (err) {
        console.log('err', err)
        return await this._fetchCollectible(collectibleCid)
      }
    },
    this,
    '_fetchMissions',
  )
}
