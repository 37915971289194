import {ImageStyle, Keyboard} from 'react-native'
import {StyleSheet, TouchableOpacity, View} from 'react-native'
import {colors, s} from 'lib/styles'

import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Image} from 'expo-image'
import {NFTGalleryModel} from 'state/models/media/nft-gallery'
import React from 'react'
import {Text} from 'view/com/util/text/Text'
import {isDesktopWeb} from 'platform/detection'
import {observer} from 'mobx-react-lite'
import {openAltTextModal} from 'lib/media/alt-text'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from 'state/index'

interface Props {
  gallery: NFTGalleryModel
}

export const NFTGallery = observer(function Component({gallery}: Props) {
  const store = useStores()
  const pal = usePalette('default')

  const splxRemoveClickableOnImage = true

  let side: number

  if (gallery.size === 1) {
    side = 250
  } else {
    side = (isDesktopWeb ? 560 : 350) / gallery.size
  }

  const imageStyle = {
    height: side,
    width: side,
  }

  const isOverflow = !isDesktopWeb && gallery.size > 2

  const altTextControlStyle = isOverflow
    ? {
        left: 4,
        bottom: 4,
      }
    : isDesktopWeb && gallery.size < 3
    ? {
        left: 8,
        top: 8,
      }
    : {
        left: 4,
        top: 4,
      }

  const imageControlsStyle = {
    display: 'flex' as const,
    flexDirection: 'row' as const,
    position: 'absolute' as const,
    ...(isOverflow
      ? {
          top: 4,
          right: 4,
          gap: 4,
        }
      : isDesktopWeb && gallery.size < 3
      ? {
          top: 8,
          right: 8,
          gap: 8,
        }
      : {
          top: 4,
          right: 4,
          gap: 4,
        }),
    zIndex: 1,
  }

  return !gallery.isEmpty ? (
    <>
      <View testID="selectedPhotosView" style={styles.gallery}>
        {gallery.images.map(image => (
          <View key={`selected-image-${image.path}`} style={[imageStyle]}>
            <TouchableOpacity
              testID="altTextButton"
              accessibilityRole="button"
              accessibilityLabel="Add nft metadata"
              accessibilityHint=""
              onPress={() => {
                Keyboard.dismiss()
                //openAltTextModal(store, image)
                store.shell.openModal({
                  name: 'nft-meta',
                  image: image,
                  gallery: gallery,
                })
              }}
              style={[styles.altTextControl, altTextControlStyle]}>
              <Text style={styles.altTextControlLabel} accessible={false}>
                Edit NFT
              </Text>
              {image.altText.length > 0 ? (
                <FontAwesomeIcon
                  icon="check"
                  size={10}
                  style={{color: colors.green3}}
                />
              ) : undefined}
            </TouchableOpacity>
            <View style={imageControlsStyle}>
              <TouchableOpacity
                testID="editPhotoButton"
                accessibilityRole="button"
                accessibilityLabel="Edit image"
                accessibilityHint=""
                onPress={() => gallery.edit(image)}
                style={styles.imageControl}>
                <FontAwesomeIcon
                  icon="pen"
                  size={12}
                  style={{color: colors.white}}
                />
              </TouchableOpacity>
              <TouchableOpacity
                testID="removePhotoButton"
                accessibilityRole="button"
                accessibilityLabel="Remove image"
                accessibilityHint=""
                onPress={() => gallery.remove(image)}
                style={styles.imageControl}>
                <FontAwesomeIcon
                  icon="xmark"
                  size={16}
                  style={{color: colors.white}}
                />
              </TouchableOpacity>
            </View>
            {!splxRemoveClickableOnImage && (
              <TouchableOpacity
                accessibilityRole="button"
                accessibilityLabel="Add alt text"
                accessibilityHint=""
                onPress={() => {
                  Keyboard.dismiss()
                  openAltTextModal(store, image)
                }}
                style={styles.altTextHiddenRegion}
              />
            )}
            <Image
              testID="selectedPhotoImage"
              style={[styles.image, imageStyle] as ImageStyle}
              source={{
                uri: image.cropped?.path ?? image.path,
              }}
              accessible={true}
              accessibilityIgnoresInvertColors
            />
          </View>
        ))}
      </View>
      <View style={[styles.reminder]}>
        <View style={[styles.infoIcon, pal.viewLight]}>
          <FontAwesomeIcon icon="info" size={12} color={pal.colors.text} />
        </View>
        <Text type="sm" style={[pal.textLight, s.flex1]}>
          You are creating a new NFT. Please click the NFT button to add the
          required metadata to mint.
        </Text>
      </View>
    </>
  ) : null
})

//TODO(pratik)
// - nft and image can't be selected in the same post. if a user selects image and then tries to add nft, or vice versa - they can't. disable the image icon if nft is added and vice versa.
// - change the icon of the second gallery image to be nft. ask robert for a good one.
// - the composer post icon should be disabled till we have all f the nft metadata populated.
// - when someone clicks the nft text, show the dialog box that is currently used for alt text. use it to get 2 fields: name and description.
// - once the user fills this in, show a mint button.
// - this mint button should call async NFTGallery.mint() to get the mintId. now you have image, name, description and mintId.
// - use this to setNftMeta state. see TODO(pratik) in Composer.txt near const [nftdata] = useState().
// - once you have nftData, you should be able to create a new embed as usual.
// - what happens if so licks X (removes from the gallery?)
//    - we have to show a list of minted nfts in your profile. for now, all mints should be shown in the wallet section with a share button. so you can mint + not share yet.

const styles = StyleSheet.create({
  gallery: {
    flex: 1,
    flexDirection: 'row',
    gap: 8,
    marginTop: 16,
  },
  image: {
    resizeMode: 'cover',
    borderRadius: 8,
  },
  imageControl: {
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    alignItems: 'center',
    justifyContent: 'center',
  },
  altTextControl: {
    position: 'absolute',
    zIndex: 1,
    borderRadius: 6,
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    paddingHorizontal: 8,
    paddingVertical: 3,
    flexDirection: 'row',
    alignItems: 'center',
  },
  altTextControlLabel: {
    color: 'white',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: 1,
  },
  altTextHiddenRegion: {
    position: 'absolute',
    left: 4,
    right: 4,
    bottom: 4,
    top: 30,
    zIndex: 1,
  },

  reminder: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    borderRadius: 8,
    paddingVertical: 14,
  },
  infoIcon: {
    width: 22,
    height: 22,
    borderRadius: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
})
