import {WebIrys} from '@irys/sdk'
import {HELIUS_RPC_API} from 'lib/constants'

type Tag = {
  name: string
  value: string
}

const uploadVideo = async (filePath: string, tags: Tag[]): Promise<string> => {
  // Obtain the server's public key
  const pubKeyRes = (await (
    await fetch('/api/publicKeySol')
  ).json()) as unknown as {
    pubKey: string
  }
  const pubKey = Buffer.from(pubKeyRes.pubKey, 'hex')
  console.log('got pubKey=', pubKey)
  // Create a provider
  const provider = {
    publicKey: {
      toBuffer: () => pubKey,
      byteLength: 32,
    },
    signMessage: async (message: Uint8Array) => {
      let convertedMsg = Buffer.from(message).toString('hex')
      const res = await fetch('/api/signDataSol', {
        method: 'POST',
        body: JSON.stringify({
          signatureData: convertedMsg,
        }),
      })
      const {signature} = await res.json()
      const bSig = Buffer.from(signature, 'hex')
      return bSig
    },
  }

  // Create a new WebIrys object using the provider created with server info.
  const wallet = {
    rpcUrl: HELIUS_RPC_API,
    name: 'solana',
    provider: provider,
  }
  const irys = new WebIrys({
    network: 'mainnet', // "mainnet" || "devnet"
    token: 'solana',
    wallet,
  })

  await irys.ready()
  console.log('WebIrys=', irys)

  console.log('Uploading...')

  const selectedFile = new File(
    [await fetch(filePath).then(r => r.blob())],
    'video.mp4',
    {type: 'video/mp4'},
  )

  const tx = await irys.uploadFile(selectedFile, {
    tags,
  })
  console.log(`Uploaded successfully. https://gateway.irys.xyz/${tx.id}`)

  return `https://gateway.irys.xyz/${tx.id}`
}

export default uploadVideo
