import React from 'react'

import {Text} from '../text/Text'
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native'
import {usePalette} from 'lib/hooks/usePalette'
import {AppBskyEmbedNft, AppBskyActorDefs} from '@usedispatch/atproto-api'
import {isDesktopWeb, isMobileWeb} from 'platform/detection'
import {Button} from '../forms/Button'
import {s} from 'lib/styles'
import {colors} from 'lib/styles'
import {useStores} from 'state/index'
import {useSplxWallet} from 'view/com/wallet/useSplxWallet'
import * as Toast from '../../util/Toast'
import {observer} from 'mobx-react-lite'
import {LoadingIndicator} from 'lib/icons'
import {track} from 'lib/analytics/analytics'
import {Link} from '../Link'
import {UserAvatar} from '../UserAvatar'
import {useRequireAuth} from 'view/com/auth/withAuthRequired'
import {AutoSizedImage} from '../images/AutoSizedImage'

// this is the Old one we dont use this anymore the new one is CollectibleEmbed
export const NFTEmbed = observer(function Component({
  author,
  embed,
  imageChild,
  itemUri,
  itemCid,
  text,
  indexedAt,
}: {
  author?: AppBskyActorDefs.ProfileViewBasic | any
  embed: AppBskyEmbedNft.View
  imageChild?: React.ReactNode
  style?: StyleProp<ViewStyle>
  itemUri?: string
  itemCid?: string
  text?: string
  indexedAt?: string
}) {
  const pal = usePalette('default')
  const store = useStores()
  const [buyingNft, setBuyingNft] = React.useState(false)
  const [shouldShare, setShouldShare] = React.useState(false)
  const [
    _visible,
    setVisible,
    _linkedWallet,
    _connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
  ] = useSplxWallet(false)
  const requireAuth = useRequireAuth(author?.handle)

  function shareNft() {
    const sharingText = author?.handle
      ? `I just minted ${embed.nft.name} created by @${author?.handle} on Solarplex!`
      : `I just minted ${embed.nft.name} on Solarplex!`

    if (itemUri && itemCid && indexedAt && author) {
      store.shell.openComposer({
        isSharing: true,
        sharingText: sharingText,
        quote: {
          uri: itemUri,
          author: {
            did: author?.did,
            handle: author?.handle,
            avatar: author?.avatar,
          },
          cid: itemCid,
          text: text || sharingText,
          indexedAt: indexedAt,
        },
      })
    }

    setShouldShare(false)
    track('NFT:NFTShare', {
      collectionId: embed.nft.mintId,
      buyer: store.session.currentSession?.handle,
    })
  }

  async function handleBuyNft() {
    requireAuth(async () => {
      setVisible(true)
      await store.wallet.waitForWalletConnect()

      try {
        if (!store.wallet.state.connectedWalletId || !embed.nft.mintId) {
          track('NFT:NFTBuyError', {
            collectionId: embed.nft.mintId,
            buyer: store.session.currentSession?.handle,
            error: 'No wallet connected',
          })
          throw new Error('No wallet connected')
        }
        const claimResponse = await store.me.nft.claimNftOld(
          store.wallet.state.connectedWalletId,
          embed.nft.mintId,
        )
        if (!claimResponse.actionId) {
          Toast.show(`${claimResponse.message.split('_').join(' ')}`)
          setBuyingNft(false)
          track('NFT:NFTBuyError', {
            collectionId: embed.nft.mintId,
            buyer: store.session.currentSession?.handle,
            error: claimResponse.message,
          })
          return
        }
        track('NFT:NFTBuySuccess', {
          collectionId: embed.nft.mintId,
          buyer: store.session.currentSession?.handle,
          title: embed.nft.name,
          description: embed.nft.description,
          supply: embed.nft.supply,
        })
        setBuyingNft(false)
        setShouldShare(true)
        Toast.show(`Purchase successful!`)
      } catch (e) {
        track('NFT:NFTBuyError', {
          collectionId: embed.nft.mintId,
          buyer: store.session.currentSession?.handle,
          error: e,
        })
        setBuyingNft(false)
        console.error(e)
        Toast.show('Error buying NFT')
      }
    })
  }

  return (
    <View style={[pal.border, styles.extContainer]}>
      <Text
        type="sm"
        numberOfLines={isDesktopWeb ? 2 : 4}
        style={[
          pal.text,
          {color: colors.white},
          styles.extBold,
          styles.extNFTMeta,
        ]}>
        {embed.nft.name} {embed.nft.name ? `` : ''}
      </Text>
      {/* {link.thumb ? ( */}
      {/* <Link href={link.uri}> */}
      <View style={[pal.borderDark, styles.extImageContainer]}>
        <AutoSizedImage
          style={[styles.extImage, isMobileWeb && styles.singleImageMobile]}
          uri={embed.nft.image}
        />
        {imageChild}
      </View>
      {/* </Link> */}
      {/* ) : undefined} */}
      <View style={[pal.borderDark, {borderWidth: 0.5}, styles.extInner]}>
        {store.session.hasSession && (
          <Link href="/profile/solarplex.live.solarplex.xyz">
            <UserAvatar
              avatar={
                'https://appview.solarplex.xyz/image/avatar/plain/did:plc:4srpaai54v3d35bigtfbtbd5/bafkreidfbgdswcssmcjtzo5dmezyyurljn5trlcbfbvnpwl7q3am6yxbdy@jpeg'
              }
              size={32}
            />
          </Link>
        )}
        {/* {metaDescription ? ( */}
        <View style={[pal.borderDark, s.flexRow, {marginLeft: 'auto'}]}>
          {/* {linkMeta && linkMeta.maxSupply > 1 ? (
              <Text
                type="md"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, styles.extDescription]}>
                {linkMeta.supply} / {linkMeta.maxSupply} NFT
                {linkMeta.supply > 1 ? 's' : ''}
              </Text>
            ) : undefined} */}
          <Text
            type="md"
            numberOfLines={isDesktopWeb ? 2 : 4}
            style={[pal.text, styles.extDescription]}>
            FREE!
          </Text>
          {/* {!linkMeta ? (
              <LoadingIndicator />
            ) : !linkMeta.error ? ( */}
          {/* <Text style={[pal.text, {marginLeft: 'auto'}]}>FREE!</Text> */}
          {!shouldShare ? (
            <Button
              labelStyle={{
                fontWeight: '700',
                color: colors.white,
              }}
              withLoading={true}
              type="primary"
              label={buyingNft ? '' : 'Mint Collectible'}
              onPress={async () => {
                track('NFT:NFTBuyClick', {
                  collectionId: embed.nft.mintId,
                  buyer: store.session.currentSession?.handle,
                })
                setBuyingNft(true)
                await handleBuyNft()
              }}>
              {buyingNft ? <LoadingIndicator /> : ''}
            </Button>
          ) : (
            <Button
              labelStyle={{
                fontWeight: '700',
                color: colors.white,
              }}
              withLoading={true}
              type="primary"
              label={'Share Collectible'}
              onPress={shareNft}
            />
          )}
          {/* ) : (
              <Text
                type="md"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, styles.extDescription, styles.extBold]}>
                SOLD OUT!
              </Text>
            )} */}
        </View>
        {/* // ) : undefined} */}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  extContainer: {
    flexDirection: 'column',
  },
  extNFTMeta: {
    marginBottom: -25,
    marginLeft: 4,
    zIndex: 1,
    backgroundColor: 'rgba(38, 39, 45, 0.6)',
    paddingRight: 8,
    paddingLeft: 8,
    paddingTop: 4,
    paddingBottom: 4,
    width: 'auto',
    borderEndStartRadius: 5,
    borderEndEndRadius: 5,
    alignSelf: 'flex-start',
  },
  extInner: {
    paddingHorizontal: isDesktopWeb ? 14 : 10,
    paddingTop: 8,
    paddingBottom: 10,
    flex: isDesktopWeb ? 1 : undefined,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  extImageContainer: {
    borderWidth: 0.5,
    width: '100%',
    maxHeight: 1000,
    overflow: 'hidden',
  },
  extImage: {
    width: '100%',
    maxHeight: 1000,
  },
  extUri: {
    marginTop: 2,
  },
  extDescription: {
    margin: 'auto',
    paddingRight: 8,
  },
  extBold: {
    fontWeight: 'bold',
  },
  singleImageMobile: {
    width: '100%',
    maxHeight: 500,
  },
})
