import {StyleSheet, View} from 'react-native'
import {useFocusEffect, useNavigation} from '@react-navigation/native'

import {NavigationProp} from 'lib/routes/types'
import React from 'react'
import {Text} from '../util/text/Text'
import {TouchableOpacity} from 'react-native-gesture-handler'
import {colors, s} from 'lib/styles'
import {observer} from 'mobx-react-lite'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from 'state/index'
import {PointsProgress, WeeklyStreakProgress} from './MissionsContainer'
import {ProgressBar} from './ProgressBar'

export const RewardsCardSidebar = observer(function RewardsCardSidebarImpl({
  userId,
}: {
  userId: string
}) {
  const store = useStores()

  const pal = usePalette('default')
  const dailyMissionId = store.rewards.dailyMissionId(userId)
  const weeklyMissionId = store.rewards.weeklyMissionId(userId)

  const navigation = useNavigation<NavigationProp>()
  const dailyProgress = store.rewards.missionProgress(userId, dailyMissionId)
  const weeklyProgress = store.rewards.missionProgress(userId, weeklyMissionId)
  const shouldClaimDaily = store.rewards.shouldClaimMission(
    userId,
    dailyMissionId,
  )

  const hasClaimedWeekly = store.rewards.hasClaimedMission(
    userId,
    weeklyMissionId,
  )
  const hasClaimedDaily = store.rewards.hasClaimedMission(
    userId,
    dailyMissionId,
  )
  const shouldClaimWeekly = store.rewards.shouldClaimMission(
    userId,
    weeklyMissionId,
  )
  const isDailyClaimWaitingOnBlockchain =
    store.rewards.isClaimWaitingOnBlockchain(userId, dailyMissionId)

  const isWeeklyClaimWaitingOnBlockchain = store.rewards.shouldClaimMission(
    userId,
    weeklyMissionId,
  )
  const isCreatorMissionReadyToClaim =
    store.rewards.isCreatorMissionReadyToClaim

  // console.log('shouldClaimDaily', shouldClaimDaily)
  // console.log('shouldClaimWeekly', shouldClaimWeekly)

  useFocusEffect(
    React.useCallback(() => {
      if (userId !== '') {
        store.rewards.fetchMissions(userId)
        store.rewards.fetchMissionData()
      }
    }, [store, userId]),
  )

  const onClaimHandler = async () => {
    if (!store.session.hasSession) {
      navigation.navigate('SignIn')
    } else if (shouldClaimDaily) {
      navigation.navigate('Mission', {
        id: dailyMissionId,
      })
    } else if (shouldClaimWeekly) {
      navigation.navigate('Mission', {
        id: weeklyMissionId,
      })
    } else if (isCreatorMissionReadyToClaim) {
      navigation.navigate('MissionsList')
    } else {
      navigation.navigate('Home')
    }
  }

  return (
    <View style={[styles.streaks, pal.border]}>
      <Text type="2xl-heavy" style={[styles.textPadding, pal.text]}>
        Solarplex Rewards
      </Text>

      <View style={[s.flexRow, s.alignCenter, {gap: 12, paddingVertical: 7}]}>
        <View style={[s.flexCol, {flexWrap: 'wrap', flex: 1}]}>
          <Text type="sm" style={[pal.text, {paddingBottom: 7}]}>
            Complete 7 Daily Missions in a row to earn a rare collectible
            reaction!
          </Text>
          <View style={[s.flexRow, {alignItems: 'flex-end'}]}>
            <View>
              <Text type="xs" style={s.gray3}>
                POINTS
              </Text>
              <PointsProgress
                count={!store.session ? 0 : dailyProgress.count}
                total={50}
                style={{paddingRight: 11}}
              />
            </View>
            <View style={{width: '63%'}}>
              <ProgressBar
                progressValue={dailyProgress.count}
                totalProgress={50}
                height={15}
              />
            </View>
          </View>
          <View style={[s.flexRow, {alignItems: 'flex-end', paddingTop: 7}]}>
            <View>
              <Text type="xs" style={s.gray3}>
                Days
              </Text>
              <PointsProgress
                count={!store.session ? 0 : weeklyProgress.count}
                total={7}
                style={{paddingRight: 26}}
              />
            </View>
            <WeeklyStreakProgress
              count={!store.session ? 0 : weeklyProgress.count}
            />
          </View>
        </View>
      </View>

      {(shouldClaimWeekly ||
        shouldClaimDaily ||
        !isDailyClaimWaitingOnBlockchain ||
        !isWeeklyClaimWaitingOnBlockchain) && (
        <View
          style={[
            s.flexCol,
            pal.border,
            {
              paddingTop: 7,
              borderTopWidth: 1,
              width: '100%',
              alignItems: 'center',

              justifyContent: 'center',
            },
          ]}>
          <TouchableOpacity
            accessibilityRole="button"
            style={[
              styles.ClaimCTA,
              shouldClaimWeekly
                ? {backgroundColor: colors.splx.accent.canary[30]}
                : {backgroundColor: colors.splx.primary[50]},
            ]}
            onPress={onClaimHandler}>
            <Text
              style={[
                styles.claimTextCTA,
                shouldClaimWeekly
                  ? {color: colors.splx.primary[60]}
                  : {color: colors.white},
              ]}>
              {!store.session.hasSession
                ? 'Sign In'
                : shouldClaimDaily && !hasClaimedDaily
                ? 'Claim Daily Reward'
                : shouldClaimWeekly && !hasClaimedWeekly
                ? 'Claim Weekly Reward'
                : isDailyClaimWaitingOnBlockchain ||
                  isWeeklyClaimWaitingOnBlockchain
                ? 'Adding to Wallet'
                : isCreatorMissionReadyToClaim
                ? 'Reward Available!'
                : 'Keep Going!'}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
})

const styles = StyleSheet.create({
  ClaimCTA: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 24,
    paddingVertical: 10,
    paddingHorizontal: 12,
  },
  claimTextCTA: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  streaks: {
    width: 'auto',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: 8,
    padding: 14,
    borderWidth: 1,
  },
  textPadding: {
    textAlign: 'left',
  },
})
