import {CLOUDFRONT_IMAGE_BUCKET, S3_IMAGE_BUCKET} from 'lib/constants'

export function extractDataUriMime(uri: string): string {
  return uri.substring(uri.indexOf(':') + 1, uri.indexOf(';'))
}

// Fairly accurate estimate that is more performant
// than decoding and checking length of URI
export function getDataUriSize(uri: string): number {
  return Math.round((uri.length * 3) / 4)
}

export function isUriImage(uri: string) {
  return /\.(jpg|jpeg|png).*$/.test(uri)
}

export function blobToDataUri(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject(new Error('Failed to read blob'))
      }
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

export function s3ToCdn(uri: string, size?: 128 | 512): string {
  let newUri = uri
  if (newUri?.includes(S3_IMAGE_BUCKET)) {
    newUri = newUri.replace(S3_IMAGE_BUCKET, CLOUDFRONT_IMAGE_BUCKET)

    if (size) {
      newUri = newUri.replace('.png', `_${size}.webp`)
    }
  }

  return newUri
}
