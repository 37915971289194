import {observer} from 'mobx-react-lite'
import {CreateAccount} from 'view/com/auth/create/CreateAccount'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
import {InviteCodes} from 'lib/constants'
import React from 'react'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'Breakpoint'>
export const BreakpointScreen = observer(
  function BreakpointScreenImpl({}: Props) {
    const name = 'breakpoint'
    const inviteCode = InviteCodes[name as keyof typeof InviteCodes]
    return <CreateAccount inviteCode={inviteCode} onPressBack={() => {}} />
  },
)
