import {AssetPair, UserCollectible} from 'state/models/lists/gallery-feed'
import React from 'react'
import {StyleSheet, View, Image, TouchableOpacity} from 'react-native'
import {Text} from '../util/text/Text'
import {usePalette} from 'lib/hooks/usePalette'
import {isMobileWeb} from 'platform/detection'
import {removeSuffixFromHandle} from 'lib/strings/handles'
import {useStores} from 'state/index'

export function GalleryItem({
  item,
  profile,
}: {
  item: AssetPair
  profile: string
}) {
  const pal = usePalette('default')
  const store = useStores()

  const expandGalleryItem = (asset: UserCollectible) => {
    if (asset !== undefined) {
      store.shell.openModal({
        name: 'gallery-view',
        asset: asset,
        profileHandle: profile,
      })
    }
  }

  return (
    <View
      style={[
        {
          width: '100%',
          flexDirection: isMobileWeb ? 'column' : 'row',
          marginRight: 10,
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}>
      <View style={[styles.card, pal.viewLight, pal.border]}>
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => expandGalleryItem(item.items?.asset1)}>
          <View
            style={[
              pal.borderDark,
              {borderWidth: 1, borderRadius: 8},
              pal.view,
            ]}>
            {item.items?.asset1?.image === undefined ? (
              <Text style={[pal.text, {height: 300, padding: 20}]}>
                No image
              </Text>
            ) : (
              <Image
                accessibilityIgnoresInvertColors
                style={styles.image}
                resizeMode="cover"
                source={{uri: item.items?.asset1?.image}}
              />
            )}
          </View>
          <View style={[{paddingVertical: 10}]}>
            <Text type="lg-bold" style={[pal.text]}>
              {item.items?.asset1?.title || 'No Name'}
            </Text>
            {item.items?.asset1?.handle && (
              <Text type="lg-bold" style={[pal.text, {paddingTop: 2}]}>
                <Text type="lg" style={[pal.text]}>
                  created by -{' '}
                </Text>
                {removeSuffixFromHandle(item.items?.asset1?.handle)}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      </View>

      <View style={[styles.card, pal.viewLight, pal.border]}>
        <TouchableOpacity
          accessibilityRole="button"
          onPress={() => expandGalleryItem(item.items?.asset2)}>
          <View
            style={[
              pal.borderDark,
              {borderWidth: 1, borderRadius: 8},
              pal.view,
            ]}>
            {item.items?.asset2?.image === undefined ? (
              <Text style={[pal.text, {height: 300, padding: 20}]}>
                No image
              </Text>
            ) : (
              <Image
                accessibilityIgnoresInvertColors
                style={styles.image}
                resizeMode="cover"
                source={{uri: item.items?.asset2?.image}}
              />
            )}
          </View>
          <View style={[{paddingVertical: 10}]}>
            <Text type="lg-bold" style={[pal.text]}>
              {item.items?.asset2?.title || 'No Name'}
            </Text>
            {item.items?.asset2?.handle && (
              <Text type="lg-bold" style={[pal.text, {paddingTop: 2}]}>
                <Text type="lg" style={[pal.text]}>
                  created by -{' '}
                </Text>
                {removeSuffixFromHandle(item.items?.asset2?.handle)}
              </Text>
            )}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingBottom: 8,
    paddingTop: 16,
    paddingLeft: 10,
  },
  spacer20: {
    height: 20,
  },
  card: {
    flex: 1,
    margin: 10,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    width: '90%',
  },
  image: {
    width: '100%',
    height: 300,
    borderRadius: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 8,
  },
  description: {
    fontSize: 14,
    color: '#666',
  },
  cardContainer: {
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
  },
})
