import Constants from 'expo-constants'
import {Insets} from 'react-native'
import getGlobal from 'globalthis'

const HELP_DESK_LANG = 'en-us'
export const HELP_DESK_URL = `https://blueskyweb.zendesk.com/hc/${HELP_DESK_LANG}`

export const SOLARPLEX_INTENT_LINK =
  'https://twitter.com/intent/tweet?text=Let%27s%20get%20out%20of%20the%20echo%20chamber%21%20I%27m%20on%20the%20waitlist%20for%20@solarplex_xyz%20V2%21%20I%20need%20an%20invite%2C%20who%27s%20got%20one%3F'

export const DMCA_LINK =
  'https://usedispatch.notion.site/Community-Guidelines-1224767f032a4c8bab11d85abe659a70?pvs=4'

const BASE_FEEDBACK_FORM_URL = `${HELP_DESK_URL}/requests/new`
export function FEEDBACK_FORM_URL({
  email,
  handle,
}: {
  email?: string
  handle?: string
}): string {
  let str = BASE_FEEDBACK_FORM_URL
  if (email) {
    str += `?tf_anonymous_requester_email=${encodeURIComponent(email)}`
    if (handle) {
      str += `&tf_17205412673421=${encodeURIComponent(handle)}`
    }
  }
  return (
    'https://twitter.com/intent/tweet?text=Hey%20@solarplex_xyz%20I%20have%20some%20feedback%20for%20Live%3A' ??
    str
  )
}

// Global constants

export const MAX_DISPLAY_NAME = 64
export const MAX_DESCRIPTION = 256

export const MAX_GRAPHEME_LENGTH = 300

// Recommended is 100 per: https://www.w3.org/WAI/GL/WCAG20/tests/test3.html
// but increasing limit per user feedback
export const MAX_ALT_TEXT = 300
export const MAX_NFT_NAME = 32

// Solarplex realm based for environments and testing
// For now, since we've set up the environment in a way
// that staging. and live. are staging and prod respectively
// we'll continue to use those definitions.
// the realm is useful only for internal solarplex services
// such as the v1 API for incentives.
export const SOLARPLEX_REALM = process.env.SOLARPLEX_REALM || 'dev'

const PROD_CONSTANTS = {
  SPLX_FEED_DB: 'dispatch-services:us-central1:solnews',
  SPLX_PLC_URL: 'https://plc.solarplex.xyz',
  SPLX_PDS_URL: 'https://live.solarplex.xyz',
  SPLX_APPVIEW_URL: 'https://appview.solarplex.xyz',
  SPLX_USER_HANDLE: 'solarplex.live.solarplex.xyz',
  SPLX_USER_DID: 'did:plc:4srpaai54v3d35bigtfbtbd5',
  SPLX_V1_API: 'https://prod.api.solarplex.xyz',
  HELIUS_RPC_API: 'https://rpc.helius.xyz',
  RPC_API: 'https://cindi-88a2dr-fast-mainnet.helius-rpc.com/',
  MAINNET_RPC_API: 'https://cindi-88a2dr-fast-mainnet.helius-rpc.com/',
  SPLX_UI_URL: 'https://solarplex.xyz',
  SPLX_FEED_API: 'https://feed.solarplex.xyz',
  // SPLX_FEED_API: 'http://localhost:3000',
  S3_IMAGE_BUCKET: 'https://splx-prod.s3.amazonaws.com',
  CLOUDFRONT_IMAGE_BUCKET: 'https://d2pys5jca4hywh.cloudfront.net',
  SPLX_MIXPANEL_WRITE_KEY: 'aqLhAgXweRt74dGXU3NVMqW9LVB2dmZ9',
  HANDLE_SUFFIX: '.live.solarplex.xyz',
}

const STAGING_CONSTANTS = {
  SPLX_FEED_DB: 'dispatch-services:us-central1:solnews-staging',
  SPLX_PLC_URL: 'https://staging.plc.solarplex.xyz',
  SPLX_PDS_URL: 'https://staging.live.solarplex.xyz',
  SPLX_APPVIEW_URL: 'https://staging.appview.solarplex.xyz',
  SPLX_USER_HANDLE: 'spx.staging.live.solarplex.xyz',
  SPLX_USER_DID: 'did:plc:aen2rosf555soqeup26zomir',
  SPLX_V1_API: 'https://dev.api.solarplex.xyz',
  // SPLX_V1_API: 'http://localhost:3001',
  HELIUS_RPC_API: 'https://devnet.helius-rpc.com',
  RPC_API: 'https://api.devnet.solana.com',
  MAINNET_RPC_API: 'https://cindi-88a2dr-fast-mainnet.helius-rpc.com/',
  SPLX_UI_URL: 'https://staging.v2.solarplex.xyz',
  SPLX_FEED_API: 'https://staging.feed.solarplex.xyz',
  // SPLX_FEED_API: 'http://localhost:3000',
  S3_IMAGE_BUCKET: 'https://splx-staging.s3.amazonaws.com',
  CLOUDFRONT_IMAGE_BUCKET: 'https://d30z0599kmgs5o.cloudfront.net',
  SPLX_MIXPANEL_WRITE_KEY: 'zmQ00bqOYQt6ehtV14st0r6HjpcOHpFt',
  HANDLE_SUFFIX: '.staging.live.solarplex.xyz',
}

// TODO(viksit): make this use local instance via docker
// of our services rather than connect to staging each time
const LOCALHOST_CONSTANTS = {
  SPLX_PDS_URL: 'http://localhost:2583',
  SPLX_V1_API: 'http://localhost:3001',
  SPLX_APPVIEW_URL: 'http://localhost:2584',
  SPLX_UI_URL: 'http://localhost:19006',
  SPLX_USER_DID: 'alice.test', // Note: This changes every time, so update with did output from dev-env command line call
  SPLX_FEED_API: 'http://localhost:3000',
  S3_IMAGE_BUCKET: 'https://splx-staging.s3.amazonaws.com',
  MAINNET_RPC_API: 'https://cindi-88a2dr-fast-mainnet.helius-rpc.com/',
}

let ACTIVE_CONSTANTS
switch (SOLARPLEX_REALM) {
  case 'prod': {
    ACTIVE_CONSTANTS = PROD_CONSTANTS
    break
  }
  case 'local': {
    ACTIVE_CONSTANTS = {...STAGING_CONSTANTS, ...LOCALHOST_CONSTANTS}
    break
  }
  case 'dev':
  case 'staging': {
    ACTIVE_CONSTANTS = STAGING_CONSTANTS
    break
  }
  default: {
    ACTIVE_CONSTANTS = STAGING_CONSTANTS
    break
  }
}

export const vapidKey: string | undefined = (
  Constants.expoConfig?.notification as any
)?.vapidPublicKey

export const firebaseConfig = {
  apiKey: 'AIzaSyDMK8cttzoy8x6-wZWaTmbQFerx9Klvi2k',
  authDomain: 'townsquare-f9d3c.firebaseapp.com',
  projectId: 'townsquare-f9d3c',
  storageBucket: 'townsquare-f9d3c.appspot.com',
  messagingSenderId: '425749059511',
  appId: '1:425749059511:web:7573b402eb751a19273a32',
  measurementId: 'G-JTTF0H69K2',
}

// ("constants:", ACTIVE_CONSTANTS);
// console.log("did:", SOLARPLEX_DID);

// Bsky variables which we don't touch
export function IS_LOCAL_DEV(url: string) {
  return url.includes('localhost')
}

export function IS_STAGING(url: string) {
  return !IS_LOCAL_DEV(url) && !IS_PROD(url)
}

export function IS_PROD(url: string) {
  // NOTE
  // until open federation, "production" is defined as the main server
  // this definition will not work once federation is enabled!
  // -prf
  return url.startsWith('https://live.solarplex.xyz')
}

const currUrl = getGlobal().location?.href ?? ''

if (IS_LOCAL_DEV(currUrl) && SOLARPLEX_REALM === 'prod') {
  ACTIVE_CONSTANTS.SPLX_V1_API = LOCALHOST_CONSTANTS.SPLX_V1_API
}
export const SOLARPLEX_IDENTIFIER = ACTIVE_CONSTANTS.SPLX_USER_HANDLE

export const SOLARPLEX_FEED_API = ACTIVE_CONSTANTS.SPLX_FEED_API
// export const SOLARPLEX_FEED_API = 'http://localhost:3000'
//export const SOLARPLEX_FEED_API = SOLARPLEX_FEED_API_LOCAL;
// export const SOLARPLEX_FEED_API = STAGING_CONSTANTS.SPLX_FEED_API;

export const SOLARPLEX_DID = ACTIVE_CONSTANTS.SPLX_USER_DID
export const SOLARPLEX_V1_API = ACTIVE_CONSTANTS.SPLX_V1_API
export const SOLARPLEX_APPVIEW_URL = ACTIVE_CONSTANTS.SPLX_APPVIEW_URL
// export const SOLARPLEX_V1_API = 'http://localhost:3001'
export const HELIUS_RPC_API = ACTIVE_CONSTANTS.HELIUS_RPC_API
export const SOLARPLEX_USER_DID = ACTIVE_CONSTANTS.SPLX_USER_DID
export const SOLARPLEX_UI_URL = ACTIVE_CONSTANTS.SPLX_UI_URL
export const SOLARPLEX_PDS_URL = ACTIVE_CONSTANTS.SPLX_PDS_URL
// export const SOLARPLEX_PDS_URL = 'http://localhost:2583'
export const SPLX_MIXPANEL_WRITE_KEY = ACTIVE_CONSTANTS.SPLX_MIXPANEL_WRITE_KEY
export const RPC_API = ACTIVE_CONSTANTS.RPC_API
export const MAINNET_RPC_API = ACTIVE_CONSTANTS.MAINNET_RPC_API
export const HANDLE_SUFFIX = ACTIVE_CONSTANTS.HANDLE_SUFFIX
export const SOLARPLEX_TRANSACTION_OPTIONS = {
  skipPreflight: true,
}
export const SOLARPLEX_IS_DEV =
  IS_LOCAL_DEV(currUrl) ||
  SOLARPLEX_REALM === 'dev' ||
  SOLARPLEX_REALM === 'local'

export const PROD_TEAM_HANDLES = [
  'viksit.live.solarplex.xyz',
  'zayyan.live.solarplex.xyz',
  'pratikt.live.solarplex.xyz',
  'robert.live.solarplex.xyz',
  'solarplex.live.solarplex.xyz',
]
export const STAGING_TEAM_HANDLES = []
export const DEV_TEAM_HANDLES = ['alice.test', 'bob.test', 'carla.test']

export function TEAM_HANDLES(serviceUrl: string) {
  if (serviceUrl.includes('localhost')) {
    return DEV_TEAM_HANDLES
  } else if (serviceUrl.includes('staging')) {
    return STAGING_TEAM_HANDLES
  } else {
    return PROD_TEAM_HANDLES
  }
}

export const STAGING_DEFAULT_FEED = (rkey: string) =>
  `at://${STAGING_CONSTANTS.SPLX_USER_DID}/app.bsky.feed.generator/${rkey}`
export const PROD_DEFAULT_FEED = (rkey: string) =>
  `at://${PROD_CONSTANTS.SPLX_USER_DID}/app.bsky.feed.generator/${rkey}`

export async function DEFAULT_FEEDS(
  serviceUrl: string,
  resolveHandle: (name: string) => Promise<string>,
) {
  // TODO: remove this when the test suite no longer relies on it
  if (IS_LOCAL_DEV(serviceUrl)) {
    // uncomment for local dev
    // local dev
    const splxDid = await resolveHandle(SOLARPLEX_USER_DID)
    return {
      pinned: [`at://${splxDid}/app.bsky.feed.generator/alice-favs`],
      saved: [`at://${splxDid}/app.bsky.feed.generator/alice-favs`],
    }
  } else if (IS_STAGING(serviceUrl) || SOLARPLEX_REALM === 'dev') {
    // staging
    return {
      pinned: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
      saved: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
    }
  } else {
    // production
    return {
      pinned: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
      saved: [
        PROD_DEFAULT_FEED('splx-solana'),
        PROD_DEFAULT_FEED('splx-solarplex'),
      ],
    }
  }
}

export const POST_IMG_MAX = {
  width: 2000,
  height: 2000,
  size: 1000000,
}

export const STAGING_LINK_META_PROXY =
  'https://cardyb.staging.bsky.dev/v1/extract?url='

export const PROD_LINK_META_PROXY = 'https://cardyb.bsky.app/v1/extract?url='

export const LOCAL_OG_META_PROXY =
  'http://localhost:2584/xrpc/app.bsky.splx.getFrameMeta?url='

export const STAGING_OG_META_PROXY =
  'https://staging.appview.solarplex.xyz/xrpc/app.bsky.splx.getFrameMeta?url='

export const PROD_OG_META_PROXY =
  'https://appview.solarplex.xyz/xrpc/app.bsky.splx.getFrameMeta?url='

export function LINK_META_PROXY(serviceUrl: string) {
  if (IS_LOCAL_DEV(serviceUrl)) {
    return STAGING_LINK_META_PROXY
  } else if (IS_STAGING(serviceUrl)) {
    return STAGING_LINK_META_PROXY
  } else {
    return PROD_LINK_META_PROXY
  }
}
export function OG_META_PROXY(serviceUrl: string) {
  if (IS_LOCAL_DEV(serviceUrl)) {
    return LOCAL_OG_META_PROXY
  } else if (IS_STAGING(serviceUrl)) {
    return STAGING_OG_META_PROXY
  } else {
    return PROD_OG_META_PROXY
  }
}

export function IMAGE_PROXY(serviceUrl: string) {
  if (IS_LOCAL_DEV(serviceUrl)) {
    return 'localhost:2584/imgproxy?url='
  } else if (IS_STAGING(serviceUrl)) {
    return 'https://staging.appview.solarplex.xyz/imgproxy?url='
  } else {
    return 'https://appview.solarplex.xyz/imgproxy?url='
  }
}
export const STATUS_PAGE_URL = 'placeholder'

// Hitslop constants
export const createHitslop = (size: number): Insets => ({
  top: size,
  left: size,
  bottom: size,
  right: size,
})
export const HITSLOP_10 = createHitslop(10)
export const HITSLOP_20 = createHitslop(20)
export const HITSLOP_30 = createHitslop(30)
export const BACK_HITSLOP = HITSLOP_30
export const MAX_POST_LINES = 25
export const MAX_VIDEO_BYTES = 50 * 1000000 // # of MB * bytes, appox $0.40 @ $160/SOL and $30/AR
export const BASE64_BYTES_FACTOR = 4 / 3
export const RECOMMENDED_FEEDS = [
  {
    did: 'did:plc:hsqwcidfez66lwm3gxhfv5in',
    rkey: 'aaaf2pqeodmpy',
  },
]

export const SOLARPLEX_FEEDS = [
  `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/splx-solana`,
  `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/splx-solarplex`,
  `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/wearesquidz`,
]

export const S3_IMAGE_BUCKET = ACTIVE_CONSTANTS.S3_IMAGE_BUCKET
export const CLOUDFRONT_IMAGE_BUCKET = ACTIVE_CONSTANTS.CLOUDFRONT_IMAGE_BUCKET

export const SOLARPLEX_FEED_URI_PATH = `at://${SOLARPLEX_USER_DID}/app.bsky.feed.generator/`

export function isProdTeamHandle(handle: string): boolean {
  return PROD_TEAM_HANDLES.includes(handle)
}

export const StagingInviteCodes = {
  breakpoint: 'staging-live-solarplex-xyz-nouqs-3epsm',
  creator: 'staging-live-solarplex-xyz-nouqs-3epsm',
}

export const InviteCodes = {
  breakpoint: 'live-solarplex-xyz-2sd6i-wosfi',
  creator: 'live-solarplex-xyz-2sd6i-wosfi',
}

export const bannerImages = {
  daily:
    'https://d2pys5jca4hywh.cloudfront.net/reactions/bujang/images/sad_512.webp',
  weekly:
    'https://d2pys5jca4hywh.cloudfront.net/reactions/bujang/images/emotion_512.webp',
  pepe: 'https://splx-staging.s3.amazonaws.com/reactions/pepeclown/images/zombie.png',
}

export const creatorToolsForm =
  'https://usedispatch.retool.com/form/879cd1b0-003d-4a24-a1a2-9fcc5e39e1d0'
export const creatorToolBadgeColor = '#07c0e0'
export const creatorInsightsFeedbackForm = 'https://tally.so/r/mY4gxd'
export const explorerBaseUrl = 'https://solana.fm'
export const creatorToolsDashboardRetoolUrl =
  'https://usedispatch.retool.com/app/creator-tools#handle='
export const creatorsGuideLink =
  'https://usedispatch.notion.site/Solarplex-Creator-Guide-c00fcef4d34e4016b169b79f2d29b091'
export const videoGuideLink = `https://www.notion.so/usedispatch/Solarplex-Creator-Guide-c00fcef4d34e4016b169b79f2d29b091?pvs=4#4d00d1349b9945f1b7cd1cd4391763d9`
export const innerCircleRequestAccessLink =
  'https://usedispatch.retool.com/form/739f8313-4e0a-4c81-a309-6f6e9b7dbe4d'

export const innerCircleLearnMore = `https://usedispatch.notion.site/Solarplex-Creator-Guide-c00fcef4d34e4016b169b79f2d29b091?pvs=4`
export const createChannelLink = 'https://tally.so/r/mDNVxb'
export const profilePostRegex = /profile\/(.*?)\/post\/(.*)/

export const resources = [
  {
    title: 'Solarplex Creator Wiki',
    link: 'https://usedispatch.notion.site/Solarplex-Creators-Wiki-ba8d975be66941db9d02b6b4f29b5583?pvs=4',
    icon: '🌐',
  },
  {
    title: 'Solarplex Creator Guide / FAQ',
    link: 'https://usedispatch.notion.site/Solarplex-Creator-Guide-c00fcef4d34e4016b169b79f2d29b091?pvs=74',
    icon: '⁉️',
  },
  {
    title: 'Tensor Verification Form',
    link: 'https://tally.so/r/nPOoo0',
    icon: '📈',
  },
  {
    title: 'Reaction Pack Submission Form',
    link: 'https://tally.so/r/mVQOQj',
    icon: '😎',
  },
  {
    title: 'Public Roadmap(vote on features!)',
    link: 'https://solarplex.canny.io/feature-requests',
    icon: '🛣️',
  },
  {
    title: 'Inner Circle Form',
    link: 'https://usedispatch.retool.com/form/739f8313-4e0a-4c81-a309-6f6e9b7dbe4d',
    icon: '⭕️',
  },
]
