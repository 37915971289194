import {Text} from 'view/com/util/text/Text'
import {StyleSheet, View, FlatList, Image} from 'react-native'
import React from 'react'
import {s} from 'lib/styles'
import {usePalette} from 'lib/hooks/usePalette'
import {CollectibleViewDetailed} from '@usedispatch/atproto-api/dist/client/types/app/bsky/splx/defs'
import {LAMPORTS_PER_SOL} from '@solana/web3.js'
import {useStores} from 'state/index'
import {observer} from 'mobx-react-lite'
import {isDesktopWeb, isMobileWeb} from 'platform/detection'
import {SolLogo} from 'lib/icons'

const CardGrid: React.FC<{data: CollectibleViewDetailed[]}> = ({data}) => {
  const pal = usePalette('default')
  const renderItem = ({item}: {item: CollectibleViewDetailed}) => {
    const price =
      item.price !== undefined && item.price !== 0
        ? item.price / LAMPORTS_PER_SOL
        : 0
    return (
      <View style={[styles.card, pal.viewLight, pal.border]}>
        <View
          style={[pal.borderDark, {borderWidth: 1, borderRadius: 8}, pal.view]}>
          <Image
            accessibilityIgnoresInvertColors
            style={styles.image}
            resizeMode="cover"
            source={{uri: item.imageUri}}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <View>
            <Text style={[styles.title, pal.text, s.pb2]}>
              {item.title && item.title.length > 12
                ? `${item.title.substring(0, 12)}...`
                : item.title}
            </Text>
            <Text type="md" style={[pal.text, s.pb2]}>
              {item.description && item.description.length > 12
                ? `${item.description.substring(0, 12)}...`
                : item.description}
            </Text>
          </View>
          <View style={{flex: 1, alignItems: 'flex-end'}}>
            <Text style={[pal.text, s.pb2, {marginTop: 8}]}>Earned</Text>
            <Text type="lg" style={[pal.link, s.pb2]}>
              {price * (item?.minted || 0)} SOL
            </Text>
          </View>
        </View>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
            justifyContent: 'space-between',
            marginTop: 4,
          }}>
          <Text style={[styles.description, pal.textLight]}>
            <Text style={[pal.text, {fontWeight: 'bold'}]}>
              {(item.minted || 0) + (item.pending || 0)}
            </Text>{' '}
            Minted
          </Text>
          <View style={{flex: 1, alignItems: 'flex-end'}}>
            <Text style={[pal.text, s.pb2, {marginTop: 8}]}>Price</Text>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <SolLogo size={14} fill={pal.colors.text} />
              <Text
                type="md-heavy"
                numberOfLines={isDesktopWeb ? 2 : 4}
                style={[pal.text, {paddingLeft: 4}]}>
                {price > 0 ? `${price}` : 'Free'}
              </Text>
            </View>
          </View>
        </View>
      </View>
    )
  }

  return (
    <FlatList
      data={data}
      style={{paddingBottom: isMobileWeb ? 100 : 20}}
      renderItem={renderItem}
      keyExtractor={item => item.cid}
      numColumns={isMobileWeb ? 1 : 2} // Set the number of columns
    />
  )
}

export const CollectiblesTab = observer(function CollectiblesTabImpl() {
  const pal = usePalette('default')
  const store = useStores()
  const collectibles = store.creatorTools.collectibles

  return (
    <View>
      <Text type="title" style={[pal.text, styles.heading]}>
        Your Collectibles
      </Text>
      <CardGrid data={collectibles} />
    </View>
  )
})

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingBottom: 8,
    paddingTop: 16,
    paddingLeft: 10,
  },
  card: {
    flex: 1,
    margin: 10,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
  },
  image: {
    width: '100%',
    height: 300,
    borderRadius: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 8,
  },
  description: {
    fontSize: 14,
    color: '#666',
  },
  cardContainer: {
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
  },
})
