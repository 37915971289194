import {Image} from 'expo-image'
import React from 'react'
import {ScrollView, StyleSheet, View} from 'react-native'
import {Text} from '../util/text/Text'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {useStores} from 'state/index'
import {isMobileWeb} from 'platform/detection'
import {s3ToCdn} from 'lib/media/util'
import {usePalette} from 'lib/hooks/usePalette'

export const ReactionList = observer(function ReactionListImpl({
  reactions,
}: {
  reactions: string[]
}) {
  const store = useStores()
  const pal = usePalette('default')
  // const reacts = Array.from(new Set(reactions))
  const reactionCounts = reactions.reduce((acc, reaction) => {
    return acc.set(reaction, (acc.get(reaction) || 0) + 1)
  }, new Map())

  const uniqueReactions = [...reactionCounts].map(([reactionId, count]) => ({
    reactionId,
    count,
  }))

  const lines = uniqueReactions.length > 7 ? 2 : 1
  const viewHeight = (32 + 4) * lines
  const width = isMobileWeb
    ? uniqueReactions.length > 4
      ? 800
      : 400
    : uniqueReactions.length > 15
    ? 800
    : 500

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      contentContainerStyle={[
        styles.emojiSet,
        {flexWrap: 'wrap', width: width, height: viewHeight},
      ]}>
      {uniqueReactions?.map((item, index) => {
        if (
          // index < 9 &&
          (
            store.reactions.reactionTypes[item.reactionId]?.nft_metadata
              ?.image as string
          )?.includes('http')
        ) {
          return (
            <View
              style={[
                pal.border,
                {
                  height: 32,
                  flexDirection: 'row',
                  alignItems: 'center',
                  borderRadius: 18,

                  borderWidth: 1,
                  paddingHorizontal: 8,
                  paddingVertical: 4,
                  marginRight: 4,
                  marginBottom: 4,
                },
              ]}
              key={store.reactions.reactionTypes[item.reactionId]?.id}>
              <Image
                accessibilityIgnoresInvertColors
                style={styles.image}
                source={s3ToCdn(
                  store.reactions.reactionTypes[item.reactionId]?.nft_metadata
                    ?.image as string,
                  128,
                )}
              />
              <Text type="md-heavy" style={[pal.textLight, {marginLeft: 8}]}>
                {item.count}
              </Text>
            </View>
          )
        } else {
          return (
            <Text
              key={item.reactionId}
              style={[s.f12, {marginLeft: index ? -6 : 0, zIndex: index}]}>
              {s3ToCdn(
                store.reactions.reactionTypes[item.reactionId]?.nft_metadata
                  ?.image as string,
                128,
              )}
            </Text>
          )
        }
      })}
    </ScrollView>
  )
})

const styles = StyleSheet.create({
  emojiSet: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginLeft: isMobileWeb ? 4 : 12,
  },

  image: {
    // width: '100%',
    // height: '100%',
    resizeMode: 'contain',
    width: 24,
    height: 24,
  },
})
