import {Text} from 'view/com/util/text/Text'
import {usePalette} from 'lib/hooks/usePalette'
import {Linking, StyleSheet, View} from 'react-native'
import React from 'react'
import {VerifiedIcon} from '../util/Verified'
import {creatorToolBadgeColor, creatorToolsForm} from 'lib/constants'
import {Button} from 'view/com/util/forms/Button'
import {s} from 'lib/styles'

export function CreatorOnboardingScreen() {
  const pal = usePalette('default')
  return (
    <>
      <Text type="title" style={[styles.heading, pal.text]}>
        Creator Tools
      </Text>
      <View
        style={[s.flexRow, {alignItems: 'center', justifyContent: 'center'}]}>
        <Text type="lg-bold" style={[{paddingBottom: 15}, pal.text]}>
          Become a Verified Creator on Solarplex
        </Text>
        <View style={{paddingBottom: 15}}>
          <VerifiedIcon size="sm" color={creatorToolBadgeColor} />
        </View>
      </View>
      <Text type="post-text" style={[{paddingBottom: 15}, pal.text]}>
        Verified creators are given a badge that unlocks Solarplex Compressed
        Minting!{' '}
      </Text>
      <Text type="post-text" style={pal.text}>
        NFTs you create on Solarplex will be shown in the dedicated Mints feed
        and allows Plexers to purchase and collect them.
      </Text>
      <View
        style={[
          s.flexCol,
          {
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: 22,
          },
        ]}>
        <Button
          type="primary"
          label="Apply Here"
          onPress={
            // redirect to an external link
            () => Linking.openURL(creatorToolsForm)
          }
        />
      </View>
    </>
  )
}

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingBottom: 8,
    paddingTop: 16,
    paddingLeft: 10,
  },
})
