import React from 'react'
import {View} from 'react-native'
import {useFocusEffect} from '@react-navigation/native'
import {
  NativeStackScreenProps,
  CommonNavigatorParams,
} from '../../lib/routes/types'
import {withAuthRequired} from 'view/com/auth/withAuthRequired'
import {useStores} from 'state/index'
import {ViewHeader} from '../com/util/ViewHeader'
import {ChannelMembersList} from '../com/channels/ChannelMembersList'
import {useAnalytics} from 'lib/analytics/analytics'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'ChannelMembers'>
export const ChannelMembersScreen = withAuthRequired(({route}: Props) => {
  const store = useStores()
  const {cid} = route.params
  const {screen} = useAnalytics()

  useFocusEffect(
    React.useCallback(() => {
      store.channels.fetch()
      store.shell.setMinimalShellMode(false)
      screen('ChannelMembers')
    }, [store, screen]),
  )

  return (
    <View>
      <ViewHeader title="Channel Members" canGoBack={true} />
      <ChannelMembersList cid={cid} />
    </View>
  )
})
