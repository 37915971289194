import * as Toast from '../util/Toast'

import {useEffect} from 'react'
import {useStores} from '../../../state'
import {useWallet} from '@solana/wallet-adapter-react'
import {useWalletModal} from '@solana/wallet-adapter-react-ui'

export const useSplxWallet = (shouldRegister: boolean) => {
  const store = useStores()
  const wallet = useWallet()
  // State management for wallet connection via the wallet popup
  const waitForWalletConnectIsBusy = store.wallet.waitForWalletConnectIsBusy()
  const waitingToConnectWallet = store.wallet.state.waitingToConnectWallet
  const waitingToConnectWalletCanceled =
    store.wallet.state.canceledWaitingToConnectWallet
  const walletAddressFromWalletConnect = wallet.publicKey?.toBase58() ?? ''

  // this doesn't update
  const walletAddressFromModel = store.wallet.state.connectedWalletId
  const linkedWallet = store.wallet.linkedWallet
  const linkWalletIsBusy =
    walletAddressFromWalletConnect &&
    store.wallet.linkWalletIsBusy(walletAddressFromWalletConnect)
  const unlinkWalletIsBusy = store.wallet.unlinkWalletIsBusy()
  const connectWalletIsBusy = waitingToConnectWallet || linkWalletIsBusy
  const disconnectWalletIsBusy = unlinkWalletIsBusy || wallet.disconnecting

  const {setVisible, visible} = useWalletModal()

  function openWalletConnectDialog(b: boolean) {
    store.wallet.walletPopup?.disconnect()
    store.wallet.setWalletPopup(undefined)
    if (b === true) {
      setVisible(true)
      store.wallet.waitForWalletConnect()
    } else if (b === false) {
      setVisible(false)
    }
  }

  const disconnectWallet = async () => {
    await Promise.all([store.wallet.unlinkWallet(), wallet.disconnect()])
    Toast.show('Wallet Disconnected')
  }

  useEffect(() => {
    if (
      !visible &&
      waitForWalletConnectIsBusy &&
      !waitingToConnectWallet &&
      !waitingToConnectWalletCanceled
    ) {
      setVisible(true)
    } else if (
      visible &&
      waitForWalletConnectIsBusy &&
      !waitingToConnectWallet &&
      !waitingToConnectWalletCanceled
    ) {
      store.wallet.startWaitForWalletConnect()
    } else if (
      !visible &&
      !wallet.connecting &&
      waitForWalletConnectIsBusy &&
      waitingToConnectWallet &&
      !waitingToConnectWalletCanceled
    ) {
      store.wallet.cancelWaitForWalletConnect()
    }
  }, [
    setVisible,
    store.wallet,
    waitForWalletConnectIsBusy,
    waitingToConnectWallet,
    visible,
    waitingToConnectWalletCanceled,
    wallet.connecting,
  ])

  useEffect(() => {
    if (disconnectWalletIsBusy) {
      return
    }
    if (walletAddressFromModel !== walletAddressFromWalletConnect) {
      store.wallet.setConnectedWalletId(walletAddressFromWalletConnect)
    }
    // console.log(
    //   'wallet popup effect',
    //   wallet?.connected,
    //   store.wallet.state.walletPopup?.connected,
    // )
    if (wallet?.connected !== store.wallet.state.walletPopup?.connected) {
      // console.log('setting wallet popup')
      store.wallet.setWalletPopup(wallet)
    }
    if (
      walletAddressFromWalletConnect !== walletAddressFromModel &&
      shouldRegister &&
      !walletAddressFromModel
    ) {
      store.wallet.linkWallet(walletAddressFromWalletConnect)
    }
  }, [
    shouldRegister,
    walletAddressFromModel,
    walletAddressFromWalletConnect,
    disconnectWalletIsBusy,
    store.wallet,
    wallet,
  ])

  return [
    visible,
    openWalletConnectDialog,
    linkedWallet,
    walletAddressFromModel,
    connectWalletIsBusy,
    disconnectWalletIsBusy,
    disconnectWallet,
    linkWalletIsBusy,
  ] as [
    boolean,
    typeof openWalletConnectDialog,
    typeof linkedWallet,
    typeof walletAddressFromWalletConnect,
    boolean,
    boolean,
    typeof disconnectWallet,
    boolean,
  ]
}
