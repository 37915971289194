import {RootStoreModel} from 'state/index'
import {makeAutoObservable, runInAction} from 'mobx'

export class VideoModel {
  path: string
  mime = 'video/mp4'
  width: number
  height: number
  duration?: number
  alt?: string

  constructor(
    public rootStore: RootStoreModel,
    video: {
      path: string
      width: number
      height: number
      duration?: number
      alt?: string
    },
  ) {
    makeAutoObservable(this, {
      rootStore: false,
    })

    this.path = video.path
    this.width = video.width
    this.height = video.height
    this.duration = video.duration
    this.alt = video.alt
  }

  updateVideo(video: {
    path: string
    width: number
    height: number
    duration?: number
    alt?: string
  }) {
    runInAction(() => {
      this.path = video.path
      this.width = video.width
      this.height = video.height
      this.duration = video.duration
      this.alt = video.alt
    })
  }
}
