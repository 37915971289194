import React from 'react'
import {observer} from 'mobx-react-lite'
import {
  NativeStackScreenProps,
  CommonNavigatorParams,
  NavigationProp,
} from 'lib/routes/types'
import {Login} from 'view/com/auth/login/Login'
import {useNavigation} from '@react-navigation/native'
import {useAnalytics} from 'lib/analytics/analytics.web'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'SignIn'>
export const SignInScreen = observer(function SignInScreenImpl({}: Props) {
  const navigation = useNavigation<NavigationProp>()
  const {screen} = useAnalytics()

  React.useEffect(() => {
    screen('SignInCreator')
  }, [screen])
  return <Login onPressBack={() => navigation.goBack()} />
})
