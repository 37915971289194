import {observer} from 'mobx-react-lite'
import {CreateAccount} from 'view/com/auth/create/CreateAccount'
import {NativeStackScreenProps, CommonNavigatorParams} from 'lib/routes/types'
import {InviteCodes} from 'lib/constants'
import React from 'react'
import {useAnalytics} from 'lib/analytics/analytics.web'

type Props = NativeStackScreenProps<CommonNavigatorParams, 'SignUp'>
export const SignUpScreen = observer(function SignUpScreenImpl({route}: Props) {
  const {name} = route.params
  const inviteCode = InviteCodes[name as keyof typeof InviteCodes]
  const {screen} = useAnalytics()

  React.useEffect(() => {
    screen('SignUpCreator')
  }, [screen])
  return <CreateAccount inviteCode={inviteCode} onPressBack={() => {}} />
})
