import {s3ToCdn} from 'lib/media/util'
import React from 'react'
import {View, StyleSheet, StyleProp, ViewStyle} from 'react-native'
import {useStores} from 'state/index'
import {ClaimBtn} from './ClaimBtn'
import {useAnalytics} from '../../../lib/analytics/analytics'
import {useRequireAuth} from '../auth/withAuthRequired'

interface RewardClaimedProps {
  rewardsImg?: string
  shareText: string
  userId: string
  style?: StyleProp<ViewStyle>
  canShare?: boolean
  missionId?: string
  btnText?: string
  gallery?: {
    profileHandle: string
    assetTitle: string
  }
}

export function ShareButton({
  rewardsImg,
  shareText,
  style,
  canShare = true,
  missionId,
  btnText = 'Share Reward',
  gallery,
}: RewardClaimedProps) {
  const store = useStores()
  const {track} = useAnalytics()
  const requireAuth = useRequireAuth({
    did: store.me.did,
    handle: store.me.handle,
  })
  const onPressCompose = React.useCallback(() => {
    if (store.shell.isModalActive) {
      store.shell.closeModal()
    }
    if (shareText === '') {
      track('Rewards:ReactionShare')
    }
    if (rewardsImg === '') {
      track('InnerCircle:Share', {
        creator: missionId,
        collector: store.me.handle,
      })
    }
    if (gallery?.profileHandle) {
      track('Gallery:SolarplexShare', {
        galleryOwner: gallery.profileHandle,
        user: store.me.handle,
        collectibleTitle: gallery.assetTitle,
      })
    }

    store.shell.openComposer({
      isSharing: true,
      uri: rewardsImg ? s3ToCdn(rewardsImg, 512) : '',
      sharingText: shareText,
    })
  }, [
    store,
    rewardsImg,
    shareText,
    track,
    missionId,
    gallery?.assetTitle,
    gallery?.profileHandle,
  ])

  const onPressComposeWithAuth = () => requireAuth(onPressCompose)

  return (
    <View style={[style, styles.RollBtn]}>
      <ClaimBtn
        disabled={!canShare}
        text={btnText}
        onClick={onPressComposeWithAuth}
        btnstyle={{
          paddingVertical: 12,
          paddingHorizontal: 24,
        }}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  RollBtn: {
    width: '100%',
    paddingVertical: 8,
  },
})
