import {colors} from 'lib/styles'
import {useState, useEffect} from 'react'
import {Animated, View, StyleSheet, StyleProp, ViewStyle} from 'react-native'
import React from 'react'

type ProgressBarProps = {
  progressValue: number
  totalProgress: number
  style?: StyleProp<ViewStyle>
  height?: number
}

export const ProgressBar = ({
  progressValue,
  totalProgress,
  style,
  height = 20,
}: ProgressBarProps) => {
  const [progress] = useState(new Animated.Value(0))

  useEffect(() => {
    Animated.timing(progress, {
      toValue: progressValue,
      duration: 1000,
      useNativeDriver: false,
    }).start()
  }, [progress, progressValue])

  return (
    <View style={[styles.progressContainer, style, {flex: 1, height}]}>
      <Animated.View
        style={[
          styles.bar,
          {
            width: progress.interpolate({
              inputRange: [0, totalProgress],
              outputRange: ['0%', '100%'],
            }),
            height,
          },
        ]}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  progressContainer: {
    backgroundColor: colors.splx.neutral[10],
    borderRadius: 10,
  },
  bar: {
    backgroundColor: colors.splx.primary[50],
    borderRadius: 10,
  },
})
