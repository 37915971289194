import {observer} from 'mobx-react-lite'
import * as Toast from '../util/Toast'
import * as buffer from 'buffer'
import {NewTippingModal} from 'state/models/ui/shell'
import {StyleSheet, TextInput, View, TouchableOpacity} from 'react-native'
import {colors, s} from '../../../lib/styles'
import {useAnalytics} from '../../../lib/analytics/analytics'
import {usePalette} from '../../../lib/hooks/usePalette'
import {useSplxWallet} from '../wallet/useSplxWallet'
import {useStores} from '../../../state/index'
import {useTheme} from '../../../lib/ThemeContext'
import React, {useState} from 'react'
import {useWallet} from '@solana/wallet-adapter-react'
import {Text} from '../util/text/Text'
import {TipDropdownBtn} from '../util/forms/TipdropdownBtn'
import {
  preDefinedTokenValues,
  searchTokenByName,
  SOL,
} from '../../../lib/tipTokens'
import {Image} from 'expo-image'
import {solTransferIx, splTransferIx} from '../../../lib/solana/spl-transfer'
import {Connection, PublicKey, Transaction} from '@solana/web3.js'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import * as fa from '@fortawesome/free-solid-svg-icons'
import {
  RPC_API,
  SOLARPLEX_FEED_API,
  // isProdTeamHandle,
} from '../../../lib/constants'
import {LoadingIndicator} from 'lib/icons'
import {isDesktopWeb} from 'platform/detection'
window.Buffer = buffer.Buffer

type TipBtnProps = {
  label: string
  disabled: boolean
  isLoading: boolean
  isTipSuccess: boolean
  onPress: () => void
}

function TipBtn({
  label,
  onPress,
  disabled,
  isLoading,
  isTipSuccess,
}: TipBtnProps) {
  const theme = useTheme()
  const disableBgStyle =
    theme.colorScheme === 'dark' ? styles.disabledDark : styles.disabledLight
  const disableTextStyle =
    theme.colorScheme === 'dark'
      ? styles.disabledTextDark
      : styles.disabledTextLight
  const successBtnStyle = {
    backgroundColor: colors.splx.primary[40],
    width: '60%',
  }
  return (
    <TouchableOpacity
      accessibilityRole="button"
      disabled={disabled}
      style={[
        styles.tipUIbtn,
        disabled && disableBgStyle,
        isTipSuccess && successBtnStyle,
      ]}
      onPress={() => {
        onPress()
      }}>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Text style={[styles.tiptext, disabled && disableTextStyle]}>
          {label}
        </Text>
      )}
    </TouchableOpacity>
  )
}

export const Component = observer(function ComponentImpl({
  recipientAddress,
  recipientdid,
  senderdid,
  recipientName,
  authorDid,
  authorHandle,
  avatar,
  indexedAt,
  itemCid,
  itemUri,
  text,
}: NewTippingModal) {
  const store = useStores()
  const pal = usePalette('default')
  const [tokenName, setTokenName] = useState('SOL')
  const token = searchTokenByName(tokenName)
  const preFilledAmounts = React.useMemo(() => {
    return (
      preDefinedTokenValues[
        tokenName as keyof typeof preDefinedTokenValues
      ] ?? [10, 100, 1000]
    )
  }, [tokenName])
  const [tipUIAmount, setTipUIAmount] = useState<string>(
    preFilledAmounts[1].toString(),
  )
  const [isTipsLoading, setIsTipsLoading] = useState<boolean>(false)
  const [shouldDisable, setShouldDisable] = useState<boolean>(false)
  const [isTipSuccess, setIstipSuccess] = useState<boolean>(false)
  // const [txSig, setTxSig] = useState<String>('')

  const wallet = useWallet()
  const theme = useTheme()
  const {track} = useAnalytics()
  const [
    _visible,
    setVisible,
    _linkedWallet,
    connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
  ] = useSplxWallet(false)

  React.useEffect(() => {
    setTipUIAmount(preFilledAmounts[1].toString())
  }, [tokenName, preFilledAmounts])

  function quoteShare() {
    const shareText =
      `I just tipped ${tipUIAmount} $${tokenName} to @${
        recipientName ?? 'this user'
      } on Solarplex!` +
      (tokenName === 'GUAC' ? ' 🥑🥑' : tokenName === 'BONK' ? ' 🏏🏏' : '')
    // (txSig !== '' ? `${explorerBaseUrl}/tx/${txSig}` : '')
    store.shell.closeModal()
    if (itemUri && itemCid && indexedAt && authorHandle && authorDid) {
      store.shell.openComposer({
        isSharing: true,
        sharingText: shareText,
        quote: {
          uri: itemUri,
          author: {
            did: authorDid,
            handle: authorHandle,
            avatar: avatar,
          },
          cid: itemCid,
          text: text || shareText,
          indexedAt: indexedAt,
        },
      })
    } else {
      store.shell.openComposer({
        isSharing: true,
        sharingText: shareText,
      })
    }
  }

  async function handleTipTranfer(amount: string) {
    if (!connectedWallet) {
      setVisible(true)
      await store.wallet.waitForWalletConnect()
    }
    if (!connectedWallet || !wallet.signTransaction || !wallet.connected) {
      Toast.show('Please make sure to connect your wallet')
      return
    }
    if (!token || !token.mintAddress) {
      Toast.show('Token mint not found')
      return
    }
    const senderAddress = connectedWallet
    const senderPubkey = new PublicKey(connectedWallet)
    const tx = new Transaction()
    const connection = new Connection(RPC_API)
    try {
      setIsTipsLoading(true)
      setShouldDisable(true)
      const {blockhash} = await connection.getLatestBlockhash('finalized')
      tx.recentBlockhash = blockhash
      tx.feePayer = senderPubkey

      if (tokenName !== SOL.tokenName) {
        const ixs = await splTransferIx({
          amount,
          tokenName,
          receiver: recipientAddress,
          sender: senderAddress,
          connection,
        })

        tx.add(...ixs)
      } else {
        const ix = solTransferIx({
          amount,
          receiver: recipientAddress,
          sender: senderAddress,
        })

        tx.add(ix)
      }

      const signedTx = await wallet.signTransaction(tx)
      const sig = await connection.sendRawTransaction(signedTx.serialize())
      // setTxSig(sig)
      track('Tip:TipConfirmation', {
        tokenName: tokenName,
        sender: senderAddress,
        reciever: recipientAddress,
        amount: amount,
        txSig: sig,
        senderDid: senderdid,
        recipientDid: recipientdid,
      })

      // const currentUserHandle = store.me.handle
      const body = {
        sender_did: senderdid,
        receiver_did: recipientdid,
        amount: amount,
        token_name: tokenName,
        token_mint: token.mintAddress,
        tx_sig: sig,
      }
      // TODO: (Pratik) this should be in a store but since we dont have a tips store will do once the scope of tip increase and has a store for it.
      // if (isProdTeamHandle(currentUserHandle)) {
      await store.api.post(`${SOLARPLEX_FEED_API}/splx/sent_tip`, {body})
      const isInFlight = store.api.postBusy(
        `${SOLARPLEX_FEED_API}/splx/sent_tip`,
        {body},
      )
      const error = store.api.postError(`${SOLARPLEX_FEED_API}/splx/sent_tip`, {
        body,
      })
      if (!error && !isInFlight) {
        setIstipSuccess(true)
        setShouldDisable(false)
        // Toast.show('Tip Sent')
      }
      // }
      setIsTipsLoading(false)
    } catch (error) {
      Toast.show('Something Went Wrong')
      setIsTipsLoading(false)
      setShouldDisable(false)
      console.error('Something Went Wrong', error)
    }
  }

  const cancelModal = () => {
    store.shell.closeModal()
  }
  return (
    <View style={[s.flex1, pal.view, styles.container]}>
      <View style={[styles.title, pal.border]}>
        <View style={styles.titleLeft}>
          <TouchableOpacity
            onPress={cancelModal}
            accessibilityRole="button"
            accessibilityLabel="Cancel change handle"
            accessibilityHint="Exits handle change process"
            onAccessibilityEscape={cancelModal}>
            <Text type="lg" style={pal.textLight}>
              Cancel
            </Text>
          </TouchableOpacity>
        </View>
        <Text
          type="2xl-bold"
          numberOfLines={1}
          style={[pal.text, styles.titleMiddle]}>
          Tip
        </Text>
        <View style={styles.titleRight} />
      </View>
      <Text type="md-medium" style={[pal.text, {paddingTop: 7}]}>
        Select tipping currency and amount
      </Text>
      <View style={[s.flexRow, {alignItems: 'center', paddingVertical: 7}]}>
        <TextInput
          accessibilityHint="Text input field"
          accessibilityLabel="Text input field"
          style={[pal.text, styles.textInput]}
          placeholderTextColor={pal.colors.textLight}
          placeholder="0.00"
          keyboardAppearance={theme.colorScheme}
          keyboardType="decimal-pad"
          value={tipUIAmount}
          onChangeText={text => {
            const formattedText = text.replace(/[^0-9.]/g, '')
            setTipUIAmount(formattedText)
          }}
        />
        <TipDropdownBtn
          onPress={selectedTokenName => setTokenName(selectedTokenName)}>
          <View style={styles.tipBtn}>
            {token?.tokenImage && (
              <Image
                accessibilityIgnoresInvertColors
                style={styles.icon}
                source={{
                  uri: token.tokenImage,
                }}
              />
            )}
            <Text type="button" style={[{paddingRight: 5}, pal.text]}>
              {token ? token.tokenName : tokenName}
            </Text>
            <FontAwesomeIcon
              icon={fa.faChevronDown}
              size={16}
              color={colors.gray4}
            />
          </View>
        </TipDropdownBtn>
      </View>
      <View style={styles.tipBtnsContainer}>
        {preFilledAmounts.map((amount, index) => (
          <TouchableOpacity
            accessibilityRole="button"
            key={index}
            style={[
              styles.button,
              isDesktopWeb ? {paddingHorizontal: 47} : {paddingHorizontal: 17},
              parseFloat(tipUIAmount) === amount && {
                borderColor: colors.splx.primary[50],
                backgroundColor: colors.splx.primary[10],
              },
            ]}
            onPress={() => setTipUIAmount(amount.toString())}>
            <Text
              type="md-bold"
              style={[
                pal.text,
                parseFloat(tipUIAmount) === amount && {
                  color: colors.splx.primary[50],
                },
              ]}>
              {amount}
            </Text>
          </TouchableOpacity>
        ))}
      </View>
      <View
        style={[
          s.flexCol,
          {alignItems: 'center', justifyContent: 'center', paddingTop: 20},
        ]}>
        {isTipSuccess && (
          <Text
            type="md-bold"
            style={[
              pal.text,
              {
                width: '100%',
                textAlign: 'center',
                paddingBottom: 7,
                color: s.green3.color,
              },
            ]}>
            Tip Sent Successfully
          </Text>
        )}
        <TipBtn
          isTipSuccess={isTipSuccess}
          label={isTipSuccess ? `Share` : `Send Tip`}
          disabled={
            tipUIAmount === '' || parseFloat(tipUIAmount) === 0 || shouldDisable
          }
          isLoading={isTipsLoading}
          onPress={() => {
            if (isTipSuccess) {
              quoteShare()
            } else {
              handleTipTranfer(tipUIAmount)
            }
          }}
        />
      </View>
      <Text
        type="md-heavy"
        style={[{textAlign: 'center', paddingTop: 20}, pal.text]}>
        100% of Tips goes to the creator
      </Text>
    </View>
  )
})

const styles = StyleSheet.create({
  tipBtnsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginTop: 10,
  },
  inputContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    width: '100%',
  },
  image: {
    width: 30,
    height: 30,
    paddingRight: 2,
  },
  tipAmountContainer: {
    paddingVertical: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  container: {
    paddingHorizontal: 10,
  },
  center: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textInput: {
    marginTop: 2,
    flex: 1,
    width: '100%',
    paddingVertical: 10,
    paddingHorizontal: 12,
    fontSize: 17,
    letterSpacing: 0.25,
    fontWeight: '400',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: s.gray3.color,
  },
  icon: {
    width: 22,
    height: 22,
    marginLeft: 6,
    marginRight: 8,
  },
  button: {
    paddingVertical: 10,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: s.gray3.color,
  },
  tipBtn: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: s.gray3.color,
    paddingVertical: 10,
    paddingHorizontal: 12,
    marginLeft: 10,
  },
  tipUIbtn: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 24,
    backgroundColor: colors.splx.primary[50],
  },
  disabledDark: {
    backgroundColor: colors.gray4,
  },
  disabledLight: {
    backgroundColor: colors.gray2,
  },
  successBtnColor: {
    backgroundColor: colors.green3,
  },
  successBtnText: {
    color: colors.white,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  disabledTextDark: {
    color: colors.gray6,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  disabledTextLight: {
    color: colors.gray4,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tiptext: {
    color: colors.white,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: '600',
  },
  titleMiddle: {
    flex: 1,
    textAlign: 'center',
    fontSize: 21,
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 15,
    borderBottomWidth: 1,
  },
  titleLeft: {
    width: 80,
  },
  titleRight: {
    width: 80,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
})
