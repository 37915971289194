import {StyleProp, View, ViewStyle, StyleSheet} from 'react-native'
import {ClaimBtn} from './ClaimBtn'
import {useStores} from 'state/index'
import {useNavigation} from '@react-navigation/native'
import {NavigationProp} from 'lib/routes/types'
import {observer} from 'mobx-react-lite'
import {isMobileWeb} from 'platform/detection'
import React from 'react'
import {useAnalytics} from 'lib/analytics/analytics.web'

interface UserRewardProps {
  userId: string
  style?: StyleProp<ViewStyle>
  missionId: string
  creatorHandle?: string
}

function getButtonText(
  hasSession: boolean,
  hasClaimed: boolean,
  shouldClaim: boolean,
  isClaiming: boolean,
  progressPercent?: number,
): string {
  if (!hasSession) {
    return 'Sign In'
  } else if (hasClaimed) {
    return 'Claimed'
  } else if (shouldClaim) {
    return 'Claim'
  } else if (isClaiming) {
    return 'Claiming...'
  } else if (progressPercent !== undefined && progressPercent < 1) {
    return 'Keep Going!'
  } else {
    return 'Get Started'
  }
}

export const RewardClaimButtonNavigate = observer(
  function RewardClaimButtonNavigateImpl({
    userId,
    missionId,
    creatorHandle,
  }: UserRewardProps) {
    const store = useStores()
    const {track} = useAnalytics()
    const navigation = useNavigation<NavigationProp>()
    const mission = store.rewards.mission(missionId)
    const shouldClaim =
      mission?.mechanic === 'weekly'
        ? mission?.shouldClaim &&
          !store.rewards.claimedCollectibles.includes(mission?.reward as string)
        : store.rewards.shouldClaimMission(userId, missionId)
    const hasClaimed =
      mission?.mechanic === 'weekly'
        ? store.rewards.claimedCollectibles.includes(mission?.reward as string)
        : store.rewards.hasClaimedMission(userId, missionId)
    const isClaiming = store.rewards.isClaimingMission(userId, missionId)

    const progress = store.rewards.missionProgress(userId, missionId)

    const text =
      mission &&
      getButtonText(
        store.session.hasSession,
        hasClaimed,
        shouldClaim,
        isClaiming,
        mission.progress?.percent,
      )
    let done = false
    let disabled = false
    let loading = false

    function onClick() {
      if (!store.session.hasSession) {
        navigation.navigate('SignIn')
        return
      }
      if (mission?.mechanic === 'weekly') {
        track('InnerCircle:ClaimCreatorMission', {
          creator: mission.id,
          collector: userId,
        })
      }
      if (shouldClaim && !isClaiming && !hasClaimed) {
        navigation.navigate('Mission', {id: missionId})
        return
      }

      if (
        mission?.mechanic === 'weekly' &&
        mission.progress?.percent !== undefined &&
        mission.progress?.percent < 1 &&
        creatorHandle
      ) {
        navigation.navigate('Profile', {name: creatorHandle})
        return
      }
      navigation.navigate('Home')
    }

    if (shouldClaim || isClaiming || (progress && progress?.percent < 1)) {
      done = hasClaimed
      disabled = isClaiming || done
      loading = !!isClaiming
    }

    return (
      <View>
        <ClaimBtn
          btnstyle={styles.btn}
          text={text}
          done={done}
          disabled={disabled || hasClaimed}
          loading={loading}
          onClick={onClick}
        />
      </View>
    )
  },
)

const styles = StyleSheet.create({
  btn: {
    paddingVertical: isMobileWeb ? 8 : 12,
    paddingHorizontal: isMobileWeb ? 12 : 24,
  },
})
