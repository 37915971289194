import {CommonNavigatorParams, NativeStackScreenProps} from 'lib/routes/types'
import {View} from 'react-native'

import React, {useState} from 'react'
import {CenteredView, ScrollView} from 'view/com/util/Views.web'
import {ViewHeader} from '../com/util/ViewHeader'
import {observer} from 'mobx-react-lite'
import {s} from 'lib/styles'
import {useFocusEffect} from '@react-navigation/native'
import {useStores} from 'state/index'

import {Selector} from 'view/com/util/ViewSelector'
import {clamp} from 'lib/numbers'

import {useAnalytics} from 'lib/analytics/analytics.web'

import {CreatorToolsDashboard} from 'view/com/creatorTools/CreatorToolsDashboard'
import {InsightsDashboard} from 'view/com/creatorTools/InsightsDashboard'
import {CreatorOnboardingScreen} from 'view/com/creatorTools/CreatorOnboardingScreen'
import {InnerCircleTab} from 'view/com/creatorTools/InnerCircleTab'
import {CollectiblesTab} from 'view/com/creatorTools/CollectiblesTab'

const tabs = ['Settings', 'Insights', 'Collectibles', 'Inner Circle']

type Props = NativeStackScreenProps<CommonNavigatorParams, 'CreatorTools'>
export const CreatorTools = observer(function CreatorToolsImpl(_props: Props) {
  const store = useStores()
  const creatorDetails = store.creatorTools.creator
  const {track} = useAnalytics()

  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const onPressSelection = React.useCallback(
    (index: number) => {
      setSelectedIndex(clamp(index, 0, tabs.length))
      if (index === 0) {
        track('CreatoTools:Settings', {})
      } else if (index === 1) {
        track('CreatoTools:Insights', {})
      } else if (index === 2) {
        track('CreatoTools:Collectibles', {})
      } else if (index === 3) {
        track('CreatorTools:InnerCircle', {})
      }
    },

    [setSelectedIndex, track],
  )

  useFocusEffect(
    React.useCallback(() => {
      store.shell.setMinimalShellMode(false)
      store.creatorTools.fetch()
      store.creatorTools.fetchTopMinters()
      store.creatorTools.fetchCollectibles()
    }, [store]),
  )

  return (
    <View style={[s.hContentRegion]}>
      <ScrollView style={[s.hContentRegion]} scrollIndicatorInsets={{right: 1}}>
        <CenteredView style={[s.hContentRegion, {paddingHorizontal: 12}]}>
          <ViewHeader title="Creator Tools" canGoBack={false} />
          {creatorDetails === undefined ? (
            <CreatorOnboardingScreen />
          ) : (
            <>
              <Selector
                items={tabs}
                selectedIndex={selectedIndex}
                onSelect={onPressSelection}
              />
              <View>
                {selectedIndex === 0 && (
                  <CreatorToolsDashboard creator={creatorDetails} />
                )}
                {selectedIndex === 1 && <InsightsDashboard />}
                {selectedIndex === 2 && <CollectiblesTab />}
                {selectedIndex === 3 && <InnerCircleTab />}
              </View>
            </>
          )}
        </CenteredView>
      </ScrollView>
    </View>
  )
})
