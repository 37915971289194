import {usePalette} from 'lib/hooks/usePalette'
import {observer} from 'mobx-react-lite'
import {useStores} from 'state/index'
import React, {useEffect} from 'react'
import {useAnalytics} from 'lib/analytics/analytics.web'
import {TopMintersProfile} from 'state/models/ui/creator-tools'
import {formatWalletAddress} from 'lib/strings/helpers'
import {Text} from 'view/com/util/text/Text'
import * as Toast from 'view/com/util/Toast'
import Clipboard from '@react-native-clipboard/clipboard'
import {Button} from 'view/com/util/forms/Button'
import {StyleSheet, View, FlatList, TouchableOpacity} from 'react-native'
import {colors} from 'lib/styles'
import {ProfileCard} from '../profile/ProfileCard'
import {
  FontAwesomeIcon,
  FontAwesomeIconStyle,
} from '@fortawesome/react-native-fontawesome'
import {DownloadIcon} from 'lib/icons'

function CreatorRetoolDashboard() {
  const pal = usePalette('default')
  const store = useStores()

  const onPressHandler = async () => {
    await store.creatorTools.downloadCSV()
  }

  return (
    <TouchableOpacity accessibilityRole="button" onPress={onPressHandler}>
      <View style={[pal.viewLight, styles.cardContainer]}>
        <Text title="lg-bold" style={[pal.text, {marginRight: 8}]}>
          Download all of your Collector data as a CSV
        </Text>
        <DownloadIcon style={[pal.text]} />
      </View>
    </TouchableOpacity>
  )
}

export const InsightsDashboard = observer(function InsightsDashboardImpl() {
  const pal = usePalette('default')
  const store = useStores()
  const {track} = useAnalytics()

  useEffect(() => {
    store.creatorTools.fetchTopMinters()
  }, [store.creatorTools])

  const renderItem = ({
    item,
    index,
  }: {
    item: TopMintersProfile
    index: number
  }) => {
    store.users.fetchUser(item.profile.did)
    const walletShortString = formatWalletAddress(
      store.users.userInfo[item.profile.did]?.wallet,
    )

    return item.profile ? (
      <View
        style={[
          pal.view,
          pal.border,
          {
            borderWidth: 1,
            width: '100%',
            padding: 5,
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            paddingHorizontal: 12,
          },
        ]}>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'flex-start',
          }}>
          <View
            style={{
              marginTop: 20,
              marginRight: 15,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 12,
              borderRadius: 1000,
              width: 20,
              height: 20,
              backgroundColor: colors.gray2,
            }}>
            <Text type="2xl-bold" style={[{color: colors.splx.primary[50]}]}>
              {index + 1}
            </Text>
          </View>
          <ProfileCard profile={item.profile} noBg={true} noBorder={true} />
        </View>

        <View
          style={{
            paddingTop: 10,
            flexDirection: 'column',
            alignItems: 'center',
            gap: 12,
          }}>
          <Text type="lg" style={[pal.text]}>
            {item.mints}{' '}
            <Text type="lg" style={pal.text}>
              Minted
            </Text>
          </Text>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Text type="md" style={[pal.view, pal.text]}>
              {store.users.userInfo[item.profile.did]?.wallet
                ? walletShortString
                : 'No Wallet Found'}
            </Text>
            <TouchableOpacity
              style={[pal.border, {paddingLeft: 8}]}
              accessibilityRole="button"
              onPress={() => {
                if (store.users.userInfo[item.profile.did]?.wallet) {
                  Clipboard.setString(
                    store.users.userInfo[item.profile.did]?.wallet,
                  )
                  track('CreatoTools:CopyOneAddress', {})
                  Toast.show('Copied to clipboard')
                }
              }}>
              <FontAwesomeIcon
                icon={['far', 'clone']}
                style={pal.text as FontAwesomeIconStyle}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    ) : null
  }

  return (
    <View>
      <View style={{marginTop: 12}}>
        <CreatorRetoolDashboard />
      </View>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        <Text type="title" style={[styles.heading, pal.text]}>
          Your Top Collectors
        </Text>
        <Button
          type="primary-light"
          label="Copy all wallet addresses"
          onPress={() => {
            const walletAddresses = store.creatorTools.topMinters
              .map(item => store.users.userInfo[item.profile.did]?.wallet)
              .filter(Boolean)
              .join(', ')
            Clipboard.setString(walletAddresses)
            track('CreatoTools:CopyAllAddresses', {})
            Toast.show('Copied to clipboard')
          }}
        />
      </View>
      <View style={[pal.viewLight, {padding: 10}]}>
        {store.creatorTools.topMinters.length === 0 ? (
          <Text style={[pal.text]} type="md">
            No Collectors Found
          </Text>
        ) : (
          <FlatList
            data={store.creatorTools.topMinters}
            renderItem={renderItem}
          />
        )}
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  heading: {
    fontWeight: 'bold',
    paddingBottom: 8,
    paddingTop: 16,
    paddingLeft: 10,
  },
  cardContainer: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    borderRadius: 10,
    marginBottom: 20,
  },
})
