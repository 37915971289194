import {usePalette} from 'lib/hooks/usePalette'
import {StyleSheet, View} from 'react-native'

import {Text} from '../util/text/Text'
import React from 'react'
import {Button} from '../util/forms/Button'
import {useNavigation} from '@react-navigation/native'
import {NavigationProp} from 'lib/routes/types'

export function InnerCircleInfo() {
  const pal = usePalette('default')
  const navigation = useNavigation<NavigationProp>()

  return (
    <View style={[pal.viewLight, styles.container]}>
      <Text type="2xl-heavy" style={[pal.text]}>
        ⭕ Inner-Circle Missions ⭕
      </Text>
      <Text type="md" style={[pal.text, {marginTop: 4}]}>
        Inner-Circle members get access to exclusive collectibles from their
        favorite artists!
      </Text>
      <View
        style={{
          flexDirection: 'row',

          justifyContent: 'center',
        }}>
        <Button
          style={{
            width: '40%',
            alignItems: 'center',
            paddingVertical: 10,
            marginTop: 10,
          }}
          label="Join now!"
          type="primary"
          onPress={() => {
            navigation.navigate('MissionsList')
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',

    width: '100%',
    padding: 15,
    marginBottom: 10,

    borderRadius: 10,
  },
})
