import {
  KeyboardAvoidingView,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Linking,
  // useWindowDimensions,
} from 'react-native'
import {Image} from 'expo-image'
import LinearGradient from 'react-native-linear-gradient'
import {usePalette} from 'lib/hooks/usePalette'
import {useStores} from 'state/index'
import {useTheme} from 'lib/ThemeContext'

import {observer} from 'mobx-react-lite'
import React, {useCallback, useState} from 'react'
import {Text} from '../util/text/Text'
import {isAndroid, isDesktopWeb} from 'platform/detection'
import {colors, gradients, s} from 'lib/styles'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {NFTGalleryModel} from 'state/models/media/nft-gallery'
import {Button} from '../util/forms/Button'
import * as Toast from '../util/Toast'
import {creatorsGuideLink, MAX_ALT_TEXT, MAX_NFT_NAME} from 'lib/constants'
import {enforceLen} from 'lib/strings/helpers'

import {ResizeMode, Video} from 'expo-av'
import {track} from 'lib/analytics/analytics'
import {UploadIcon} from 'lib/icons'

type Props = {
  gallery: NFTGalleryModel
}

export const Component = observer(function ComponentImpl({gallery}: Props) {
  const pal = usePalette('default')
  const [isPicked, setIsPicked] = useState<boolean>(false)
  const [traits, setTraits] = useState<string>('')
  const store = useStores()
  const theme = useTheme()
  // const windim = useWindowDimensions()
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onCreateInnerCircleMissionReward = async () => {
    setIsPicked(false)
    await gallery.pickAny()
    setIsPicked(true)
  }

  const onPressSave = useCallback(async () => {
    setIsLoading(true)
    setError(null)
    try {
      const {status, res, createError} = await gallery.createInnerCircleReward()

      if (status === 'success') {
        gallery.setMintId(res.cid)
        gallery.setUri(res.uri)
        gallery.setSupply(1000)
        track('NFT:NFTEmbedAdd', {
          poster: store.session.currentSession?.handle,
          title: gallery.name,
          description: gallery.description,
          supply: gallery.supply,
          price: gallery.uiPrice,
          isPaid: gallery.isTypePaid,
        })
      }
      if (status === 'error') {
        setError(createError ?? 'Something went wrong')
      }

      // store.shell.closeModal()
    } catch (err) {
      console.log('error', err)
      setError('Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }, [gallery, store.session.currentSession?.handle])

  // console.log('isPicked', isPicked)
  // console.log('gallery', gallery.images)
  // console.log('gallery', gallery.innerCircleReward)
  // console.log('gallery Image', gallery.isInnerCircleRewardImage)

  // const imageStyles = useMemo<ImageStyle>(() => {
  //   const maxWidth = isDesktopWeb ? 450 : windim.width
  //   if (gallery.images[0].height > gallery.images[0].width) {
  //     return {
  //       resizeMode: 'contain',
  //       width: '100%',
  //       aspectRatio: 1,
  //       borderRadius: 8,
  //     }
  //   }
  //   return {
  //     width: '100%',
  //     height: (maxWidth / gallery.images[0].width) * gallery.images[0].height,
  //     borderRadius: 8,
  //   }
  // }, [gallery.images, windim])

  const onPressCancel = () => {
    store.shell.closeModal()
  }
  return (
    <KeyboardAvoidingView
      behavior={isAndroid ? 'height' : 'padding'}
      style={[pal.view, styles.container]}>
      {error !== null && (
        <View style={styles.statusLine}>
          <View style={styles.errorIcon}>
            <FontAwesomeIcon
              icon="exclamation"
              style={{color: colors.red4}}
              size={10}
            />
          </View>
          <Text style={[s.red4, s.flex1]}>{error}</Text>
        </View>
      )}
      {isLoading && (
        <View style={[styles.statusLine, {backgroundColor: colors.green1}]}>
          <Text style={[s.green4, s.flex1]}>
            {gallery.mintId
              ? 'Reward Created!'
              : isLoading
              ? 'Creating Inner-Circle Reward...'
              : null}
          </Text>
        </View>
      )}
      <ScrollView
        testID="altTextImageModal"
        style={styles.scrollContainer}
        keyboardShouldPersistTaps="always"
        nativeID="imageAltText">
        <View style={styles.scrollInner}>
          <View style={[pal.view, styles.imageContainer, pal.borderDark]}>
            {!gallery.isRewardUploaded() && (
              <View
                style={[
                  {
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: 10,
                  },
                ]}>
                <View
                  style={[
                    {borderRadius: 100, padding: 10, marginBottom: 8},
                    pal.viewLight,
                  ]}>
                  <UploadIcon style={pal.text} />
                </View>
                <Button
                  label="Upload Image or Video"
                  onPress={onCreateInnerCircleMissionReward}
                />
              </View>
            )}
            {isPicked &&
            gallery.isInnerCircleRewardImage() &&
            gallery.innerCircleReward !== undefined ? (
              <Image
                testID="selectedPhotoImage"
                style={{width: '100%', height: 350, borderRadius: 8}}
                contentFit="cover"
                source={{
                  uri: gallery.innerCircleReward.path ?? gallery.images[0].path,
                }}
                accessible={true}
                accessibilityIgnoresInvertColors
              />
            ) : isPicked &&
              gallery.isInnerCircleRewardVideo() &&
              gallery.innerCircleReward !== undefined ? (
              <Video
                source={{uri: gallery.innerCircleReward.path}}
                useNativeControls
                style={[{width: '100%', height: 300}]}
                videoStyle={[
                  {
                    width: '100%',
                    height: 300,
                  },
                ]}
                resizeMode={ResizeMode.CONTAIN}
              />
            ) : null}
          </View>
          <View style={[pal.border, {borderTopWidth: 1, paddingTop: 18}]}>
            <View
              style={{
                flex: 1,
                paddingRight: 8,
                flexDirection: 'row',
                width: '100%',
                alignItems: 'baseline',
                justifyContent: 'space-between',
                paddingBottom: 10,
              }}>
              {gallery.isInnerCircleRewardVideo() ? (
                <Button
                  style={{marginRight: 8}}
                  type="primary"
                  isdisabled={gallery.images.length > 0}
                  label={
                    gallery.images.length > 0
                      ? 'Cover Photo Added'
                      : 'Add Cover Photo'
                  }
                  onPress={async () => {
                    await gallery.pick()
                  }}
                />
              ) : (
                <View />
              )}
              <TouchableOpacity
                accessibilityRole="button"
                onPress={() => Linking.openURL(creatorsGuideLink)}>
                <Text type="md-heavy" style={[pal.text, s.underline]}>
                  Get Help
                </Text>
              </TouchableOpacity>
            </View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Text type="lg" style={[pal.text, {paddingBottom: 2}]}>
                Name
              </Text>
              {gallery.name.length >= 32 && (
                <Text type="md" style={[pal.textLight, {paddingBottom: 2}]}>
                  Name should be less than 36 characters
                </Text>
              )}
            </View>
            <TextInput
              testID="altTextImageInput"
              style={[styles.nftName, pal.border, pal.text]}
              keyboardAppearance={theme.colorScheme}
              placeholder="eg. Genesis #1"
              placeholderTextColor={pal.colors.textLight}
              value={gallery.name}
              onChangeText={text =>
                gallery.setName(enforceLen(text, MAX_NFT_NAME))
              }
              accessibilityLabel="Nft name"
              accessibilityHint=""
              accessibilityLabelledBy="nftName"
              autoFocus
            />
          </View>
          <View>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <Text type="lg" style={[pal.text, {paddingBottom: 2}]}>
                Description
              </Text>
              {gallery.description.length >= MAX_ALT_TEXT && (
                <Text type="md" style={[pal.textLight, {paddingBottom: 2}]}>
                  Description should be less than {MAX_ALT_TEXT} characters
                </Text>
              )}
            </View>

            <TextInput
              testID="altTextImageInput"
              style={[styles.textArea, pal.border, pal.text]}
              keyboardAppearance={theme.colorScheme}
              multiline
              placeholder="eg. A unique piece of art"
              placeholderTextColor={pal.colors.textLight}
              value={gallery.description}
              onChangeText={text =>
                gallery.setDescription(enforceLen(text, MAX_ALT_TEXT))
              }
              accessibilityLabel="NFT description"
              accessibilityHint=""
              accessibilityLabelledBy="nftDescription"
            />
          </View>

          <View style={{paddingBottom: 12}}>
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}>
              <Text type="lg" style={[pal.text, {paddingBottom: 2}]}>
                Traits
              </Text>
              <Text
                type="md"
                style={[pal.textLight, {paddingBottom: 2, paddingLeft: 2}]}>
                (eg. rarity:common)
              </Text>
            </View>
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <TextInput
                style={[styles.nftName, pal.border, pal.text, {width: '60%'}]}
                accessibilityLabel="Text input field"
                accessibilityHint=""
                accessibilityLabelledBy="nftTraits"
                value={traits}
                onChangeText={text => {
                  setTraits(text)
                }}
              />
              <Button
                style={{marginLeft: 8}}
                type="default-light"
                label="Add"
                onPress={() => {
                  const regex = /\w+:\w+/
                  if (regex.test(traits)) {
                    const trait = traits.split(':')

                    gallery.setTrait(trait[0], trait[1])
                  } else {
                    // Handle the error here
                    Toast.show(
                      'Invalid format. Please use format: trait_type:trait_value',
                    )
                  }
                }}
              />
            </View>
            {Object.keys(gallery.attributes).length > 0 && (
              <View
                style={[
                  pal.viewLight,
                  {padding: 8, marginTop: 12, borderRadius: 5},
                ]}>
                <View style={{paddingTop: 12}}>
                  <Text type="title" style={[pal.text]}>
                    Traits
                  </Text>
                  {Object.keys(gallery.attributes).map((trait, index) => (
                    <View
                      key={index}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        paddingVertical: 4,
                      }}>
                      <Text type="2xl-bold" style={[pal.text]}>
                        {trait}:
                      </Text>
                      <Text type="2xl" style={[pal.text, {paddingLeft: 4}]}>
                        {gallery.attributes[trait]}
                      </Text>
                      <Button
                        style={{marginLeft: 8}}
                        type="default-light"
                        label="Remove"
                        onPress={() => {
                          gallery.removeTrait(trait)
                        }}
                      />
                    </View>
                  ))}
                </View>
              </View>
            )}
          </View>

          <View style={styles.buttonControls}>
            {gallery.mintId ? (
              <TouchableOpacity
                testID="altTextImageSaveBtn"
                onPress={onPressCancel}
                disabled={
                  !gallery.name ||
                  !gallery.description ||
                  isLoading ||
                  gallery.supply === 0 ||
                  gallery.innerCircleReward === undefined ||
                  (gallery.isTypePaid && gallery.uiPrice === 0)
                }
                accessibilityLabel="Save nft metadata"
                accessibilityHint={`Saves nft metadata, which reads: ${gallery.name} ${gallery.description}`}
                accessibilityRole="button">
                <LinearGradient
                  colors={[gradients.green.start, gradients.green.end]}
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 1}}
                  style={[styles.button]}>
                  <Text type="button-lg" style={[s.white, s.bold]}>
                    Reward Created
                  </Text>
                </LinearGradient>
              </TouchableOpacity>
            ) : (
              <TouchableOpacity
                testID="altTextImageSaveBtn"
                onPress={onPressSave}
                disabled={
                  !gallery.name ||
                  !gallery.description ||
                  isLoading ||
                  gallery.innerCircleReward === undefined
                }
                accessibilityLabel="Save nft metadata"
                accessibilityHint={`Saves nft metadata, which reads: ${gallery.name} ${gallery.description}`}
                accessibilityRole="button">
                <LinearGradient
                  colors={
                    !gallery.name || !gallery.description || isLoading
                      ? [gradients.gray.start, gradients.gray.end]
                      : [gradients.purple.start, gradients.purple.end]
                  }
                  start={{x: 0, y: 0}}
                  end={{x: 1, y: 1}}
                  style={[styles.button]}>
                  {isLoading ? (
                    <ActivityIndicator />
                  ) : (
                    <Text type="button-lg" style={[s.white, s.bold]}>
                      Create Reward
                    </Text>
                  )}
                </LinearGradient>
              </TouchableOpacity>
            )}

            {gallery.mintId === '' && (
              <TouchableOpacity
                testID="altTextImageCancelBtn"
                onPress={() => gallery.clearInnerCircleReward()}
                disabled={isLoading}
                accessibilityRole="button"
                accessibilityLabel="Cancel add image alt text"
                accessibilityHint=""
                // onAccessibilityEscape={onPressCancel}
              >
                <View style={[styles.button]}>
                  <Text type="button-lg" style={[pal.textLight]}>
                    Cancel
                  </Text>
                </View>
              </TouchableOpacity>
            )}
          </View>
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  )
})

const styles = StyleSheet.create({
  toggleContainer: {
    paddingHorizontal: 6,
    marginBottom: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  container: {
    flex: 1,
    height: '100%',
    width: '100%',
    paddingVertical: isDesktopWeb ? 0 : 18,
  },
  scrollContainer: {
    flex: 1,
    height: '100%',
    paddingHorizontal: isDesktopWeb ? 0 : 12,
  },
  scrollInner: {
    gap: 12,
  },
  imageContainer: {
    borderRadius: 8,
    marginBottom: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    maxHeight: 350,
  },
  textArea: {
    borderWidth: 1,
    borderRadius: 6,
    paddingTop: 10,
    paddingHorizontal: 12,
    fontSize: 16,
    height: 80,
    textAlignVertical: 'top',
  },
  nftName: {
    borderWidth: 1,
    borderRadius: 6,
    paddingHorizontal: 12,
    fontSize: 16,
    height: 36,
    textAlignVertical: 'top',
  },
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: 32,
    padding: 10,
  },
  buttonControls: {
    gap: 8,
  },
  statusLine: {
    flexDirection: 'row',
    backgroundColor: colors.red1,
    borderRadius: 6,
    marginHorizontal: 15,
    paddingHorizontal: 8,
    paddingVertical: 6,
    marginVertical: 6,
  },
  errorIcon: {
    borderWidth: 1,
    borderColor: colors.red4,
    color: colors.red4,
    borderRadius: 30,
    width: 16,
    height: 16,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 5,
  },
})
