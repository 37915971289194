import {usePalette} from 'lib/hooks/usePalette'
import React from 'react'
import {Linking, StyleSheet, View} from 'react-native'
import {Text} from '../util/text/Text'
import {Image} from 'expo-image'
import {NFTBuySuccessModal} from 'state/models/ui/shell'
import {Button} from '../util/forms/Button'
import {useSplxWallet} from '../wallet/useSplxWallet'
import {walletFormatted} from 'lib/functions'
import {isDesktopWeb, isMobileWeb} from 'platform/detection'
import {useStores} from 'state/index'
import {colors} from 'lib/styles'

import {sanitizeUrl} from '@braintree/sanitize-url'
import {track} from 'lib/analytics/analytics'

import {useNavigation} from '@react-navigation/native'
import {NavigationProp} from 'lib/routes/types'
export function Component({
  imageSrc,
  onShare,
  twitterIntentLink,
  creatorDid,
}: NFTBuySuccessModal) {
  const pal = usePalette('default')
  const store = useStores()
  const [
    _visible,
    _setVisible,
    _linkedWallet,
    connectedWallet,
    _connectWalletIsBusy,
    _disconnectWalletIsBusy,
    _disconnectWallet,
  ] = useSplxWallet(true)
  const navigation = useNavigation<NavigationProp>()

  return (
    <View style={[pal.view, styles.container]}>
      <Text type="2xl-bold" style={[{paddingBottom: 12}, pal.text]}>
        Collectible Purchased!
      </Text>
      <Text
        numberOfLines={2}
        type="lg-medium"
        style={[pal.text, {textAlign: 'center'}]}>
        Congratulations! You have successfully purchased this collectible
      </Text>

      <View
        style={{
          width: '100%',
          overflow: 'hidden',
          paddingTop: 20,
          paddingBottom: 20,
        }}>
        <Image
          style={{width: '100%', height: 300}}
          source={{
            uri: imageSrc,
          }}
          accessibilityIgnoresInvertColors
        />
      </View>
      <Text type="md-medium" style={[pal.text, {textAlign: 'center'}]}>
        Your collectible will be available in this wallet in a few moments.
      </Text>
      <Text
        type="md-bold"
        style={[
          pal.text,
          pal.viewLight,
          {
            marginTop: isMobileWeb ? 10 : 20,
            textAlign: 'center',
            paddingVertical: 10,
            paddingHorizontal: 14,
            overflow: 'hidden',
            marginBottom: isMobileWeb ? 5 : 0,
          },
        ]}>
        {connectedWallet
          ? isMobileWeb
            ? walletFormatted(connectedWallet)
            : connectedWallet
          : 'Wallet not found'}{' '}
      </Text>
      <View style={styles.shareContainer}>
        <Button
          style={styles.shareButtonTwitter}
          labelStyle={{
            fontWeight: '700',
            flex: 1,
            textAlign: 'center',

            color: colors.white,
          }}
          label="Share on Twitter"
          type="primary"
          onPress={() => {
            track('NFT:NFTShareTwitter', {
              twitterIntentLink: twitterIntentLink,
              buyer: store.session.currentSession?.handle,
            })
            Linking.openURL(sanitizeUrl(twitterIntentLink))
          }}
        />
        {store.rewards.isCreatorMissionReadyToClaimForCreator(creatorDid) ? (
          <Button
            style={styles.innerCircleBtn}
            labelStyle={{
              fontWeight: '700',
              fontSize: 12,
              flex: 1,
              textAlign: 'center',

              color: colors.splx.primary[60],
            }}
            label="reward available!"
            type="primary"
            onPress={() => {
              navigation.navigate('MissionsList')
              store.shell.closeModal()
            }}
          />
        ) : (
          <Button
            style={styles.shareButtonSolarplex}
            labelStyle={{
              fontWeight: '700',
              fontSize: 12,
              flex: 1,
              textAlign: 'center',

              color: colors.white,
            }}
            label="Share on Solarplex"
            type="primary"
            onPress={() => {
              onShare()
              store.shell.closeModal()
            }}
          />
        )}

        <Button
          style={styles.shareButtonSolarplex}
          labelStyle={{
            fontWeight: '700',
            fontSize: 12,
            flex: 1,
            paddingVertical: 2,
            textAlign: 'center',
          }}
          label="Close"
          type="default"
          onPress={() => {
            store.shell.closeModal()
          }}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: isDesktopWeb ? 0 : 18,
    paddingHorizontal: isDesktopWeb ? 0 : 18,
  },
  shareContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: isMobileWeb ? 10 : 25,
    gap: isMobileWeb ? 10 : 20,
  },
  shareButtonSolarplex: {
    width: '40%',
    justifyContent: 'center',
    fontSize: 12,
  },
  innerCircleBtn: {
    width: '40%',
    justifyContent: 'center',
    fontSize: 12,
    backgroundColor: colors.splx.accent.canary[30],
    color: colors.splx.primary[60],
  },
  shareButtonTwitter: {
    marginTop: isMobileWeb ? 10 : 20,
    fontSize: 12,

    width: isMobileWeb ? '80%' : '40%',
    justifyContent: 'center',
    backgroundColor: colors.blue2,
  },
})
