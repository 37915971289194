import React from 'react'
import {View} from 'react-native'
import {StyleSheet} from 'react-native'

import {Image} from 'react-native'
import {
  NativeDropdown,
  DropdownItem as NativeDropdownItem,
} from './NativeDropdown'
import {EventStopper} from '../EventStopper'
import {usePalette} from 'lib/hooks/usePalette'
import {colors} from 'lib/styles'
import {useStores} from 'state/index'
import {ChannelDropDownIcon, CommunitiesIcon} from 'lib/icons'
import {useAnalytics} from 'lib/analytics/analytics.web'

type ChannelDropDownProps = {
  currentHashtag: string
  setChannel: (channeltag: string) => void
}

export function ChannelDropDown({
  setChannel,
  currentHashtag,
}: ChannelDropDownProps) {
  const store = useStores()
  // const theme = useTheme()
  const {track} = useAnalytics()
  const pal = usePalette('default')
  // const defaultCtrlColor = theme.palette.default.postCtrl
  const joinedChannels = store.me.followedChannels.channels
  const channelFeedItems = store.channels.channelFeeds

  const userChannelFeedItems = channelFeedItems.filter(item => {
    if (item.data?.id) {
      return joinedChannels.includes(item.data?.id)
    }
    return false
  })

  const dropdownItems: NativeDropdownItem[] = userChannelFeedItems
    .filter(item => item.data?.hashtag !== null && item.data?.image !== null)
    .map(item => {
      return {
        image: item.data?.image,
        label: item.data?.name,
        onPress() {
          if (item.data?.hashtag) {
            track('ChannelDropDown:ChannelSelected', {
              channelName: item.data?.name,
            })

            setChannel(item.data?.hashtag)
          }
        },
      }
    })
    .filter(Boolean) as NativeDropdownItem[]

  const currentItem = userChannelFeedItems.find(
    item => item.data?.hashtag === currentHashtag,
  )

  return (
    <EventStopper>
      <NativeDropdown
        testID={'ChannelDropdownBtn'}
        items={dropdownItems}
        accessibilityLabel="More post options"
        accessibilityHint="">
        <View style={{}}>
          <View style={[pal.border, styles.channelDropdown]}>
            {currentItem !== undefined ? (
              <Image
                accessibilityIgnoresInvertColors
                source={{uri: currentItem.data?.image}}
                style={{width: 24, height: 24, borderRadius: 100}}
              />
            ) : (
              <CommunitiesIcon fill={colors.splx.primary[50]} />
            )}
            <ChannelDropDownIcon
              style={[{paddingLeft: 12, color: colors.splx.primary[50]}]}
            />
          </View>
        </View>
      </NativeDropdown>
    </EventStopper>
  )
}

const styles = StyleSheet.create({
  channelDropdown: {
    borderRadius: 100,
    paddingHorizontal: 8,
    paddingVertical: 2,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderColor: '#B0A6D4',
    borderWidth: 1,
    backgroundColor: colors.splx.primary[20],
  },
})
